import React, { useEffect } from "react";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import moment from "moment";

function ResidentAgreement() {
  const location = useLocation();
  const { inspectionData } = location.state || {};
  const { companyname } = location.state || {};
  console.log(inspectionData);
  console.log(companyname);
  useEffect(() => {
    const generatePDF = () => {
      const doc = new jsPDF();
      doc.setFontSize(12);
      let contentAdded = false;
      let leasestartedate = inspectionData.LEASESTARTDATE;
      let leaseenddate = inspectionData.LEASEENDDATE;
      let project_name = inspectionData.PROJECTNAME;
      let rentamount = inspectionData.RENTAMOUNT;
      let companyNameS = companyname;
      // Define header content
      let startDate = moment(leasestartedate);
      let endDate = moment(leaseenddate);

      // Swap dates if endDate is before startDate
      if (endDate.isBefore(startDate)) {
        [startDate, endDate] = [endDate, startDate];
      }

      let diffInMonths = endDate.diff(startDate, "months");

      console.log(diffInMonths);
      const header = () => {
        const originalFontSize = doc.internal.getFontSize();
        // `${companyNameS}`
        doc.setFontSize(10);
        doc.setTextColor(0);

        // // Load a font that supports italic style (e.g., Times New Roman italic)
        doc.setFont("times", "italic");

        // // Add the text with italic style
        doc.text(`${project_name}`, 10, 10);

        doc.setFontSize(originalFontSize); // Restore original font size
        // Reset font to the default (optional)
        doc.setFont("helvetica", "normal");
      };

      // Define footer content
      const footer = (pageNum, numPages) => {
        doc.setFontSize(10);
        const companyName = `${companyNameS}`; // Retrieve this from the database
        const textWidth = doc.getStringUnitWidth(`Managed by: ${companyName}`);
        const xPosition = doc.internal.pageSize.width - 60 - textWidth;
        const yPosition1 = doc.internal.pageSize.height - 10;

        doc.text(`Managed by: ${companyName}`, xPosition, yPosition1);

        const textWidth2 = doc.getStringUnitWidth("- from Database");
        const yPosition2 = yPosition1 + 5;
        // doc.text("- from Database", xPosition, yPosition2);
      };

      // Function to generate PDF for each section
      const generateSectionPDF = (contentLines) => {
        if (contentLines.length > 0) {
          if (!contentAdded) {
            contentAdded = true;
          } else {
            doc.addPage(); // Add a new page if content exists and content has been added previously
          }
          header(); // Add header to new page
          const wrapWidth = 550;
          let yPosition = 20;

          contentLines.forEach((line) => {
            const lines = splitTextIntoLines(line, wrapWidth, doc);
            lines.forEach((line) => {
              doc.text(10, yPosition, line);
              yPosition += 6; // Reduced vertical space between lines
            });
            yPosition += 2; // Reduced space after each point
            if (yPosition > doc.internal.pageSize.height - 10) {
              doc.addPage(); // Add a new page if content exceeds page height
              yPosition = 10; // Reset y position for new page
              header(); // Add header to new page
            }
          });
        }
      };

      const generateSectionPDFtable = (contentLines) => {
        if (contentLines.length > 0) {
          doc.addPage(); // Add a new page if content exists
          header(); // Add header to new page
          const cellPadding = 2; // Padding between cell content and borders
          const cellWidth = 50; // Width of each cell
          const cellHeight = 10; // Height of each cell
          const tableX = 10; // X-coordinate for the top-left corner of the table
          let yPosition = 20; // Initial Y-coordinate for the top-left corner of the table

          // Iterate over each row in the maintenance table
          contentLines.forEach((row, rowIndex) => {
            // Iterate over each cell in the row
            row.forEach((cell, cellIndex) => {
              // Calculate the X-coordinate for the top-left corner of the current cell
              const x = tableX + cellIndex * cellWidth;
              // Calculate the Y-coordinate for the top-left corner of the current cell
              const y = yPosition + rowIndex * cellHeight;
              // Draw the cell border
              doc.rect(x, y, cellWidth, cellHeight);
              // Add cell content with padding
              doc.text(cellPadding + x, cellPadding + y + 3, cell);
            });
          });

          // Adjust Y-coordinate for next content
          yPosition += contentLines.length * cellHeight + 5;

          // Check if new page is needed
          if (yPosition > doc.internal.pageSize.height - 10) {
            doc.addPage(); // Add a new page if content exceeds page height
            yPosition = 10; // Reset Y-coordinate for new page
            header(); // Add header to new page
          }
        }
        generateSectionPDFs(fireProcedure);
      };
      const generateSectionPDFs = (contentLines) => {
        if (contentLines.length > 0) {
          if (!contentAdded) {
            contentAdded = true;
          }
          header(); // Add header to new page
          const wrapWidth = 550;
          let yPosition = 20;

          contentLines.forEach((line) => {
            const lines = splitTextIntoLines(line, wrapWidth, doc);
            lines.forEach((line) => {
              doc.text(10, yPosition, line);
              yPosition += 6; // Reduced vertical space between lines
            });
            yPosition += 2; // Reduced space after each point
            if (yPosition > doc.internal.pageSize.height - 10) {
              doc.addPage(); // Add a new page if content exceeds page height
              yPosition = 10; // Reset y position for new page
              header(); // Add header to new page
            }
          });
        }
      };
      // Function to split text into lines that fit within the wrap width
      const splitTextIntoLines = (text, wrapWidth, doc) => {
        const words = text.split(" ");
        const lines = [];
        let currentLine = "";

        words.forEach((word) => {
          const width =
            doc.getStringUnitWidth(currentLine + word) *
            doc.internal.getFontSize();
          if (width < wrapWidth) {
            currentLine += (currentLine === "" ? "" : " ") + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        });

        lines.push(currentLine); // Push the last line
        return lines;
      };

      var termStart = leasestartedate
        ? moment(leasestartedate).format("DD/MM/YYYY")
        : "N/A";

      var rent = rentamount;
      //   var diff = diffInMonths;
      const text = "Resident Agreement";
      const fontSize = 12; // Adjust the font size as needed
      const textWidth = doc.getStringUnitWidth(text) * fontSize;
      const pageWidth = doc.internal.pageSize.width;

      // Calculate text position to center it horizontally
      const textX = (pageWidth - textWidth) / 2;
      var firstPartLines = [
        " ",
        "                                            Resident Agreement",
        "1. This agreement ('Agreement') is made and entered into on the date of acceptance of this contract by the Resident ('Resident'), and by the Owner/Operator of the student hostel ('Owner/Operator').",
        "",
        "2. Premises. The Owner/Operator in consideration of the rent, hereby leases to the Resident the right to occupy a room in the student hostel located at Muhoho Avenue, South c, Nairobi (the 'Premises').",
        "",
        "3. Term. The term of this Agreement shall begin on " +
          termStart +
          " and shall be on a fixed period of " +
          diffInMonths +
          " (months/years) and continue thereafter on a monthly basis, with a minimum stay requirement of three months, unless terminated by either party.",
        "",
        "4. A deposit equal to one month's rent is due at the time of registration.",
        "",
        "5. Rent. The Resident shall pay rent to the Owner/Operator in the amount of " +
          rent +
          "  Ksh per month. Rent shall be due on the 5th of each month and shall be considered late if not received by the Owner/Operator by this date. A late fee of 3% of total rent shall be charged per day for any rent received after the 5th of every month.",
        "",
        "6. Utilities. The Resident shall be responsible for paying their own utility bills (electricity) unless otherwise specified in the lease.",
        "",
        "7. Quiet hours. The Resident shall respect the rights of other residents by observing quiet hours between the hours of 10 PM - 6 AM.",
        "",
        "8. Guests. The Resident shall be allowed to have guests visit, but the Resident is responsible for their guests and must register them with the hostel management.",
        "",
        "9. Damages. The Resident shall be responsible for any damages caused to the Premises or the common areas of the student hostel by the Resident, the Resident's guests, or the Resident's invitees. Such damages will be assessed through an inspection at the conclusion of the stay, and any applicable costs will be deducted from the Resident's deposit.",
      ];
      var complianceAndTerminationLines = [
        "10. Compliance with Laws and Regulations. The Resident acknowledges and agrees that they shall comply with all covenants of the tenancy agreement, whether explicitly set out in the document or implied by law or practice or conveyed through electronic communication, including but not limited to interpretations of the tenancy agreement and relevant clauses that are provided via email. The resident shall comply with applicable laws, ordinances, and hostel regulations as outlined via email and in the handbook, and that failure to comply with any such covenant shall constitute a breach of the tenancy agreement.",
        "",
        "11. Termination. This Agreement may be terminated by the owner/Operator upon written notice to the Resident for any reason, including but not limited to material breach of this Agreement such as non-payment of rent. The Resident may terminate this agreement by providing written notice to the Owner at least one (1) month prior to the desired date of termination. If the Resident fails to provide such written notice, the Owner may retain the deposit as liquidated damages for the Owner's loss of rent and other costs associated with the early termination of this agreement. This provision shall also apply to residents who violate the minimum stay requirement of three (3) months.",
        "",
        "12. Governing Law. This Agreement shall be governed by and construed in accordance with the laws of Kenya.",
        "",
        "13. Entire Agreement. This Agreement constitutes the entire agreement between the parties hereto and supersedes all prior agreements and understandings, whether oral or written.",
        "",
        "14. Waiver. The failure of either party to enforce any provision of this Agreement shall not be deemed a waiver of the right to enforce such provision in the future.",
        "",
        "15. Severability. If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck, and the remaining provisions shall be enforced.",
        "IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above written.",
        "",
        "Resident's Signature ______________________________ Date ________________",
        "",
        "Owner/Operator's Signature ________________________ Date ________________",
      ];

      var handbookLines = [
        "",
        "Homescope Hostels Resident Handbook",
        "Welcome to Homescope Hostels! We are excited to have you as a resident and hope that your stay here is both comfortable and enjoyable. This manual has been created to provide you with important information about our hostel's policies and procedures, as well as to answer any questions you may have about your stay. We encourage you to read through it carefully and to contact us if you have any further questions or concerns. Thanks for choosing our hostel!",
        "",
        "Visitors Protocol:",
        "",
        "The following guidelines are prescribed for visitors:",
        "Visiting hours:",
        " - Monday through Sunday: 8 AM to 9 PM",
        "",
        "Visitors arriving outside these times will not be given entry to the building.",
        "",
        "Visitor Rules:",
        " - Residents must fill and submit a visitor request form prior to their guests arriving.",
        " - Residents may have a maximum of 2 visitors at any one time.",
        " - All visitors must show appropriate identification and failure to provide this will result in them being asked to leave the building.",
        " - It is the responsibility of the resident to arrange to collect their visitor from outside the hostel prior to sign-in.",
        " - Both the resident and their visitor must be present for the visitor's arrival/departure to sign the visitor's book. Visitors will not be allowed to sign in/out alone.",
        " - Any visitor who refuses to leave the premises when requested to do so by staff will be banned from visiting the building.",
        " - Please note that staff reserves the right to refuse visitors' entry at any time.",
        " - Residents must accompany their guests at all times.",
        " - Visitors will not be allowed to stay in the hostel overnight.",
        "Male visitors are only allowed in the reception's lounge.",
        "Residents are responsible for their visitor's full compliance of hostel rules and regulations. Any breach of the hostel rules and regulations by the visitor shall be deemed to be a breach by the resident.",
        "All residents are advised to extend their fullest cooperation to ensure that no unauthorized person enters or stays in the hostel premises. If you see a person you cannot identify, in the interest of the hostel and your own safety, please bring this matter to the notice of staff immediately.",
      ];

      //   const text = "Homescope Hostels Resident Handbook";

      const hostelRules = [
        "Hostel Rules:",
        "",
        "These rules shall apply to all residents residing in Homescope hostels. Violation of any rule will make the resident liable to disciplinary action which may include a warning letter, fine, or dismissal from the hostel.",
        "",
        "As a resident, you must not:",
        " - Take part in any criminal, political, ethnic and/or other undesirable activity.",
        " - Write slogans or create any drawings on the hostel walls or in the rooms.",
        " - Possess or display lethal weapons including firearms, sticks, rods, chains, etc. in the hostel rooms or anywhere within the premises.",
        " - Possess, consume, store or supply liquor or any sort of intoxicant or any contraband item or prohibited medicine/drug without a doctor's prescription.",
        " - Break open or try to break open occupied/vacant rooms in the hostels.",
        " - Vandalise the hostel including windows or furniture within the rooms.",
        " - Keep a pet of any kind including fish, animals and birds.",
        " - Feed stray dogs or cats in the hostel premises.",
        " - Sleep anywhere other than your own room.",
        " - Bring gas cookers and any flammable materials such as candles, incense, or flammable liquids and fluids.",
        " - Smoke on the premises.",
        "",
        "",
        "The Rights of Hostel Management Access to your room:",
        "- We may need to enter your room without your permission under the following circumstances:",
        "  • to carry out a health & safety check",
        "  • to carry out maintenance work",
        "  • to carry out routine inspection of room condition",
        "  • the police provide a warrant to search your room",
        "  • we believe you or someone else is at risk",
        "  • we suspect you may be involved in a criminal act e.g. using/selling drugs or in violation of hostel rules",
        "  • we believe you have abandoned your room i.e. being absent from your room for a long period without prior information or any valid reason.",
        "",
      ];
      const movingInAndOut = [
        "Upon moving in:",
        "Immediately upon taking possession of the Room the Tenant must check that the room and the supplied Room Items correspond to the Inventory move-in form and must inform the Management Company of any discrepancy or damage within 24 hours of taking possession of the Room. Failure to do so will mean that the Tenant accepts that the Inventory is correct and that the Room Items are in good condition.",
        "",
        "Upon moving out:",
        "The hostel's management will carry out a check-out inspection. It will be performed by the site's management team. The student must attend and sign a copy of the move-out form.",
        "If the Tenant fails to attend the check-out inspection, the Tenant shall not challenge the inspection and any charges levied for damage and disposal of refuse and abandoned items.",
        "",
        "Payment of Dues:",
        " • The hostel rent must be paid in advance.",
        " • A deposit equal to one month's rent is payable at the time of registration.",
        " • The hostel rent does not include meals, electricity charges, and transportation unless otherwise stated.",
        " • Residents leaving the hostel for vacation should clear all their dues before they leave.",
        " • The deposit minus the cost of any damages is refunded on termination of residence.",
        " • In case the resident vacates the hostel before the committed date, the rent will not be refunded.",
        " • Due to inflation, the hostel management reserves the right to review the hostel rent.",
        "",
        "Methods of Payment:",
        "All charges must be paid via MPESA. An invoice will be sent which includes An MPESA Paybill number and your own unique account number. Payment by credit card or ATM card is not acceptable.",
        "",
        "Maintenance and Repairs:",
        "If you have any repairs that need to be done, please report this to the front desk by filling out the maintenance request form, a member of staff will arrange for the work to be carried out. The maintenance forms are found in the rooms but can also be requested from the front desk.",
        "The length of time the repairs take will depend on how urgent the repair is:",
      ];
      const maintenanceTable = [
        ["Type of Repair", "Response Time", "Examples"],

        ["Emergency repairs", "24 hours", "Electrical issues"],

        ["Urgent repairs", "7 days", "Blocked sinks or toilets"],
      ];

      const fireProcedure = [
        "",
        " ",
        "",
        " ",
        " ",
        "FIRE PROCEDURE",
        "",
        "If you discover a fire:",
        "- Activate the alarm at the nearest break-glass point",
        "- Do not try to deal with the fire yourself - Exit the building immediately",
        "",
        "On hearing the fire alarm:",
        "- Leave the building by the nearest fire exit immediately",
        "- Do not stop to collect all your belongings",
        "- Go to the assembly point (outside the building)",
        "- Do not re-enter the building until you are told by a member of staff that it is safe to do so",
        "",
        "How to Prevent Fires",
        " - DO NOT leave pans on the cookers unattended",
        " - DO NOT burn candles, incense or oil burners in your room",
        " - DO NOT smoke in your room or at the front of the building",
        " - DO NOT prop open or block doors",
        " - UNPLUG electrical appliances when not in use e.g. vanity lights, hair straighteners, irons etc.",
        " - THE FIRE ALARM SOUNDS AS A CONTINUOUS BELL",
        "",
        "We will carry out practice drills to ensure that residents know the escape route. Failure to leave the building during a drill will result in a warning being issued to you.",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "How to make a complaint",
        "",
        "Homescope Hostel encourages residents to provide feedback to see where we can do things better and where we need to make improvements in our service.",
        "A complaint is an expression of dissatisfaction because:",
        "",
        " - You are dealing with disturbances from other students",
        " - We have provided a poor service to you",
        "    E.g., we may have let you a room with lots of furniture missing when you move in",
        " - We have taken too long to deal with a request",
        "    E.g., you previously told staff a week ago about a neighbour who disturbs you late at night and staff has done nothing about it yet",
        "",
        "- Homescope Hostel staff have behaved unhelpfully or inappropriately",
        "  E.g., Staff were rude to you",
        "",
        "The simplest and quickest way to resolve a problem is to raise it with the relevant staff member. If you feel the matter has not been resolved to your satisfaction, you may invoke the formal procedure by contacting top management through info@homescopehostels.co.ke or WhatsApp us on 0704 313 313",
      ];

      generateSectionPDF(firstPartLines);
      generateSectionPDF(complianceAndTerminationLines);
      generateSectionPDF(handbookLines);
      generateSectionPDF(hostelRules);
      generateSectionPDF(movingInAndOut);
      generateSectionPDFtable(maintenanceTable);
      //   generateSectionPDF(fireProcedure);

      const numPages = doc.internal.getNumberOfPages(); // Get total number of pages
      for (let i = 1; i <= numPages; i++) {
        doc.setPage(i);
        footer(i, numPages); // Add footer to each page
      }

      // Output the PDF as a data URI
      const pdfDataUri = doc.output("datauristring");
      const iframe = document.createElement("iframe");
      iframe.src = pdfDataUri;
      iframe.width = "100%";
      iframe.height = "600px";

      const targetElement = document.getElementById("target-element");
      targetElement.appendChild(iframe);
    };

    generatePDF();
  }, [inspectionData]);

  return <div id="target-element" />;
}

export default ResidentAgreement;
