import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
function JobIdCreation() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const authToken = window.sessionStorage.getItem("auth-token");

  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [ticketid, setticketid] = useState(
    location.state ? location.state.jobid : ""
  );
  const [commonareaorunit, setcommonareaorunit] = useState(
    location.state ? location.state.commonareaorunit : ""
  );
  const [projectname, setprojectname] = useState(
    location.state ? location.state.projectname : ""
  );
  const [unitnumber, setunitnumber] = useState(
    location.state ? location.state.unitno : ""
  );
  const [ticketdate, setticketdate] = useState(new Date().toISOString());
  const [tasktobedone, settasktobedone] = useState("");
  const [createdby, setCreatedBy] = useState("");
  const [ticketlist, setticketlist] = useState([]);
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const loginAs = state.user.emailId.split("@")[0];
  const [jobstatus, setjobstatus] = useState("");
  const [isloading, setisloading] = useState(true);
  const [startdatesort, setStartdatesort] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [enddatesort, setEnddatesort] = useState(new Date().toISOString());
  const navigate = useNavigate();

  useEffect(() => {
    scrollToSection();
    setCreatedBy(loginAs);
    ListOfTickets();
  }, []);

  const filteredData = ticketlist.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const Date = item.TASKSTATUSDATE;

    if (searchData.length === 0 && !startdatesort && !enddatesort) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return ["JOBID", "TASKTOBEDONE", "TASKSTATUS"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort || Date >= startdatesort) &&
      (!enddatesort || Date <= enddatesort);

    return textMatch && dateMatch;
  });

  function handleExpenses(value) {
    navigate("/PropertyJobExpenses", { state: { value, projectname } });
  }

  const FetchTableValuesInFields = (item) => {
    // seteditflag(1);
    setticketid(item.JOBID);
    setticketdate(item.TASKSTATUSDATE);
    settasktobedone(item.TASKTOBEDONE);
    setjobstatus(item.TASKSTATUS);
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const clearallinputs = () => {
    setticketid("");
    setticketdate(new Date().toISOString());
    settasktobedone("");
    setjobstatus("");
  };

  const ListOfTickets = () => {
    axios
      .post(
        `${BASEURL}/getpropertytickettasks`,
        {
          JOBID: location.state ? location.state.jobid : 1,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setticketlist(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Tickets:", error);
      });
  };

  //   Save ----------------------------------------
  async function Savedetails() {
    try {
      const response = await axios.post(
        `${BASEURL}/createpropertytask`,
        {
          JOBID: ticketid,
          TASKTOBEDONE: tasktobedone,
          TASKSTATUS: jobstatus,
          TASKSTATUSDATE: ticketdate,
          TASKSTATUSUPDATEDBY: createdby,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }
  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between items-center">
                <h1 className="header__of__page">
                  {menu ? menu : "Job ID Creation"}
                </h1>
                <button
                  onClick={() => {
                    navigate("/TicketCreation");
                  }}
                  className="button"
                >
                  Back
                </button>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Job ID</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={ticketid}
                        readOnly
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Task status date</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        type="date"
                        value={ticketdate.split("T")[0]}
                        onChange={(e) => {
                          if (e.target.value > new Date().toISOString()) {
                            alert(
                              "Ticket date can not be greater than Current Date"
                            );
                          } else {
                            setticketdate(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Common Area Or Unit</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={commonareaorunit}
                        readOnly
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Unit Number</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={unitnumber}
                        readOnly
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Tasks to be done</label>
                    </p>
                    <div>
                      <textarea
                        style={{
                          maxWidth: "300px",
                          minWidth: "300px",
                          border: "1px solid black",
                          minHeight: "100px",
                          maxHeight: "100px",
                        }}
                        className="input_1"
                        required
                        value={tasktobedone}
                        onChange={(e) => {
                          settasktobedone(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Task Status</label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(e) => {
                            if (e.value === "") {
                              alert("Kindly choose proper job status");
                            } else {
                              setjobstatus(e.value);
                            }
                          }}
                          value={
                            jobstatus
                              ? {
                                  label: jobstatus,
                                  value: jobstatus,
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            { value: "STARTED", label: "STARTED" },
                            {
                              value: "WORK IN PROGRESS",
                              label: "WORK IN PROGRESS",
                            },
                            { value: "COMPLETED", label: "COMPLETED" },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project Name</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={projectname}
                        readOnly
                      />
                    </div>
                  </div>
                </aside>

                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                      // seteditflag(0);
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!ticketid) {
                        alert("Kindly supply Job Id");
                      } else if (!tasktobedone) {
                        alert("Kindly Supply the task to be done");
                      } else if (!jobstatus) {
                        alert("Kindly Supply the status");
                      } else {
                        await Savedetails();
                        // seteditflag(0);
                        clearallinputs();
                        ListOfTickets();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
      <main className="lease__bothfilte">
        <div className="searc__lease">
          <p>Search:</p>
          <input
            className="input_1 search__lease"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
        </div>
        <aside className="startand__enddate__lease ">
          <div>
            <p>
              <label className="">Start date</label>
            </p>
            <div>
              <input
                required
                className="input_1"
                type="date"
                value={startdatesort.split("T")[0]}
                onChange={(e) => setStartdatesort(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p>
              <label className="">End Date</label>
            </p>
            <div>
              <input
                className="input_1"
                type="date"
                required
                value={enddatesort.split("T")[0]}
                onChange={(e) => setEnddatesort(e.target.value)}
              />
            </div>
          </div>
        </aside>
      </main>

      <div className="service_detials ">
        <table className="table_1">
          <thead>
            <tr>
              <td>PROJECT NAME</td>
              <td>TASK STATUS DATE</td>
              <td>TASK TO BE DONE</td>
              <td>TASK STATUS</td>
              <td>EDIT</td>
              <td>EXPENSES</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                style={{
                  textTransform: "uppercase",
                }}
              >
                <td>{projectname}</td>
                <td style={{ width: "130px", textAlign: "right" }}>
                  {item.TASKSTATUSDATE
                    ? moment(item.TASKSTATUSDATE).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>{item.TASKTOBEDONE ? item.TASKTOBEDONE : "NA"}</td>
                <td style={{ textAlign: "left", width: "180px" }}>
                  {item.TASKSTATUS ? item.TASKSTATUS : "NA"}
                </td>

                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    FetchTableValuesInFields(item);
                    scrollToSection();
                  }}
                >
                  <BiSolidEditAlt />
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    handleExpenses(item.JOBID);
                  }}
                >
                  <GiTakeMyMoney />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default JobIdCreation;
