import React from "react";

import "../PropertyManagementCss/NotFound.css";
import { useNavigate } from "react-router";
import NotFoundNavbar from "./NotFoundNavbar";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <aside>
      <NotFoundNavbar />
      <p className="flex justify-end">
        <button
          onClick={() => navigate("/PropertyManagerDashboard")}
          className="bg-blue-600 p-1 m-2 text-white rounded "
        >
          Back
        </button>
      </p>
      <div className="not_found">
        <h1>404</h1>
        <p>NOT FOUND</p>
      </div>
    </aside>
  );
};

export default PageNotFound;
