import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
const PropertyJobExpenses = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const navigate = useNavigate();
  const jobid = location.state?.value || " ";
  const [projectname, setprojectname] = useState(
    location.state?.projectname || " "
  );
  const authToken = window.sessionStorage.getItem("auth-token");

  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [unitmastertable, setunitmastertable] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [expenseId, setexpenseId] = useState("");
  const [jobIdList, setJobIdList] = useState("");
  const [selectedJobId, setSelectedJobId] = useState("");
  const [listofexpense, setlistofexpense] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [expenseRecieptNo, setExpenseRecieptNo] = useState("");
  const [expensePassedToTenant, setExpensePassedToTenant] = useState("");
  const [expensepassedtotenantreason, setexpensepassedtotenantreason] =
    useState("");
  const [expenseProcessedBy, setExpenseProcessedBy] = useState("");
  const [selectedcheckedvalue, setselectedcheckedvalue] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [descriptionNo, setdescriptionNo] = useState("");
  const [jobExpenseTable, setJobExpenseTable] = useState("");
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [passedToTenantFilter, setPassedToTenantFilter] = useState("");
  const state = useSelector((state) => state.changeTheState);
  useEffect(() => {
    scrollToSection();
    setExpenseProcessedBy(
      state.user ? state.user.emailId.split("@")[0] : "N/A"
    );
  }, [state.user]);

  function JobExpense() {
    axios
      .get(`${BASEURL}/newjobexpenseid`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setexpenseId(response.data[0].NEXTNO);
        setisloading(false);
      });
    axios
      .get(`${BASEURL}/propertyticketlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setJobIdList(response.data);
        setisloading(false);
      });
    axios
      .post(
        `${BASEURL}/listofjobexpense`,
        {
          JOBID: location.state.value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setunitmastertable(response.data);
        setisloading(false);
      });
  }

  const setCurrentDateAsExpenseDate = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    setExpenseDate(currentDate);
  };

  useEffect(() => {
    JobExpense();
    setCurrentDateAsExpenseDate();
  }, []);

  async function SaveExpense() {
    try {
      const response = await axios.post(
        `${BASEURL}/propertyjobexpense`,
        {
          JOBID: selectedJobId,
          JOBEXPENSEID: expenseId,
          JOBEXPENSEDESCRIPTION: descriptionNo,
          JOBEXPENSEAMOUNT: expenseAmount,
          JOBEXPENSERECEIPTNO: expenseRecieptNo,
          JOBEXPENSEPASSEDTOTENANT: selectedcheckedvalue,
          JOBEXPENSEPASSEDTOTENANTREASON: expensePassedToTenant,
          JOBEXPENSERECEIPTDATE: expenseDate,
          JOBEXPENSEPROCESSEDBY: expenseProcessedBy,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCheckbox = (e) => {
    if (e.target.value === "yes") {
      setselectedcheckedvalue(1);
    } else {
      setselectedcheckedvalue(0);
    }
  };
  const FetchTableValuesInFields = (item) => {
    setSelectedJobId(item.JOBID);
    setexpenseId(item.EXPENSEID);
    setExpenseAmount(item.EXPENSEAMOUNT);
    setExpenseRecieptNo(item.RECEIPTNO);
    if (item.PASSEDTOTENANT === true) {
      setselectedcheckedvalue(1);
    } else {
      setselectedcheckedvalue(0);
    }

    setExpensePassedToTenant(item.PASSEDTOTENANTREASON);
    setExpenseDate(item.RECEIPTDATE);
    setdescriptionNo(item.EXPENSEDESCRIPTION);
  };

  const passedToTenantOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const handlePassedToTenantChange = (selectedOption) => {
    setPassedToTenantFilter(selectedOption.value);
  };

  const filteredData = unitmastertable.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return [
          "JOBID",
          "EXPENSEID",
          "EXPENSEAMOUNT",
          "RECEIPTNO",
          "PROJECT",
          "PASSEDTOTENANTREASON",
          "PASSEDTOTENANT",
          "RECEIPTDATE",
          "EXPENSEDESCRIPTION",
        ].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

    const fromDateFilter = fromDate !== "" ? new Date(fromDate) : null;
    const toDateFilter = toDate !== "" ? new Date(toDate) : null;
    const itemDate = new Date(item.RECEIPTDATE);

    const dateMatch =
      (!fromDateFilter || itemDate >= fromDateFilter) &&
      (!toDateFilter || itemDate <= toDateFilter);

    const passedToTenantMatch =
      passedToTenantFilter === "" ||
      item.PASSEDTOTENANT === (passedToTenantFilter === "yes");

    return textMatch && dateMatch && passedToTenantMatch;
  });

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between items-center">
                <h1 className="header__of__page">
                  {menu ? menu : "Property Job Expenses"}
                </h1>
                <button
                  onClick={() => {
                    navigate("/JobIdCreation", {
                      state: { jobid, projectname },
                    });
                  }}
                  className="button"
                >
                  Back
                </button>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">JOB ID </label>
                    </p>
                    <div>
                      <div>
                        {/* <Select
                          styles={customStyless}
                          options={
                            Array.isArray(jobIdList)
                              ? jobIdList.map((job) => ({
                                  value: job.TICKETID,
                                  label: job.TICKETID,
                                }))
                              : []
                          }
                          value={{ value: selectedJobId, label: selectedJobId }}
                          onChange={(selectedOption) =>
                            setSelectedJobId(selectedOption.value)
                          }
                        /> */}
                      </div>
                      <div className="text-red-600 font-bold">
                        {" "}
                        {jobid ? jobid : ""}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_ "> JOB EXPENSE ID </label>
                    </p>
                    <div className="text-red-600 font-bold">{expenseId}</div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  {/* <div>
                    <p>
                      <label className="garage_id_">
                        JOBEXPENSE PROCESSED BY{" "}
                      </label>
                    </p>

                    <div>
                      <input
                        className="input_1"
                        value={expenseProcessedBy ? expenseProcessedBy : "N/A"}
                        // onChange={(e) => {
                        //   setExpenseProcessedBy(e.target.value);
                        // }}
                      />
                    </div>
                  </div> */}
                  <div>
                    <p>
                      <label className="garage_id_">
                        JOB EXPENSE RECEIPT DATE{" "}
                      </label>
                    </p>
                    <div>
                      <input
                        value={expenseDate.slice(0, 10)}
                        className="input_1"
                        type="date"
                        onChange={(e) => {
                          setExpenseDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">JOB EXPENSE AMOUNT </label>
                    </p>
                    <div>
                      <input
                        type="number"
                        className="input_1"
                        onChange={(e) => {
                          setExpenseAmount(e.target.value);
                        }}
                        value={expenseAmount}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">
                        JOB EXPENSE RECEIPT NO{" "}
                      </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        onChange={(e) => {
                          setExpenseRecieptNo(e.target.value);
                        }}
                        value={expenseRecieptNo}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">
                        JOB EXPENSE PASSED TO TENANT{" "}
                      </label>
                    </p>
                    <aside className="m-4 flex items-center">
                      <input
                        type="checkbox"
                        value="yes"
                        checked={selectedcheckedvalue === 1}
                        onChange={handleCheckbox}
                        className="input_1 m-2"
                      />
                      yes
                      <input
                        type="checkbox"
                        value="no"
                        checked={selectedcheckedvalue === 0}
                        onChange={handleCheckbox}
                        className="input_1 m-2"
                      />
                      No
                    </aside>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">
                        JOB EXPENSE PASSED TO TENANT REASON{" "}
                      </label>
                    </p>
                    <div>
                      <input
                        disabled={selectedcheckedvalue === 0}
                        className="input_1"
                        value={expensePassedToTenant}
                        onChange={(e) => {
                          setExpensePassedToTenant(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">PROJECT NAME</label>
                    </p>
                    <div>
                      <input disabled className="input_1" value={projectname} />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">
                        JOB EXPENSE DESCRIPTION{" "}
                      </label>
                    </p>
                    <div>
                      <textarea
                        name=""
                        class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[100px] max-h-[100px]"
                        onChange={(e) => {
                          setdescriptionNo(e.target.value);
                        }}
                        value={descriptionNo}
                      ></textarea>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    New Record
                  </button>
                  <button className="button" onClick={() => SaveExpense()}>
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
        <div className="search__tag flex mx-10 my-4 items-center justify-between">
          <div className="flex">
            <p>Search:</p>
            <input
              className="input_1 w-[250px]"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>{" "}
            <div className="relative top-[6px] right-5">
              {" "}
              <FaSearch />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <p>From Date:</p>
            <input
              className="input_1"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <p>To Date:</p>
            <input
              className="input_1"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="flex items-center">
            <p> PASSED TO TENANT</p>
            <div className="m-2 w-[100px]">
              <Select
                options={passedToTenantOptions}
                value={passedToTenantOptions.find(
                  (option) => option.value === passedToTenantFilter
                )}
                onChange={handlePassedToTenantChange}
              />
            </div>
          </div>
        </div>

        <div className="service_detials">
          <table className="table_1">
            <thead>
              <tr>
                {/* <td style={{ textAlign: "right" }}>JOB ID</td> */}

                <td style={{ textAlign: "right", width: "70px" }}>
                  EXPENSE&nbsp;ID
                </td>
                <td style={{ textAlign: "right" }}>RECEIPT NO</td>
                <td>RECEIPT DATE</td>
                <td style={{ textAlign: "right" }}>EXPENSE AMOUNT</td>
                {/* <td style={{ textAlign: "right" }}>RECEIPT NO</td> */}

                <td>PASSED TO TENANT</td>
                <td>PROJECT NAME</td>
                <td>PASSED TO TENANT REASON</td>

                <td>EXPENSE DESCRIPTION</td>
                <td>EDIT</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => {
                return (
                  <tr
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    {/* <td style={{ textAlign: "right" }}>
                      {item.JOBID ? item.JOBID : "N/A"}
                    </td> */}
                    <td style={{ textAlign: "right" }}>
                      {item.EXPENSEID ? item.EXPENSEID : ""}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {item.RECEIPTNO ? item.RECEIPTNO : "N/A"}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {item.RECEIPTDATE
                        ? moment(item.RECEIPTDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {item.EXPENSEAMOUNT
                        ? item.EXPENSEAMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "NA"}
                    </td>

                    <td style={{ textAlign: "left" }}>
                      {item.PASSEDTOTENANT === true ? "yes" : "No"}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {projectname ? projectname : "N/A"}
                    </td>

                    <td style={{ textAlign: "left" }}>
                      {item.PASSEDTOTENANTREASON
                        ? item.PASSEDTOTENANTREASON
                        : "NA"}
                    </td>

                    <td style={{ textAlign: "left" }}>
                      {item.EXPENSEDESCRIPTION ? item.EXPENSEDESCRIPTION : "NA"}
                    </td>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "22px",
                        textAlign: "right",
                      }}
                      onClick={() => {
                        FetchTableValuesInFields(item);
                        scrollToSection();
                      }}
                    >
                      <BiSolidEditAlt />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PropertyJobExpenses;
