import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
const ApprovedClients = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [search, setSearch] = useState("");
  const [ApprovedClients, setApprovedClients] = useState([]);
  const [isloading, setisloading] = useState(true);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );

  useEffect(() => {
    masterData();
  }, []);

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = ApprovedClients?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        "CLIENTCODE",
        "CLIENTNAME",
        "CLIENTEMAIL",
        "PROJECT",
        "UNIT",
        "CONTACTNUMBER",
        "DOB",
        "EMERGENCYCONTACTNAME",
        "GUARDIANCONTACTNAME",
        "GUARDIANEMAIL",
        "GUARDIANBOX",
        "GUARDIANPHYSICALADDRESS",
        "GUARDIANHOMEADDRESS",
        "GUARDIANNAME",
        "GUARDIANIDPASSPORT",
        "STUDENTEMAIL",
        "STUDENTIDPASSPORT",
        "STUDENTUNIVERSITY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const isDateRangeMatch =
    //   (!fromDateFilter ||
    //     new Date(item?.BILLDATE) >= new Date(fromDateFilter)) &&
    //   (!toDateFilter || new Date(item?.BILLDATE) <= new Date(toDateFilter));

    return textMatch;
    // && isDateRangeMatch;
  });
  console.log(filteredData);
  function masterData() {
    setisloading(true);
    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        const approvedclients = response.data.filter(
          (item) => item.APPROVED === "APPROVED"
        );
        setApprovedClients(approvedclients);
        console.log(approvedclients);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
        <div>
          <header className="header__of__main flex justify-between px-3 items-center">
            {menu ? (
              <h1 className="header__of__page"> {menu}</h1>
            ) : (
              <h1 className="header__of__page"> Approved Clients</h1>
            )}

            <button
              className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
              onClick={() => {
                navigate("/ClientMaster");
              }}
            >
              Back
            </button>
          </header>

          <article>
            <div className="flex gap-3 relative mx-[30px] my-2"></div>
            <div className="lg:flex items-center justify-between mx-[20px]">
              <div className="flex">
                <p className="lg:mx-2 text-[16px]">Search</p>
                <input
                  className="w-[300px] lg:mr-[50px] h-[30px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              {/* <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
                <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateFilterChange}
                  />
                </div>
                <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
                  <input
                    type="date"
                    value={toDateFilter}
                    onChange={handleToDateFilterChange}
                  />
                </div>
              </section> */}
            </div>
            <div className="  overflow-auto m-[10px]  p-[20px] ">
              <table className="table_1  table__size__reducer ">
                <thead>
                  <tr style={{ fontWeight: "400" }}>
                    <td>CLIENT CODE </td>
                    <td>CLIENT NAME</td>
                    <td>CLIENT EMAIL</td>
                    <td className="text-right">DEPOSIT </td>
                    <td>PROJECT</td>
                    {/* <td>UNIT NO </td>
                    <td>UNIT</td>
                    <td>LEASE START DATE</td>
                    <td>END START DATE</td>
                    <td>UNIT PRICE </td>

                    <td>APPROVED </td> */}
                    <td className="text-right">CONTACT NUMBER </td>
                    <td>DOB </td>
                    <td>EMERGENCY CONTACT NAME </td>
                    <td className="text-right">EMERGENCY CONTACT PHONE </td>
                    <td>GUARDIAN CONTACT NAME </td>
                    <td>GUARDIAN EMAIL </td>
                    <td>GUARDIAN BOX </td>
                    <td>GUARDIAN PHYSICAL ADDRESS </td>
                    <td>GUARDIAN HOME ADDRESS </td>
                    <td>GUARDIAN NAME </td>
                    <td className="text-right">GUARDIAN ID PASSPORT </td>
                    <td>STUDENT EMAIL </td>
                    <td className="text-right">STUDENT ID PASSPORT </td>
                    <td>STUDENT LAST NAME </td>
                    <td>STUDENT UNIVERSITY </td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((item, index) => {
                      const leaseStartDate = new Date(item?.LEASESTARTDATE);
                      const currentDate = new Date();
                      const timeDiff = Math.abs(currentDate - leaseStartDate);
                      const diffDays = Math.ceil(
                        timeDiff / (1000 * 60 * 60 * 24)
                      ); // Calculating difference in days

                      return (
                        <tr key={index} className="table-row">
                          <td>{item?.CLIENTCODE ? item?.CLIENTCODE : "N/A"}</td>
                          <td>{item?.CLIENTNAME ? item?.CLIENTNAME : "N/A"}</td>
                          <td>
                            {item?.CLIENTEMAIL ? item?.CLIENTEMAIL : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.DEPOSIT?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}{" "}
                          </td>{" "}
                          <td>{item?.PROJECT ? item?.PROJECT : "N/A"}</td>
                          {/* <td>{item?.UNITNO ? item?.UNITNO : "N/A"}</td>
                          <td>{item?.UNIT ? item?.UNIT : "N/A"}</td>
                          <td>
                            {item?.LEASESTARTDATE
                              ? item?.LEASESTARTDATE.slice(8, 10) +
                                "/" +
                                item?.LEASESTARTDATE.slice(5, 7) +
                                "/" +
                                item?.LEASESTARTDATE.slice(0, 4)
                              : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.ENDSTARTDATE
                              ? item?.ENDSTARTDATE.slice(8, 10) +
                                "/" +
                                item?.ENDSTARTDATE.slice(5, 7) +
                                "/" +
                                item?.ENDSTARTDATE.slice(0, 4)
                              : "N/A"}{" "}
                          </td>
                          <td>{item?.UNITPRICE ? item?.UNITPRICE : "N/A"}</td>
                          <td>{item?.APPROVED ? item?.APPROVED : "N/A"}</td> */}
                          <td className="text-right">
                            {item?.CONTACTNUMBER ? item?.CONTACTNUMBER : "N/A"}
                          </td>
                          <td>
                            {item?.DOB
                              ? item?.DOB.slice(8, 10) +
                                "/" +
                                item?.DOB.slice(5, 7) +
                                "/" +
                                item?.DOB.slice(0, 4)
                              : "N/A"}{" "}
                          </td>
                          <td>
                            {item?.EMERGENCYCONTACTNAME
                              ? item?.EMERGENCYCONTACTNAME
                              : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.EMERGENCYCONTACTPHONE
                              ? item?.EMERGENCYCONTACTPHONE
                              : "N/A"}
                          </td>
                          <td>
                            {item?.GUARDIANCONTACTNAME
                              ? item?.GUARDIANCONTACTNAME
                              : "N/A"}
                          </td>
                          <td>
                            {item?.GUARDIANEMAIL ? item?.GUARDIANEMAIL : "N/A"}
                          </td>
                          <td>
                            {item?.GUARDIANBOX ? item?.GUARDIANBOX : "N/A"}
                          </td>
                          <td>
                            {item?.GUARDIANPHYSICALADDRESS
                              ? item?.GUARDIANPHYSICALADDRESS
                              : "N/A"}
                          </td>
                          <td>
                            {item?.GUARDIANHOMEADDRESS
                              ? item?.GUARDIANHOMEADDRESS
                              : "N/A"}
                          </td>
                          <td>
                            {item?.GUARDIANNAME ? item?.GUARDIANNAME : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.GUARDIANIDPASSPORT
                              ? item?.GUARDIANIDPASSPORT
                              : "N/A"}
                          </td>
                          <td>
                            {item?.STUDENTEMAIL ? item?.STUDENTEMAIL : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.STUDENTIDPASSPORT
                              ? item?.STUDENTIDPASSPORT
                              : "N/A"}
                          </td>
                          <td>
                            {item?.STUDENTLASTNAME
                              ? item?.STUDENTLASTNAME
                              : "N/A"}
                          </td>
                          <td>
                            {item?.STUDENTUNIVERSITY
                              ? item?.STUDENTUNIVERSITY
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ApprovedClients;
