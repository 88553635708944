import {
  Navigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ProtectedRoute from "./context/ProtectedRoute";
import PageNotFound from "./PropertyManagement/PropertyManagementJS/PageNotFound";
//Client related import
import Login from "./Pages/ClientLogin/ClientLogin";
import ClientDashboard from "./Pages/ClientDashboard/ClientDashboard";
import LandingPage from "./Pages/ClientLandingPage/ClientLandingPage";
import ClientMainDashboard from "./Pages/ClientLandingPage/ClientMainDashboard";
import ClientCheckoutPage from "./Pages/ClientCheckout/ClientCheckOut";
import TenantNotices from "./Pages/TenantNotices/TenantNotices";
import TicketCreationClinet from "./Pages/TicketCreation/TicketCreationClinet";

// property management import
import UnitMaster from "./PropertyManagement/PropertyManagementJS/UnitMaster";
import ProjectMaster from "./PropertyManagement/PropertyManagementJS/ProjectMaster";
import PropertyUnitLeases from "./PropertyManagement/PropertyManagementJS/PropertyUnitLeases";
import DocumentMaster from "./PropertyManagement/PropertyManagementJS/DocumentMaster";
import PropertyManagerDashboard from "./PropertyManagement/PropertyManagementJS/PropertyManagerDashboard";
import ElectricityBill from "./PropertyManagement/PropertyManagementJS/ElectricityBill";
import UnitTypeMaster from "./PropertyManagement/PropertyManagementJS/UnitTypeMaster";
import PropertyTypeMaster from "./PropertyManagement/PropertyManagementJS/PropertyTypeMaster";
import LeaseExpiry from "./PropertyManagement/PropertyManagementJS/LeaseExpiry";
import InspectionMaster from "./PropertyManagement/PropertyManagementJS/InspectionMaster";
import InspectionDetails from "./PropertyManagement/PropertyManagementJS/InspectionDetails";
import TicketCreation from "./PropertyManagement/PropertyManagementJS/TicketCreation";
import PropertyJobExpenses from "./PropertyManagement/PropertyManagementJS/PropertyJobExpenses";
import JobIdCreation from "./PropertyManagement/PropertyManagementJS/JobIdCreation";
import EditInspectionDetails from "./PropertyManagement/PropertyManagementJS/EditInspectionDetails";
import TenantNoticeProperty from "./PropertyManagement/PropertyManagementJS/TenantNoticeProperty";
import SubUnitMaster from "./PropertyManagement/PropertyManagementJS/SubUnitMaster";
import Inspection from "./PropertyManagement/PropertyManagementJS/Inspection";
import InspectionDetailsHistoricDocuments from "./PropertyManagement/PropertyManagementJS/InspectionDetailsHistoricDocuments";
import ElectricityBillEndOfMonth from "./PropertyManagement/PropertyManagementJS/ElectricityBillEndOfMonth";
import CaptureMeterReading from "./PropertyManagement/PropertyManagementJS/CaptureMeterReading";
import ElectricityBillClient from "./PropertyManagement/PropertyManagementJS/ElectricityBillClient";
import ViewStatement from "./PropertyManagement/PropertyManagementJS/ViewStatement";
import TenantUnitTransfer from "./PropertyManagement/PropertyManagementJS/TenantUnitTransfer";
import Footer from "./Components/Footer/Footer";
import ResidentAgreement from "./PropertyManagement/PropertyManagementJS/ResidentAgreement";
import ResidenceTest from "./PropertyManagement/PropertyManagementJS/ResidenceTest";
import Electricity from "./PropertyManagement/PropertyManagementJS/Electricity";
import AllocationInvoices from "./Pages/UnapprovedInvoice/AllocationInvoices";
import RentAmountCalculation from "./PropertyManagement/PropertyManagementJS/RentAmountCalculation";
import RentSaved from "./PropertyManagement/PropertyManagementJS/RentSaved";
import AdminClientDashboard from "./PropertyManagement/PropertyManagementJS/AdminClientdashboard";
import TransportExpencesHistoric from "./PropertyManagement/PropertyManagementJS/TransportExpencesHistoric";
import AdminClientCheckoutPage from "./PropertyManagement/PropertyManagementJS/AdminClientCheckout";
import UnAllocationReceipts from "./Pages/UnapprovedInvoice/UnAllocationReceipts";
import TransportBill from "./PropertyManagement/PropertyManagementJS/TransportBill";
import ClientMaster from "./PropertyManagement/PropertyManagementJS/ClientMaster";
import UnoccupiedUnit from "./PropertyManagement/PropertyManagementJS/UnoccupiedUnit";
import ProceededInternet from "./PropertyManagement/PropertyManagementJS/ProceededInternet";
import InternetCalculation from "./PropertyManagement/PropertyManagementJS/InternetCalculation";
import ClientStatementDetails from "./PropertyManagement/PropertyManagementJS/ClientStatementDetails";
import UnapprovedClients from "./PropertyManagement/PropertyManagementJS/UnapprovedClients";
import ApprovedClients from "./PropertyManagement/PropertyManagementJS/ApprovedClients";
import ExpiredLeases from "./PropertyManagement/PropertyManagementJS/ExpiredLeases";
import ResidentAgreement1 from "./PropertyManagement/PropertyManagementJS/RedisentAgreement1";
import CheckOutPage from "./PropertyManagement/PropertyManagementJS/CheckOutPage";
import ProcessUnallocatedWrongClientReceipts from "./PropertyManagement/PropertyManagementJS/ProcessUnallocatedWrongClientReceipts";
import HistoricExits from "./PropertyManagement/PropertyManagementJS/HistoricExits";
import ClientViewStatement from "./PropertyManagement/PropertyManagementJS/ClientViewStatement";
import UnallocatedReceipts from "./PropertyManagement/PropertyManagementJS/UnallocatedReceipts";
import ProcessUnallocatedReceipts from "./PropertyManagement/PropertyManagementJS/ProcessUnallocatedReceipts";
import EMPLOYEEMASTER from "./PropertyManagement/PropertyManagementJS/EMPLOYEEMASTER";
import EMPLOYEEMASTERProcessed from "./PropertyManagement/PropertyManagementJS/EMPLOYEEMASTERProcessed";
import KYCTYPES from "./PropertyManagement/PropertyManagementJS/KYCTYPES";
import TENANTKYC from "./PropertyManagement/PropertyManagementJS/TENANTKYC";
import UnattachedKYC from "./PropertyManagement/PropertyManagementJS/UnattachedKYC";
import RequestForMaintenance from "./Pages/RequestForMaintenance";
import RegistrationforSeller from "./PropertyManagement/PropertyManagementJS/RegistrationforSeller";
import ServiceProviderSetup from "./PropertyManagement/PropertyManagementJS/ServiceProviderSetup";
import SpecializationSetup from "./PropertyManagement/PropertyManagementJS/SpecializationSetup";
import SERVICEPROVIDERPORTAL from "./Pages/SERVICEPROVIDERPORTAL/SERVICEPROVIDERPORTAL";
import ServiceProviderDashboard from "./Pages/ServiceProvider/ServiceProviderDashboard";
import MyEnqueries from "./Pages/ServiceProvider/MyEnqueries";
import MySales from "./Pages/ServiceProvider/MySales";
import ConfirmedLeases from "./PropertyManagement/PropertyManagementJS/ConfirmedLeases";
import UnConfirmedLeases from "./PropertyManagement/PropertyManagementJS/UnConfirmedLeases";
import RejectedClients from "./PropertyManagement/PropertyManagementJS/RejectedClients";
import LEASECONFIRMATION from "./PropertyManagement/PropertyManagementJS/LEASECONFIRMATION";
import APPROVETENANTNOTICE from "./PropertyManagement/PropertyManagementJS/APPROVETENANTNOTICE";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/*" element={<PageNotFound />} />
          {/* Client related */}
          <Route path="/" element={<Login />} />
          <Route
            path="/LandingPage"
            element={
              <ProtectedRoute>
                <LandingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ClientMainDashboard"
            element={
              <ProtectedRoute>
                <ClientMainDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/LEASECONFIRMATION"
            element={
              <ProtectedRoute>
                <LEASECONFIRMATION />
              </ProtectedRoute>
            }
          />
          <Route
            path="/APPROVETENANTNOTICES"
            element={
              <ProtectedRoute>
                <APPROVETENANTNOTICE />
              </ProtectedRoute>
            }
          />
          <Route
            path="/RejectedClients"
            element={
              <ProtectedRoute>
                <RejectedClients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ConfirmedLeases"
            element={
              <ProtectedRoute>
                <ConfirmedLeases />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UnConfirmedLeases"
            element={
              <ProtectedRoute>
                <UnConfirmedLeases />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MYSTATEMENT"
            element={
              <ProtectedRoute>
                <ClientDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSPAYMENT"
            element={
              <ProtectedRoute>
                <ClientDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSNOTICE"
            element={
              <ProtectedRoute>
                <TenantNotices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSTICKET"
            element={
              <ProtectedRoute>
                <TicketCreationClinet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TENANTSUBUNITTRANSFER"
            element={
              <ProtectedRoute>
                <TenantUnitTransfer />
              </ProtectedRoute>
            }
          />
          {/* property management */}
          <Route
            path="/SERVICEPROVIDERS"
            element={
              <ProtectedRoute>
                <ServiceProviderSetup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SERVICEPROVIDERSPECIALIZATIONSETUP"
            element={
              <ProtectedRoute>
                <SpecializationSetup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UnitMaster"
            element={
              <ProtectedRoute>
                <UnitMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ProjectMaster"
            element={
              <ProtectedRoute>
                <ProjectMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PropertyManagerDashboard"
            element={
              <ProtectedRoute>
                <PropertyManagerDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PropertyUnitLeases"
            element={
              <ProtectedRoute>
                <PropertyUnitLeases />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DocumentMaster"
            element={
              <ProtectedRoute>
                <DocumentMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UnitTypeMaster"
            element={
              <ProtectedRoute>
                <UnitTypeMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PropertyTypeMaster"
            element={
              <ProtectedRoute>
                <PropertyTypeMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TOTALCONSUMPTION"
            element={
              <ProtectedRoute>
                <ElectricityBill />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ElectricityBillEndOfMonth"
            element={
              <ProtectedRoute>
                <ElectricityBillEndOfMonth />
              </ProtectedRoute>
            }
          />
          <Route
            path="/LeaseExpiry"
            element={
              <ProtectedRoute>
                <LeaseExpiry />
              </ProtectedRoute>
            }
          />
          <Route
            path="/InspectionMaster"
            element={
              <ProtectedRoute>
                <InspectionMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/InspectionDetails"
            element={
              <ProtectedRoute>
                <InspectionDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TicketCreation"
            element={
              <ProtectedRoute>
                <TicketCreation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SubUnitMaster"
            element={
              <ProtectedRoute>
                <SubUnitMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/JobIdCreation"
            element={
              <ProtectedRoute>
                <JobIdCreation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CaptureMeterReading"
            element={
              <ProtectedRoute>
                <CaptureMeterReading />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PropertyJobExpenses"
            element={
              <ProtectedRoute>
                <PropertyJobExpenses />
              </ProtectedRoute>
            }
          />{" "}
          <Route
            path="/EditInspectionDetails"
            element={
              <ProtectedRoute>
                <EditInspectionDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TENANTNOTICE"
            element={
              <ProtectedRoute>
                <TenantNoticeProperty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Inspection"
            element={
              <ProtectedRoute>
                <Inspection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PENDINGEXITINSPECTIONDETAILS"
            element={
              <ProtectedRoute>
                <InspectionDetailsHistoricDocuments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ElectricityhistoricalDetails"
            element={
              <ProtectedRoute>
                <ElectricityBillClient />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ViewStatement"
            element={
              <ProtectedRoute>
                <ViewStatement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ResidentAgreement"
            element={
              <ProtectedRoute>
                <ResidentAgreement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ResidentAgreement1"
            element={
              <ProtectedRoute>
                <ResidentAgreement1 />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ResidenceTest"
            element={
              <ProtectedRoute>
                <ResidenceTest />
              </ProtectedRoute>
            }
          />
          <Route
            path="/electricitychargecalculation"
            element={
              <ProtectedRoute>
                <Electricity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AllocationInvoices"
            element={
              <ProtectedRoute>
                <AllocationInvoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UnAllocationReceipts"
            element={
              <ProtectedRoute>
                <UnAllocationReceipts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/RentAmountCalculation"
            element={
              <ProtectedRoute>
                <RentAmountCalculation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TRANSPORTBILLCALCULATION"
            element={
              <ProtectedRoute>
                <TransportBill />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSEDRENT"
            element={
              <ProtectedRoute>
                <RentSaved />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSEDTRANSPORTEXPENSES"
            element={
              <ProtectedRoute>
                <TransportExpencesHistoric />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ClientMaster"
            element={
              <ProtectedRoute>
                <ClientMaster />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UNOCCUPIEDUNITS"
            element={
              <ProtectedRoute>
                <UnoccupiedUnit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSPAYMENTONBEHALFOFTENANT"
            element={
              <ProtectedRoute>
                <AdminClientDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AdminClientCheckoutPage"
            element={
              <ProtectedRoute>
                <CheckOutPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PROCESSEDINTERNETCHARGES"
            element={
              <ProtectedRoute>
                <ProceededInternet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/InternetCalculation"
            element={
              <ProtectedRoute>
                <InternetCalculation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CLIENTSTATEMENTVIEW"
            element={
              <ProtectedRoute>
                <ClientStatementDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UnapprovedClients"
            element={
              <ProtectedRoute>
                <UnapprovedClients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ApprovedClients"
            element={
              <ProtectedRoute>
                <ApprovedClients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ExpiredLeases"
            element={
              <ProtectedRoute>
                <ExpiredLeases />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/PROCESSRECEIPTONBEHALFOFTENANT" element={<CheckOutPage />} /> */}
          <Route
            path="/RECEIPTSWITHWRONGACCOUNTCODES"
            element={
              <ProtectedRoute>
                <ProcessUnallocatedWrongClientReceipts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <ClientCheckoutPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/HISTORICALEXITS"
            element={
              <ProtectedRoute>
                <HistoricExits />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ClientViewStatement"
            element={
              <ProtectedRoute>
                <ClientViewStatement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UNALLOCATEDRECEIPTS"
            element={
              <ProtectedRoute>
                <UnallocatedReceipts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/processunalloctaedreceipts"
            element={
              <ProtectedRoute>
                <ProcessUnallocatedReceipts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EMPLOYEEMASTER"
            element={
              <ProtectedRoute>
                <EMPLOYEEMASTER />
              </ProtectedRoute>
            }
          />
          <Route
            path="/EMPLOYEEMASTERProcessed"
            element={
              <ProtectedRoute>
                <EMPLOYEEMASTERProcessed />
              </ProtectedRoute>
            }
          />
          <Route
            path="/KYCTYPES"
            element={
              <ProtectedRoute>
                <KYCTYPES />
              </ProtectedRoute>
            }
          />
          <Route
            path="/TENANTKYC"
            element={
              <ProtectedRoute>
                <TENANTKYC />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UnattachedKYC"
            element={
              <ProtectedRoute>
                <UnattachedKYC />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SERVICEREQUESTFROMPROVIDERS"
            element={
              <ProtectedRoute>
                <RequestForMaintenance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/RegistrationforSeller"
            element={
              <ProtectedRoute>
                <RegistrationforSeller />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SERVICEPROVIDERPORTAL"
            element={
              <ProtectedRoute>
                <SERVICEPROVIDERPORTAL />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ServiceProviderDashboard"
            element={
              <ProtectedRoute>
                <ServiceProviderDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MYENQUIRIES"
            element={
              <ProtectedRoute>
                <MyEnqueries />
              </ProtectedRoute>
            }
          />
          <Route
            path="/MYSALES"
            element={
              <ProtectedRoute>
                <MySales />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
