import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import MenuNavbar from "./MenuNavbar";
import jsPDF from "jspdf";
import { IoMdClose } from "react-icons/io";
import { FcProcess } from "react-icons/fc";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
export default function CheckOutPage() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = window.sessionStorage.getItem("auth-token");
  const userState = useSelector((state) => state?.changeTheState?.user);
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  console.log(userState);
  const selectedStatements = useSelector((state) => state?.selectStatements);

  console.log(selectedStatements);
  const [processingMsg, setprocessingMsg] = useState("");
  const [statements, setStatements] = useState([]);
  const [total, setTotal] = useState(0);
  const [alreadypaid, setAlreadyPaid] = useState(0);
  const [paymentDate, setPaymentDate] = useState(null);
  const [mpesarefNo, setMPesaRefNo] = useState("");
  const [mobileNumber, setmobileNumber] = useState(0);

  const [currentTab, setCurrentTab] = useState("processing");
  // const socket = io("http://localhost:3001/propertymanagement");

  const [breakdowns, setBreakDowns] = useState([]);
  const [amounttoPay, setAmountToPay] = useState(0);
  const [isOpen2, setIsOpen2] = useState(false);
  const openPopup2 = () => {
    setIsOpen2(true);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };
  function fetchInitialData() {
    axios
      .post(
        `${BASEURL}/getstamentBreakdown`,
        {
          DOCNO: selectedStatements?.statements[0]?.INVOICENUMBER,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // calculateAlreadyPaid(response.data);
        const modifiedArr = response.data.map((item) => {
          return { ...item, PAID: 0 };
        });
        console.log(modifiedArr);
        setBreakDowns(modifiedArr);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  }
  useEffect(fetchInitialData, []);

  useEffect(() => {
    setStatements(selectedStatements?.statements);
    calculateAlreadyPaid(selectedStatements?.statements);
    // setTotal(selectedStatements?.total);
  });
  //   useEffect(() => {
  //     const socket = io("https://mtt-ts-api.azurewebsites.net");

  //     socket.on("connect", () => {
  //       console.log("Connected to WebSocket server");
  //     });

  //     socket.on("welcome", (message) => {
  //       console.log("Received welcome message:", message);
  //     });

  //     socket.on("payment-confirmation", (data) => {
  //       console.log("Received payment confirmation:", data);
  //       const {
  //         ResultCode: resultCode,
  //         CallbackMetadata: { Item },
  //       } = data?.Body?.stkCallback;
  //       const mpesaReceiptNumber = Item.find(
  //         (item) => item.Name === "MpesaReceiptNumber"
  //       ).Value;

  //       console.log("ResultCode:", resultCode);
  //       console.log("MpesaReceiptNumber:", mpesaReceiptNumber);
  //       if (resultCode === 0) {
  //         setMPesaRefNo(mpesaReceiptNumber);
  //         makepayment(mpesaReceiptNumber);
  //         setCurrentTab("completed");
  //         openPopup2();
  //       } else {
  //         alert("Payment failed");
  //         setCurrentTab("rejected");
  //         openPopup2();
  //       }
  //     });

  //     return () => {
  //       socket.disconnect();
  //     };
  //   }, [makepayment]);

  //   async function processMPesaPayment() {
  //     if (amounttoPay === 0 || amounttoPay === "") {
  //       alert("Please provide amount to Pay");
  //       return;
  //     }
  //     if (mobileNumber === 0 || mobileNumber === "") {
  //       alert("Please provide mobile Number");
  //       return;
  //     }

  //     if (amounttoPay && mobileNumber) {
  //       try {
  //         const response = await axios.post(
  //           `${BASEURL}/processpayment`,
  //           {
  //             AMOUNT: amounttoPay,
  //             PHONENUMBER: mobileNumber,
  //           },
  //           {
  //             headers: {
  //               "auth-token": authToken,
  //             },
  //           }
  //         );
  //         console.log(response);
  //         alert(
  //           "Please accept the payment request and do not close this window !!!"
  //         );
  //         setCurrentTab("processing");
  //         openPopup2();
  //       } catch (err) {
  //         console.log(err);
  //         alert(err.response.data.error);
  //         setCurrentTab("rejected");
  //         openPopup2();
  //       }
  //     }
  //   }
  // mpesarefNo
  // HH010076

  useEffect(() => {}, []);
  console.log(selectedStatements.clienCode);
  async function makepayment(mpesanum) {
    console.log(mpesanum);
    if (!mpesanum) {
      alert("Mpes Reference Number Required");
      return;
    }
    if (!paymentDate) {
      alert("Payment Date Required");
      return;
    }
    console.log(amounttoPay, paymentDate, mpesanum);
    console.log(breakdowns);
    if (amounttoPay && paymentDate) {
      if (alreadypaid < total) {
        const confres = window.confirm("Are you sure want to proceed");
        if (confres) {
          setprocessingMsg(
            "Processing Receipt. Please do not close or refresh the page!"
          );
          try {
            const response1 = await axios.post(
              `${BASEURL}/savePayment`,
              {
                CLIENTCODE: selectedStatements.clienCode,
                MPESAREFERENCENO: mpesanum,
                PAYMENTAMOUNT: amounttoPay,
                PAYMENTDATE: paymentDate,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            );
            console.log(response1);
            setCurrentTab("processing");
            setprocessingMsg(
              "Allocating Receipt. Please do not close or refresh the page!"
            );
            for (var i = 0; i < statements.length; i++) {
              console.log(statements[i]);
              if (statements[i]?.PAID) {
                const response2 = await axios.post(
                  `${BASEURL}/insertReceiptAllocation`,
                  {
                    RECEIPTNO: mpesanum,
                    RECEIPTFORINCOME: statements[i]?.SERVICE,
                    RECEIPTAMOUNT:
                      statements[i]?.DR === statements[i]?.ALREADYPAIDAMOUNT
                        ? statements[i]?.ALREADYPAIDAMOUNT
                        : Number(statements[i]?.PAID) +
                          Number(statements[i]?.ALREADYPAIDAMOUNT),
                    RECEIPTINVOICENO: statements[i]?.INVOICENUMBER,
                  },
                  {
                    headers: {
                      "auth-token": authToken,
                      "session-token": sessiontoken,
                    },
                  }
                );
                console.log(response2);
              }
            }
            console.log("Payment Succesful. You can now close the Window.");
            setCurrentTab("completed");
            setprocessingMsg(
              "Payment Succesful. You can now close the Window."
            );
            // setInterval(() => {
            //   window.location.reload();
            // }, 2000);
          } catch (err) {
            console.log(err);
            setCurrentTab("rejected");
            setprocessingMsg("Payment Failed. You can now close the Window.");
          }
        } else {
          alert("Payment cancelled");
        }
      } else {
        alert("Already paid total amount");
      }
    } else {
      alert("Enter amount, payment date and Mpesa reference number to pay");
    }
  }

  function autoAllocation(arr, amount) {
    let remainingAmount = amount;

    // Find the item in the array with REVENUESTREAM 'Electricity Charges'
    const electricityItems = arr.filter(
      (item) => item.SERVICE === "Electricity Charges"
    );

    for (const electricityItem of electricityItems) {
      if (remainingAmount <= 0) {
        electricityItem.PAID = 0;
        break; // Stop allocation if remaining amount is zero or negative
      }

      if (electricityItem.DR > electricityItem.ALREADYPAIDAMOUNT) {
        const amountToAllocate = Math.min(
          electricityItem.DR - electricityItem.ALREADYPAIDAMOUNT,
          remainingAmount
        );

        electricityItem.PAID = amountToAllocate;
        remainingAmount -= amountToAllocate;
      } else {
        electricityItem.PAID = 0;
      }
    }

    const serviceChargeItems = arr.filter(
      (item) => item.SERVICE === "Service Charges"
    );
    for (const serviceChargeItem of serviceChargeItems) {
      if (remainingAmount <= 0) {
        serviceChargeItem.PAID = 0;
        break; // Stop allocation if remaining amount is zero or negative
      }

      if (serviceChargeItem.DR > serviceChargeItem.ALREADYPAIDAMOUNT) {
        const amountToAllocate = Math.min(
          serviceChargeItem.DR - serviceChargeItem.ALREADYPAIDAMOUNT,
          remainingAmount
        );

        serviceChargeItem.PAID = amountToAllocate;
        remainingAmount -= amountToAllocate;
      } else {
        serviceChargeItem.PAID = 0;
      }
    }

    const transportChargeItems = arr.filter(
      (item) => item.SERVICE === "Transport Charges"
    );
    for (const transportChargeItem of transportChargeItems) {
      if (remainingAmount <= 0) {
        transportChargeItem.PAID = 0;
        break; // Stop allocation if remaining amount is zero or negative
      }

      if (transportChargeItem.DR > transportChargeItem.ALREADYPAIDAMOUNT) {
        const amountToAllocate = Math.min(
          transportChargeItem.DR - transportChargeItem.ALREADYPAIDAMOUNT,
          remainingAmount
        );

        transportChargeItem.PAID = amountToAllocate;
        remainingAmount -= amountToAllocate;
      } else {
        transportChargeItem.PAID = 0;
      }
    }

    const internetChargeItems = arr.filter(
      (item) => item.SERVICE === "Internet Fee"
    );
    for (const internetChargeItem of internetChargeItems) {
      if (remainingAmount <= 0) {
        internetChargeItem.PAID = 0;
        break; // Stop allocation if remaining amount is zero or negative
      }

      if (internetChargeItem.DR > internetChargeItem.ALREADYPAIDAMOUNT) {
        const amountToAllocate = Math.min(
          internetChargeItem.DR - internetChargeItem.ALREADYPAIDAMOUNT,
          remainingAmount
        );

        internetChargeItem.PAID = amountToAllocate;
        remainingAmount -= amountToAllocate;
      } else {
        internetChargeItem.PAID = 0;
      }
    }

    const rentalItems = arr.filter((item) => item.SERVICE === "Rental Charges");
    for (const rentalItem of rentalItems) {
      if (remainingAmount <= 0) {
        rentalItem.PAID = 0;
        break; // Stop allocation if remaining amount is zero or negative
      }

      if (rentalItem.DR > rentalItem.ALREADYPAIDAMOUNT) {
        const amountToAllocate = Math.min(
          rentalItem.DR - rentalItem.ALREADYPAIDAMOUNT,
          remainingAmount
        );

        rentalItem.PAID = amountToAllocate;
        remainingAmount -= amountToAllocate;
      } else {
        rentalItem.PAID = 0;
      }
    }
  }

  function calculateAlreadyPaid(arr) {
    var tempTotal = 0;
    var tempTotaltoPay = 0;
    for (var i = 0; i < arr.length; i++) {
      tempTotal = tempTotal + arr[i]?.ALREADYPAIDAMOUNT;
    }
    setAlreadyPaid(tempTotal);
    // AMOUNT
    for (var i = 0; i < arr.length; i++) {
      tempTotaltoPay = tempTotaltoPay + arr[i]?.DR;
    }
    console.log(tempTotaltoPay, tempTotal);
    setTotal(tempTotaltoPay);
    return tempTotal;
  }

  return (
    <div>
      <MenuNavbar />
      <header className="header__of__main flex items-center justify-between mr-5">
        <h1 className="header__of__page">
          {menu ? menu : "Admin Client Checkout Page"}
        </h1>
        {/* <aside className="button__load__off__main"> */}

        {/* <div className=" lg:flex gap-5 lg:m-2">
          <button
            className="button-50 "
            onClick={() => {
              navigate("/CLIENTRECEIPTALLOCATION");
            }}
          >
            Back
          </button>
        </div> */}
        {/* </aside> */}
      </header>
      {/* <table className="table__test3">
        <thead>
          <tr className="tr__test1">
            <td>
              <h5 className="text-right pr-4 w-[80px]"> Sr.No.</h5>
            </td>
            <td>
              <h5> TRANSACTION TYPE </h5>
            </td>
            <td>
              <h5 className="text-right pr-4 w-[100px]"> DOC NO</h5>
            </td>
            <td>
              <h5> DATE </h5>
            </td>
            <td>
              <h5 className="text-right pr-4 w-[100px]"> DR</h5>
            </td>
            <td>
              <h5 className="text-right pr-4 w-[100px]"> CR </h5>
            </td>
            <td>
              <h5> CURRENCY </h5>
            </td>
            <td>
              <h5 className="text-right pr-4 w-[100px]"> PAID </h5>
            </td>
          </tr>
        </thead>

        <tbody>
          {statements.map((item, index) => (
            <tr key={index}>
              <td>
                <p className="text-right pr-4 w-[80px]"> {index + 1}</p>
              </td>
              <td>{item.TRANSACTIONTYPE ? item.TRANSACTIONTYPE : "N/A"}</td>
              <td>
                <p className="text-right pr-4 w-[100px]">
                  {item.DOCNO ? item.DOCNO : "N/A"}{" "}
                </p>
              </td>

              <td>
                {item.DOCDATE
                  ? new Date(item.DOCDATE).toLocaleDateString("en-GB")
                  : "N/A"}
              </td>
              <td>
                <p className="text-right pr-4 w-[100px]">
                  {" "}
                  {item.DRCR === "DR"
                    ? item.AMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""}
                </p>
              </td>
              <td>
                <p className="text-right pr-4 w-[100px]">
                  {item.DRCR === "CR"
                    ? item.AMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""}
                </p>
              </td>
              <td>{item.CURRENCY ? item.CURRENCY : "N/A"}</td>

              <td>
                <p className="text-right pr-4 w-[100px]">
                  {item.PAIDAMOUNT
                    ? item.PAIDAMOUNT.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : "0.00"}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <div className="lg:flex gap-2 mx-[30px] mb-2">
        <aside></aside>
        <h2>Payment Date : </h2>
        <input
          type="date"
          onChange={(e) => {
            setPaymentDate(e.target.value);
          }}
        />
        <button
          onClick={() => {
            window.history.back();
          }}
          className="lg:ml-auto mt-2 lg:mt-0 bg-[#002d62] rounded p-1 px-2 text-white font-[500] border-[1px] border-green-300"
        >
          BACK
        </button>
      </div>

      <div className="lg:flex gap-2 mx-[30px] mb-2"></div>

      <aside className="to__center__main">
        <table className="table__test3">
          <thead>
            <tr className="tr__test1">
              <td className="text-left pl-4 w-[80px]">INCOME TYPE</td>
              <td className="text-right pr-4 w-[80px]">CHARGE AMOUNT</td>
              <td className="text-right pr-4 w-[80px]">ALREADY PAID</td>
              <td className="text-right pr-4 w-[80px]">ALLOCATED</td>
            </tr>
          </thead>

          {statements?.map((item) => {
            return (
              <tr>
                <td className="text-left pl-4 w-[80px]">{item?.SERVICE}</td>
                <td className="text-right pr-4 w-[80px]">
                  {item?.DR?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td className="text-right pr-4 w-[80px]">
                  {item?.ALREADYPAIDAMOUNT?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </td>

                {/* <td className="text-right pr-4 w-[80px]">
                  {item?.AMOUNT - item?.ALREADYALLOCATED > 0
                    ? amounttoPay >= item.AMOUNT
                      ? item.AMOUNT?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0
                    : 0}
                </td> */}
                <td className="text-right pr-4 w-[80px]">
                  {item?.PAID?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            );
          })}
        </table>
      </aside>
      <div className="lg:flex  mx-[30px] items-center">
        <aside className="lg:flex gap-2 lg:w-[400px]">
          <h2 className="lg:w-[112px]">MPESA REFNO :</h2>
          <input
            type="text"
            onChange={(e) => {
              setMPesaRefNo(e.target.value);
            }}
          />
        </aside>
        <aside className="lg:flex gap-2 lg:w-[480px]">
          <h2 className="lg:w-[154px]">Unallocated Amount :</h2>
          <input
            value={
              amounttoPay >= total - alreadypaid
                ? Number(amounttoPay) + Number(alreadypaid) - Number(total)
                : 0
            }
            type="number"
            disabled
            className="border-[1px] border-solid border-black"
          />
        </aside>
        <aside className="lg:flex gap-3">
          <h2 className="lg:w-[120px] lg:text-right">Total : </h2>
          <p>
            <span className="font-bold text-green-600 text-lg">
              {" "}
              {total?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </span>
          </p>
        </aside>
      </div>
      <div className="lg:flex  mx-[30px] items-center mt-2">
        <aside className="lg:flex gap-2 lg:w-[400px]">
          <h2 className="lg:w-[110px]">Already Paid : </h2>
          <p>
            <span className="font-bold text-green-600 text-lg">
              {" "}
              {alreadypaid?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </span>
          </p>
        </aside>
        <aside className="lg:flex gap-2 lg:w-[480px]">
          <h2 className="lg:w-[150px]">Reamining to Pay : </h2>
          <p>
            <span className="font-bold text-green-600 text-lg">
              {" "}
              {total > alreadypaid
                ? Number(total - alreadypaid)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                : 0}
            </span>
          </p>
        </aside>{" "}
        <aside className="lg:flex gap-2 ">
          <h2 className="lg:w-[120px] lg:text-right">Amount to pay :</h2>
          <input
            type="number"
            onChange={(e) => {
              setAmountToPay(e.target.value);
              autoAllocation(statements, e.target.value);
            }}
            className="border-[1px] border-solid border-black lg:text-right"
          />{" "}
        </aside>
      </div>

      <div className="mx-[30px]">
        <aside className="lg:flex justify-between gap-2 py-2">
          <div className=" lg:flex  items-center">
            <h2 className="lg:w-[120px] lg:text-right">Mobile Number :</h2>
            <input
              type="number"
              onChange={(e) => {
                setmobileNumber(e.target.value);
              }}
              className="border-[1px] border-solid border-black lg:text-right"
            />
          </div>
          <button
            className="lg:mx-1 mt-2 lg:mt-0 bg-[#002d62] rounded p-1 px-2 text-white font-[500] border-[1px] border-green-300"
            onClick={() => {
              makepayment(mpesarefNo);
              openPopup2();
            }}
          >
            Save
          </button>
        </aside>
      </div>

      {/* <h1
        style={
          processingMsg === "Payment Succesful. You can now close the Window."
            ? { color: "green" }
            : processingMsg === "Payment Failed. You can now close the Window."
            ? { color: "red" }
            : { color: "yellow" }
        }
      >
        {processingMsg}
      </h1> */}

      {/* <button
        onClick={() => {
          console.log(breakdowns);
        }}
      >
        Test
      </button> */}

      <div>
        {isOpen2 && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                background: "white",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1001,
              }}
              className="w-[98%] h-auto lg:h-[300px] lg:w-[75%] p-[10px] lg:p-[20px]"
            >
              <div className="flex justify-between mb-3 lg:mb-2">
                {" "}
                <header className="text-2xl   font-bold text-[#002d62] font-serif">
                  <h1 className=" ">Payment Gateway</h1>
                </header>
                <button
                  onClick={closePopup2}
                  className=" font-bold text-2xl  text-blue-800"
                >
                  <IoMdClose />
                </button>
              </div>

              <section className="w-[100%]">
                {currentTab === "processing" && (
                  <div className="flex justify-center flex-col items-center">
                    <p className="text-2xl   font-bold text-[#002d62] font-serif gap-4">
                      Allocating Receipt. Please do not close or refresh the
                      page!
                    </p>

                    <p className="icon__rotate">
                      <FcProcess />
                    </p>
                    <p className="text-xl   font-bold text-[#002d62] font-serif gap-4">
                      Please accept the payment request
                    </p>
                    <p className="text-xl   font-bold text-[#002d62] font-serif gap-4">
                      and do not close this window !!!
                    </p>
                  </div>
                )}
                {currentTab === "completed" && (
                  <div className="flex justify-center flex-col items-center">
                    <p className="text-2xl   font-bold text-[#002d62] font-serif gap-4">
                      Payment Succesful. You can now close the Window.
                    </p>
                    <p className="icon__zoom__in__out text-green-400">
                      <IoCheckmarkDoneCircle />
                    </p>
                    <button
                      className="px-3 py-1 my-3 w-[100px] bg-green-300"
                      onClick={closePopup2}
                    >
                      Close
                    </button>
                  </div>
                )}
                {currentTab === "rejected" && (
                  <div className="flex justify-center flex-col items-center">
                    <p className="text-2xl   font-bold text-[#002d62] font-serif gap-4">
                      Payment Failed. You can now close the Window.
                    </p>
                    <p className="icon__zoom__in__out text-red-400">
                      <MdCancel />
                    </p>
                    <button
                      className="px-3 py-1 my-3 w-[100px] bg-green-300"
                      onClick={closePopup2}
                    >
                      Close
                    </button>
                  </div>
                )}

                <div></div>
              </section>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
