import React, { useEffect, useState } from "react";
import axios, { toFormData } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import "../PropertyManagement/PropertyManagementCss/elctricpage.css";
import MenuNavbar from "../PropertyManagement/PropertyManagementJS/MenuNavbar";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../Components/Loading/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
function RequestForMaintenance() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [isloading, setisloading] = useState(false);
  const [newServiceRequestNo, setnewServiceRequestNo] = useState("");
  const [clientProperty, setClientProperty] = useState("");
  const [StatusReason, setStatusReason] = useState("");
  const [SpecialisationValue, setSpecialisationValue] = useState("");
  const [ServiceRequestList, setServiceRequestList] = useState([]);
  const [unitId, setUnitId] = useState(0);
  const [clientName, setClientName] = useState("");
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const [propertyDisplay, setPropertyDisplay] = useState("");
  const [search, setSearch] = useState("");
  const [Location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [distances, setDistances] = useState("");
  const [ProviderCompany, setProviderCompany] = useState("");
  const [ProviderCompanyId, setProviderCompanyId] = useState("");
  const [ProviderLatitude, setProviderLatitude] = useState("");
  const [Reportedissue, setReportedissue] = useState("");
  const [PopupForCancelRequest, setPopupForCancelRequest] = useState(false);
  const [selectedRowforStatus, setselectedRowforStatus] = useState([]);
  const [LocationValue, setLocationValue] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [search2, setSearch2] = useState("");
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation({ latitude, longitude });
          //   fetchDistance(latitude, longitude);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  }

  // Function to convert degrees to radians
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function parseGeolocation(geolocation) {
    const [latitude, longitude] = geolocation.split(", ").map(Number);
    return { latitude, longitude };
  }
  //   const distance = calculateDistance(12.8876544, 77.7355264, 12.8788, 77.6377);
  //   console.log(`Distance between points: ${distance.toFixed(2)} km`);

  const [currentDateTime, setCurrentDateTime] = useState({
    date: "",
    time: "",
  });
  const [ServiceProvidersList, setServiceProvidersList] = useState([]);
  useEffect(() => {
    const now = new Date();
    const date = now.toISOString().slice(0, 10); // YYYY-MM-DD
    const time = now.toTimeString().slice(0, 5); // HH:MM

    setCurrentDateTime({ date, time });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentDateTime((prev) => ({ ...prev, [name]: value }));
  };
  const options = [
    { value: "too_far", label: "Service provider is too far" },
    { value: "cancel_request", label: "Service provider requested to cancel" },
    { value: "expensive", label: "Service provider is expensive" },
    { value: "another_provider", label: "Chose to go with another provider" },
    { value: "personal_reasons", label: "Personal reasons" },
  ];
  const handleChangeValue = (selectedOption) => {
    console.log("Selected Option:", selectedOption);
    setStatusReason(selectedOption.label);
  };
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState.userCode);
  console.log(menu);
  function masterData() {
    setisloading(true);

    axios
      .get(`${BASEURL}/getnewServiceRequestNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setnewServiceRequestNo(response?.data[0]?.NEXTNO);
        console.log(response.data);
      });
    axios
      .post(
        `${BASEURL}/getclentunitidandpropid`,
        {
          CLIENTCODE: userState.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setClientProperty(response.data[0]?.PROJECTID);
        setPropertyDisplay(response.data[0]?.PROJECTNAME);
        setUnitId(response.data[0]?.UNITNUMBER);
        setClientName(response.data[0]?.CLIENTNAME);
        setisloading(false);
      });
    axios
      .get(`${BASEURL}/getServiceRequests?TENAANTCODE=${userState.userCode}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setServiceRequestList(response.data);
        console.log(response.data);
      });
    axios
      .get(`${BASEURL}/getServiceProvidersList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setServiceProvidersList(response.data);
        console.log(response.data);
      });
  }
  useEffect(() => {
    masterData();
  }, []);
  const filteredData = ServiceRequestList.filter((item) => {
    const searchData = search2.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.REQUESTDATE;
    const eDate = item.REQUESTDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        "REQUESTID",
        "TOPROVIDERCODE",
        "TOPROVIDERNAME",
        "EMAILADDRESS",
        "PROVIDERGEOLOCATION",
        "FROMOPROPERTYNAME",
        "PROPERTYUNITID",
        "TENANTNAME",
        "STATUSREASON",
        "ISSUEREPORTED",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  const filtHistoricCash = ServiceProvidersList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !SpecialisationValue && !LocationValue) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROVIDERNAME",
        "PROVIDEREMAIL",
        "SPECIALIZATION",
        "PROVIDERCOUNTRY",
        "PROVIDERADDRESS",
        "PROVIDERCITY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const specialisationMatch =
      !SpecialisationValue || item?.SPECIALIZATION === SpecialisationValue;
    const LocationValueMatch =
      !LocationValue || item?.PROVIDERADDRESS === LocationValue;

    return textMatch && specialisationMatch && LocationValueMatch;
  });

  function HandleClickProvider(row) {
    setcashsalepopup(false);
    console.log(row);
    const { latitude, longitude } = parseGeolocation(row?.PROVIDERGEOLOCATION);
    const distance = calculateDistance(
      Location.latitude,
      Location.longitude,
      latitude,
      longitude
    );
    console.log(
      calculateDistance(
        Location.latitude,
        Location.longitude,
        latitude,
        longitude
      )
    );
    setDistances(distance?.toFixed(2));
    console.log(row?.PROVIDERGEOLOCATION);
    setProviderCompany(row?.PROVIDERNAME);
    setProviderCompanyId(row?.PROVIDERID);
    setProviderLatitude(row.PROVIDERGEOLOCATION);
    // setProviderLongitude();
    // setDistances((prevDistances) => [...prevDistances, distance]);
  }
  //   @REQUESTNO NUMERIC,
  // @TOCOMPANYID NUMERIC,
  // @TOCOMPANYNAME VARCHAR(100),
  // @REQUESTDATE DATETIME,
  // @REQUESTTIME DATETIME,
  // @PROPERTYNAME VARCHAR(100),
  // @UNITNO VARCHAR(100),
  // @TENANTCODE VARCHAR(100),
  // @TENANTNAME VARCHAR(1000),
  // @ISSUEREPORTED VARCHAR(1000),

  async function SaveMaintenanceService() {
    setisloading(true);
    // if (ticketNarrationForReview === "") {
    //   alert("Please write narration");
    // } else if (rating < 1) {
    //   alert("Please provide rating");
    // } else {

    console.log(Reportedissue);
    const isConfirmed = window.confirm(
      "Are you sure you want to Request for service?"
    );
    console.log(currentDateTime.date);
    console.log(currentDateTime.time);
    if (isConfirmed) {
      const Date =
        currentDateTime.date.slice(8, 10) +
        "/" +
        currentDateTime.date.slice(5, 7) +
        "/" +
        currentDateTime.date.slice(0, 4);
      try {
        const response = await axios.post(
          `${BASEURL}/saveserviceRequest`,
          {
            REQUESTNO: newServiceRequestNo,
            TOCOMPANYID: ProviderCompanyId,
            TOCOMPANYNAME: ProviderCompany,
            REQUESTDATE: Date,
            REQUESTTIME: currentDateTime.time,
            PROPERTYNAME: propertyDisplay,
            UNITNO: unitId,
            TENANTCODE: userState.userCode,
            TENANTNAME: clientName,
            ISSUEREPORTED: Reportedissue,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setisloading(false);
      } catch (error) {
        console.error(`Error saving details`);
        setisloading(false);
      }
    } else {
      // User canceled submission
      console.log("Submission canceled");
      setisloading(false);
    }
    // }
  }
  const HandleCancelPopup = (row) => {
    setselectedRowforStatus(row);
    setPopupForCancelRequest(true);
  };
  async function HandleCancel(value) {
    console.log(value);
    const isConfirmed = window.confirm(`Are you sure you want to ${value}?`);

    console.log(selectedRowforStatus?.REQUESTID);
    console.log(StatusReason);
    console.log(clientName);
    if (isConfirmed) {
      try {
        const response = await axios.post(
          `${BASEURL}/updateserviceRequestStatus`,
          {
            REQUESTNO: selectedRowforStatus?.REQUESTID,
            STATUS: value,
            STATUSREASON: StatusReason,
            UPDATEDBYACCOUNT: clientName,
            UPDATEDBYACCOUNTTYPE: "TENANT",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setisloading(false);
      } catch (error) {
        console.error(`Error saving details`);
        setisloading(false);
      }
    } else {
      // User canceled submission
      console.log("Submission canceled");
      setisloading(false);
    }
  }

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <MenuNavbar />

      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main lg:flex justify-between px-3 items-center">
                {menu ? (
                  <h1 className="header__of__page">
                    {" "}
                    {menu ? menu : "Client Master"}
                  </h1>
                ) : (
                  <h1 className="header__of__page">
                    Request For Maintenance Service
                  </h1>
                )}

                {/* <div>
                  <button
                    className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                    onClick={() => {
                      navigate("/ApprovedClients");
                    }}
                  >
                    Approved Clients
                  </button>
                  <button
                    className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                    onClick={() => {
                      navigate("/UnapprovedClients");
                    }}
                  >
                    Unapproved Clients
                  </button>
                </div> */}
              </header>
              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div className="flex gap-[16px]">
                    <div className="flex gap-6">
                      <p>
                        <label className="garage_id_">REQUEST NO</label>
                      </p>
                      <div className="">
                        <p className="text-red-600"> {newServiceRequestNo}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="w-[200px]">
                      <label className="garage_id_">SERVICE COMPANY </label>
                    </p>
                    <div className="w-[300px]">
                      <input value={ProviderCompany ? ProviderCompany : ""} />
                    </div>
                    <button
                      className="bg-blue-900 text-white px-2 py-2 ml-2"
                      onClick={() => {
                        setcashsalepopup(true);
                      }}
                    >
                      ...
                    </button>
                  </div>
                </aside>
                {cashsalepopup && (
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[1000px] p-1 overflow-y-auto">
                    <div className="flex flex-row mt-2">
                      <p className="text-red-600 ml-2">Search:</p>
                      <input
                        className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></input>
                    </div>
                    <div className="flex items-center mt-2">
                      <p className="text-red-600 ml-2">Specialization:</p>
                      <div className="w-[300px] ml-2">
                        <Select
                          onChange={(e) => {
                            setSpecialisationValue(e.value);
                          }}
                          value={{
                            label: SpecialisationValue,
                            value: SpecialisationValue,
                          }}
                          options={[
                            { value: "", label: "" }, // Add the empty option
                            ...ServiceProvidersList.map((item) => ({
                              value: item?.SPECIALIZATION,
                              label: item?.SPECIALIZATION,
                            })),
                          ]}
                        />
                      </div>
                      <p className="text-red-600 ml-2">Location :</p>
                      <div className="w-[300px] ml-2">
                        <Select
                          onChange={(e) => {
                            setLocationValue(e.value);
                          }}
                          value={{
                            label: LocationValue,
                            value: LocationValue,
                          }}
                          options={[
                            { value: "", label: "" }, // Add the empty option
                            ...ServiceProvidersList.map((item) => ({
                              value: item?.PROVIDERADDRESS,
                              label: item?.PROVIDERADDRESS,
                            })),
                          ]}
                        />
                      </div>
                    </div>

                    <button
                      className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                      onClick={() => setcashsalepopup(false)}
                    >
                      Close
                    </button>
                    <aside className="flex justify-center mt-4 ">
                      <table class="table_1  ">
                        <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                          <tr>
                            <td className="text-left ">PROVIDER NAME</td>
                            <td className="text-left">PROVIDERE MAIL</td>
                            <td className="text-left">SPECIALIZATION</td>
                            <td className="text-left">PROVIDER ADDRESS</td>
                            <td className="text-left">PROVIDER COUNTRY</td>
                            <td className="text-left">PROVIDER CITY</td>
                          </tr>
                        </thead>
                        <tbody>
                          {filtHistoricCash.map((row, index) => (
                            <tr
                              key={row.PONO}
                              onClick={() => HandleClickProvider(row)}
                              className={
                                index < filtHistoricCash.length
                                  ? "border-b cursor-pointer table-row"
                                  : ""
                              }
                            >
                              <td className="text-left pr-6">
                                {row.PROVIDERNAME ? row.PROVIDERNAME : ""}
                              </td>
                              {/* <td>
                                    {row.PODATE
                                      ? moment(row.PODATE.split("T")[0]).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""}
                                  </td> */}
                              <td>
                                {row["PROVIDEREMAIL"]
                                  ? row["PROVIDEREMAIL"]
                                  : ""}
                              </td>
                              <td>
                                {row["SPECIALIZATION"]
                                  ? row["SPECIALIZATION"]
                                  : ""}
                              </td>
                              <td>
                                {row["PROVIDERADDRESS"]
                                  ? row["PROVIDERADDRESS"]
                                  : ""}
                              </td>
                              <td>
                                {row["PROVIDERCOUNTRY"]
                                  ? row["PROVIDERCOUNTRY"]
                                  : ""}
                              </td>
                              <td>
                                {row["PROVIDERCITY"] ? row["PROVIDERCITY"] : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </aside>
                  </div>
                )}

                {PopupForCancelRequest && (
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[400px] w-[500px] p-1 overflow-y-auto">
                    <div className="flex flex-row mt-6">
                      <button
                        className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3  py-1 ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                        onClick={() => setPopupForCancelRequest(false)}
                      >
                        <IoMdClose />
                      </button>
                      <p className="">
                        <label className="ml-2">STATUS REASONS : </label>
                        <div className="ml-2 w-[300px]">
                          <Select
                            options={options}
                            onChange={handleChangeValue}
                            placeholder="Select reason..."
                          />
                        </div>
                      </p>
                      <p className="mt-8">
                        <button
                          className="bg-[#002d62] p-1 ml-2 text-white"
                          onClick={() => HandleCancel("CANCEL")}
                        >
                          Save
                        </button>
                      </p>
                    </div>
                  </div>
                )}
                <aside className="lg:flex lg:ml-[30px] lg:gap-[64px] gap-2">
                  <p className="lg:flex lg:gap-[16px] gap-4">
                    <div className=" items-center bg-white rounded-md p-3.5 m-0 shadow-md flex lg:w-[301px] w-[355px] gap-2">
                      <p>
                        <label className="garage_id_">REQUEST DATE </label>
                      </p>
                      <div className="">
                        <input
                          type="date"
                          name="date"
                          value={currentDateTime.date}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className=" items-center bg-white rounded-md p-3.5 m-0 shadow-md flex lg:w-[260px]  w-[355px] gap-2">
                      <p>
                        <label className="garage_id_">REQUEST TIME</label>
                      </p>
                      <div className="">
                        <input
                          type="time"
                          name="time"
                          value={currentDateTime.time}
                          onChange={handleChange}
                          className="p-2 border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </p>
                  <div className="flex gap-[15px] items-center bg-white rounded-md p-3.5 m-0 shadow-md lg:w-[578px]">
                    <div className="font-medium">{`APPROXIMATE DISTANCE :`}</div>
                    {distances && (
                      <div className="text-red-600"> {`${distances}km`}</div>
                    )}
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">PROPERTY NAME </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        name="propertyDisplay"
                        value={propertyDisplay || ""}
                        type="text"
                        // onChange={(e) => {
                        //   setClientCode(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">UNIT NO</label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        value={unitId || ""}
                        name="unitId"
                        type="text"
                        // onChange={(e) => {
                        //   setClientName(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  {/* <div>
                    <p>
                      <label className="garage_id_">TENANT CODE </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        name="clientcode"
                        value={userState.userCode || ""}
                        type="text"
                        // onChange={(e) => {
                        //   setClientCode(e.target.value);
                        // }}
                      />
                    </div>
                  </div> */}
                  <div>
                    <p>
                      <label className="garage_id_">TENANT NAME</label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        value={clientName || ""}
                        name="clientname"
                        type="text"
                        // onChange={(e) => {
                        //   setClientName(e.target.value);
                        // }}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> ISSUE REPORTED</label>
                    </p>
                    <div>
                      <textarea
                        required
                        className="mx-1 textmax__width border-solid border-black border-[1px] min-w-[308px] max-w-[308px] min-h-[60px] max-h-[60px]"
                        type="text"
                        name="NOTES"
                        //   defaultValue={formInput.NOTES}
                        onChange={(e) => setReportedissue(e.target.value)}
                      />
                    </div>
                  </div>
                </aside>
                {/* <aside className="ml-[30px] flex w-[55%] ">
                  <div className="flex gap-[84px] items-center bg-white rounded-md p-3.5 m-0 shadow-md w-[576px]">
                    <p>
                      <label className="garage_id_"> ISSUE REPORTED</label>
                    </p>
                    <div>
                      <textarea
                        required
                        className="mx-1 textmax__width border-solid border-black border-[1px] min-w-[308px] max-w-[308px] min-h-[60px] max-h-[60px]"
                        type="text"
                        name="NOTES"
                        //   defaultValue={formInput.NOTES}
                        // onChange={(e) => handleInputItem(e)}
                      />
                    </div>
                  </div>
                </aside> */}

                <div className="savebutton">
                  <button className="button" onClick={SaveMaintenanceService}>
                    Save
                  </button>
                  {/* <button
                    className="button"
                    //    onClick={ClearInput}
                  >
                    New
                  </button> */}
                </div>
              </section>
            </main>
          </div>
        </div>
        {/* {error ? (
          <p>Error: {error}</p>
        ) : (
          <div>
            <p>Latitude: {Location.latitude}</p>
            <p>Longitude: {Location.longitude}</p>
          </div>
        )} */}
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <div>
          <div className="lg:flex">
            <p>
              <div className="searchhhhhh">
                <p>Search:</p>
                <input
                  className="input_1"
                  required
                  value={search2}
                  onChange={(e) => {
                    setSearch2(e.target.value);
                  }}
                ></input>
                <div className="search__icon">
                  {" "}
                  <FaSearch />
                </div>
              </div>
            </p>
            <p className="flex items-center lg:ml-2 ml-14">
              <p>Date:</p>
              <p>
                <input
                  className="input_1 ml-2 w-[200px]"
                  type="date"
                  value={startdate.split("T")[0]}
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                ></input>
              </p>
            </p>
          </div>
          <div className="lg:flex justify-center mt-4 overflow-auto p-2">
            <table className="table_1  " style={{ fontSize: "12px" }}>
              <thead class="header-cell sticky top-0  z-0 text-sm">
                <tr>
                  <td>REQUEST NO</td>

                  <td className="text-left">COMPANY NAME</td>
                  <td className="text-left">REQUEST DATE & TIME</td>

                  <td className="text-right">UNIT NO</td>

                  <td className="text-left">ISSUE REPORTED</td>
                  <td>STATUS UPDATED DATE & TIME</td>
                  <td>REQUEST STATUS</td>
                  <td>ACTION</td>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row, index) => (
                  <tr
                    key={row.PONO}
                    //   className={
                    //     index < filtHistoricCashItem.length
                    //       ? "border-b cursor-pointer table-row"
                    //       : ""
                    //   }
                  >
                    <td className="text-right w-[100px]">
                      {row.REQUESTID ? row.REQUESTID : ""}
                    </td>

                    <td>{row.TOPROVIDERNAME ? row.TOPROVIDERNAME : ""}</td>
                    <td className=" w-[160px]">
                      {row.REQUESTDATE
                        ? row?.REQUESTDATE
                          ? row?.REQUESTDATE.slice(8, 10) +
                            "/" +
                            row?.REQUESTDATE.slice(5, 7) +
                            "/" +
                            row?.REQUESTDATE.slice(0, 4)
                          : "N/A"
                        : ""}

                      <span className="ml-2">
                        {" "}
                        {row.REQUESTTIME ? row.REQUESTTIME.slice(11, 16) : ""}
                      </span>
                    </td>

                    <td className="text-right w-[100px]">
                      {row["PROPERTYUNITID"] ? row["PROPERTYUNITID"] : ""}
                    </td>

                    <td>{row["ISSUEREPORTED"] ? row["ISSUEREPORTED"] : ""}</td>
                    <td className="w-[160px]">
                      {row.STATUSUPDATEDDATE
                        ? row.STATUSUPDATEDDATE.slice(8, 10) +
                          "/" +
                          row.STATUSUPDATEDDATE.slice(5, 7) +
                          "/" +
                          row.STATUSUPDATEDDATE.slice(0, 4)
                        : "NA"}
                      <span className="ml-2">
                        {" "}
                        {row.STATUSUPDATEDDATE
                          ? row.STATUSUPDATEDDATE.slice(11, 16)
                          : ""}
                      </span>
                    </td>
                    <td>{row["REQUESTSTATUS"] ? row["REQUESTSTATUS"] : ""}</td>
                    <td>
                      <p className="flex gap-1">
                        {row["REQUESTSTATUS"] === "CANCEL" ||
                        row["REQUESTSTATUS"] === "ACCEPT" ? (
                          <button
                            onClick={() => HandleCancelPopup(row)}
                            className="bg-red-100 px-1 py-1 text-white"
                            disabled // disables the button if REQUESTSTATUS is "CANCEL"
                          >
                            CANCEL
                          </button>
                        ) : (
                          <button
                            onClick={() => HandleCancelPopup(row)}
                            className="bg-red-600 px-1 py-1 text-white"
                          >
                            CANCEL
                          </button>
                        )}
                      </p>
                    </td>
                    {/* <td className="text-right">
                    {row["DR"]
                      ? row["DR"].toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0.0}
                  </td>
                  <td className="text-right">
                    {row["CR"]
                      ? row["CR"].toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0.0}
                  </td>

                  <td className="text-right">
                    {row["CUMMUALTIVE"]
                      ? row["CUMMUALTIVE"].toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0.0}{" "}
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestForMaintenance;
