import React from "react";
import Navbar2 from "../../PropertyManagement/PropertyManagementJS/DashboardNavbar";
import InfiniteCardSlider from "../Advertising/ClientInfiniteCardSlider";

const ServiceProviderDashboard = () => {
  return (
    <div>
      <Navbar2 />
      <InfiniteCardSlider />
    </div>
  );
};

export default ServiceProviderDashboard;
