import changeTheState from "./loginLogout";
import unallocatedReceipts from "./unAllocatedReducer";
import changeLoginState from "./loginstatred";
import selectStatements from "./statements";
import { combineReducers } from "redux";
import UnapprovedReceiptred from "./UnapprovedReceiptred";
import EmployeeMasterEditReducer from "./EmployeeMasterEditReducer";

const rootReducers = combineReducers({
  changeTheState,
  changeLoginState,
  selectStatements,
  UnapprovedReceiptred,
  unallocatedReceipts,
  EmployeeMasterEditReducer,
});

export default rootReducers;
