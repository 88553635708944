const initialState = {};
const unallocatedReceipts = (state = initialState, action) => {
  switch (action.type) {
    case "UNALLOCATEDRECEIPTS":
      return action.data;

    default:
      return state;
  }
};

export default unallocatedReceipts;
