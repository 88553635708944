import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
function Inspection() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [InspectionArea, setInspectionArea] = useState([]);
  function inspectionarea() {
    axios
      .get(`${BASEURL}/getinspectionlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setInspectionArea(response.data);
      });
  }
  useEffect(() => {
    inspectionarea();
  }, []);
  const DropDownlist = ["NC", "NP", "NR", "SC", "NSC", "NSP", "RP", "OTHER"];

  const groupedInspections = InspectionArea.reduce((acc, item) => {
    const key = item.INSPECTIONAREAS;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
  console.log(groupedInspections);
  return (
    <div>
      {" "}
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1_  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "Inspection"}
                </h1>
              </header>
              <main className="main__style__home">
                <section className="section__newgarage__">
                  <aside>
                    <div className="details__capture">
                      <div className="garage_id_label">
                        <p>NC </p>
                        <p> NEEDS CLEANING</p>
                      </div>

                      <div className="garage_id_label">
                        <p>NP </p>
                        <p> NEEDS PAINTING</p>
                      </div>
                      <div className="garage_id_label">
                        <p>NR </p>
                        <p> NEEDS REPAIR</p>
                      </div>
                      <div className="garage_id_label">
                        <p>SC </p>
                        <p> SCRATCHED</p>
                      </div>
                    </div>
                    <div className="details__capture">
                      <div className="garage_id_label">
                        <p>NSC </p>
                        <p> NEEDS SPOT CLEANING</p>
                      </div>
                      <div className="garage_id_label">
                        <p>NSP </p>
                        <p> NEEDS SPOT PAINTING</p>
                      </div>
                      <div className="garage_id_label">
                        <p>RP </p>
                        <p> NEEDS REPLACING</p>
                      </div>
                      <div className="garage_id_label">
                        <p style={{ width: "200px" }}>OTHER </p>
                        <p></p>
                      </div>
                    </div>
                  </aside>
                </section>

                <div className="service_detials__">
                  {/* Render tables based on grouped inspection data */}
                  {Object.keys(groupedInspections).map((inspectionArea) => (
                    <div
                      key={inspectionArea}
                      style={{ display: "flex" }}
                      className="iside__grid__postion"
                    >
                      <p className="table_grid">
                        <p
                          style={{
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <b>{inspectionArea}</b>
                        </p>
                        {groupedInspections[inspectionArea].map((item) => (
                          <p
                            style={{
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p key={item.INSPECTIONITEM}>
                              {item.INSPECTIONITEM} :{" "}
                            </p>
                          </p>
                        ))}
                      </p>
                      <table className="table_1">
                        <thead>
                          <tr>
                            <td>MOVE-IN</td>
                            <td>MOVE-OUT</td>
                          </tr>
                        </thead>

                        <tbody>
                          {groupedInspections[inspectionArea].map((item) => (
                            <tr className="tr_values" key={item.INSPECTIONITEM}>
                              <td>
                                <p style={{ display: "flex" }}>
                                  <input
                                    type="checkbox"
                                    className="checkbox__inspect"
                                  />
                                  <select className="select__inspect">
                                    {DropDownlist.map((option) => (
                                      <option key={option}>{option}</option>
                                    ))}
                                  </select>
                                </p>
                              </td>
                              <td>
                                <p style={{ display: "flex" }}>
                                  <input
                                    type="checkbox"
                                    className="checkbox__inspect"
                                  />

                                  <select className="select__inspect">
                                    {DropDownlist.map((option) => (
                                      <option key={option}>{option}</option>
                                    ))}
                                  </select>
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </main>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inspection;
