import React, { useContext } from "react";
import "./Navbar.css";

import { Link, useNavigate } from "react-router-dom";
import { logIn } from "../../Redux/Action";

import { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CgProfile } from "react-icons/cg";
import { AuthContext } from "../../context/AuthContext";

const Navbar = () => {
  const myState = useSelector((state) => state.changeTheState);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAuthUser } = useContext(AuthContext);

  const [logoutVisible, setLogoutVisible] = useState(false);

  const ulRef = useRef(null);

  function logOut() {
    dispatch(logIn([]));
    navigate("/login");
    setAuthUser(null);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setLogoutVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleLogout = () => {
    setLogoutVisible(!logoutVisible);
  };

  return (
    <div>
      <nav className=" bg-gradient-to-r from-green-100 to-blue-100 hover:from-green-200 hover:to-blue-200">
        <header className="flex items-center justify-between px-[100px] py-2">
          <h2 className="text-[25px] font-bold">EazyPay</h2>
          <div className="flex gap-8 font-[500]">
            <h5>
              <a href="#Solution"> Solution</a>
            </h5>
            <h5>
              {" "}
              <Link to="/">
                {" "}
                <a href="#About">About</a>
              </Link>
            </h5>
            <h5>
              {" "}
              <a href="#Support">Support</a>
            </h5>
            <h5>
              {" "}
              <a href="#Product">Product</a>
            </h5>
          </div>
          <div>
            <header>
              {/* <ProfileIcon onClick={handleProfileClick} /> */}
              <button className="Logout__btn log__only" onClick={logOut}>
                Sign In
              </button>
              {/* {logoutVisible && <LogoutOption />} */}
            </header>
          </div>
        </header>
      </nav>
    </div>
  );
};
export default Navbar;
