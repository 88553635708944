import React, { useEffect, useState, useContext } from "react";
import "./ClientLogin.css";
import io from "socket.io-client";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { logIn, logOut } from "../../Redux/Action/index";
import Axios from "axios";
import { legacy_createStore } from "redux";

import { logInStatus } from "../../Redux/Action/loginstatus";
import LoadingSpinner from "../../Components/Loading/Loading";

import LoginImg from "../../Images/payLog.PNG";
// import Footer from "./Footer1";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/ClientNavbarLogin";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const { setAuthUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errData, setErrData] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const myState = useSelector((state) => state.changeTheState);
  const [servererror, setserverError] = useState("");
  const loginState = useSelector((state) => state.changeLoginState);
  const [module, setModule] = useState(null);
  console.log(module);
  let serverResponse = true;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();
  // const socket = io("http://localhost:3001");

  const options = [
    { value: "TENANT PORTAL", label: "TENANT" },
    { value: "MANAGER PROPERTY MANAGEMENT", label: "PROPERTY MANAGER" },
    { value: "SERVICE PROVIDER PORTAL", label: "SERVICE PROVIDER" },
  ];

  function clearState() {
    dispatch(logIn([]));
    dispatch(logInStatus("FALSE"));
  }

  useEffect(() => {
    clearState();
  }, []);

  function handleClick() {
    setIsLoading(true);

    if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Invalid email format");
      setIsLoading(false);
      return;
    }

    if (!module) {
      alert("Please select a module");
      setIsLoading(false);
      return;
    }

    try {
      Axios.post(`${BASEURL}/login`, {
        email: email,
        password: password,
        MODULE: module.value,
      })
        .then((response) => {
          console.log(response);
          if (response.data.message) {
            setIsLoading(false);
            setserverError(response.data.message);
            dispatch(logIn([])); // Clear previous login data
            dispatch(logInStatus("FALSE"));
          } else {
            dispatch(logIn(response.data));
            dispatch(logInStatus("TRUE"));
            window.sessionStorage.setItem(
              "auth-token",
              response.data.authenticationToken
            );
            window.sessionStorage.setItem(
              "session-token",
              response.data.sclientSecret
            );
            setAuthUser(response.data.authenticationToken);
            console.log(response.data);

            if (module.value === "TENANT PORTAL") {
              navigate("/ClientMainDashboard");
            } else if (module.value === "MANAGER PROPERTY MANAGEMENT") {
              navigate("/PropertyManagerDashboard");
            } else if (module.value === "SERVICE PROVIDER PORTAL") {
              navigate("/ServiceProviderDashboard");
            }

            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setserverError("Wrong username or password");
            } else if (error.response.status === 501) {
              setserverError("Internal server error");
            }
          } else {
            setserverError(
              "No response from server or unmatched email or password"
            );
          }
          setIsLoading(false);
        });
    } catch {
      setserverError("No response from server or unmatched email or password");
      setIsLoading(false);
    }
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const renderList = (
    <div>
      {/* <Navbar /> */}
      <div className="for__amin__login__size">
        {/* company name  */}

        {/* login page heading  */}

        <div className="header">
          <picture className="pic__name">
            <p className="company__name__top text-[25px]">
              Property Management
            </p>
            <img
              className="image__style"
              src={LoginImg}
              alt=""
              width="500"
              height="300"
            />
            <h1 className="company__name__bottom">Property Management</h1>
          </picture>
          {/* form for admin login starts here  */}
          <div className="form">
            <main className="mainpage">
              <h2 className="headeruselogin text-[20px]">Login Page</h2>
              <p className="loginerror">
                <span className="skew-shake-x text-sm">
                  {errData} {servererror}
                </span>
              </p>
              <div className=" mb-2">
                {/* dropdown for module selection */}
                <label>Select Module</label>
                <Select
                  id="moduleSelect"
                  autoComplete="off"
                  options={options}
                  onChange={(selectedOption) => setModule(selectedOption)}
                  placeholder="Select Module"
                />
              </div>

              <div className="form_group">
                {/* email address is taken here as input  */}
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className={`form_control input`}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* a secure message for user so that they can enter their e-mail securely */}
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form_group">
                {/* password is taken here as input  */}
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form_control input"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <aside className="aside__forgot__and__Show">
                  {/* Forgot Password option is given so that the user can reset the password  */}
                  <div className="">
                    <Link to="/ForgotPwd">
                      <span className="forgotpass">forgot password</span>
                    </Link>
                  </div>
                  <div className="checkbox__show__password">
                    <p className="show_p_para"> Show Password</p>
                    <input
                      className="checkboxninput"
                      type="checkbox"
                      onClick={togglePassword}
                      checked={passwordShown}
                    />
                  </div>
                </aside>
              </div>

              <div className="loginpagebtn">
                <button
                  disabled={!serverResponse}
                  onClick={handleClick}
                  type="submit"
                  className="submitbutton1"
                >
                  Login
                </button>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );

  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};

export default Login;
