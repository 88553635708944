import { React, useState, useEffect, useRef } from "react";
import Navbar1 from "../../PropertyManagement/PropertyManagementJS/MenuNavbar";
import axios from "axios";
import "../../PropertyManagement/PropertyManagementCss/ProjectMaster.css";
import { BiSolidEditAlt } from "react-icons/bi";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

const MyEnqueries = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const menu = location.state ? location.state.menu : null;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const [showCancel, setShowCancel] = useState(false);
  const [cancelRequestId, setCancelRequestId] = useState("");
  const [selCancelStatus, setSelCancelStatus] = useState("");

  const [showPayment, setShowPayment] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [paymentRequestId, setPaymentRequestId] = useState("");
  const [editableRow, setEditableRow] = useState(null);
  const editButton = useRef(false);

  const [selAction, setSelAction] = useState({});
  const [confirmStatus, setConfirmStatus] = useState({});

  const handleActionChange = async (e, requestId) => {
    if (e.value === "") {
      alert("Kindly Supply proper Action");
      setSelAction((prev) => ({ ...prev, [requestId]: "" }));
    } else {
      setSelAction((prev) => ({ ...prev, [requestId]: e.value }));
      if (e.value === "CANCEL") {
        setCancelRequestId(requestId);
        setShowCancel(true);
      } else {
        await Save(requestId);
        await SaveWorkStatus("STARTED", requestId);
        setConfirmStatus((prev) => ({ ...prev, [requestId]: "STARTED" }));
        window.location.reload();
      }
    }
  };

  const handleConfirmStatusChange = async (e, requestId) => {
    if (e.value === "") {
      alert("Kindly Supply proper Confirm Status");
      setConfirmStatus((prev) => ({ ...prev, [requestId]: "" }));
    } else {
      setConfirmStatus((prev) => ({ ...prev, [requestId]: e.value }));
      await SaveWorkStatus(e.value, requestId);
      window.location.reload();
    }
  };

  const filteredData = tableData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.REQUESTDATE;
    const eDate = item.REQUESTDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        "REQUESTID",
        "FROMOPROPERTYNAME",
        "PROPERTYUNITID",
        "TENANTNAME",
        "REQUESTSTATUS",
        "STATUSREASON",
        "STATUSUPDATEDBY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    getServiceRequests();
  }, []);

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "100px",
      maxWidth: "100px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      zIndex: "50",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const customStyless1 = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      zIndex: "50",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const getServiceRequests = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/getServiceRequests?EMAILADDRESS=${username}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response?.data);
        const filteredData = response?.data.filter(
          (item) =>
            item.STATUSUPDATEDBYCLIENTORPROVIDER !== "TENANT" &&
            item.REQUESTSTATUS !== "CANCEL"
        );
        console.log(filteredData);

        setTableData(filteredData);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching the Service Requests", error.message);
      });
  };

  async function Save(reqno) {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/updateserviceRequestStatus`,
        {
          REQUESTNO: reqno,
          STATUS: showCancel === true ? "CANCEL" : "ACCEPT",
          STATUSREASON: showCancel === true ? selCancelStatus : "",
          UPDATEDBYACCOUNT: username?.split("@")[0].toUpperCase(),
          UPDATEDBYACCOUNTTYPE: "SERVICE PROVIDER",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert(error.response.data.message);
    }
  }

  async function SaveWorkStatus(status, reqno) {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/updateWorkRequestStatus`,
        {
          REQUESTNO: reqno,
          WORKSTATUS: status,
          UPDATEDBYACCOUNT: username?.split("@")[0].toUpperCase(),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert(error.response.data.message);
    }
  }

  async function SavePaymentStatus() {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/updatepaymentStatus`,
        {
          REQUESTNO: paymentRequestId,
          PAYMENTSTATUS: 1,
          PAYMENTAMOUNT: Number(paymentAmount),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <header className="header__of__main px-4">
        <h1 className="header__of__page "> {menu ? menu : "My Enqueries"}</h1>
      </header>
      <div className="lg:flex items-center gap-3 ">
        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
        </div>
        <div className="mx-8 lg:flex items-center gap-4">
          <p className="lg:ml-4">Start Date:</p>
          <input
            className="w-[130px]"
            type="date"
            value={startdate.split("T")[0]}
            onChange={(e) => {
              setstartdate(e.target.value);
            }}
          ></input>
          <p className="lg:ml-4">End Date:</p>
          <input
            className="w-[130px]"
            type="date"
            value={enddate.split("T")[0]}
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <div className="  overflow-auto relative lg:flex justify-center p-2 ">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>REQUEST ID</td>
              <td>Property Name</td>
              <td>Unit Name</td>
              <td>Client Name</td>
              <td>REQUEST DATE & TIME</td>
              <td>REQUEST STATUS</td>
              <td>STATUS REASON</td>
              <td>STATUS DATE & TIME</td>
              <td>ACTION</td>
              <td>Work&nbsp;Status</td>
              <td>Payment Status</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  key={index}
                  style={{ textTransform: "uppercase", fontSize: "12px" }}
                >
                  <td style={{ paddingLeft: "54px" }}>
                    {item.REQUESTID ? item.REQUESTID : "NA"}
                  </td>
                  <td>
                    {item.FROMOPROPERTYNAME ? item.FROMOPROPERTYNAME : "NA"}
                  </td>
                  <td>{item.PROPERTYUNITID ? item.PROPERTYUNITID : "NA"}</td>
                  <td>{item.TENANTNAME ? item.TENANTNAME : "NA"}</td>
                  <td>
                    {item.REQUESTDATE
                      ? moment(item.REQUESTDATE).format("DD/MM/YYYY HH:mm:ss")
                      : "NA"}
                  </td>
                  <td>{item.REQUESTSTATUS ? item.REQUESTSTATUS : "NA"}</td>

                  <td>{item.STATUSREASON ? item.STATUSREASON : "NA"}</td>
                  <td>
                    {item.STATUSUPDATEDDATE
                      ? moment(item.STATUSUPDATEDDATE).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )
                      : "NA"}
                  </td>
                  <td>
                    {item.REQUESTSTATUS !== "PENDING" ? (
                      item.REQUESTSTATUS
                    ) : (
                      <div style={{ zIndex: 200 }}>
                        <Select
                          styles={customStyless}
                          onChange={(e) =>
                            handleActionChange(e, item.REQUESTID)
                          }
                          value={
                            selAction[item.REQUESTID]
                              ? {
                                  label: selAction[item.REQUESTID],
                                  value: selAction[item.REQUESTID],
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            { value: "ACCEPT", label: "ACCEPT" },
                            { value: "CANCEL", label: "CANCEL" },
                          ]}
                        />
                      </div>
                    )}
                  </td>
                  {/* <td>
                    {item.WORKSTATUS ? (
                      item.WORKSTATUS
                    ) : (
                      <div style={{ zIndex: 200 }}>
                        <Select
                          styles={customStyless}
                          onChange={(e) =>
                            handleConfirmStatusChange(e, item.REQUESTID)
                          }
                          value={
                            confirmStatus[item.REQUESTID]
                              ? {
                                  label: confirmStatus[item.REQUESTID],
                                  value: confirmStatus[item.REQUESTID],
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            { value: "STARTED", label: "STARTED" },
                            {
                              value: "WIP",
                              label: "WIP",
                            },
                            { value: "COMPLETE", label: "COMPLETE" },
                          ]}
                        />
                      </div>
                    )}
                    {item.WORKSTATUS === "STARTED" ||
                    item.WORKSTATUS === "WIP" ? (
                      <FiEdit size={25} />
                    ) : (
                      ""
                    )}
                  </td> */}
                  <td>
                    <section className="flex items-center justify-between gap-2">
                      {editableRow === item.REQUESTID ? (
                        <div style={{ zIndex: 200 }}>
                          <Select
                            styles={customStyless}
                            onChange={(e) => {
                              if (e.value === item.WORKSTATUS) {
                                alert(
                                  "This is the current Work Status , kindly choose other"
                                );
                              } else if (
                                e.value === "STARTED" &&
                                item.WORKSTATUS === "WIP"
                              ) {
                                alert("You had already marked this as STARTED");
                              } else {
                                handleConfirmStatusChange(e, item.REQUESTID);
                              }
                            }}
                            value={
                              confirmStatus[item.REQUESTID]
                                ? {
                                    label: confirmStatus[item.REQUESTID],
                                    value: confirmStatus[item.REQUESTID],
                                  }
                                : { label: "", value: "" }
                            }
                            options={[
                              { value: "", label: "" },
                              { value: "STARTED", label: "STARTED" },
                              { value: "WIP", label: "WIP" },
                              { value: "COMPLETE", label: "COMPLETE" },
                            ]}
                          />
                        </div>
                      ) : (
                        <p>{item.WORKSTATUS}</p>
                      )}

                      {editableRow !== item.REQUESTID
                        ? (item.WORKSTATUS === "STARTED" ||
                            item.WORKSTATUS === "WIP") && (
                            <FiEdit
                              size={20}
                              onClick={() =>
                                setEditableRow(
                                  editableRow === item.REQUESTID
                                    ? null
                                    : item.REQUESTID
                                )
                              }
                            />
                          )
                        : ""}
                    </section>
                  </td>
                  <td>
                    {item.PAYMENTSTATUSAMOUNT > 0 ? (
                      item.PAYMENTSTATUSAMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    ) : (
                      <button
                        className="p-1 font-semibold bg-sky-200"
                        onClick={() => {
                          if (item.WORKSTATUS === "COMPLETE") {
                            setPaymentRequestId(item.REQUESTID);
                            setShowPayment(true);
                          } else {
                            alert(
                              "Kindly Complete the Work, To proceed with payment"
                            );
                          }
                        }}
                      >
                        Process Payment
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {showCancel && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                maxWidth: "800px",
                minHeight: "80vh",
                width: "100%",
              }}
            >
              <div className="flex justify-between cursor-pointer">
                <header className="header__of__main">
                  <h1 className="header__of__page">Reason For Cancel</h1>
                </header>

                <AiOutlineCloseCircle
                  size={30}
                  onClick={() => setShowCancel(false)}
                />
              </div>
              <div className="flex lg:items-center gap-2 m-5 lg:flex-row flex-col">
                {" "}
                <p>Status Reason</p>
                <Select
                  className="lg:w-[500px] test-sm"
                  value={
                    selCancelStatus
                      ? { value: selCancelStatus, label: selCancelStatus }
                      : { value: "", label: "" }
                  }
                  onChange={(e) => {
                    setSelCancelStatus(e.value);
                  }}
                  options={[
                    {
                      value: "Client did not Pick the call",
                      label: "Client did not Pick the call",
                    },
                    {
                      value: "I am too far from the Site",
                      label: "I am too far from the Site",
                    },
                    { value: "I am busy", label: "I am busy" },
                    {
                      value: "I do not have the resources",
                      label: "I do not have the resources",
                    },
                    {
                      value: "I do not have Transport to reach the site",
                      label: "I do not have Transport to reach the site",
                    },
                    {
                      value:
                        "I do not have the capability to provide the service asked for",
                      label:
                        "I do not have the capability to provide the service asked for",
                    },
                  ]}
                ></Select>
                <button
                  className="button "
                  onClick={async () => {
                    if (!selCancelStatus) {
                      alert("Kindly Select the reason to Cancel");
                    } else {
                      await Save(cancelRequestId);
                      setShowCancel(false);
                      window.location.reload();
                    }
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {showPayment && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                maxWidth: "800px",
                minHeight: "80vh",
                width: "100%",
              }}
            >
              <div className="flex justify-between cursor-pointer">
                <header className="header__of__main">
                  <h1 className="header__of__page"> Process Payment</h1>
                </header>
                <AiOutlineCloseCircle
                  size={30}
                  onClick={() => setShowPayment(false)}
                />{" "}
              </div>
              <div className="lg:flex items-center gap-2 m-5">
                <p>Payment Amount</p>
                <input
                  className="w-32 p-1 h-7 rounded-md"
                  type="number"
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(e.target.value)}
                ></input>
                <button
                  className="button"
                  onClick={async () => {
                    if (!paymentAmount) {
                      alert("Kindly Supply Payment amount");
                    } else {
                      await SavePaymentStatus();
                      setShowPayment(false);
                      window.location.reload();
                    }
                  }}
                >
                  Pay
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyEnqueries;
