import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import Loading from "../../Components/Loading/Loading";
import NavbarDashboard from "../../Pages/NavbarClient/ClientMenuNavbar";
import { statementPass } from "../../Redux/Action/selectStatements";
import jsPDF from "jspdf";

function ResidenceTest() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const Statement = location.state;
  console.log(Statement?.inspectionData?.CLIENTCODE[0]);
  console.log(menu);
  const [isLoading, setIsLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [InspectionArea, setInspectionArea] = useState([]);
  const [inspectionAreasitem, setInspectionAreasitem] = useState({});
  const [Companydetails, setCompanydetails] = useState([]);
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState.userCode);
  function fetchInitialData() {
    setIsLoading(true);

    try {
      axios
        .post(
          `${BASEURL}/getclientdetails`,
          {
            CLIENTCODE: Statement?.inspectionData?.CLIENTCODE,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(response.data[0]);
          if (response?.data?.length === 0) {
            alert("Data Not found");
          }
          setClientDetails(response.data[0] ? response?.data[0] : {});
          setIsLoading(false);
        });
    } catch (err) {
      alert(err?.response?.data?.message);
    }
    try {
      axios
        .get(
          `${BASEURL}/getinspectionlist`,

          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(response.data[0]);
          setInspectionArea(response.data ? response.data : {});
          setIsLoading(false);
        });
    } catch (err) {
      alert(err?.response?.data?.message);
    }
    try {
      axios
        .get(
          `${BASEURL}/getcompanydetails`,

          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(response.data[0]);
          setCompanydetails(response.data[0].ORG_NAME);
          setIsLoading(false);
        });
    } catch (err) {
      alert(err?.response?.data?.message);
    }
  }
  useEffect(fetchInitialData, []);
  useEffect(() => {
    let inspectionAreas__ = {};
    for (let i = 0; i < InspectionArea.length; i++) {
      const area = InspectionArea[i].INSPECTIONAREAS;
      const item = InspectionArea[i].INSPECTIONITEM;
      if (!inspectionAreas__[area]) {
        inspectionAreas__[area] = [];
      }
      inspectionAreas__[area].push(item);
    }
    setInspectionAreasitem(inspectionAreas__);
  }, [InspectionArea]);

  useEffect(() => {
    if (!isLoading && Object?.keys(clientDetails)?.length > 0) {
      generatePDF();
    }
  }, [isLoading, clientDetails]);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    let contentAdded = false;
    // Function to generate the table

    // Define header content
    const header = () => {
      doc.setFontSize(12);
      doc.setTextColor(0);
      doc.text(`${clientDetails.PROJECT}`, 10, 10);
    };
    let comp = Companydetails;
    // Define footer content
    const footer = (pageNum, numPages) => {
      doc.setFontSize(10);
      const companyName = `${comp}`; // Retrieve this from the database
      const textWidth = doc.getStringUnitWidth(`Managed by: ${companyName}`);
      const xPosition = doc.internal.pageSize.width - 60 - textWidth;
      const yPosition1 = doc.internal.pageSize.height - 10;

      doc.text(`Managed by: ${companyName}`, xPosition, yPosition1);

      const textWidth2 = doc.getStringUnitWidth("- from Database");
      const yPosition2 = yPosition1 + 5;
      // doc.text("- from Database", xPosition, yPosition2);
    };

    // const generateTable = (data, startX, startY) => {
    //   const tableColumns = ["BEDROOM", "MOVE-IN", "MOVE-OUT"];
    //   const tableRows = [];

    //   // Assuming data is an array of strings for both MOVE-IN and MOVE-OUT
    //   for (let i = 0; i < data.length; i++) {
    //     const rowData = [data[i], "", ""]; // Initialize each row with empty MOVE-IN and MOVE-OUT columns
    //     tableRows.push(rowData);
    //   }

    //   doc.autoTable({
    //     startY,
    //     head: [tableColumns],
    //     body: tableRows,
    //     tableWidth: 100, // Set the width of the table
    //   });
    // };
    // const generateTable2 = (data, startX, startY) => {
    //   const tableColumns = ["FURNITURE INV.", "MOVE-IN", "MOVE-OUT"];
    //   const tableRows = [];

    //   // Assuming data is an array of strings for both MOVE-IN and MOVE-OUT
    //   for (let i = 0; i < data.length; i++) {
    //     const rowData = [data[i], "", ""]; // Initialize each row with empty MOVE-IN and MOVE-OUT columns
    //     tableRows.push(rowData);
    //   }

    //   doc.autoTable({
    //     startY,
    //     head: [tableColumns],
    //     body: tableRows,
    //     tableWidth: 100, // Set the width of the table
    //   });
    // };
    // const generateTable3 = (data, startX, startY) => {
    //   const tableColumns = ["BATHROOM", "MOVE-IN", "MOVE-OUT"];
    //   const tableRows = [];

    //   // Assuming data is an array of strings for both MOVE-IN and MOVE-OUT
    //   for (let i = 0; i < data.length; i++) {
    //     const rowData = [data[i], "", ""]; // Initialize each row with empty MOVE-IN and MOVE-OUT columns
    //     tableRows.push(rowData);
    //   }

    //   doc.autoTable({
    //     startY,
    //     head: [tableColumns],
    //     body: tableRows,
    //     tableWidth: 100, // Set the width of the table
    //   });
    // };

    // const generateTable4 = (data, startX, startY) => {
    //   const tableColumns = ["KITCHEN", "MOVE-IN", "MOVE-OUT"];
    //   const tableRows = [];

    //   // Assuming data is an array of strings for both MOVE-IN and MOVE-OUT
    //   for (let i = 0; i < data.length; i++) {
    //     const rowData = [data[i], "", ""]; // Initialize each row with empty MOVE-IN and MOVE-OUT columns
    //     tableRows.push(rowData);
    //   }

    //   doc.autoTable({
    //     startY,
    //     head: [tableColumns],
    //     body: tableRows,
    //     tableWidth: 100, // Set the width of the table
    //   });
    // };
    // const generateTable5 = (data, startX, startY) => {
    //   const tableColumns = ["MECHANICAL", "MOVE-IN", "MOVE-OUT"];
    //   const tableRows = [];

    //   // Assuming data is an array of strings for both MOVE-IN and MOVE-OUT
    //   for (let i = 0; i < data.length; i++) {
    //     const rowData = [data[i], "", ""]; // Initialize each row with empty MOVE-IN and MOVE-OUT columns
    //     tableRows.push(rowData);
    //   }

    //   doc.autoTable({
    //     startY,
    //     head: [tableColumns],
    //     body: tableRows,
    //     tableWidth: 100, // Set the width of the table
    //   });
    // };
    // const generateTable6 = (data, startX, startY) => {
    //   const tableColumns = ["SHARED SPACE", "MOVE-IN", "MOVE-OUT"];
    //   const tableRows = [];

    //   // Assuming data is an array of strings for both MOVE-IN and MOVE-OUT
    //   for (let i = 0; i < data.length; i++) {
    //     const rowData = [data[i], "", ""]; // Initialize each row with empty MOVE-IN and MOVE-OUT columns
    //     tableRows.push(rowData);
    //   }

    //   doc.autoTable({
    //     startY,
    //     head: [tableColumns],
    //     body: tableRows,
    //     tableWidth: 100, // Set the width of the table
    //   });
    // };

    const generateTablesOnPage = (data1, data2, startX, startY) => {
      const tableColumns = ["BEDROOM", "MOVE-IN", "MOVE-OUT"];
      const tableColumns2 = ["FURNITURE-IN", "MOVE-IN", "MOVE-OUT"];
      const tableRows1 = data1.map((item) => [item, "", ""]);
      const tableRows2 = data2.map((item) => [item, "", ""]);
      const tableHeight = (tableRows1.length + tableRows2.length) * 5; // Adjust height as needed

      doc.autoTable({
        startY,
        head: [tableColumns],
        body: tableRows1,
        tableWidth: 100,
      });

      doc.autoTable({
        startY: startY + tableHeight + 10, // Add some space between tables
        head: [tableColumns2],
        body: tableRows2,
        tableWidth: 100,
      });
    };
    const generateTablesOnPage2 = (
      data1,
      data2,

      startX,
      startY
    ) => {
      const tableColumns = ["BATHROOM", "MOVE-IN", "MOVE-OUT"];
      const tableColumns2 = ["KITCHEN", "MOVE-IN", "MOVE-OUT"];
      // const tableColumns3 = ["MECHANICAL", "MOVE-IN", "MOVE-OUT"];
      // const tableColumns4 = ["SHARED SPACE", "MOVE-IN", "MOVE-OUT"];
      const tableRows1 = data1.map((item) => [item, "", ""]);
      const tableRows2 = data2.map((item) => [item, "", ""]);
      // const tableRows3 = data3.map((item) => [item, "", ""]);
      // const tableRows4 = data4.map((item) => [item, "", ""]);
      const tableHeight = (tableRows1.length + tableRows2.length) * 5; // Adjust height as needed

      doc.autoTable({
        startY,
        head: [tableColumns],
        body: tableRows1,
        tableWidth: 100,
      });

      doc.autoTable({
        startY: startY + tableHeight + 5, // Add some space between tables
        head: [tableColumns2],
        body: tableRows2,
        tableWidth: 100,
      });
      // doc.autoTable({
      //   startY: startY + tableHeight + 5, // Add some space between tables
      //   head: [tableColumns3],
      //   body: tableRows3,
      //   tableWidth: 100,
      // });
      // doc.autoTable({
      //   startY: startY + tableHeight + 5, // Add some space between tables
      //   head: [tableColumns4],
      //   body: tableRows4,
      //   tableWidth: 100,
      // });
    };
    const generateTablesOnPage3 = (data1, data2, startX, startY) => {
      const tableColumns = ["MECHANICAL", "MOVE-IN", "MOVE-OUT"];
      const tableColumns2 = ["SHARED SPACE", "MOVE-IN", "MOVE-OUT"];
      const tableRows1 = data1.map((item) => [item, "", ""]);
      const tableRows2 = data2.map((item) => [item, "", ""]);
      const tableHeight = (tableRows1.length + tableRows2.length) * 10; // Adjust height as needed

      doc.autoTable({
        startY,
        head: [tableColumns],
        body: tableRows1,
        tableWidth: 100,
      });

      doc.autoTable({
        startY: startY + tableHeight + 10, // Add some space between tables
        head: [tableColumns2],
        body: tableRows2,
        tableWidth: 100,
      });
    };
    // Function to generate PDF for each section
    const generateSectionPDF = (contentLines) => {
      if (contentLines.length > 0) {
        if (!contentAdded) {
          contentAdded = true;
        } else {
          doc.addPage(); // Add a new page if content exists and content has been added previously
        }
        header(); // Add header to new page
        const wrapWidth = 550;
        let yPosition = 50;

        contentLines.forEach((line) => {
          const lines = splitTextIntoLines(line, wrapWidth, doc);
          lines.forEach((line) => {
            doc.text(10, yPosition, line);
            yPosition += 6; // Reduced vertical space between lines
          });
          yPosition += 2; // Reduced space after each point
          if (yPosition > doc.internal.pageSize.height - 20) {
            doc.addPage(); // Add a new page if content exceeds page height
            yPosition = 10; // Reset y position for new page
            header(); // Add header to new page
          }
        });
      }
    };

    // Function to split text into lines that fit within the wrap width
    const splitTextIntoLines = (text, wrapWidth, doc) => {
      const words = text.split(" ");
      const lines = [];
      let currentLine = "";

      words.forEach((word) => {
        const width =
          doc.getStringUnitWidth(currentLine + word) *
          doc.internal.getFontSize();
        if (width < wrapWidth) {
          currentLine += (currentLine === "" ? "" : " ") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      });

      lines.push(currentLine); // Push the last line
      return lines;
    };

    // Add content to the document for the next page

    var termStart = "______________";
    // Define the content for the new resident contract section
    var residentContractPage1 = [
      `.                                        New Resident Contract`,
      "",
      `Room Number: ___ _____________ Room Type:(Executive/Twin Executive/Twin/Triple/Quad)`,
      "Bed Assigned (if applicable): ________________",
      `Student First Name: ${clientDetails.CLIENTNAME}`,
      `Last Name: ${clientDetails.STUDENTLASTNAME}`,

      `Date of Birth: ${clientDetails.DOB}`,
      "Home Address: ",
      `ID/Passport: ${clientDetails.STUDENTIDPASSPORT}`,
      `University: ${clientDetails.STUDENTUNIVERSITY}`,
      `Email: ${clientDetails.STUDENTEMAIL}`,
      `Contact Number:${clientDetails.CONTACTNUMBER}`,
      "Do you have any food allergies or restrictions that we must be aware of?",
      "Yes ___  No ___.",
      "If yes, please outline them: ____________________",
      "Do you have any chronic illnesses that we must be aware of?",
      "Yes ___  No ___.",
      "If yes, please outline them: ____________________",
    ];

    // Define the content for the next page
    var residentContractPage2 = [
      "Tick to confirm you received and understood",

      ".     Resident Handbook",

      ".     Room Key",

      ".     Move-in & Move-out Inventory Checklist",
      "",
      "Guardian Details (Will be treated as Guarantor)",
      `Name of Guardian: ${clientDetails.GUARDIANCONTACTNAME}`,
      `National ID/Passport No.: ${clientDetails.GUARDIANIDPASSPORT}`,
      `Contact Number: ${clientDetails.EMERGENCYCONTACTPHONE}`,
      `Physical Address: ${clientDetails.GUARDIANPHYSICALADDRESS}`,
      `P.O. Box: ${clientDetails.GUARDIANBOX}`,
      `Email: ${clientDetails.GUARDIANEMAIL}`,
      "",
      "Emergency Contacts Information",
      "In the event of an emergency, the following individuals should be contacted:",
      `Name: ${clientDetails.EMERGENCYCONTACTNAME}`,
      `Phone Number:${clientDetails.EMERGENCYCONTACTPHONE}`,
      "Email Address: ____________________",
      "",
      "I acknowledge and confirm that the information provided is correct and true to the best of my knowledge and belief:",
      "Signature: ____________________",
    ];
    // Define the content for the "For Official Use Only" section
    var forOfficialUseContent = [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ];

    const residentAgreementLines = [
      ".                           Resident Agreement",
      "",
      'This agreement ("Agreement") is made and entered into on the date of acceptance of this contract by the Resident ("Resident"), and by the Owner/Operator of the student hostel ("Owner/Operator*).',
      "",
      '1. Premises. The Owner/Operator in consideration of the rent, hereby leases to the Resident the right to occupy a room in the student hostel located at Muhoho Avenue, South C, Nairobi (the "Premises").',
      "",
      "2. Term. The term of this Agreement shall begin on _________ and shall be on a fixed period of _________ (months/years) and continue thereafter on a monthly basis, with a minimum stay requirement of three months, unless terminated by either party.",
      "",
      "3. A deposit equal to one month's rent is due at the time of registration.",
      "",
      "4. Rent. The Resident shall pay rent to the Owner/Operator in the amount of _________ Ksh per month. Rent shall be due on the 5th of each month and shall be considered late if not received by the Owner/Operator by this date. A late fee of 3% of total rent shall be charged per day for any rent received after the 5th of every month.",
      "",
      "5. Utilities. The Resident shall be responsible for paying their own utility bills (electricity) unless otherwise specified in the lease.",
      "",
      "6. Quiet hours. The Resident shall respect the rights of other residents by observing quiet hours between the hours of 10 PM - 6 AM.",
      "",
    ];

    // Define the content for the new page
    const newPageContent = [
      "7. Guests. The Resident shall be allowed to have guests visit, but the Resident is responsible for their guests and must register them with the hostel management.",
      "",
      "8. Damages. The Resident shall be responsible for any damages caused to the Premises or the common areas of the student hostel by the Resident, the Resident's guests, or the Resident's invitees. Such damages will be assessed through an inspection at the conclusion of the stay, and any applicable costs will be deducted from the Resident's deposit.",
      "",
      "9. Compliance with Laws and Regulations. The Resident acknowledges and agrees that they shall comply with all covenants of the tenancy agreement, whether explicitly set out in the document or implied by law or practice or conveyed through electronic communication, including but not limited to interpretations of the tenancy agreement and relevant clauses that are provided via email. The resident shall comply with applicable laws, ordinances, and hostel regulations as outlined via email and in the handbook, and that failure to comply with any such covenant shall constitute a breach of the tenancy agreement.",
      "",
      "10. Termination. This Agreement may be terminated by the Owner/Operator upon written notice to the Resident for any reason, including but not limited to material breach of this Agreement such as non-payment of rent. The Resident may terminate this agreement by providing written notice to the Owner at least one (1) month prior to the desired date of termination. If the Resident fails to provide such written notice, the Owner may retain the deposit as liquidated damages for the Owner's loss of rent and other costs.",
    ];

    // Define the content for the new page
    const remainingContent = [
      "associated with the early termination of this agreement. This provision shall also apply to residents who violate the minimum stay requirement of three(3) months.",
      "",
      "11. Governing Law. This Agreement shall be governed by and construed in accordance with the laws of Kenya.",
      "",
      "12. Entire Agreement. This Agreement constitutes the entire agreement between the parties hereto and supersedes all prior agreements and understandings, whether oral or written.",
      "",
      "13. Waiver. The failure of either party to enforce any provision of this Agreement shall not be deemed a waiver of the right to enforce such provision in the future.",
      "",
      "14. Severability. If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck, and the remaining provisions shall be enforced.",
      "",
      "IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above written.",
      "",
      "Resident's Signature: ________________ Date: ________________",
      "",
      "Owner/Operator's Signature: ________________ Date: ________________",
      "",
    ];

    // Define the content for the "Move-in and Move-out Inventory Checklist" section
    const inventoryChecklistContent = [
      "Before you move-in and upon moving-out, be sure to carefully complete this checklist.",
      "",
      "TENANT NAME(S): ____________________________________________",
      "BED ASSIGNED: ______________________________________________",
      "TENANT HOME ADDRESS: ________________________________________",
      "",
      "YOU ARE TO COMPLETE THIS CHECKLIST NOTING THE CONDITION OF THE RENTAL UNIT AND RETURN IT TO THE LANDLORD WITHIN 24 HOURS AFTER OBTAINING POSSESSION OF THE RENTAL UNIT.",
      "",
      "MOVE-IN ROOM No.: ___________________      MOVE-OUT ROOM No.: ___________________",
      "MOVE-IN DATE: _______________________      MOVE-OUT DATE: _______________________",
      "INSPECTION DATE: ____________________     INSPECTION DATE: _____________________",
      "INSPECTION TIME: ____________________      INSPECTION TIME: _____________________",
      "BY: _________________________________       BY: __________________________________",
      "",

      "Unless otherwise noted, the premises are in clean, good working order and undamaged. Use Abbreviations below:",
      "",
      "NC: NEEDS CLEANING                 NSC: NEEDS SPOT CLEANING",
      "NP: NEEDS PAINTING                  NSP: NEED SPOT PAINTING",
      "NR: NEEDS REPAIR                     RP: NEEDS REPLACING",
      "SC: SCRATCHED                          OTHER:_____________________________ ",
      "",
    ];
    // Define the content for the "Tenant Move-in and Move-out Property/Room Checklist" section
    // const propertyRoomChecklistContent1 = [
    //   "BEDROOM",
    //   "MOVE-IN MOVE-OUT",
    //   "DOOR & LOCKS",
    //   "WINDOWS & LOCKS",
    //   "SCREENS/ CURTAINS",
    //   "W. PRIVACY SCREENS",
    //   "CURTAIN ROD:",
    //   "SANITARY BIN:",
    //   "FLOOR TILES:",
    //   "WALLS:",
    //   "CEILING:",
    //   "LIGHTS & SWITCHES:",
    //   "ROOM KEY:",
    //   "",
    //   "FURNITURE INV.",
    //   "MOVE-IN MOVE-OUT",
    //   "DESK CHAIR:",
    //   "DESK TABLE:",
    //   "SIDE TABLE",
    //   "BED:",
    //   "CLOSET:",
    //   "CLOSET KEY:",
    //   "SHELVES:",
    //   "MATTRESS",
    //   "MATTRESS PROTECTOR:",
    //   "OTHER:",
    //   "",
    // ];
    // const propertyRoomChecklistContent2 = [
    //   "",
    //   "BATHROOM",
    //   "MOVE-IN MOVE-OUT",
    //   "FLOOR",
    //   "WALL",
    //   "CEILING",
    //   "DOORS & LOCKS",
    //   "CABINETS & DRWS",
    //   "SINK & MIRROR",
    //   "SHOWER & RACKS",
    //   "WINDOW",
    //   "BOWL/SEAT",
    //   "LIGHT & SWITCHES",
    // ];
    // const propertyRoomChecklistContent3 = [
    //   "TENANT MOVE-IN AND MOVE-OUT PROPERTY/ROOM CHECKLIST",
    //   "",
    //   "KITCHEN",
    //   "MOVE-IN MOVE-OUT",
    //   "FLOOR WALL",
    //   "CEILING",
    //   "CABINETS & DRWS",
    //   "SINK & COUNTERS",
    //   "",
    //   "MECHANICAL",
    //   "SHOWER HEATER:",
    //   "SMOKE DETECTOR:",
    //   "K. PLUG ADAPTER:",
    //   "WIFI ROUTER",
    //   "",
    //   "SHARED SPACE",

    //   "MICROWAVE",
    //   "",
    //   "INDUCTION COOKER",
    //   "REFRIGERATOR:",
    //   "KETTLE LIGHT &",
    //   "SWITCHES",
    //   "",
    // ];

    const entry = ["ENTRY", "PAINTING"];
    const exit = ["EXIT", "TEST"];
    const propertyRoomChecklistContent4 = [
      "",
      "MOVE-IN ____________________                                 MOVE-OUT ____________________",
      "DATE: ____________________                                      DATE: ____________________",

      "SIGNATURE: ____________________                          SIGNATURE: ____________________",

      "",
      "I/We (the tenant(s)) understand that unless otherwise noted, all discrepancies will be the tenant's responsibility and will be deducted from the security deposit at the time of move-out.",
      "",
      "MOVE-IN DATE: ____________________                     MOVE-OUT DATE: ____________________",
      "DATE: ____________________                                     DATE: ____________________",
      "LANDLORD/AGENT                                                        LANDLORD/AGENT ",
      "SIGNATURE: _______________________                    SIGNATURE: _______________________",

      "TENANT'S FORWARDING ADDRESS: ____________________________________________________________ ",
      "",
      "Landlord and Tenant acknowledge that video and/or photos (digital or otherwise) have been taken of the premises. The original copies/files are in the possession of the Landlord / Tenant.",
    ];

    // Add content to the document for the next page
    generateSectionPDF(residentContractPage1);
    generateSectionPDF(residentContractPage2);
    generateSectionPDF(forOfficialUseContent);
    // Define the margin
    const topMargin = 20;
    const bottomMargin = 10;

    // Draw a rectangle (box) for the "Bus Transportation" section with margin
    const busTransportationY = 10 + topMargin; // Add top margin
    doc.rect(10, busTransportationY, 180, 60); // x, y, width, height
    // Add text content for "Bus Transportation" section
    doc.text(
      "For Official Use Only",
      15,
      25 // Adjust y-coordinate with margin
    ); // x, y
    doc.text(
      "Bus Transportation: (Yes) ________ (No) ________    Cost: ____________________",
      15,
      busTransportationY + 10 // Adjust y-coordinate with margin
    ); // x, y

    doc.text(
      "Are there any other provisions provided: (Yes) ________ (No) ________",
      15,
      busTransportationY + 20 // Adjust y-coordinate with margin
    ); // x, y
    doc.text(
      "If yes, specify: ____________________",
      15,
      busTransportationY + 30
    ); // x, y
    doc.text(
      "For Official Use Only",
      15,
      busTransportationY + 80 // Adjust y-coordinate with margin25 // Adjust y-coordinate with margin
    ); // x, y
    // Draw a rectangle (box) for the "Certified Copies" section with margin
    const certifiedCopiesY = busTransportationY + 80 + bottomMargin; // Add bottom margin

    doc.rect(10, certifiedCopiesY, 180, 70); // x, y, width, height
    // Add text content for "Certified Copies" section
    doc.text(
      "Tick to confirm you have received & attached certified copies of:",
      15,
      certifiedCopiesY + 10 // Adjust y-coordinate with margin
    ); // x, y
    doc.text(
      "Personal Identification (National ID, Passport, Birth Certificate)",
      15,
      certifiedCopiesY + 20
    ); // x, y
    doc.text(
      "Proof of Study (Admission letter, School ID, Valid Student Visa/Pass)",
      15,
      certifiedCopiesY + 30
    ); // x, y
    doc.text("Passport size photo", 15, certifiedCopiesY + 40); // x, y
    doc.text("Signature: ____________________", 15, certifiedCopiesY + 50); // x, y

    generateSectionPDF(residentAgreementLines);
    generateSectionPDF(newPageContent);
    generateSectionPDF(remainingContent);
    generateSectionPDF(inventoryChecklistContent);
    // Adjust startX and startY values as needed

    // generateSectionPDF(propertyRoomChecklistContent1);
    doc.addPage();
    const pizzaData = [
      "DOOR & LOCKS",
      "WINDOWS & LOCKS",
      "SCREENS/ CURTAINS",
      "W. PRIVACY SCREENS",
      "CURTAIN ROD:",
      "SANITARY BIN:",
      "FLOOR TILES:",
      "WALLS:",
      "CEILING:",
      "LIGHTS & SWITCHES:",
      "ROOM KEY:",
    ];

    // generateTable(pizzaData, 10, 100);

    // Adjust startY for the next table
    // startY = doc.internal.pageSize.height - 10;
    // doc.addPage();
    const pizzaData2 = [
      "DESK CHAIR:",
      "DESK TABLE:",
      "SIDE TABLE",
      "BED:",
      "CLOSET:",
      "CLOSET KEY:",
      "SHELVES:",
      "MATTRESS",
      "MATTRESS PROTECTOR:",
      "OTHER:",
    ];
    // generateTable2(pizzaData2, 10, 100);
    generateTablesOnPage(pizzaData, pizzaData2, 10, 10);
    // Adjust startY for the next table
    // startY = doc.internal.pageSize.height - 10;
    doc.addPage();
    const pizzaData3 = [
      "FLOOR",
      "WALL",
      "CEILING",
      "DOORS & LOCKS",
      "CABINETS & DRWS",
      "SINK & MIRROR",
      "SHOWER & RACKS",
      "WINDOW",
      "BOWL/SEAT",
      "LIGHT & SWITCHES",
      "BATHROOM KEY",
    ];
    // // generateTable(pizzaData, 10, 100);
    // // generateTable2(pizzaData2, 10, 100);
    // generateTable3(pizzaData3, 10, 100);
    // doc.addPage();
    const pizzaData4 = [
      "FLOOR WALL",
      "CEILING",
      "CABINETS & DRWS",
      "SINK & COUNTERS",
      "MICROWAVE",
      "INDUCTION COOKER",
      "REFRIGERATOR:",
      "KETTLE LIGHT &",
      "SWITCHES",
    ];

    generateTablesOnPage2(
      pizzaData3,
      pizzaData4,

      10,
      10
    );
    // generateTable4(pizzaData4, 10, 100);
    doc.addPage();
    const pizzaData5 = [
      "SHOWER HEATER:",
      "SMOKE DETECTOR:",
      "K. PLUG ADAPTER:",
      "WIFI ROUTER",
    ];
    const pizzaData6 = [];

    generateTablesOnPage3(
      pizzaData5,
      pizzaData6,

      10,
      10
    );
    // generateTable5(pizzaData5, 10, 100);
    // doc.addPage();

    // generateTable6(pizzaData6, 10, 100);

    // generateSectionPDF(propertyRoomChecklistContent2);
    // generateSectionPDF(propertyRoomChecklistContent3);
    generateSectionPDF(propertyRoomChecklistContent4);

    const numPages = doc.internal.getNumberOfPages(); // Get total number of pages
    for (let i = 1; i <= numPages; i++) {
      doc.setPage(i);
      footer(i, numPages); // Add footer to each page
    }

    // Output the PDF as a data URI
    const pdfDataUri = doc.output("datauristring");

    // Create a new element and set its attributes
    const pdfElement = document.createElement("embed");
    pdfElement.setAttribute("src", pdfDataUri);
    pdfElement.setAttribute("width", "100%");
    pdfElement.setAttribute("height", "600px");

    // Get the target element by ID and append the PDF element to it
    const targetElement = document.getElementById("target-element");
    targetElement.appendChild(pdfElement);
  };

  return <div id="target-element"></div>;
}

export default ResidenceTest;
