import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuNavbar from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import "../PropertyManagementCss/elctricpage.css";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import Loading from "../../Components/Loading/Loading";
// import MenuNavbar from "./MenuNavbar";
function ViewStatement() {
  const location = useLocation();

  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState); // Add this line to get the location object
  const {
    clientCode,
    leaseStartDate,
    leaseEndDate,
    CustomerName,
    Project,
    Unit,
    UnitPrice,
  } = location.state;
  console.log(clientCode);
  console.log(leaseStartDate);
  console.log(leaseEndDate);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  const [statements, setStatements] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [noDatamsg, setNoDataMessage] = useState("");
  const [ageingDetails, setAgeingDetails] = useState({});
  const [totalDr, setTotalDr] = useState(0);
  const [totalCr, setTotalCr] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function fetchStatement() {
    if (leaseStartDate && leaseEndDate) {
      if (leaseStartDate > leaseEndDate) {
        alert("From date cannot be greater than To date");
        return;
      }

      try {
        setIsLoading(true);
        console.log(clientCode);
        console.log(leaseStartDate);
        console.log(leaseEndDate);
        const response = await axios.post(
          `${BASEURL}/getclientstatement`,
          {
            CLIENTCODE: clientCode,
            STARTDATE: leaseStartDate,
            ENDDATE: leaseEndDate,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.data.length === 0) {
          setNoDataMessage("No statements available");
          setIsLoading(false);
        } else {
          var temparray = response.data ? response.data : [];
          const newKey = "CUMULATIVE";
          let tempData = 0;
          setIsLoading(false);
          temparray.forEach((obj, i) => {
            if (i === 0) {
              obj[newKey] = response.data[0]?.AMOUNT;
              tempData = response.data[0]?.AMOUNT;
            } else {
              if (response.data[i].DRCR === "CR") {
                obj[newKey] = tempData - response.data[i]?.AMOUNT;
                tempData = tempData - response.data[i]?.AMOUNT;
              } else if (response.data[i]?.DRCR === "DR") {
                obj[newKey] = tempData + response.data[i]?.AMOUNT;
                tempData = tempData + response.data[i]?.AMOUNT;
              } else {
                // Handle other cases if needed
                obj[newKey] = tempData; // Set to the current tempData value
              }
            }
          });
          calculatetotalDrCr(temparray);
          setStatements(temparray);
          const ageingdetailsResponse = await axios.post(
            `${BASEURL}/getagingdetails`,
            {
              CLIENTCODE: clientCode,
              STARTDATE: leaseStartDate,
              ENDDATE: leaseEndDate,
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          );

          setTotalOutstanding(
            ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
              ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
              ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
              ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
          );
          setAgeingDetails(
            ageingdetailsResponse.data[0] ? ageingdetailsResponse.data[0] : {}
          );
        }
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("Select from and to date");
    }
  }

  function calculatetotalDrCr(item) {
    var totaldr = 0;
    var totalcr = 0;

    for (var i = 0; i < item.length; i++) {
      if (item[i].DRCR === "CR") {
        totalcr += item[i].AMOUNT;
      } else if (item[i].DRCR === "DR") {
        totaldr += item[i].AMOUNT;
      }
    }

    // Set the state using the setTotalDr and setTotalCr functions
    setTotalDr(totaldr);
    setTotalCr(totalcr);
  }
  useEffect(() => {
    fetchStatement();
  }, []);

  //   function navigateToCheckOut() {
  //     dispatch(statementPass({ statements: selectedRows, total: total }));
  //     navigate("/checkout");
  //   }
  //   function fetchInitialData() {
  //     setIsLoading(true);
  //     // Set default values when the component first renders
  //     const currentDate = new Date();
  //     const currentYear = currentDate.getFullYear();

  //     // Set fromDate to January 1 of the current year
  //     const defaultFromDate = `${currentYear}-01-01`;
  //     setFromDate(defaultFromDate);

  //     // Set toDate to today's date
  //     const defaultToDate = currentDate.toISOString().split("T")[0];
  //     setToDate(defaultToDate);

  //     // Call fetchStatement after setting initial dates
  //     try {
  //       axios
  //         .post(
  //           `${BASEURL}/getclientdetails`,
  //           {
  //             CLIENTCODE: clientCode,
  //             STARTDATE: leaseStartDate,
  //             ENDDATE: leaseEndDate,
  //           },
  //           {
  //             headers: {
  //               "auth-token": authToken,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           console.log(response);
  //           // console.log(response.data[0]);
  //           setClientDetails(response.data ? response.data[0] : {});
  //           setIsLoading(false);
  //         });

  //       axios
  //         .post(
  //           `${BASEURL}/getclientstatement`,
  //           {
  //             CLIENTCODE: clientCode,
  //             STARTDATE: leaseStartDate,
  //             ENDDATE: leaseEndDate,
  //           },
  //           {
  //             headers: {
  //               "auth-token": authToken,
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           if (response.data.length === 0) {
  //             setNoDataMessage("No statements available");
  //           } else {
  //             var temparray = response.data;
  //             const newKey = "CUMULATIVE";
  //             let tempData = 0;

  //             temparray.forEach((obj, i) => {
  //               if (i === 0) {
  //                 obj[newKey] = response.data[0]?.AMOUNT;
  //                 tempData = response.data[0]?.AMOUNT;
  //               } else {
  //                 if (response.data[i].DRCR === "CR") {
  //                   obj[newKey] = tempData - response.data[i]?.AMOUNT;
  //                   tempData = tempData - response.data[i]?.AMOUNT;
  //                 } else if (response.data[i]?.DRCR === "DR") {
  //                   obj[newKey] = tempData + response.data[i]?.AMOUNT;
  //                   tempData = tempData + response.data[i]?.AMOUNT;
  //                 } else {
  //                   // Handle other cases if needed
  //                   obj[newKey] = tempData; // Set to the current tempData value
  //                 }
  //               }
  //             });
  //             calculatetotalDrCr(temparray);
  //             setStatements(temparray);
  //             axios
  //               .post(
  //                 `${BASEURL}/getagingdetails`,
  //                 {
  //                   CLIENTCODE: clientCode,
  //                   STARTDATE: leaseStartDate,
  //                   ENDDATE: leaseEndDate,
  //                 },
  //                 {
  //                   headers: {
  //                     "auth-token": authToken,
  //                   },
  //                 }
  //               )
  //               .then((ageingdetailsResponse) => {
  //                 setTotalOutstanding(
  //                   ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
  //                     ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
  //                     ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
  //                     ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
  //                 );
  //                 setAgeingDetails(ageingdetailsResponse.data[0]);
  //               });
  //           }
  //         });
  //     } catch (err) {
  //       alert(err.response.data.message);
  //     }
  //   }
  //   useEffect(fetchInitialData, []);
  //////////

  const ExcelLeaseData = () => {
    const confirmed = window.confirm(
      "Do you want to generate the Excel Lease Expiry Report?"
    );
    if (!confirmed) {
      return; // User canceled, do nothing
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Lease Expiry Report");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [200, 100, 100, 150, 130, 100, 250, 170, 80, 20];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "Transaction Type",
      "Doc No",
      "Date",
      "DR",
      "CR",
      "Currency",
      "Paid",
      "Cumulative",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Lease_Expiry_Report.xlsx";
      link.click();
    });
  };

  const PdfLeaseData = () => {
    const confirmed = window.confirm(
      "Do you want to generate the Pdf Lease Expiry Report?"
    );
    if (!confirmed) {
      return; // User canceled, do nothing
    }
    const doc = new jsPDF();

    doc.text("Lease Expiry - Report", 10, 10);

    const columns = [
      "Transaction Type",
      "Doc No",
      "Date",
      "DR",
      "CR",
      "Currency",
      "Paid",
      "Cumulative",
    ];
    const data = statements.map((item) => [
      item.TRANSACTIONTYPE ? item.TRANSACTIONTYPE : "N/A",
      item.DOCNO ? item.DOCNO : "N/A",
      item.DOCDATE ? new Date(item.DOCDATE).toLocaleDateString("en-GB") : "N/A",
      item.DRCR === "DR"
        ? item.AMOUNT?.toLocaleString(undefined, { minimumFractionDigits: 2 })
        : "N/A",
      item.DRCR === "CR"
        ? item.AMOUNT?.toLocaleString(undefined, { minimumFractionDigits: 2 })
        : "N/A",
      item.CURRENCY ? item.CURRENCY : "N/A",
      item.PAIDAMOUNT
        ? item.PAIDAMOUNT.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })
        : "0.00",
      item.CUMULATIVE
        ? Number(item.CUMULATIVE).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })
        : "N/A",
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Lease_Expiry_Report.pdf");
  };

  const renderList = (
    <div>
      <MenuNavbar />

      <header className="header__of__main lg:flex justify-between px-3 items-center">
        <h1 className="header__of__page">{menu ? menu : "View Statement"}</h1>
      </header>

      <section className="flex justify-between items-baseline uppercase">
        <div className="custom-container w-[85%]">
          <div className="flex items-center custom-item ">
            <h2>CUSTOMER NAME:</h2>
            <h2 className="font-bold text-lg text-red-500">
              {CustomerName ? CustomerName : "N/A"}
            </h2>
          </div>
          <div className="flex items-center custom-item">
            <h2>PROJECT:</h2>
            <h2 className="font-bold text-lg text-red-500">
              {Project ? Project : "N/A"}
            </h2>
          </div>
          <div className="flex items-center custom-item">
            <h2>ACCOUNT NUMBER: </h2>
            <h2 className="font-bold text-lg text-red-500">
              {clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : "N/A"}
            </h2>
          </div>
          <div className="flex items-center custom-item">
            <h2>UNIT: </h2>
            <h2 className="font-bold text-lg text-red-500">
              {Unit ? Unit : "N/A"}
            </h2>
          </div>
          <div className="flex items-center custom-item">
            <h2>UNIT PRICE: &nbsp;</h2>
            <h2 className="font-bold text-lg text-red-500">
              {UnitPrice ? UnitPrice : "N/A"}
            </h2>
          </div>
        </div>
        <aside>
          <button
            onClick={() => {
              navigate("/PENDINGEXITINSPECTIONDETAILS");
            }}
            className="button "
            style={{ marginRight: "35px" }}
          >
            Back
          </button>
        </aside>
      </section>
      <div className="flex justify-between items-end">
        <section class="item_section__filter">
          <p> From : </p>
          <input
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            className="border-[1px]  border-black m-1"
            type="date"
            defaultValue={fromDate}
          />
          <p>To : </p>
          <input
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            className="border-[1px]  border-black m-1"
            type="date"
            defaultValue={toDate}
          />
          {/* <button
            className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={fetchStatement}
          >
            GET STATEMENTS
          </button> */}
        </section>
        <div className="export-buttons flex gap-2 mx-10 my-1 cursor-pointer">
          <RiFileExcel2Line onClick={ExcelLeaseData} size={35} />
          <BsFileEarmarkPdf onClick={PdfLeaseData} size={35} />
        </div>
      </div>
      <aside className="to__center__main">
        <table className="table__test3">
          <thead>
            <tr className="tr__test1">
              <td>
                <h5 className="text-right pr-4 w-[80px]"> Sr.No.</h5>
              </td>
              <td>
                <h5> TRANSACTION&nbsp;TYPE </h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> DOC&nbsp;NO</h5>
              </td>
              <td>
                <h5> DATE </h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> DR</h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> CR </h5>
              </td>
              <td>
                <h5> CURRENCY </h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> PAID </h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[150px]"> CUMULATIVE </h5>
              </td>
            </tr>
          </thead>

          <tbody>
            {statements.map((item, index) => (
              <tr key={index}>
                {/* <td>
                  <input
                    disabled={
                      item.PAIDAMOUNT === 0 || item.TRANSACTIONTYPE === "SINV"
                        ? false
                        : true
                    }
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={selectedRows.includes(item)}
                  />
                </td> */}
                <td>
                  <p className="text-right pr-4 w-[80px]"> {index + 1}</p>
                </td>
                <td>{item.TRANSACTIONTYPE ? item.TRANSACTIONTYPE : "N/A"}</td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.DOCNO ? item.DOCNO : "N/A"}{" "}
                  </p>
                </td>

                <td>
                  {item.DOCDATE
                    ? new Date(item.DOCDATE).toLocaleDateString("en-GB")
                    : "N/A"}
                </td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {" "}
                    {item.DRCR === "DR"
                      ? item.AMOUNT?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "N/A"}
                  </p>
                </td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.DRCR === "CR"
                      ? item.AMOUNT?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "N/A"}
                  </p>
                </td>
                <td>{item.CURRENCY ? item.CURRENCY : "N/A"}</td>

                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.PAIDAMOUNT
                      ? item.PAIDAMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "0.00"}
                  </p>
                </td>
                <td>
                  <p className="text-right pr-4 w-[150px]">
                    {item.CUMULATIVE
                      ? Number(item.CUMULATIVE).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "N/A"}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </aside>
      <div className="flex justify-end items-center mr-10 ">
        <h2>
          Total :{" "}
          <span className="font-bold text-green-600 text-lg">
            {" "}
            {total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </span>
        </h2>
        {/* <button
          className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[500] border-[1px] border-green-300"
          onClick={navigateToCheckOut}
        >
          Make Payment
        </button> */}
      </div>
      <div className="p-2">
        <header>
          {/* <table>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>LEDGER TYPE</th>
                <th>LEDGER</th>
                <th>Dr/Cr</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table> */}
          <aside className="input__and__mapped__values">
            <div>
              <p>
                {" "}
                <span className=" inline-block ">Total Dr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={totalDr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div>
            <div>
              <p>
                {" "}
                <span className="inline-block ">Total Cr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={totalCr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div>
            <div>
              <p>
                {" "}
                <span className="w-[84px] inline-block ">Balance Cr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={(totalCr - totalDr).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div>
          </aside>
          <aside className="input__and__mapped__values">
            <div>
              <p className="  ">0-30 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.ZEROTHIRTYDAYS
                    ? ageingDetails?.ZEROTHIRTYDAYS?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
            <div>
              <p className="  ">30-60 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.THIRTYSIXTYDAYS
                    ? ageingDetails?.THIRTYSIXTYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
            <div>
              <p className="  ">60-90 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.SIXTYNINETYDAYS
                    ? ageingDetails?.SIXTYNINETYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
            <div>
              <p className=" above__90days ">Above 90 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.ABOVENINETYDAYS
                    ? ageingDetails?.ABOVENINETYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
          </aside>
          <aside className="input__and__mapped__values">
            <div>
              <p>
                <span>Total Outstanding :</span>
              </p>
              <input
                type="number"
                disabled
                value={totalOutstanding?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
          </aside>

          <aside className="mx-[25px] mb-4">
            {/* <div className="flex justify-end items-center">
              <p>
                <span className="w-[84px] inline-block text-right">
                  Total Dr :
                </span>
                <input
                  disabled
                  value={totalDr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
              <p>
                <span className="w-[84px] inline-block text-right">
                  Total Cr :
                </span>
                <input
                  disabled
                  value={totalCr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div> */}
            {/* <p>
              <span className="w-[84px] inline-block text-right">
                Balance Cr :
              </span>
              <input
                disabled
                value={(totalCr - totalDr).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
                type=""
                name=""
              />
            </p> */}
          </aside>
          {/* <aside className="flex justify-end ">
            <p className="block ">
              <p className="  ">0-30 Days</p>
              <input
                disabled
                value={
                  ageingDetails.ZEROTHIRTYDAYS
                    ? ageingDetails.ZEROTHIRTYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  ">30-60 Days</p>
              <input
                disabled
                value={
                  ageingDetails.THIRTYSIXTYDAYS
                    ? ageingDetails.THIRTYSIXTYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  ">60-90 Days</p>
              <input
                disabled
                value={
                  ageingDetails.SIXTYNINETYDAYS
                    ? ageingDetails.SIXTYNINETYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  "> Above 90 Days</p>
              <input
                disabled
                value={
                  ageingDetails.ABOVENINETYDAYS
                    ? ageingDetails.ABOVENINETYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
          </aside> */}
          {/* <aside>
            <p className="text-end">
              <span>Total Outstanding : </span>
              <input
                disabled
                value={totalOutstanding.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
          </aside> */}
        </header>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
}

export default ViewStatement;
