import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../Pages/ClientDashboard/ClientDashboard.css";
import Loading from "../../Components/Loading/Loading";
import NavbarDashboard from "../../Pages/NavbarClient/ClientMenuNavbar";
import { statementPass } from "../../Redux/Action/selectStatements";
import jsPDF from "jspdf";
import { FaFileInvoice } from "react-icons/fa";
import moment from "moment";
const ProcessUnallocatedReceipts = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();

  const menu = location.state ? location.state.menu : null;
  const statement = location.state;
  console.log(statement);
  console.log(statement?.clientCode);

  const userState = useSelector((state) => state.changeTheState.user);
  const selStatement = useSelector((state) => state.unallocatedReceipts);
  console.log(selStatement);
  const [statements, setStatements] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [noDatamsg, setNoDataMessage] = useState("");
  const [ageingDetails, setAgeingDetails] = useState({});
  const [totalDr, setTotalDr] = useState(0);
  const [totalCr, setTotalCr] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [Document, setDocument] = useState([]);
  const [Clientcode, setClientcode] = useState("");
  const [compPin, setCompPin] = useState("");
  // const[companyDetails, setCompanydetails] = useState
  const navigate = useNavigate();
  const [Companydetails, setCompanydetails] = useState([]);
  const [lastInv, setLastInv] = useState(0);
  const logOutIfNotLogin = useSelector(
    (logOutIfNotLogin) => logOutIfNotLogin.changeLoginState
  );
  console.log(logOutIfNotLogin);
  useEffect(() => {
    if (logOutIfNotLogin === "FALSE") {
      navigate("/");
    }
  }, [logOutIfNotLogin, navigate]);
  const dispatch = useDispatch();
  console.log(userState);
  console.log(userState.userCode);
  async function fetchStatement() {
    console.log(userState.userCode);
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("From date cannot be greater than To date");
        return;
      }

      try {
        setIsLoading(true);
        const formattedFromDate = fromDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
        const formattedToDate = toDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/");
        const response = await axios.post(
          `${BASEURL}/getclientstatement`,
          {
            CLIENTCODE: selStatement?.CLIENTCODE,
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.data.length === 0) {
          setNoDataMessage("No statements available");
          setIsLoading(false);
        } else {
          console.log(response.data);
          var temparray = response.data ? response.data : [];
          const newKey = "CUMULATIVE";
          let tempData = 0;
          setIsLoading(false);
          temparray.forEach((obj, i) => {
            if (i === 0) {
              obj[newKey] = response.data[0]?.DR;
              tempData = response.data[0]?.DR;
            } else {
              if (response.data[i].CR) {
                obj[newKey] = tempData - response.data[i]?.CR;
                tempData = tempData - response.data[i]?.CR;
              } else if (response.data[i]?.DR) {
                obj[newKey] = tempData + response.data[i]?.DR;
                tempData = tempData + response.data[i]?.DR;
              } else {
                // Handle other cases if needed
                obj[newKey] = tempData; // Set to the current tempData value
              }
            }
          });
          setTotal(temparray[temparray.length - 1]?.CUMULATIVE);
          console.log(temparray);
          calculatetotalDrCr(temparray);
          setStatements(temparray);
          const formattedFromDate2 = fromDate
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");
          const formattedToDate2 = toDate
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/");

          const ageingdetailsResponse = await axios.post(
            `${BASEURL}/getagingdetails`,
            {
              CLIENTCODE: selStatement?.CLIENTCODE,
              STARTDATE: formattedFromDate2,
              ENDDATE: formattedToDate2,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          setTotalOutstanding(
            ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
              ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
              ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
              ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
          );
          setAgeingDetails(
            ageingdetailsResponse.data[0] ? ageingdetailsResponse.data[0] : {}
          );
        }
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("Select from and to date");
    }
  }

  function calculatetotalDrCr(item) {
    var totaldr = 0;
    var totalcr = 0;

    for (var i = 0; i < item.length; i++) {
      if (item[i].CR) {
        totalcr += item[i].CR;
      } else if (item[i].DR) {
        totaldr += item[i].DR;
      }
    }

    // Set the state using the setTotalDr and setTotalCr functions
    setTotalDr(totaldr);
    setTotalCr(totalcr);
  }

  async function navigateToCheckOut() {
    if (selectedRows.length === 0) {
      alert("Select a transaction first");
    } else {
      let totalseltoPay = 0;
      for (var i = 0; i < selectedRows.length; i++) {
        totalseltoPay = totalseltoPay + selectedRows[i].DR;
      }
      if (totalseltoPay > selStatement.AMOUNT) {
        alert("Selected amount is greater than pending to be allocated");
      } else {
        for (var i = 0; i < selectedRows.length; i++) {
          console.log(selectedRows[i]);
          const response2 = await axios.post(
            `${BASEURL}/insertReceiptAllocation`,
            {
              RECEIPTNO: selStatement.MPESAREFERENCENO,
              RECEIPTFORINCOME: selectedRows[i]?.SERVICE,
              RECEIPTAMOUNT: selectedRows[i].DR,
              RECEIPTINVOICENO: selectedRows[i]?.INVOICENUMBER,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          alert(response2.data.message);
        }
      }
      console.log(totalseltoPay);
    }
  }

  function fetchInitialData() {
    setIsLoading(true);
    // Set default values when the component first renders
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // Set fromDate to January 1 of the current year
    const defaultFromDate = `${currentYear}-01-01`;
    setFromDate(defaultFromDate);

    // Set toDate to today's date
    const defaultToDate = currentDate.toISOString().split("T")[0];
    setToDate(defaultToDate);
    console.log(fromDate);
    console.log(toDate);
    const formattedFromDate = fromDate
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const formattedToDate = toDate.split("T")[0].split("-").reverse().join("/");
    // Call fetchStatement after setting initial dates
    try {
      axios
        .post(
          `${BASEURL}/getclientdetails`,
          {
            CLIENTCODE: selStatement?.CLIENTCODE,
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(response.data[0]);
          setClientDetails(response.data ? response.data[0] : {});
          setClientcode(response.data[0].USERNAME);
          console.log(response.data[0].USERNAME);
          setIsLoading(false);
          axios
            .post(
              `${BASEURL}/getclentunitidandpropid`,
              {
                CLIENTCODE: response.data[0].USERNAME,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              setDocument(response.data[0]);
            });

          axios
            .get(
              `${BASEURL}/getcompanydetails`,

              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              setCompanydetails(response.data[0].ORG_NAME);
              setCompPin(response.data[0].ORG_PIN_NO);
            });
        });
      console.log(defaultFromDate);
      console.log(defaultToDate);
      const formattedFromDate2 = defaultFromDate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      const formattedToDate2 = defaultToDate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      console.log(userState.userCode);
      console.log(defaultFromDate);
      console.log(defaultFromDate);
      const DateFrom =
        defaultFromDate.slice(8, 10) +
        "/" +
        defaultFromDate.slice(5, 7) +
        "/" +
        defaultFromDate.slice(0, 4);
      const dateTo =
        defaultToDate.slice(8, 10) +
        "/" +
        defaultToDate.slice(5, 7) +
        "/" +
        defaultToDate.slice(0, 4);
      console.log(DateFrom);
      console.log(dateTo);
      console.log(defaultFromDate, defaultToDate, userState.userCode);
      if (DateFrom && dateTo && userState?.userCode) {
        axios
          .post(
            `${BASEURL}/getclientstatement`,
            {
              CLIENTCODE: selStatement?.CLIENTCODE,
              STARTDATE: DateFrom,
              ENDDATE: dateTo,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            if (response.data.length === 0) {
              setNoDataMessage("No statements available");
            } else {
              console.log(response.data);
              var temparray = response.data;
              const newKey = "CUMULATIVE";
              let tempData = 0;

              temparray.forEach((obj, i) => {
                if (i === 0) {
                  obj[newKey] = response.data[0]?.DR;
                  tempData = response.data[0]?.DR;
                } else {
                  if (response.data[i].CR) {
                    obj[newKey] = tempData - response.data[i]?.CR;
                    tempData = tempData - response.data[i]?.CR;
                  } else if (response.data[i]?.DR) {
                    obj[newKey] = tempData + response.data[i]?.DR;
                    tempData = tempData + response.data[i]?.DR;
                  } else {
                    // Handle other cases if needed
                    obj[newKey] = tempData; // Set to the current tempData value
                  }
                }
              });
              setTotal(temparray[temparray.length - 1]?.CUMULATIVE);
              console.log(temparray[temparray.length - 1]?.CUMULATIVE);
              calculatetotalDrCr(temparray);
              setStatements(temparray);
              axios
                .post(
                  `${BASEURL}/getagingdetails`,
                  {
                    CLIENTCODE: selStatement?.CLIENTCODE,
                    STARTDATE: defaultFromDate,
                    ENDDATE: defaultToDate,
                  },
                  {
                    headers: {
                      "auth-token": authToken,
                      "session-token": sessiontoken,
                    },
                  }
                )
                .then((ageingdetailsResponse) => {
                  setTotalOutstanding(
                    ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
                      ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
                      ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
                      ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
                  );
                  setAgeingDetails(ageingdetailsResponse.data[0]);
                });
            }
          });
      }
    } catch (err) {
      alert(err?.response?.data?.message);
      console.log(err);
    }
  }
  useEffect(fetchInitialData, []);

  const handleCheckboxChange = (item) => {
    if (selectedRows.includes(item)) {
      // If the item is already selected, remove it from the selectedRows array
      setSelectedRows(selectedRows.filter((row) => row !== item));
      // total > 0 ? setTotal(total - Number(item.AMOUNT)) : setTotal(0);
    } else {
      // If the item is not selected, add it to the selectedRows array
      setSelectedRows([...selectedRows, item]);
      // setTotal(total + item.AMOUNT);
    }
    setLastInv(item.DOCNO);
  };

  async function autoAllocateStatements() {
    try {
      const response = await axios.post(
        `${BASEURL}/autoallocateunprocessedreceipt`,
        {
          CLIENTCODE: selStatement?.CLIENTCODE,
          AMOUNT: Number(selStatement.AMOUNT - selStatement.ALREADYALLOCATED)
            ? Number(selStatement.AMOUNT - selStatement.ALREADYALLOCATED)
            : 0,
          REFNO: selStatement.MPESAREFERENCENO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
      window.history.back();
    } catch (err) {
      alert(err.response.data.message);
    }
  }

  const renderList = (
    <div>
      <NavbarDashboard />
      <header className="header__of__main flex justify-between px-3 items-center">
        <h1 className="header__of__page">{menu ? menu : "Client dashboard"}</h1>
      </header>
      <div className="custom-container">
        <div className="flex items-center custom-item">
          <h2>CUSTOMER NAME:</h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.CLIENTNAME ? clientDetails.CLIENTNAME : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>PROJECT:</h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.PROJECT ? clientDetails.PROJECT : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>ACCOUNT NUMBER: </h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>UNIT: </h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.UNIT ? clientDetails.UNIT : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>UNIT PRICE: &nbsp;</h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.UNITPRICE ? clientDetails.UNITPRICE : "N/A"}
          </h2>
        </div>
      </div>
      <div className="custom-container">
        <div className="flex items-center custom-item">
          <h2>RECEIPTNO</h2>
          <h2 className="font-bold text-lg text-red-500">
            {selStatement.RECEIPTNO ? selStatement.RECEIPTNO : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>MPESA REF NO</h2>
          <h2 className="font-bold text-lg text-red-500">
            {selStatement.MPESAREFERENCENO
              ? selStatement.MPESAREFERENCENO
              : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>AMOUNT: </h2>
          <h2 className="font-bold text-lg text-red-500">
            {selStatement.AMOUNT ? selStatement.AMOUNT : 0}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>ALREADY ALLOCATED: </h2>
          <h2 className="font-bold text-lg text-red-500">
            {selStatement.ALREADYALLOCATED ? selStatement.ALREADYALLOCATED : 0}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>PENDING: &nbsp;</h2>
          <h2 className="font-bold text-lg text-red-500">
            {Number(selStatement.AMOUNT - selStatement.ALREADYALLOCATED)
              ? Number(selStatement.AMOUNT - selStatement.ALREADYALLOCATED)
              : 0}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>RECEIPT DATE: &nbsp;</h2>
          <h2 className="font-bold text-lg text-red-500">
            {selStatement.RECEIPTDATE
              ? moment(selStatement.RECEIPTDATE).format("DD/MM/YYYY")
              : 0}
          </h2>
        </div>
      </div>

      {/* <div className="flex mx-8 my-3 ">
        <section className="mr-[100px]">
          <table>
            <tr>
              <td>
                {" "}
                <h2>CUSTOMER NAME:</h2>
              </td>
              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.CLIENTNAME ? clientDetails.CLIENTNAME : "N/A"}
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <h2>ACCOUNT NUMBER: </h2>
              </td>
              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : "N/A"}
                </h2>
              </td>
            </tr>
          
          </table>
        </section>
        <section>
          <table>
            <tr>
              <td>
                {" "}
                <h2>PROJECT:</h2>
              </td>
              <td>
                <h2 className="font-bold text-lg text-red-500">
                  {" "}
                  {clientDetails.PROJECT ? clientDetails.PROJECT : "N/A"}
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <h2>UNIT: </h2>
              </td>
              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.UNIT ? clientDetails.UNIT : "N/A"}
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <h2>UNIT PRICE: &nbsp;</h2>
              </td>

              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.UNITPRICE ? clientDetails.UNITPRICE : "N/A"}
                </h2>
              </td>
            </tr>
          </table>
        </section>
      </div> */}

      <div>
        <section class="item_section__filter">
          <p> From : </p>
          <input
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            className="border-[1px]  border-black m-1"
            type="date"
            defaultValue={fromDate}
          />
          <p>To : </p>
          <input
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            className="border-[1px]  border-black m-1"
            type="date"
            defaultValue={toDate}
          />
          <button
            className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={fetchStatement}
          >
            VIEW STATEMENTS
          </button>
          <button
            className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={autoAllocateStatements}
          >
            AUTO ALLOCATE
          </button>
          {/* <button
            className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={Viewdocument}
          >
            RESIDENT AGREEMENT
          </button>
          <button
            className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={() => generateStatementPDF(statements)}
          >
            PRINT STATEMENT
          </button> */}
        </section>
      </div>
      <aside className="to__center__main">
        <table className="table__test3">
          <thead>
            <tr className="tr__test1">
              <td></td>
              <td>
                <h5 className="text-right pr-4 w-[80px]"> Sr.No.</h5>
              </td>
              <td>
                <h5> TRANSACTION&nbsp;TYPE </h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> DOC&nbsp;NO</h5>
              </td>
              <td>
                <h5> DATE </h5>
              </td>
              <td>
                <h5> SERVICE&nbsp;NAME</h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> DR</h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> CR </h5>
              </td>
              <td>
                <h5> CURRENCY </h5>
              </td>
              {/* <td>
                <h5 className="text-right pr-4 w-[100px]"> PAID </h5>
              </td> */}
              <td>
                <h5 className="text-right pr-4 w-[150px]"> CUMULATIVE </h5>
              </td>
              {/* <td>
                <h5 className=" pr-4 w-[150px]"> INVOICE </h5>
              </td> */}
            </tr>
          </thead>

          <tbody>
            {statements.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    disabled={
                      item.SALETYPE === "RECIEPT" ||
                      item.DR === item.ALREADYPAIDAMOUNT
                        ? true
                        : false
                    }
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={selectedRows.includes(item)}
                  />
                </td>
                <td>
                  <p className="text-right pr-4 w-[80px]"> {index + 1}</p>
                </td>
                <td>{item.SALETYPE ? item.SALETYPE : "N/A"}</td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.INVOICENUMBER ? item.INVOICENUMBER : "N/A"}{" "}
                  </p>
                </td>

                <td>
                  {item.INVOICEDATE
                    ? new Date(item.INVOICEDATE).toLocaleDateString("en-GB")
                    : "N/A"}
                </td>
                <td>
                  <p className="text-right pr-4 w-[80px]">
                    {item.SERVICE ? item.SERVICE : "N/A"}{" "}
                  </p>
                </td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {" "}
                    {item.DR
                      ? item.DR?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0}
                  </p>
                </td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.CR
                      ? item.CR?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0}
                  </p>
                </td>
                <td>{item.CURRENCY ? item.CURRENCY : "N/A"}</td>

                {/* <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.PAIDAMOUNT
                      ? item.PAIDAMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "0.00"}
                  </p>
                </td> */}
                <td>
                  <p className="text-right pr-4 w-[150px]">
                    {item.CUMULATIVE
                      ? Number(item.CUMULATIVE).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "N/A"}
                  </p>
                </td>
                {/* <td className="text-right">
                  <button
                    onClick={() => {
                      generateInvoice(item.INVOICENUMBER);
                      setLastInv(item.INVOICENUMBER);
                    }}
                    className="text-right pr-4 w-[150px]"
                  >
                    <FaFileInvoice />
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </aside>
      <div className="flex justify-end items-center gap-8 ">
        <h2>
          Total Outstanding:{" "}
          <span className="font-bold text-green-600 text-lg">
            {" "}
            {total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </span>
        </h2>
        <button
          className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[500] border-[1px] border-green-300"
          onClick={async () => {
            await navigateToCheckOut();
            window.history.back();
          }}
        >
          Make Payment
        </button>
      </div>
      <div className="p-2">
        <header>
          {/* <table>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>LEDGER TYPE</th>
                <th>LEDGER</th>
                <th>Dr/Cr</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table> */}
          <aside className="input__and__mapped__values">
            {/* <div>
              <p>
                {" "}
                <span className=" inline-block ">Total Dr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={totalDr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div> */}
            {/* <div>
              <p>
                {" "}
                <span className="inline-block ">Total Cr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={totalCr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div> */}
            {/* <div>
              <p>
                {" "}
                <span className="w-[84px] inline-block ">Balance Cr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={(totalCr - totalDr).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div> */}
          </aside>
          <aside className="input__and__mapped__values">
            {/* <div>
              <p className="  ">0-30 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.ZEROTHIRTYDAYS
                    ? ageingDetails?.ZEROTHIRTYDAYS?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div> */}
            {/* <div>
              <p className="  ">30-60 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.THIRTYSIXTYDAYS
                    ? ageingDetails?.THIRTYSIXTYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div> */}
            {/* <div>
              <p className="  ">60-90 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.SIXTYNINETYDAYS
                    ? ageingDetails?.SIXTYNINETYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div> */}
            {/* <div>
              <p className=" above__90days ">Above 90 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.ABOVENINETYDAYS
                    ? ageingDetails?.ABOVENINETYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div> */}
          </aside>
          <aside className="input__and__mapped__values">
            {/* <div>
              <p>
                <span>Total Outstanding :</span>
              </p>
              <input
                type="number"
                disabled
                value={totalOutstanding?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div> */}
          </aside>

          <aside className="mx-[25px] mb-4">
            {/* <div className="flex justify-end items-center">
              <p>
                <span className="w-[84px] inline-block text-right">
                  Total Dr :
                </span>
                <input
                  disabled
                  value={totalDr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
              <p>
                <span className="w-[84px] inline-block text-right">
                  Total Cr :
                </span>
                <input
                  disabled
                  value={totalCr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div> */}
            {/* <p>
              <span className="w-[84px] inline-block text-right">
                Balance Cr :
              </span>
              <input
                disabled
                value={(totalCr - totalDr).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
                type=""
                name=""
              />
            </p> */}
          </aside>
          {/* <aside className="flex justify-end ">
            <p className="block ">
              <p className="  ">0-30 Days</p>
              <input
                disabled
                value={
                  ageingDetails.ZEROTHIRTYDAYS
                    ? ageingDetails.ZEROTHIRTYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  ">30-60 Days</p>
              <input
                disabled
                value={
                  ageingDetails.THIRTYSIXTYDAYS
                    ? ageingDetails.THIRTYSIXTYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  ">60-90 Days</p>
              <input
                disabled
                value={
                  ageingDetails.SIXTYNINETYDAYS
                    ? ageingDetails.SIXTYNINETYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  "> Above 90 Days</p>
              <input
                disabled
                value={
                  ageingDetails.ABOVENINETYDAYS
                    ? ageingDetails.ABOVENINETYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
          </aside> */}
          {/* <aside>
            <p className="text-end">
              <span>Total Outstanding : </span>
              <input
                disabled
                value={totalOutstanding.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
          </aside> */}
        </header>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default ProcessUnallocatedReceipts;
