import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select";
import { useLocation } from "react-router-dom";

function HistoricExits() {
  const location = useLocation();
  const [listofprojectid, setlistofprojectid] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const authToken = window.sessionStorage.getItem("auth-token");
  const menu = location.state ? location.state.menu : null;
  const [search, setSearch] = useState("");
  console.log(menu);
  const getListOfProjectIds = () => {
    // console.log(Startdatesort);
    // setisloading(true);
    axios
      .get(
        `${BASEURL}/gethistoricalexits`,

        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofprojectid(response.data ? response.data : "");
        console.log(response.data);
        // setisloading(false);
      })
      .catch((error) => {
        // setisloading(false);
        console.error("Error fetching List Of Project Idss:", error);
      });
  };
  useEffect(() => {
    getListOfProjectIds();
  }, []);

  const filteredData = listofprojectid.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "CLIENTNAME",
        "CLIENTCODE",
        "UNITNUMBER",
        "LEASESTARTDATE",
        "SUBUNITNO",
        "LEASEENDDATE",
        "DAYSCONSUMED",
        "METERREADING",
        "RATEPERUNIT",
        "TOTALDAYS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  console.log(filteredData);
  return (
    <div className="elctric__page">
      <MenuNavbar />

      <header className="header__of__main">
        <h1 className="header__of__page">{menu ? menu : "HISTORICAL EXITS"}</h1>
      </header>
      <div className="lg:flex gap-2 items-center filter__no__search ">
        {/* <p>
          <label>Select the Date</label>
        </p>
        <div>
          <input
            required
            className=""
            type="date"
              value={Startdatesort}
              onChange={(e) => setStartdatesort(e.target.value)}
          />
        </div> */}

        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="search__icon">
            <FaSearch />
          </div>
        </div>
        {/* <button
          style={{ backgroundColor: "#2f2f5a", color: "white" }}
          className="button__logout__navbar  text-white p-1 rounded "
          onClick={HandleSave}
        >
          SAVE
        </button> */}
        {/* <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div> */}
      </div>
      {/* {isloading ? (
      <Loading />
    ) : ( */}
      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>PROJECT NAME</td>
              <td>CLIENT NAME</td>
              {/* <td>CLIENT CODE</td> */}
              <td className="text-right">UNIT NUMBER</td>
              <td>SUB-UNIT NUMBER</td>
              <td>LEASE START DATE</td>
              <td>LEASE END DATE</td>
              <td className="text-right">INSPECTION NUMBER</td>
              <td>EXIT DATE</td>
              <td className="text-right">DAMAGE AMOUNT</td>
              <td>INSPECTION EXIT CARRIED BY</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} style={{ textTransform: "uppercase" }}>
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td>{item.CLIENTNAME ? item.CLIENTNAME : ""}</td>
                  {/* <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.CLIENTCODE ? item.CLIENTCODE[0] : "NA"}
                  </td> */}
                  <td className="text-right">
                    {item.UNITNUMBER ? item.UNITNUMBER : "NA"}
                  </td>
                  <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                  <td>
                    {item.LEASESTARTDATE
                      ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>
                    {item.LEASEENDDATE
                      ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td className="text-right">
                    {item.INSPECTIONNO ? item.INSPECTIONNO : "NA"}
                  </td>
                  <td>
                    {item.EXITDATE
                      ? moment(item.EXITDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td className="text-right">
                    {item.DAMAGEAMOUNT ? item.DAMAGEAMOUNT : "0.00"}
                  </td>
                  <td>
                    {item.INSPECTIONEXITCARRIEDBY
                      ? item.INSPECTIONEXITCARRIEDBY
                      : "NA"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  No data for selected date
                </td>
              </tr>
            )}
          </tbody>
          {/* <tbody>
            {listofprojectid.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.CLIENTCODE === item.CLIENTCODE
                        )}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </label>
                  </td>
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                  </td>
                  <td>{item.UNITNUMBER ? item.UNITNUMBER : "NA"}</td>
                  <td>
                    {item.LEASESTARTDATE
                      ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                  <td>
                    {item.LEASEENDDATE
                      ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td>{item.DAYSCONSUMED ? item.DAYSCONSUMED : "NA"}</td>
                  <td>{item.METERREADING ? item.METERREADING : "NA"}</td>
                  <td>{item.RATEPERUNIT ? item.RATEPERUNIT : "NA"}</td>
                  <td>{item.TOTALDAYS ? item.TOTALDAYS : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {item.DAYSCONSUMED * item.RATEPERUNIT}
                  </td>
                </tr>
              );
            })}
          </tbody> */}
        </table>
      </div>
      {/* )} */}
    </div>
  );
}

export default HistoricExits;
