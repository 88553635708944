import React, { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./EmployeeMaster.css";

import MenuNavbar from "./MenuNavbar";
import Select from "react-select";
import Loading2 from "../../Components/Loading/Loading";
import { EmployeeMasterEdit } from "../../Redux/Action/EmployeeMasterEdit";

import { useDispatch, useSelector } from "react-redux";
const EMPLOYEEMASTER = () => {
  const dispatch = useDispatch();

  const [isloading, setisloading] = useState(true);
  const useSelect = (initialState) => {
    const [selectedOption, setSelectedOption] = useState(initialState);

    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption.value);
    };

    return [selectedOption, handleSelectChange];
  };
  const state = useSelector((state) => state.changeTheState);
  const editDateEmployee = useSelector(
    (state) => state.EmployeeMasterEditReducer
  );
  console.log(editDateEmployee);
  console.log(state?.user?.emailId);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  //   const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const initialValues = {
    EMPCODE: editDateEmployee.EMPCODE ? editDateEmployee.EMPCODE : "",
    EMPNAME: editDateEmployee.EMPNAME ? editDateEmployee.EMPNAME : "",
    ACCOUNTNO: editDateEmployee.ACCOUNTNO ? editDateEmployee.ACCOUNTNO : "",
    // JOINDATE: "",
    NATIONALID: editDateEmployee.NATIONALID ? editDateEmployee.NATIONALID : "",
    TELEPHONE: editDateEmployee.TELEPHONE ? editDateEmployee.TELEPHONE : "",
    EMAIL: editDateEmployee.EMAIL ? editDateEmployee.EMAIL : "",
    OVERTIME: editDateEmployee.OVERTIME
      ? Number(editDateEmployee.OVERTIME)
      : "0",
    FREEZE: editDateEmployee.FREEZE ? Number(editDateEmployee.FREEZE) : "0",

    CASUAL: editDateEmployee.CASUAL ? Number(editDateEmployee.CASUAL) : "1", // Default to Yes (1)
    SUBCONTRACTED: editDateEmployee.SUBCONTRACTED
      ? Number(editDateEmployee.SUBCONTRACTED)
      : "0", // Default to No (0)

    IMAGENAME: editDateEmployee.IMAGENAME ? editDateEmployee.IMAGENAME : "",
    CONFIRM: editDateEmployee.CONFIRMED
      ? Number(editDateEmployee.CONFIRMED)
      : "0",
    NOTES: editDateEmployee.NOTES ? editDateEmployee.NOTES : "",
    PORTPASS: editDateEmployee.KPAPORTPASS
      ? Number(editDateEmployee.KPAPORTPASS)
      : "0",
    PORTPASSNO: editDateEmployee.PASSPORTNO ? editDateEmployee.PASSPORTNO : "",
    // PORTPASSEXPIRY: "",
  };

  const [formInput, setFormInput] = useState(initialValues);
  const [JOINDATE, setJOINDATE] = useState("");
  const [PORTPASSPORTPASSEXPIRY, setPORTPASSPORTPASSEXPIRY] = useState("");
  const [section1, setSection1] = useState(true);
  const [desigVal, setDesigVal] = useState([]);
  const [DEPARTMENTVal, setDEPARTMENTVal] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [dispPicture, setDispPicture] = useState("");
  const [picture, setPicture] = useState(null);
  const [cameraOn, setCameraOn] = useState(false);
  const [captured, setCaptured] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [fileUpload, setFileUpload] = useState(null);
  const [filePreview, setFilePreview] = useState(false);
  const [hide, sethide] = useState(true);
  const [empidPass, setEmpId] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [vehicleAllocated, setVehicleAllocated] = useSelect("");
  const [previousDesignation, setPreviousDesignation] = useState("");
  const [passingTypeSaveUpdate, setPassingTypeSaveUpdate] = useState("");
  const [ImagineValue, setImagineValue] = useState("");

  // Reset vehicleAllocated if designation changes
  useEffect(() => {
    if (previousDesignation && designation !== "DRIVER") {
      setVehicleAllocated("");
    }
    setPreviousDesignation(designation);
  }, [designation, previousDesignation]);
  console.log(designation, department, vehicleAllocated, LoggedInUserName);
  console.log(vehicleAllocated === undefined ? null : vehicleAllocated);
  const handleInputItem = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    console.log(formInput);
  };
  function masterData() {
    setisloading(true);
    //
    //
    axios
      .get(`${BASEURL}/getnewempcde`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);

        console.log(response.data[0].EMPID, "getnewempcde");
        setEmpId(response.data[0].EMPID);
      });

    // //
    // axios.get(`${BASEURL}/getListOfVehicles`).then((response) => {
    //   setisloading(false);
    //   console.log(response.data, "getListOfVehicles");
    //   setVehicles(response.data);
    // });

    axios
      .get(`${BASEURL}/getdepartments`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        console.log(response.data, "getdepartments");
        setDEPARTMENTVal(response.data);
      });
    //
    //
    axios
      .get(`${BASEURL}/getdesignations`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setDesigVal(response.data);
        console.log(response.data, "getdesignations");
      });
    const empcode = initialValues?.EMPCODE;
    axios
      .post(
        `${BASEURL}/getemployeeimage`,
        {
          EMPCODE: empcode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        setImagineValue(response.data[0]?.IMAGEBINARY);
        console.log(response.data[0]?.IMAGEBINARY, "getemployeeimage");
      });
  }
  useEffect(() => {
    masterData();
    setisloading(false);
  }, []);

  // if (editDateEmployee) {
  //   const empcode = initialValues?.EMPCODE;
  //   console.log(empcode);
  //   axios
  //     .post(`${BASEURL}/getemployeeimage`, {
  //       EMPCODE: empcode,
  //     })
  //     .then((response) => {
  //       setisloading(false);
  //       setDispPicture(response.data[0]?.IMAGENAME);
  //       console.log(response.data[0]?.IMAGENAME, "getemployeeimage");
  //     });
  // }

  function tofixnumber(e) {
    const input = e.target;
    const TELEPHONE = input.value.replace(/\D/g, "");

    if (TELEPHONE.length > 15) {
      const givenvalue = TELEPHONE.replace(/(\d{15})/);
      input.value = givenvalue;
    }
  }

  const openCamera = async () => {
    setSection1(false);
    setCameraOn(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      // Handle the error or show a message to the user
    }
  };

  const capturePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !video.srcObject) {
      return;
    }

    // Rest of the code for capturing the picture
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    const pictureData = canvas.toDataURL();
    canvas.toBlob((blob) => {
      setPicture(blob);
    });
    setDispPicture(pictureData);

    // Stop video tracks
    const videoTracks = video.srcObject.getTracks();
    videoTracks.forEach((track) => track.stop());

    setCaptured(true);
    setCameraOn(false);
  };

  const retakePicture = () => {
    setPicture(null);
    setDispPicture("");
    setCaptured(false);
    openCamera();
  };
  function handlePictureUpload(e) {
    const file = e.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check file size
      if (file.size <= 500 * 1024) {
        // 500KB in bytes
        setPicture(file);
        console.log(file);
        console.log(file);

        const imageURL = URL.createObjectURL(file);
        setDispPicture(imageURL);
      } else {
        // Notify user if the file exceeds 500KB
        alert("Please select an image file that is 500KB or smaller.");
        // Clear the input field
        e.target.value = null;
      }
    }
  }

  function handleRegister(e) {
    e.preventDefault();
    if (picture === "") {
      alert("Upload image");
      return;
    }
    console.log(
      formInput.EMPNO,
      "formInput.EMPNO",
      Number(formInput.CONFIRM),
      "Number(formInput.CONFIRM)"
    );
    console.log(picture, "picture.EMPNO");

    console.log(formInput);
    console.log(
      empidPass,
      JOINDATE,
      PORTPASSPORTPASSEXPIRY,
      picture,
      LoggedInUserName
    );
    const vehiclepush = "";
    // vehicleAllocated === undefined ? null : vehicleAllocated;
    console.log(JOINDATE);
    const passDate =
      JOINDATE.slice(8, 10) +
      "/" +
      JOINDATE.slice(5, 7) +
      "/" +
      JOINDATE.slice(0, 4);
    setisloading(true);
    const formData = new FormData();
    console.log(formData);
    // formData.append("data", JSON.stringify(formInput));
    formData.append("EMPNO", empidPass);
    formData.append("EMPCODE", formInput.EMPCODE);
    formData.append("EMPNAME", formInput.EMPNAME);
    formData.append("ACCOUNTNO", formInput.ACCOUNTNO);

    formData.append("JOINDATE", JOINDATE);
    formData.append("NATIONALID", formInput.NATIONALID);
    formData.append("TELEPHONE", formInput.TELEPHONE);
    formData.append("EMAIL", formInput.EMAIL);
    formData.append("OVERTIME", Number(formInput.OVERTIME));
    formData.append("DESIGNATION", designation);
    formData.append("DEPARTMENT", department);
    formData.append("FREEZE", Number(formInput.FREEZE));
    formData.append("VEHICLEALLOCATED", vehiclepush);
    formData.append("CASUAL", Number(formInput.CASUAL));
    formData.append("SUBCONTRACTED", Number(formInput.SUBCONTRACTED));
    formData.append("CREATEDBY", LoggedInUserName);
    formData.append("IMAGENAME", picture?.name);
    formData.append("CONFIRM", Number(formInput.CONFIRM));
    formData.append("NOTES", formInput.NOTES);
    formData.append("PORTPASS", 0);
    formData.append("PORTPASSNO", 0);

    formData.append("PORTPASSEXPIRY", "");
    formData.append("image", picture);
    console.log(passingTypeSaveUpdate, "passingTypeSaveUpdate");
    console.log(formData);
    axios
      .post(`${BASEURL}/registeremploee/${passingTypeSaveUpdate}`, formData, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        if (response.data.message === "niet") {
          alert("Employee code already exists!");
          setisloading(false);
        } else {
          alert(response.data.message);
          setisloading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });
  }

  const renderFilePreview = () => {
    if (fileUpload) {
      const fileType = fileUpload.type.split("/")[1];

      if (fileType === "pdf") {
        return (
          <embed
            src={URL.createObjectURL(fileUpload)}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        );
      } else if (
        ["jpg", "jpeg", "png", "heic", "heif", "webp"].includes(fileType)
      ) {
        return <img src={URL.createObjectURL(fileUpload)} />;
      }

      return <p>Preview not available for this file type.</p>;
    }
  };
  function editUserData() {
    setEmpId(editDateEmployee?.EMPID);
    console.log(editDateEmployee?.DESIGNATION);
    console.log(editDateEmployee?.DEPARTMENT);
    console.log(editDateEmployee?.JOINDATE?.slice(0, 10));
    setDesignation(editDateEmployee?.DESIGNATION);
    setDepartment(editDateEmployee?.DEPARTMENT);
    setJOINDATE(editDateEmployee?.JOINDATE?.slice(0, 10));
  }
  useEffect(() => {
    editUserData();
    setisloading(false);
  }, [editDateEmployee]);
  console.log(editDateEmployee);
  console.log(designation, "designation");
  if (isloading) {
    return <Loading2 />;
  }
  return (
    <div className="bg-slate-100 input__box">
      <MenuNavbar />
      <header className="header__of__main lg:flex justify-end items-end px-3">
        <button
          className="button-50 "
          onClick={() => {
            navigate("/EMPLOYEEMASTERProcessed");
          }}
        >
          View Employee List
        </button>
      </header>
      {cameraOn ? (
        <div className="camera__align">
          <video ref={videoRef} />

          <button
            className="Capture"
            onClick={() => {
              capturePicture();
            }}
          >
            Capture
          </button>
          <canvas ref={canvasRef} />
        </div>
      ) : null}
      {captured ? (
        <div>
          <div>
            <img className="Image" src={dispPicture} />
          </div>

          <div className="camera_btn2">
            <button
              className="camera_btn2"
              onClick={() => {
                retakePicture();
              }}
            >
              Retake
            </button>
            <button
              className="Proceed"
              onClick={() => {
                setCameraOn(false);
                setCaptured(false);
                setSection1(true);
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : null}

      {section1 ? (
        <div className="lg:w-[800px] w-[100%] mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6">Employee Master</h2>{" "}
          <form
            onSubmit={(e) => {
              const CONFIRMBox = window.confirm(
                "Are you sure all the details are correct?"
              );
              if (CONFIRMBox === true) {
                handleRegister(e);
              } else {
                e.preventDefault();
              }
            }}
          >
            <div className="row">
              <div className="row">
                <div className="rounded-[20%] w-[200px] h-[200px] shadow-lg bg-slate-50 py-4 px-2">
                  <img
                    required
                    name="userImg"
                    style={{ fontFamily: "Cursive" }}
                    src={dispPicture ? dispPicture : ImagineValue}
                    alt="Upload image here "
                    className={dispPicture ? "img" : "profile_pic"}
                  />
                  {editDateEmployee && (
                    <img src={editDateEmployee.IMAGEBINARY?.data} alt="" />
                  )}
                </div>
              </div>
              <div className="camera_upload">
                <div className="small-12 medium-2 large-2 columns">
                  <div className="camera_upload">
                    <div className="camera__icon">
                      <button
                        className="camera_btn"
                        onClick={() => {
                          const CONFIRMBox = window.confirm(
                            "Allow access to camera?"
                          );
                          if (CONFIRMBox === true) {
                            setSection1(false);
                            setCameraOn(true);
                            openCamera();
                          } else {
                            return null;
                          }
                        }}
                      >
                        {" "}
                        <p className="bg-green">
                          <FaCamera />
                        </p>
                      </button>
                    </div>
                    <div>
                      <input
                        style={{ padding: "5px", display: "none" }}
                        id="file"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          handlePictureUpload(e);
                        }}
                      />
                      <div className="upload_file">
                        <label className="View" htmlFor="file">
                          Upload
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:flex justify-between flex-wrap">
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMPLOYEE NO{" "}
                  </label>
                  <input
                    required
                    type="text"
                    name="EMPNO"
                    defaultValue={empidPass}
                    disabled
                    className="bg-white"
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMPLOYEE CODE
                  </label>
                  <input
                    required
                    type="text"
                    name="EMPCODE"
                    defaultValue={formInput.EMPCODE}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMPLOYEE NAME
                  </label>
                  <input
                    required
                    type="text"
                    name="EMPNAME"
                    defaultValue={formInput.EMPNAME}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    ACCOUNT NO
                  </label>
                  <input
                    required
                    type="text"
                    name="ACCOUNTNO"
                    defaultValue={formInput.ACCOUNTNO}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    JOIN DATE
                  </label>
                  <input
                    required
                    type="date"
                    name="JOINDATE"
                    defaultValue={JOINDATE}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      setJOINDATE(moment(e.target.value).format("DD/MM/YYYY"));
                    }}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    NATIONAL ID
                  </label>
                  <input
                    required
                    type="text"
                    name="NATIONALID"
                    defaultValue={formInput.NATIONALID}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    TELEPHONE
                  </label>
                  <input
                    required
                    type="number"
                    name="TELEPHONE"
                    defaultValue={formInput.TELEPHONE}
                    onChange={(e) => handleInputItem(e)}
                    onInput={tofixnumber}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMAIL
                  </label>
                  <input
                    required
                    type="EMAIL"
                    name="EMAIL"
                    defaultValue={formInput.EMAIL}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%] flex mt-2">
                  <label className="block text-gray-700 font-bold mb-2">
                    OVERTIME
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        required
                        type="radio"
                        name="OVERTIME"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(1)}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        required
                        type="radio"
                        name="OVERTIME"
                        defaultChecked={true}
                        onChange={(e) => handleInputItem(e)}
                        value={Number(0)}
                      />
                      No
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:flex justify-between flex-wrap">
                {/* <div>
                <label className="block text-gray-700 font-bold mb-2">DESIGNATION</label>
                <select
                  className="input_field"
                  required
                  name="DESIGNATION"
                  value={formInput.DESIGNATION}
                  onChange={(e) => handleInputItem(e)}
                >
                  <option value="">Select</option>

                
                  {desigVal.map((data) => (
                    <option
                      key={data.OM_DESIGNATION_NAME}
                      value={data.OM_DESIGNATION_NAME}
                    >
                      {data.OM_DESIGNATION_NAME}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-2">DEPARTMENT</label>
                <select
                  className="input_field"
                  required
                  name="DEPARTMENT"
                  onChange={(e) => handleInputItem(e)}
                >
                  <option value="">Select</option>

              
                  {DEPARTMENTVal.length > 0
                    ? DEPARTMENTVal.map((data) => (
                        <option
                          key={data.OM_DEPARTMENT_NAME}
                          value={data.OM_DEPARTMENT_NAME}
                        >
                          {data.OM_DEPARTMENT_NAME}
                        </option>
                      ))
                    : ""}
                </select>
              </div> */}
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    DESIGNATION
                  </label>
                  <Select
                    className="input_field text-left uppercase"
                    required
                    onChange={(e) => {
                      setDesignation(e.value);
                    }}
                    defaultValue={
                      designation
                        ? { label: designation, value: designation }
                        : { label: "", value: "" }
                    }
                    options={[
                      { value: "", label: "" },
                      ...desigVal.map((item) => ({
                        label: item.OM_DESIGNATION_NAME,
                        value: item.OM_DESIGNATION_NAME,
                      })),
                    ]}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    DEPARTMENT
                  </label>
                  <Select
                    className="input_field text-left uppercase"
                    required
                    onChange={(e) => {
                      setDepartment(e.value);
                    }}
                    defaultValue={
                      department
                        ? { label: department, value: department }
                        : { label: "", value: "" }
                    }
                    options={[
                      { value: "", label: "" },
                      ...DEPARTMENTVal.map((item) => ({
                        label: item.OM_DEPARTMENT_NAME,
                        value: item.OM_DEPARTMENT_NAME,
                      })),
                    ]}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%] flex my-3 justify-between">
                  <label className="block text-gray-700 font-bold mb-2">
                    FREEZE
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        name="FREEZE"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(1)}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        name="FREEZE"
                        defaultChecked={true}
                        onChange={(e) => handleInputItem(e)}
                        value={Number(0)}
                      />
                      No
                    </div>
                  </div>
                </div>

                {/* <div>
                <label className="block text-gray-700 font-bold mb-2">VEHICLE ALLOCATED</label>
                <select
                  className="input_field"
                  name="VEHICLEALLOCATED"
                  onChange={(e) => handleInputItem(e)}
                >
                  <option value="">Select</option>

               
                  {vehicles.map((vehi, index) => (
                    <option key={index} value={vehi["VEHICLE REG NO"]}>
                      {vehi["VEHICLE REG NO"]}
                    </option>
                  ))}
                </select>
              </div> */}
                {/* <div>
                <label className="block text-gray-700 font-bold mb-2">VEHICLE ALLOCATED</label>
                <Select
                  className="input_field text-left uppercase"
                  isDisabled={designation === "" || designation !== "DRIVER"}
                  value={
                    vehicleAllocated
                      ? { label: vehicleAllocated, value: vehicleAllocated }
                      : { label: "select", value: "" }
                  }
                  onChange={setVehicleAllocated}
                  options={vehicles.map((vehi) => ({
                    label: vehi["VEHICLE REG NO"],
                    value: vehi["VEHICLE REG NO"],
                  }))}
                />
              </div> */}
                <div className="mb-4 lg:w-[48%] w-[90%]  flex my-3 justify-between">
                  <label className="block text-gray-700 font-bold mb-2">
                    CASUAL
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        required
                        type="radio"
                        name="CASUAL"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "0") {
                            // If CASUAL is No, set SUBCONTRACTEDED to Yes
                            setFormInput({
                              ...formInput,
                              CASUAL: 0,
                              SUBCONTRACTED: 1,
                            });
                          } else {
                            // If CASUAL is Yes, set SUBCONTRACTEDED to No
                            setFormInput({
                              ...formInput,
                              CASUAL: 1,
                              SUBCONTRACTED: 0,
                            });
                          }
                        }}
                        value={1}
                        checked={formInput.CASUAL === 1}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        required
                        type="radio"
                        name="CASUAL"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "1") {
                            // If CASUAL is Yes, set SUBCONTRACTEDED to No
                            setFormInput({
                              ...formInput,
                              CASUAL: 1,
                              SUBCONTRACTED: 0,
                            });
                          } else {
                            // If CASUAL is No, set SUBCONTRACTEDED to Yes
                            setFormInput({
                              ...formInput,
                              CASUAL: 0,
                              SUBCONTRACTED: 1,
                            });
                          }
                        }}
                        value={0}
                        checked={formInput.CASUAL === 0}
                      />
                      No
                    </div>
                  </div>
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]  flex my-3 justify-between">
                  <label className="block text-gray-700 font-bold mb-2">
                    SUBCONTRACTED
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        required
                        className="radio"
                        type="radio"
                        name="SUBCONTRACTED"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "0") {
                            // If SUBCONTRACTEDED is No, set CASUAL to Yes
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 0,
                              CASUAL: 1,
                            });
                          } else {
                            // If SUBCONTRACTEDED is Yes, set CASUAL to No
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 1,
                              CASUAL: 0,
                            });
                          }
                        }}
                        value={1}
                        checked={formInput.SUBCONTRACTED === 1}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        required
                        className="radio"
                        type="radio"
                        name="SUBCONTRACTED"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "1") {
                            // If SUBCONTRACTEDED is Yes, set CASUAL to No
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 1,
                              CASUAL: 0,
                            });
                          } else {
                            // If SUBCONTRACTEDED is No, set CASUAL to Yes
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 0,
                              CASUAL: 1,
                            });
                          }
                        }}
                        value={0}
                        checked={formInput.SUBCONTRACTED === 0}
                      />
                      No
                    </div>
                  </div>
                </div>
                {/* <div className="Pass_port_no">
                <label className="block text-gray-700 font-bold mb-2">CREATEDBY </label>
                <input
                  className="input_field"
                  type="text"
                  name="CREATEDBY"
                  defaultValue={formInput.CREATEDBY}
                  onChange={(e) => handleInputItem(e)}
                />
              </div> */}

                <div className="mb-4 lg:w-[48%] w-[90%]  flex my-3 justify-between">
                  <label className="block text-gray-700 font-bold mb-2">
                    CONFIRM{" "}
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        name="CONFIRM"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(1)}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        name="CONFIRM"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(0)}
                        defaultChecked={true}
                      />
                      No
                    </div>
                  </div>
                </div>
                <div className="textarea_NOTES">
                  <label className="block text-gray-700 font-bold mb-2">
                    {" "}
                    NOTES
                  </label>
                  <textarea
                    required
                    className="Note"
                    type="text"
                    name="NOTES"
                    defaultValue={formInput.NOTES}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                {/* <div className="portDetails">
                <label className="block text-gray-700 font-bold mb-2">PORT PASS</label>
                <div className="radio_btn">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      value={Number(1)}
                      name="PORTPASS"
                      onChange={(e) => {
                        handleInputItem(e);
                        sethide(false);
                      }}
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      defaultChecked={true}
                      value={Number(0)}
                      name="PORTPASS"
                      onChange={(e) => {
                        handleInputItem(e);
                        sethide(true);
                      }}
                    />
                    No
                  </div>
                </div>
              </div>
              <div className="Pass_port_no">
                <label className="block text-gray-700 font-bold mb-2">PORT PASS NUMBER</label>
                <input
                  className="input_field"
                  type="text"
                  name="PORTPASSNO"
                  defaultValue={formInput.PORTPASSNO}
                  onChange={(e) => {
                    handleInputItem(e);
                  }}
                />
              </div>
              <div className="Pass_port_no">
                <label className="block text-gray-700 font-bold mb-2">PORT PASS EXPIRY DATE</label>
                <input
                  className="input_field"
                  type="date"
                  name="PORTPASSEXPIRY"
                  onChange={(e) => {
                    setPORTPASSPORTPASSEXPIRY(
                      moment(e.target.value).format("DD/MM/YYYY")
                    );
                  }}
                />
              </div> */}

                {/* <div className="upload_file_for_register">
                <label>Upload file:</label>
                <input
                  style={{ width: "250px", backgroundColor: "#ecf1f2" }}
                  id="file"
                  type="file"
                  accept=".png, .jpg, .jpeg, .docx, .doc, .pdf, .xsl, .txt, .ppt, .pptx"
                  onChange={(e) => {
                    setFileUpload(e.target.files[0]);
                  }}
                ></input>
              </div> */}
                {/* <span className="">
                <button
                  className="Preview"
                  type="button"
                  padding="10px 20px"
                  backgroundColor="#002d62"
                  color="white"
                  onClick={() => {
                    if (fileUpload !== null) {
                      setFilePreview(!filePreview);
                    } else {
                      alert("Please select a file first.");
                    }
                  }}
                >
                  {filePreview ? "Close" : "Preview"}
                </button>
              </span> */}

                {filePreview ? <div>{renderFilePreview()}</div> : null}
              </div>
              <div className="nxt">
                <button
                  className="View button"
                  onClick={() => {
                    window.location.reload();
                    dispatch(EmployeeMasterEdit(""));
                  }}
                >
                  Reset
                </button>

                <button
                  className="View button bg-green"
                  type="submit"
                  onClick={() => {
                    setPassingTypeSaveUpdate("update");
                  }}
                >
                  Update
                </button>
                <button
                  className="View button bg-green"
                  type="submit"
                  onClick={() => {
                    setPassingTypeSaveUpdate("new");
                  }}
                >
                  Register
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : null}

      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
};

export default EMPLOYEEMASTER;
