import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
const ExpiredLeases = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const authToken = window.sessionStorage.getItem("auth-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [search, setSearch] = useState("");
  const [ExpiredLeases, setExpiredLeases] = useState([]);
  const [isloading, setisloading] = useState(true);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");

  useEffect(() => {
    masterData();
  }, []);

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = ExpiredLeases?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        "PROJECTID",
        "PROJECTNAME",
        "UNITID",
        "UNITNUMBER",
        "LEASENUMBER",
        "CLIENTCODE",
        "LEASESTARTDATE",
        "LEASEENDDATE",
        "RENTAMOUNT",
        "SERVICEAMOUNT",
        "TRANSPORTSERVICES",
        "INTERNETSERVICES",
        "INTERNETCHARGES",
        "TRANSPORTCHARGE",
        "CREATEDBY",
        "CREATEDDATE",
        "CONFIRMED",
        "CONFIRMEDBY",
        "SUBUNITID",
        "CLIENTNAME",
        "SUBUNITNO",
        "ELECTRICITYRATEPERUNIT",
        "DEPOSITAMOUNT",
        "EMAILADDRESS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const sDate = item.LEASEENDDATE;
    const eDate = item.LEASEENDDATE;
    const isDateRangeMatch =
      (!fromDateFilter || sDate >= fromDateFilter) &&
      (!toDateFilter || eDate <= toDateFilter);
    return textMatch && isDateRangeMatch;
  });

  function masterData() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getexpiredleases`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setExpiredLeases(response.data);
        console.log(response.data);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
        <div>
          <header className="header__of__main lg:flex justify-between px-3 items-center">
            <h1 className="header__of__page">
              {" "}
              {menu ? menu : "Expired Leases"}{" "}
            </h1>
            {/* <button
              className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
              onClick={() => {
                navigate("/RentAmountCalculation");
              }}
            >
              Back
            </button> */}
          </header>

          <article>
            <div className="flex gap-3 relative mx-[30px] my-2"></div>
            <div className="lg:flex items-center justify-between mx-[20px]">
              <div className="flex">
                <p className="lg:mx-2 text-[16px]">Search</p>
                <input
                  className="w-[300px] lg:mr-[50px] h-[30px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
                <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateFilterChange}
                  />
                </div>
                <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
                  <input
                    type="date"
                    value={toDateFilter}
                    onChange={handleToDateFilterChange}
                  />
                </div>
              </section>
            </div>
            <div className="  overflow-auto m-[10px]  p-[20px] ">
              <table className="table_1  table__size__reducer">
                <thead>
                  <tr style={{ fontWeight: "400" }}>
                    <td className="text-right">PROJECT ID</td>
                    <td>PROJECT NAME</td>
                    <td className="text-right">UNIT ID</td>
                    <td className="text-right">UNIT NUMBER</td>
                    <td className="text-right">LEASE NUMBER</td>
                    <td>CLIENT CODE</td>
                    <td className="text-right">LEASE START DATE</td>
                    <td className="text-right">LEASE END DATE</td>
                    <td className="text-right">RENT AMOUNT</td>
                    <td className="text-right">SERVICE AMOUNT</td>
                    <td>TRANSPORT SERVICES</td>
                    <td>INTERNET SERVICES</td>
                    <td className="text-right">INTERNET CHARGES</td>
                    <td className="text-right">TRANSPORT CHARGE</td>
                    <td>CREATED BY</td>
                    <td className="text-right">CREATED DATE</td>
                    <td>CONFIRMED</td>
                    <td>CONFIRMED BY</td>
                    <td className="text-right">SUB UNIT ID</td>
                    <td>CLIENT NAME</td>
                    <td>SUB UNIT NO</td>
                    <td className="text-right">ELECTRICITY RATE PER UNIT</td>
                    <td className="text-right">DEPOSIT AMOUNT</td>
                    <td>EMAIL ADDRESS</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((item, index) => {
                      const leaseStartDate = new Date(item?.LEASESTARTDATE);
                      const currentDate = new Date();
                      const timeDiff = Math.abs(currentDate - leaseStartDate);
                      const diffDays = Math.ceil(
                        timeDiff / (1000 * 60 * 60 * 24)
                      ); // Calculating difference in days

                      return (
                        <tr key={index} className="table-row">
                          <td className="text-right">
                            {item?.PROJECTID ? item?.PROJECTID : "N/A"}
                          </td>
                          <td>
                            {item?.PROJECTNAME ? item?.PROJECTNAME : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.UNITID ? item?.UNITID : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.UNITNUMBER ? item?.UNITNUMBER : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.LEASENUMBER ? item?.LEASENUMBER : "N/A"}
                          </td>
                          <td>{item?.CLIENTCODE ? item?.CLIENTCODE : "N/A"}</td>
                          <td className="text-right">
                            {item?.LEASESTARTDATE
                              ? item?.LEASESTARTDATE.slice(8, 10) +
                                "/" +
                                item?.LEASESTARTDATE.slice(5, 7) +
                                "/" +
                                item?.LEASESTARTDATE.slice(0, 4)
                              : "N/A"}{" "}
                          </td>
                          <td className="text-right">
                            {item?.LEASEENDDATE
                              ? item?.LEASEENDDATE.slice(8, 10) +
                                "/" +
                                item?.LEASEENDDATE.slice(5, 7) +
                                "/" +
                                item?.LEASEENDDATE.slice(0, 4)
                              : "N/A"}{" "}
                          </td>

                          <td className="text-right">
                            {item?.RENTAMOUNT?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}{" "}
                          </td>
                          <td className="text-right">
                            {item?.SERVICEAMOUNT?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}{" "}
                          </td>
                          <td>
                            {item?.TRANSPORTSERVICES
                              ? item?.TRANSPORTSERVICES
                              : "N/A"}
                          </td>
                          <td>
                            {item?.INTERNETSERVICES
                              ? item?.INTERNETSERVICES
                              : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.INTERNETCHARGES?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}{" "}
                          </td>
                          <td className="text-right">
                            {item?.TRANSPORTCHARGE?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}{" "}
                          </td>
                          <td>{item?.CREATEDBY ? item?.CREATEDBY : "N/A"}</td>
                          <td className="text-right">
                            {item?.CREATEDDATE
                              ? item?.CREATEDDATE.slice(8, 10) +
                                "/" +
                                item?.CREATEDDATE.slice(5, 7) +
                                "/" +
                                item?.CREATEDDATE.slice(0, 4)
                              : "N/A"}
                          </td>
                          <td>{item?.CONFIRMED ? "YES" : "NO"}</td>
                          <td>
                            {item?.CONFIRMEDBY ? item?.CONFIRMEDBY : "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.SUBUNITID ? item?.SUBUNITID : "N/A"}
                          </td>
                          <td>{item?.CLIENTNAME ? item?.CLIENTNAME : "N/A"}</td>
                          <td>{item?.SUBUNITNO ? item?.SUBUNITNO : "N/A"}</td>
                          <td className="text-right">
                            {item?.ELECTRICITYRATEPERUNIT?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            ) || "N/A"}{" "}
                          </td>
                          <td className="text-right">
                            {item?.DEPOSITAMOUNT?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}{" "}
                          </td>
                          <td>
                            {item?.EMAILADDRESS ? item?.EMAILADDRESS : "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="25">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ExpiredLeases;
