import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
function InspectionMaster(props) {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [inspectiondata, setinspectiondata] = useState([]);
  const [selinspectiontype, setselinspectiontype] = useState("");
  const [inspectionitem, setinspectionitem] = useState("");
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const filteredData = inspectiondata.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["INSPECTIONTYPE", "INSPECTIONITEM", "INSPECTIONAREAS"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    getInspectionList();
  }, []);

  const FetchTableValuesInFields = (item) => {
    setselinspectiontype(item.INSPECTIONAREAS);
    setinspectionitem(item.INSPECTIONITEM);
  };

  const getInspectionList = () => {
    axios
      .get(`${BASEURL}/getinspectionlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setinspectiondata(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching Inspection List:", error);
        setisloading(false);
      });
  };

  const clearallinputs = () => {
    setselinspectiontype("");
    setinspectionitem("");
  };

  async function Savedetails() {
    try {
      const response = await axios.post(
        `${BASEURL}/saveinspectionparametermaster`,
        {
          INSPECTIONITEMAREA: selinspectiontype,
          INSPECTIONITEM: inspectionitem,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "Inspection Master"}
                </h1>
              </header>
              <section className="section__newgarage">
                <aside className="aside__new__garage  inpection__master">
                  <div>
                    <p>
                      <label className="garage_id_">Inspection Area</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (e.value === "") {
                            alert("Kindly supply proper Inspection Type");
                            setselinspectiontype("");
                          } else {
                            setselinspectiontype(e.value);
                          }
                        }}
                        value={
                          selinspectiontype
                            ? {
                                label: selinspectiontype,
                                value: selinspectiontype,
                              }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          { value: "BEDROOM", label: "BEDROOM" },
                          {
                            value: "FURNITURE INVENTORY",
                            label: "FURNITURE INVENTORY",
                          },
                          { value: "BATHROOM", label: "BATHROOM" },
                          { value: "KITCHEN", label: "KITCHEN" },
                          { value: "MECHANICAL", label: "MECHANICAL" },
                          { value: "SHARED SPACE", label: "SHARED SPACE" },
                        ]}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Inspection Item </label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        type="text"
                        value={inspectionitem}
                        onChange={(e) => {
                          setinspectionitem(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!selinspectiontype) {
                        alert("Kindly supply Inspection Type");
                      } else if (!inspectionitem) {
                        alert("Kindly supply Inspection Item");
                      } else {
                        await Savedetails();
                        clearallinputs();
                        getInspectionList();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
        </div>
        <div className="service_details">
          <table className="table_1 ">
            <thead>
              <tr>
                <td>INSPECTION AREA</td>
                <td>INSPECTION NAME</td>
                <td>EDIT</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td style={{ width: "270px", paddingLeft: "10px" }}>
                    {item.INSPECTIONAREAS ? item.INSPECTIONAREAS : "NA"}
                  </td>
                  <td style={{ width: "320px", paddingLeft: "10px" }}>
                    {item.INSPECTIONITEM ? item.INSPECTIONITEM : "NA"}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

InspectionMaster.propTypes = {};

export default InspectionMaster;
