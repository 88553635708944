import React, { useEffect, useState, useRef } from "react";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { FaCameraRetro, FaTrash } from "react-icons/fa";

function SpecializationSetup() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);

  const [specializationCode, setSpecializationCode] = useState("");
  const [specializationName, setSpecializationName] = useState("");
  const [tableData, setTableData] = useState([]);

  const [imagesHistoric, setImagesHistoric] = useState(null);
  const [images, setImages] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [type, setType] = useState("new");

  // ********************************* Image Upload Section *************************************
  const handleUploadChange = (event) => {
    const files = event.target.files[0];
    setImages(files);
  };

  const openCamera = () => {
    setIsCameraOpen(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error("Error accessing camera: ", err);
      });
  };

  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL("image/png");
    setImages((prevImages) => [...prevImages, dataUrl]);
    stopCamera();
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setIsCameraOpen(false);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteImage = () => {
    setImages(null);
  };
  const handleDeleteImageHistoric = () => {
    setImagesHistoric(null);
  };

  const filteredData = tableData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["SPECCODE", "SPECNAME"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    getListOfSpecialization();
  }, []);

  const FetchTableValuesInFields = (item) => {
    setSpecializationCode(item.SPECCODE);
    setSpecializationName(item.SPECNAME);
    setImagesHistoric(item.IMAGEPATH);
    setType("update");
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getListOfSpecialization = () => {
    axios
      .get(`${BASEURL}/getSpecializations`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setTableData(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Specializations", error);
        setisloading(false);
      });
  };

  async function Save() {
    setisloading(true);
    try {
      const formData = new FormData();
      if (type === "new") {
        formData.append("SPECIALIZATIONCODE", specializationCode);
        formData.append("SPECIALIZATIONNAME", specializationName);
        formData.append("imageValue", images);
      } else {
        formData.append("SPECIALIZATIONCODE", specializationCode);
        formData.append("SPECIALIZATIONNAME", specializationName);
        formData.append("imageValue", imagesHistoric);
      }
      const response = await axios.post(
        `${BASEURL}/insertSpecialization/${type}`,
        formData,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert("Error Saving Specialization", error);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Document Master"}
              </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage DocumentMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">SPECIALIZATION CODE</label>
                  </p>
                  <div>
                    <input
                      required
                      className="inputttt"
                      value={specializationCode}
                      onChange={(e) => setSpecializationCode(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">SPECIALIZATION NAME</label>
                  </p>
                  <div>
                    <input
                      required
                      className="input_1"
                      value={specializationName}
                      onChange={(e) => {
                        setSpecializationName(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>

              <aside className="aside__new__garage DocumentMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">SPECIALIZATION IMAGE</label>
                  </p>
                  <div className="photo-section m-4">
                    <div className="form-group mb-4">
                      <input
                        type="file"
                        id="photoUpload"
                        accept="image/*"
                        multiple
                        className="hidden"
                        ref={fileInputRef}
                        onChange={handleUploadChange}
                      />
                      <div className="lg:flex items-center gap-5">
                        <button
                          type="button"
                          className="w-[150px] p-2 bg-blue-500 text-white rounded-md"
                          onClick={handleFileUploadClick}
                        >
                          Choose File
                        </button>
                        <button
                          type="button"
                          className="w-[50px] p-2 bg-blue-500 text-white rounded-md hidden lg:block"
                          onClick={openCamera}
                        >
                          <span className="lg:flex justify-center text-[25px]">
                            <FaCameraRetro />
                          </span>
                        </button>
                      </div>
                    </div>
                    {isCameraOpen && (
                      <div className="camera-section mb-4">
                        <video
                          ref={videoRef}
                          className="w-full mb-2"
                          autoPlay
                        ></video>
                        <button
                          type="button"
                          className="w-full p-2 bg-red-500 text-white rounded-md"
                          onClick={capturePhoto}
                        >
                          Capture Photo
                        </button>
                        <canvas
                          ref={canvasRef}
                          className="hidden"
                          width="300"
                          height="200"
                        ></canvas>
                      </div>
                    )}
                    <div className="preview mt-4 grid grid-cols-2 gap-4">
                      {imagesHistoric && (
                        <div className="relative">
                          <img
                            src={imagesHistoric}
                            alt="historic preview"
                            className="max-w-full h-auto"
                          />
                          <button
                            className="absolute top-0 right-0 p-2 m-1 bg-red-500 text-white rounded-full"
                            onClick={async () => {
                              handleDeleteImageHistoric();
                            }}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      )}
                      {images && (
                        <div className="relative">
                          <img
                            src={
                              typeof images === "string"
                                ? images
                                : URL.createObjectURL(images)
                            }
                            alt="Image Preview"
                            className="max-w-full h-auto"
                          />
                          <button
                            className="absolute top-0 right-0 p-2 m-1 bg-red-500 text-white rounded-full"
                            onClick={() => handleDeleteImage()}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </aside>
              <div className="savebutton">
                <button
                  className="buttonunit2"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit"
                  onClick={async () => {
                    if (!specializationCode) {
                      alert("Kindly Supply Specialization Code");
                    } else if (!specializationName) {
                      alert("Kindly Supply Specialization Name");
                    } else {
                      if (type === "new" && !images) {
                        alert("Kindly Supply a Specialization image");
                      } else if (type === "update" && !imagesHistoric) {
                        alert("Kindly Supply a Specialization image");
                      } else {
                        await Save();
                        window.location.reload();
                      }
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>{" "}
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_details">
        <table className="table_1">
          <thead>
            <tr>
              <td>SPECIALIZATION CODE</td>
              <td>SPECIALIZATION NAME</td>
              <td>SPECIALIZATION IMAGE</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td style={{ paddingRight: "80px" }}>
                    {item.SPECCODE ? item.SPECCODE : ""}
                  </td>
                  <td>{item.SPECNAME ? item.SPECNAME : "NA"}</td>
                  <td>
                    <img src={item.IMAGEPATH} width={100}></img>
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SpecializationSetup;
