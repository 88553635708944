import React, { useState, useEffect } from "react";
import Navbar from "../NavbarClient/ClientMenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
// import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { FaEdit } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import { Clientname } from "../../Redux/Action/UnapprovedReceipt";

import moment from "moment";

function UnapprovedReceipts() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [Startdatesort, setStartdatesort] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.changeTheState);
  console.log(state.user.userCode);
  const loginAs = state?.user?.emailId?.split("@")[0];
  const [isloading, setisloading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  var clientcode = state?.user?.userCode;
  function getListOfProjectIds() {
    console.log(clientcode);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/getUnallocatedStatements`,
        {
          CLIENTCODE: clientcode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofprojectid(response.data);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Project Idss:", error);
      });
  }
  useEffect(() => {
    getListOfProjectIds();
  }, []);
  const filteredData = listofprojectid.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const startDate = moment(Startdatesort).format("YYYY-MM-DD"); // Format start date

    if (searchData.length === 0 && !Startdatesort) {
      // Check if start date is not selected
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "VOUCHERCODE",
        "RECEIPTNO",
        "AMOUNT",
        "RECEIPTDATE",
        "CLIENTCODE",
        "CLIENTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return (
      textMatch &&
      (!Startdatesort ||
        moment(item.RECEIPTDATE).format("YYYY-MM-DD") === startDate)
    );
  });
  if (isloading) {
    <Loading />;
  }
  return (
    <div>
      {" "}
      <Navbar />
      <header className="header__of__main">
        <h1 className="header__of__page">
          {" "}
          {menu ? menu : "UnAllocation Receipts"}
        </h1>
      </header>
      <div className="lg:flex gap-2 items-center filter__no__search mx-2 lg:mx-10">
        <p>
          <label>Select the Date</label>
        </p>
        <div>
          <input
            required
            className=""
            type="date"
            value={Startdatesort}
            onChange={(e) => setStartdatesort(e.target.value)}
          />
        </div>

        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="search__icon">
            <FaSearch />
          </div>
        </div>
        {/* <button
          style={{ backgroundColor: "#2f2f5a", color: "white" }}
          className="button__logout__navbar  text-white p-1 rounded "
          //   onClick={HandleSave}
        >
          SAVE
        </button> */}
        {/* <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div> */}
      </div>
      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              {/* <td>VOUCHER CODE</td> */}
              <td className="text-right">RECEIPT NO</td>
              <td>RECEIPT DATE</td>
              <td className="text-right">AMOUNT</td>

              {/* <td>CLIENT CODE</td> */}
              <td>CLIENT NAME</td>
              <td>MPESA REFERENCE NO</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr
                  onClick={() => {
                    dispatch(Clientname(item));
                    navigate("/AllocationInvoices");
                  }}
                  key={index}
                  style={{ textTransform: "uppercase" }}
                >
                  {/* <td>{item.VOUCHERCODE ? item.VOUCHERCODE : "NA"}</td> */}
                  <td className="text-right">
                    {item.RECEIPTNO ? item.RECEIPTNO : "NA"}
                  </td>
                  <td>
                    {item.RECEIPTDATE
                      ? moment(item.RECEIPTDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td className="text-right">
                    {item.AMOUNT ? item.AMOUNT.toLocaleString() : "NA"}
                  </td>

                  {/* <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td> */}

                  <td>{item.CLIENTNAME ? item.CLIENTNAME : "NA"}</td>
                  <td>
                    {/* <input type="text"></input> */}
                    {item.MPESAREFERENCENO ? item.MPESAREFERENCENO : "NA"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No data Avialiable
                </td>
              </tr>
            )}
          </tbody>

          {/* <tbody>
            {listofprojectid.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.CLIENTCODE === item.CLIENTCODE
                        )}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </label>
                  </td>
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                  </td>
                  <td>{item.UNITNUMBER ? item.UNITNUMBER : "NA"}</td>
                  <td>
                    {item.LEASESTARTDATE
                      ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                  <td>
                    {item.LEASEENDDATE
                      ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td>{item.DAYSCONSUMED ? item.DAYSCONSUMED : "NA"}</td>
                  <td>{item.METERREADING ? item.METERREADING : "NA"}</td>
                  <td>{item.RATEPERUNIT ? item.RATEPERUNIT : "NA"}</td>
                  <td>{item.TOTALDAYS ? item.TOTALDAYS : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {item.DAYSCONSUMED * item.RATEPERUNIT}
                  </td>
                </tr>
              );
            })}
          </tbody> */}
        </table>
      </div>
    </div>
  );
}

export default UnapprovedReceipts;
