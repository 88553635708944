import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
const getLastDayOfMonth = (year, month) => {
  const lastDay = new Date(year, month + 1, 0).getDate();
  return lastDay;
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const lastDayOfMonth = getLastDayOfMonth(currentYear, currentMonth);
  return `${currentYear}-${(currentMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;
};

function ElectricityBill() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [meterreadingNo, setmeterreadingNo] = useState("");
  const userState = useSelector((state) => state.changeTheState.user);
  const [unitnoList, setunitnoList] = useState([]);
  const [projectidlist, setprojectidlist] = useState([]);
  const [selprojectname, setselprojectname] = useState("");
  const [selectedpid, setselectedpid] = useState("");
  const [selecteduno, setselecteduno] = useState("");
  const [selunitid, setselunitid] = useState("");
  const [meterNo, setMeterNo] = useState("");
  const [readingValue, setreadingValue] = useState(0);
  const [dateValue, setDateValue] = useState(getCurrentDate());
  const [timeValue, setTimeValue] = useState("");
  const [listElectricityBill, setListElectricityBill] = useState([]);
  const [search, setSearch] = useState("");
  const [tableflag, settableflag] = useState("");
  const [isloading, setisloading] = useState(true);
  const [startdatesort, setStartdatesort] = useState("");
  const [enddatesort, setEnddatesort] = useState("");
  const [prevmonthreading, setprevmonthreading] = useState("");

  const filteredData = listElectricityBill.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const Startmonthreadingdate = item.ENDMONTHDATE;

    if (searchData.length === 0 && !startdatesort && !enddatesort) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ENDMONTHREADING",
        "READINGVALUE",
        "METERNO",
        "UNITNO",
        "PROJECTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort || Startmonthreadingdate >= startdatesort) &&
      (!enddatesort || Startmonthreadingdate <= enddatesort);

    return textMatch && dateMatch;
  });

  const handleOptionChange = (selectedOption) => {
    const selected = projectidlist.find(
      (item) => item.PROJECTNAME === selectedOption.value
    );
    console.log(selected);
    setselectedpid(selected.PROJECTID);
    UnitList(selected.PROJECTID);
  };

  const UnitList = (value) => {
    axios
      .post(
        `${BASEURL}/getunitnolistforelectricitybill`,
        {
          PROJECTID: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setunitnoList(response.data);
      })
      .catch((err) => {
        alert("Server error");
      });
  };

  const getlatestmeterreading = (unit, project, dateval) => {
    console.log(dateval);
    console.log(unit);
    console.log(project);
    axios
      .post(
        `${BASEURL}/getlatestmeterreading`,
        {
          UNITID: unit,
          PROJECTID: project,
          PreviousmonthDate: dateval,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.ENDMONTHREADING);
        setprevmonthreading(
          response.data[0] ? response.data[0].ENDMONTHREADING : 0
        );
      })
      .catch((err) => {
        console.log(err);
        alert("Server error");
      });
  };

  const getMeterNumber = (unit, project) => {
    console.log(unit, "UNIT");
    console.log(project, "PROJECT");
    axios
      .post(
        `${BASEURL}/fetchelectricitymeterfromunitid`,
        {
          UNITNO: unit,
          PROJECTID: project,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setMeterNo(response.data[0].ELECTRICITYMETER);
      })
      .catch((err) => {
        alert("Server error");
      });
  };

  function fetchInitialData() {
    axios
      .get(`${BASEURL}/getmeterreadingnumber`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setmeterreadingNo(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });

    axios
      .get(`${BASEURL}/getprojectidlistforelectricitybill`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setprojectidlist(response.data);
      })
      .catch((err) => {
        alert("Server error");
      });
  }
  function fetchElcticData() {
    axios
      .get(`${BASEURL}/getlistofelectricitybill`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setListElectricityBill(response.data);
        console.log(response, "data");

        setisloading(false);
      })
      .catch((err) => {
        alert("Server error");
        setisloading(false);
      });
  }

  async function saveMeterReading() {
    console.log(selecteduno);
    console.log(selunitid);
    console.log(meterreadingNo);
    console.log(selectedpid);
    console.log(meterNo);
    console.log(dateValue);
    console.log(timeValue);
    console.log(Number(readingValue));
    console.log(userState.emailId.split("@")[0].toUpperCase());
    const Date =
      dateValue.slice(8, 10) +
      "/" +
      dateValue.slice(5, 7) +
      "/" +
      dateValue.slice(0, 4);
    console.log(Date);
    try {
      const response = await axios.post(
        `${BASEURL}/savemeterreadings`,
        {
          READINGNO: meterreadingNo,
          PROJECTNO: selectedpid,
          // UNITID: selunitid,
          UNITNO: selunitid,
          METERNO: Number(meterNo),
          ENDMONTHDATE: dateValue,
          ENDMONTHTIME: timeValue,
          ENDMONTHREADING: Number(readingValue),
          ENDMONTHREADINGCREATEDBY: userState.emailId
            .split("@")[0]
            .toUpperCase(),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (err) {
      alert(err.response.data.message);
    }
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  useEffect(() => {
    scrollToSection();
    fetchInitialData();
    fetchElcticData();
    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour12: false,
    });
    setTimeValue(currentTime);
  }, []);

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleUnitNo = (selectedOption) => {
    const [year, month] = dateValue.split("-");

    const lastDayOfMonth = new Date(
      parseInt(year),
      parseInt(month),
      0
    ).getDate();

    // Calculate the previous month
    const prevMonth = new Date(parseInt(year), parseInt(month) - 1);
    prevMonth.setMonth(prevMonth.getMonth() - 1);

    const prevYear = prevMonth.getFullYear();
    const prevMonthValue = prevMonth.getMonth();

    // Calculate the last day of the previous month
    const lastDayOfPrevMonth = new Date(
      prevYear,
      prevMonthValue + 1,
      0
    ).getDate();

    const lastDateOfPrevMonthValue = `${prevYear}-${(prevMonthValue + 1)
      .toString()
      .padStart(2, "0")}-${lastDayOfPrevMonth.toString().padStart(2, "0")}`;
    console.log(unitnoList);
    const selected = unitnoList.find(
      (item) => item.UNITNO === selectedOption.value
    );
    setselunitid(selected.PROJECTUNIT);
    console.log(selected.PROJECTUNIT);
    getlatestmeterreading(
      selected.PROJECTUNIT,
      selectedpid,
      lastDateOfPrevMonthValue
    );
  };

  const handleMonthChange = (selectedDate) => {
    if (selprojectname === "") {
      alert("SELECT PROJECT NAME");
      return;
    }
    if (selecteduno === "") {
      alert("SELECT UNIT NO");
      return;
    }

    // Check if all components of the date are provided
    if (
      selectedDate.getFullYear() &&
      selectedDate.getMonth() >= 0 &&
      selectedDate.getDate()
    ) {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1; // Adding 1 since getMonth returns zero-based month index
      const lastDayOfMonth = new Date(year, month, 0).getDate();

      // Calculate the previous month
      const prevMonth = new Date(year, month - 1);
      prevMonth.setMonth(prevMonth.getMonth() - 1);

      const prevYear = prevMonth.getFullYear();
      const prevMonthValue = prevMonth.getMonth() + 1; // Adding 1 since getMonth returns zero-based month index

      // Calculate the last day of the previous month
      const lastDayOfPrevMonth = new Date(
        prevYear,
        prevMonthValue,
        0
      ).getDate();

      const newDateValue = `${year}-${month
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;
      const lastDateOfPrevMonthValue = `${prevYear}-${prevMonthValue
        .toString()
        .padStart(2, "0")}-${lastDayOfPrevMonth.toString().padStart(2, "0")}`;

      setDateValue(newDateValue);
      getlatestmeterreading(selunitid, selectedpid, lastDateOfPrevMonthValue);
    }
  };

  const FetchTableValuesInFields = async (item) => {
    settableflag(1);
    console.log(item);
    setmeterreadingNo(item.RECORDNO);
    setreadingValue(item.ENDMONTHREADING);
    setMeterNo(item.METERNO);
    setDateValue(item.ENDMONTHDATE ? item.ENDMONTHDATE.slice(0, 10) : "");
    setTimeValue(item.ENDMONTHTIME ? item.ENDMONTHTIME.slice(11, 19) : "");
    setselunitid(item.UNITID);
    // UNITID: 2,
    //     UNITNO: '202'
    setselecteduno(item.UNITNO);
    setselectedpid(item.PROPERTYNO);
    setselprojectname(item.PROJECTNAME);
    setprevmonthreading(item.PREVIOUSMONTHREADING);

    const endDate = item.ENDMONTHDATE
      ? new Date(item.ENDMONTHDATE)
      : new Date();

    // Calculate the previous month
    const prevMonth = new Date(endDate);
    prevMonth.setMonth(prevMonth.getMonth() - 1);

    const year = prevMonth.getFullYear();
    const month = prevMonth.getMonth();

    // Calculate the last day of the previous month
    const lastDayOfPrevMonth = new Date(year, month + 1, 0).getDate();
    console.log(lastDayOfPrevMonth);

    const lastDateOfPrevMonthValue = `${year}-${(month + 1)
      .toString()
      .padStart(2, "0")}-${lastDayOfPrevMonth.toString().padStart(2, "0")}`;

    console.log(lastDateOfPrevMonthValue);

    getlatestmeterreading(
      item.UNITNO,
      item.PROPERTYNO,
      lastDateOfPrevMonthValue
    );
  };

  const clearallinputs = () => {
    setreadingValue("");
    setMeterNo("");
    setDateValue(getCurrentDate());
    setselecteduno("");
    setselectedpid("");
    setmeterreadingNo("");
    setselprojectname("");
    setprevmonthreading("");
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="elctric__page">
      <MenuNavbar />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "TOTAL CONSUMPTION"}
              </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p style={{ width: "350px" }}>
                    <label className="garage_id_ ">
                      Meter Reading Number:{" "}
                    </label>
                  </p>
                  <div className="ml-4 text-red-600 font-bold">
                    {meterreadingNo}
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Project Name:</label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(e) => {
                        setselprojectname(e.value);
                        handleOptionChange(e);
                      }}
                      value={
                        selprojectname
                          ? { label: selprojectname, value: selprojectname }
                          : { label: "", value: "" }
                      }
                      options={projectidlist.map((item) => ({
                        label: item.PROJECTNAME,
                        value: item.PROJECTNAME,
                      }))}
                      isDisabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Unit No:</label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(e) => {
                        setselecteduno(e.value);
                        getMeterNumber(e.value, selectedpid);
                        handleUnitNo(e);
                      }}
                      value={
                        selecteduno
                          ? { label: selecteduno, value: selecteduno }
                          : { label: "", value: "" }
                      }
                      options={unitnoList.map((item) => ({
                        label: item.UNITNO,
                        value: item.UNITNO,
                      }))}
                      isDisabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Meter Number</label>
                  </p>
                  <div>
                    <input
                      value={meterNo}
                      readOnly
                      type="text"
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">
                      Previous Month Reading value :
                    </label>
                  </p>
                  <div>
                    {" "}
                    <input value={prevmonthreading} type="text" readOnly />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Reading value :</label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={readingValue}
                      onChange={(e) => {
                        setreadingValue(e.target.value);
                      }}
                      type="number"
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Date</label>
                  </p>
                  <div>
                    <input
                      value={dateValue}
                      onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        handleMonthChange(selectedDate);
                      }}
                      type="date"
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Time :</label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={timeValue}
                      onChange={(e) => {
                        setTimeValue(e.target.value);
                      }}
                      type="time"
                    />
                  </div>
                </div>
              </aside>

              <div className="savebutton">
                <button
                  className="buttonunit mr-4"
                  onClick={async () => {
                    if (!selprojectname) {
                      alert("Kindly Supply Project Name");
                    } else if (!selunitid) {
                      alert("Kindly Supply Unit Number");
                    } else if (!meterNo) {
                      alert("Kindly Supply Meter Number");
                    } else if (!readingValue) {
                      alert("Kindly Supply Reading Value");
                    } else if (!dateValue) {
                      alert("Kindly Supply Date Value");
                    } else if (!timeValue) {
                      alert("Kindly Supply Time Value");
                    } else if (
                      Number(readingValue) < Number(prevmonthreading)
                    ) {
                      alert(
                        "Kindly Supply reading value greater than previous month value"
                      );
                    } else {
                      clearallinputs();
                      settableflag(0);
                      await saveMeterReading();
                      fetchElcticData();
                      fetchInitialData();
                    }
                  }}
                >
                  Submit
                </button>
                <button
                  className="buttonunit"
                  onClick={() => {
                    clearallinputs();
                    settableflag(0);
                    fetchInitialData();
                  }}
                >
                  New Record
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <main className="lease__bothfilte">
        <div className="searc__lease">
          <p>Search:</p>
          <input
            className="input_1 search__lease"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
        </div>
        <aside className="startand__enddate__lease ">
          <div>
            <p>
              <label className="">Start date</label>
            </p>
            <div>
              <input
                required
                className="input_1"
                type="date"
                value={startdatesort.split("T")[0]}
                onChange={(e) => setStartdatesort(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p>
              <label className="">End Date</label>
            </p>
            <div>
              <input
                className="input_1"
                type="date"
                required
                value={enddatesort.split("T")[0]}
                onChange={(e) => setEnddatesort(e.target.value)}
              />
            </div>
          </div>
        </aside>
      </main>
      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>PROPERTY NAME</td>
              <td style={{ textAlign: "right", paddingRight: "50px" }}>
                UNIT NO
              </td>
              <td>METER NO</td>
              <td style={{ textAlign: "right" }}>READINGVALUE</td>
              <td>READINGDATE</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.UNITNO ? item.UNITNO : "NA"}
                  </td>
                  <td className="w-[100px] text-right ">
                    {item.METERNO ? item.METERNO : ""}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.ENDMONTHREADING ? item.ENDMONTHREADING : "NA"}
                  </td>
                  <td>
                    {item.ENDMONTHDATE
                      ? moment(item.ENDMONTHDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>

                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      clearallinputs();
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <></>
    </div>
  );
}

export default ElectricityBill;
