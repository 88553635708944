import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/NavbarClient/ClientMenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
const TenantNotices = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const userState = useSelector((state) => state.changeTheState.user);
  const [noticeId, setNoticeId] = useState(0);
  const [clientProperty, setClientProperty] = useState("");
  const [unitId, setUnitId] = useState(0);
  const [unitDisplay, setUnitDisplay] = useState("");
  const [noticeDate, setNticeDate] = useState("");
  const [noticeReason, setNoticeReasoon] = useState("");
  const [clientName, setClientName] = useState("");
  const [isloading, setisloading] = useState(true);
  const [editBlockState, seteditBlockState] = useState(false);
  const [statusmessage, setStatusmessage] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const [propertyDisplay, setPropertyDisplay] = useState("");
  console.log(userState);
  const date = moment().format("YYYY-MM-DD");
  console.log(date);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  function fetchInitialData() {
    axios
      .post(
        `${BASEURL}/getnoticeforclient`,
        {
          CLIENTCODE: userState.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data[0]) {
          setNoticeId(response.data[0].NOTICEID);
          setNticeDate(response.data[0].EXITDATE.split("T")[0]);
          setNoticeReasoon(response.data[0].REASON);
          setStatusmessage(
            response.data[0].APPROVED
              ? "This Notice has been approved"
              : "This Notice has not been approved "
          );
          axios
            .post(
              `${BASEURL}/getclentunitidandpropid`,
              {
                CLIENTCODE: userState.userCode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              console.log(response);
              setClientProperty(response.data[0]?.PROJECTID);
              setPropertyDisplay(response.data[0]?.PROJECTNAME);
              setUnitId(response.data[0]?.UNITID);
              setClientName(response.data[0]?.CLIENTNAME);
              setisloading(false);
              if (response.data[0]?.PROJECTID) {
                setisloading(true);
                axios
                  .post(
                    `${BASEURL}/getunitnobasedonunitid`,
                    {
                      PROJECTID: response.data[0]?.PROJECTID,
                    },
                    {
                      headers: {
                        "auth-token": authToken,
                        "session-token": sessiontoken,
                      },
                    }
                  )
                  .then((response) => {
                    console.log(response);
                    setUnitDisplay(response.data[0]?.UNITNO);
                    setisloading(false);
                  });

                axios
                  .post(
                    `${BASEURL}/getleaseentrydate`,
                    {
                      CLIENTCODE: userState.userCode,
                    },
                    {
                      headers: {
                        "auth-token": authToken,
                        "session-token": sessiontoken,
                      },
                    }
                  )
                  .then((response) => {
                    setEntryDate(
                      response.data[0]?.LEASESTARTDATE.split("T")[0]
                    );
                    console.log(response);
                  });
              }
            });
          seteditBlockState(true);
        } else {
          axios
            .get(`${BASEURL}/getnewnoticeid`, {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            })
            .then((response) => {
              console.log(response);
              setNoticeId(response.data[0]?.NEXTNO);
            })
            .catch((err) => {
              console.log(err);
            });

          axios
            .post(
              `${BASEURL}/getclentunitidandpropid`,
              {
                CLIENTCODE: userState.userCode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              console.log(response);
              setClientProperty(response.data[0]?.PROJECTID);
              setPropertyDisplay(response.data[0]?.PROJECTNAME);
              setUnitId(response.data[0]?.UNITID);
              setClientName(response.data[0]?.CLIENTNAME);
              setisloading(false);
              if (response.data[0]?.PROJECTID) {
                setisloading(true);
                axios
                  .post(
                    `${BASEURL}/getunitnobasedonunitid`,
                    {
                      PROJECTID: response.data[0]?.PROJECTID,
                    },
                    {
                      headers: {
                        "auth-token": authToken,
                        "session-token": sessiontoken,
                      },
                    }
                  )
                  .then((response) => {
                    console.log(response);
                    setUnitDisplay(response.data[0]?.UNITNO);
                    setisloading(false);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
          axios
            .post(
              `${BASEURL}/getleaseentrydate`,
              {
                CLIENTCODE: userState.userCode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              setEntryDate(response.data[0?.LEASESTARTDATE?.split("T")[0]]);
            });
        }
      });
  }

  useEffect(fetchInitialData, []);

  async function handleSave() {
    console.log(noticeId);
    console.log(userState.userCode);
    console.log(clientProperty);
    console.log(unitId);
    if (noticeDate && unitId && clientProperty && noticeId) {
      if (noticeDate < entryDate) {
        alert("Exit Date can not be before entry date");
      } else {
        try {
          const obj = {
            NOTICEID: noticeId,
            NOTICECLIENTCODE: userState?.userCode,
            NOTICECLIENTPROPERTY: clientProperty,
            NOTICECLIENTUNITID: unitId,
            NOTICEEXITDATE: noticeDate,
            NOTICEREASON: noticeReason,
            NOTICEPROCESSEDBY: userState.emailId.toString().split("@")[0],
            NOTICEPROCESSEDDATE: date,
          };
          const response = await axios.post(
            `${BASEURL}/inserttenantnotice/save`,
            obj,
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          alert(response.data.message);
          console.log(response);
          window.location.reload();
        } catch (err) {
          console.log(err);
          alert(err.response.data.message);
        }
      }
    }
  }
  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <Navbar />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "PROCESS NOTICE"}
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE ID </label>
                    </p>
                    <div>
                      <input
                        value={noticeId}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> CLIENT </label>
                    </p>
                    <div>
                      <input
                        value={clientName}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                    {/* <div>
                      <Select styles={customStyless} />
                    </div> */}
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">CLIENT PROPERTY </label>
                    </p>
                    <div>
                      <input
                        value={propertyDisplay}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> CLIENT UNIT </label>
                    </p>

                    <div>
                      <input
                        value={unitDisplay}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_"> EXIT DATE </label>
                    </p>
                    <div>
                      <input
                        disabled={editBlockState}
                        value={noticeDate}
                        onChange={(e) => {
                          setNticeDate(e.target.value);
                        }}
                        className="input_1"
                        type="date"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE REASON </label>
                    </p>
                    <div>
                      <textarea
                        disabled={editBlockState}
                        value={noticeReason}
                        name=""
                        onChange={(e) => {
                          setNoticeReasoon(e.target.value);
                        }}
                        class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[70px] max-h-[70px]"
                      ></textarea>
                    </div>
                  </div>
                </aside>

                {/* <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE PROCESSED BY </label>
                    </p>
                    <div>
                      <input
                        disabled
                        value={userState.emailId.toString().split("@")[0]}
                        className="input_1"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">
                        NOTICE PROCESSED DATE{" "}
                      </label>
                    </p>
                    <div>
                      <input
                        value={date}
                        disabled
                        className="input_1"
                        type="date"
                      />
                    </div>
                  </div>
                </aside> */}
                {/* <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE APPROVED </label>
                    </p>
                    <div>
                      <input className="input_1" />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> NOTICE APPROVED BY </label>
                    </p>
                    <div>
                      <input className="input_1" />
                    </div>
                  </div>
                </aside> */}
                {/* <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE APPROVED DATE</label>
                    </p>
                    <div>
                      <input className="input_1" type="date" />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">
                        NOTICE APPROVED time{" "}
                      </label>
                    </p>
                    <div>
                      <input className="input_1" type="time" />
                    </div>
                  </div>
                </aside> */}

                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <div className="flex justify-between my-3">
                  <div className="w-[200px]">&nbsp;</div>
                  <h1 className=" text-red-700 font-bold ">{statusmessage}</h1>

                  <div>
                    {" "}
                    <button
                      onClick={() => {
                        window.location.reload();
                      }}
                      className="button"
                    >
                      Clear
                    </button>
                    <button
                      disabled={editBlockState}
                      onClick={handleSave}
                      className="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantNotices;
