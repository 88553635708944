import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

const ClientStatementDetails = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState);
  console.log(menu);
  const [search, setSearch] = useState("");
  const [RentSaved, setRentSaved] = useState([]);
  const [isloading, setisloading] = useState(true);
  // const [filteredData, setFilteredData] = useState([]);
  const [totalCR, setTotalCR] = useState(0);
  const [totalDR, setTotalDR] = useState(0);
  const [totalcummualtive, settotalcumaltive] = useState(0);
  const [clientList, setClientList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [ageingDetails, setAgeingDetails] = useState({});
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [OpeningBalance, setOpeningBalance] = useState("");
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const [selectedClient, setSelectedClient] = useState("");
  useEffect(() => {
    // Set default fromDate to the start of the current month
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1); // Set to the first day of the month
    const formattedFirstDayOfMonth = firstDayOfMonth.toISOString().slice(0, 10);
    setFromDate(formattedFirstDayOfMonth);

    // Set default toDate to the current date
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
    setToDate(formattedCurrentDate);

    masterData();
  }, []); // Initialize with null

  useEffect(() => {
    masterData();
  }, []);

  useEffect(() => {
    console.log(selectedClient?.value);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/getClientstatementDetails`,
        { CLIENTCODE: selectedClient?.value },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        setRentSaved(response.data);
        console.log(response.data);
        // setFilteredData(response.data); // Initialize filteredData with all data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setisloading(false);
      });
    const startdate =
      fromDateFilter.slice(8, 10) +
      "/" +
      fromDateFilter.slice(5, 7) +
      "/" +
      fromDateFilter.slice(0, 4);
    const date = new Date(fromDateFilter);

    // Subtract one day
    date.setDate(date.getDate() - 1);

    // Format the new date as DD/MM/YYYY
    const newDay = `0${date.getDate()}`.slice(-2);
    const newMonth = `0${date.getMonth() + 1}`.slice(-2); // Months are 0-based
    const newYear = date.getFullYear();

    const formattedDate = `${newDay}/${newMonth}/${newYear}`;

    console.log(formattedDate);
    const enddate =
      toDateFilter.slice(8, 10) +
      "/" +
      toDateFilter.slice(5, 7) +
      "/" +
      toDateFilter.slice(0, 4);
    axios
      .post(
        `${BASEURL}/getOpeningBalance`,
        {
          CLIENTCODE: selectedClient?.value,
          STARTDATE: formattedDate,
          ENDDATE: enddate,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        setOpeningBalance(response.data[0]);
        console.log(response.data);
        // setFilteredData(response.data); // Initialize filteredData with all data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setisloading(false);
      });
  }, [selectedClient]);

  const masterData = () => {
    try {
      axios
        .get(`${BASEURL}/Fetchingtheclientcode`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        })
        .then((response) => {
          setClientList(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const ageing = () => {
    console.log(userState.userCode);
    console.log(fromDateFilter);
    console.log(toDateFilter);

    const startdate =
      fromDateFilter.slice(8, 10) +
      "/" +
      fromDateFilter.slice(5, 7) +
      "/" +
      fromDateFilter.slice(0, 4);
    const enddate =
      toDateFilter.slice(8, 10) +
      "/" +
      toDateFilter.slice(5, 7) +
      "/" +
      toDateFilter.slice(0, 4);
    try {
      axios
        .post(
          `${BASEURL}/getagingdetails`,
          {
            CLIENTCODE: userState.userCode,
            STARTDATE: startdate,
            ENDDATE: enddate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((ageingdetailsResponse) => {
          setTotalOutstanding(
            ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
              ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
              ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
              ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
          );
          setAgeingDetails(ageingdetailsResponse.data[0]);
          console.log(ageingdetailsResponse.data[0]);
        })
        .catch((error) => {
          console.error("Error fetching ageing details:", error);
        });
    } catch (err) {
      alert(err?.response?.data?.message);
      console.error(err);
    }
  };

  useEffect(() => {
    ageing();
  }, [fromDateFilter, toDateFilter]);
  //   const lowerSearchText = searchText.toLowerCase().trim();

  //   const filtered = RentSaved.filter((item) => {
  //     const matchesClient =
  //       !selectedClient || item.CLIENTCODE === selectedClient.value;
  //     const matchesSearch =
  //       Object.values(item).some(
  //         (value) =>
  //           typeof value === "string" &&
  //           value.toLowerCase().includes(lowerSearchText)
  //       ) || item.INVOICENUMBER?.toString().includes(searchText); // Check INVOICENUMBER

  //     // Parse the INVOICEDATE string to compare with fromDate and toDate
  //     const invoiceDate = new Date(item.INVOICEDATE);

  //     // Parse fromDate and toDate strings to Date objects for comparison
  //     const startDate = fromDate ? new Date(fromDate) : null;
  //     const endDate = toDate ? new Date(toDate) : null;

  //     // Compare dates based on the filter range
  //     const isInDateRange =
  //       (!startDate || invoiceDate >= startDate) &&
  //       (!endDate || invoiceDate <= endDate);

  //     return matchesClient && matchesSearch && isInDateRange;
  //   });

  //   setFilteredData(filtered);

  // };

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    console.log(selectedOption);
    // filterData(search, selectedOption);
  };

  // const handleSearchChange = (e) => {
  //   const searchText = e.target.value;
  //   setSearch(searchText);
  //   // filterData(searchText, selectedClient);
  // };
  // const handleFromDateChange = (e) => {
  //   setFromDate(e.target.value);
  //   // filterData(search, selectedClient);
  // };

  // const handleToDateChange = (e) => {
  //   setToDate(e.target.value);
  //   // filterData(search, selectedClient);
  // };
  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = RentSaved?.filter((item) => {
    //  const statusMatch =
    //   !selectedClient ||  selectedClient.label === item.CLIENTNAME;
    // console.log(selectedStatus?.value, "selectedStatus");
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        // ["BILLNO"],
        ["SALETYPE"],
        ["CLIENTCODE"],
        ["CLIENTNAME"],
        ["PROJECT"],
        ["UNIT"],
        ["SERVICE"],
        // ["DAYSCOUNTED"],
        // ["STANDARDRENT"],
        // ["RENTCHARGES"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const Date = item.INVOICEDATE;
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    const dateMatch =
      (!fromDateFilter || Date >= fromDateFilter) &&
      (!toDateFilter || Date <= toDateFilter);

    return textMatch && dateMatch;
  });

  console.log(filteredData);

  useEffect(() => {
    // Calculate totals whenever filteredData changes
    let totalCR = 0;
    let totalDR = 0;
    let totalcummualtive = 0;

    filteredData.forEach((item) => {
      totalDR += parseFloat(item?.DR);
      totalCR += parseFloat(item?.CR);
      totalcummualtive += parseFloat(item?.DR) - parseFloat(item?.CR);
    });

    setTotalCR(totalCR);
    setTotalDR(totalDR);
    settotalcumaltive(totalcummualtive);
    console.log(totalCR);
    console.log(totalDR);
  }, [selectedClient, filteredData]);
  if (isloading) {
    return <Loading />;
  }

  function printfunction() {
    console.log("Print button clicked");
    window.print();
    console.log("Print button clicked");
  }
  return (
    <div>
      <section className=" print:hidden">
        <MenuNavbar />

        <div>
          <header className="header__of__main lg:flex justify-between px-3 items-center">
            <h1 className="header__of__page">
              {menu ? menu : "CLIENT STATEMENT VIEW"}
            </h1>

            <div>
              <button className="button-50" onClick={() => printfunction()}>
                Print
              </button>
            </div>
          </header>
          <div className="lg:flex items-center mx-[20px] lg:mx-[30px] mt-[20px] gap-3">
            <h2 className="w-[90px]">Select Client</h2>
            <div className="w-[500px] z-[999]">
              {" "}
              <Select
                onChange={handleClientChange}
                value={selectedClient}
                options={clientList.map((item) => ({
                  value: item?.CLIENTCODE,
                  label: item?.CLIENTNAME,
                }))}
              />
            </div>
          </div>
          <article className="mt-2">
            <div className="lg:flex items-center justify-between mx-[20px]">
              <div className="flex">
                <p className="lg:mx-2 text-[16px] w-[100px]">Search</p>
                <input
                  className="w-[300px] lg:mr-[50px] h-[30px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
                <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateFilterChange}
                  />
                </div>
                <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
                  <input
                    type="date"
                    value={toDateFilter}
                    onChange={handleToDateFilterChange}
                  />
                </div>
              </section>
            </div>

            <div className="service_detials">
              {filteredData.length === 0 ? (
                <p>
                  {selectedClient == ""
                    ? "Please Select Client Name"
                    : "No data available"}{" "}
                </p>
              ) : (
                <div className="item-center">
                  <div className="mt-4 flex gap-8 mb-2 ml-2">
                    <p className="flex text-center">
                      <span>TRANSACTION TYPE : </span>
                      <span className="text-red-500">
                        {OpeningBalance?.TRANSACTIONTYPE}
                      </span>
                    </p>
                    {/* <p className="flex text-center">
                      <span>DOC NO : </span>
                      <span className="text-red-500">
                        {OpeningBalance?.DOCNO}
                      </span>
                    </p> */}
                    <p className="flex text-center">
                      <span>REF NO : </span>
                      <span className="text-red-500">
                        {OpeningBalance?.REFNO}
                      </span>
                    </p>
                    <p className="flex text-center">
                      <span>DOC DATE : </span>
                      <span className="text-red-500">
                        {OpeningBalance?.DOCDATE
                          ? OpeningBalance?.DOCDATE.slice(8, 10) +
                            "/" +
                            OpeningBalance?.DOCDATE.slice(5, 7) +
                            "/" +
                            OpeningBalance?.DOCDATE.slice(0, 4)
                          : ""}
                      </span>
                    </p>
                    <p className="flex text-center">
                      <span>AMOUNT : </span>
                      <span className="text-red-500">
                        {OpeningBalance?.AMOUNT}
                      </span>
                    </p>
                    <p className="flex text-center">
                      <span>CURRENCY : </span>
                      <span className="text-red-500">
                        {OpeningBalance?.CURRENCY}
                      </span>
                    </p>
                  </div>
                  <table className="table_1">
                    <thead>
                      <tr>
                        <td>SALE TYPE</td>
                        <td>CLIENT CODE</td>
                        <td>CLIENT NAME</td>
                        <td>PROJECT NAME</td>
                        <td>UNIT</td>
                        <td>INVOICE DATE</td>
                        <td className="text-right">INVOICE NUMBER</td>
                        <td>REFERENCE NUMBER</td>
                        <td>SERVICE</td>
                        <td className="text-right">DR</td>
                        <td className="text-right">CR</td>
                        <td className="text-right">CUMMUALTIVE</td>
                        <td>CURRENCY</td>
                        <td>DEPOSIT</td>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.length > 0 ? (
                        filteredData.map((item, index) => (
                          <tr key={index} className="table-row">
                            <td>{item?.SALETYPE || "N/A"}</td>
                            <td>{item?.CLIENTCODE || "N/A"}</td>
                            <td className="w-100px">
                              {item?.CLIENTNAME || "N/A"}
                            </td>
                            <td className="w-100px">
                              {item?.PROJECT || "N/A"}
                            </td>
                            <td className="text-right">
                              {item?.UNIT || "N/A"}
                            </td>
                            <td className="text-right">
                              {item?.INVOICEDATE?.slice(8, 10) +
                                "/" +
                                item?.INVOICEDATE?.slice(5, 7) +
                                "/" +
                                item?.INVOICEDATE?.slice(0, 4) || "N/A"}
                            </td>
                            <td className="text-right">
                              {item?.INVOICENUMBER || "N/A"}
                            </td>
                            <td>{item?.REFNUMBER || "N/A"}</td>
                            <td>{item?.SERVICE || "N/A"}</td>
                            <td className="text-right">
                              {item?.DR?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "N/A"}
                            </td>
                            <td className="text-right">
                              {item?.CR?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0"}
                            </td>
                            <td className="text-right">
                              {item?.CUMMUALTIVE?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0"}
                            </td>
                            <td className="text-right">
                              {item?.CURRENCY?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0"}
                            </td>
                            <td className="text-right">
                              {item?.DEPOSIT?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          {selectedClient == "" ? (
                            <td colSpan="12">Please select Client Name</td>
                          ) : (
                            <td colSpan="12">No data available</td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </article>
        </div>
        <div className="relative  flex bottom-0 justify-end right-[14px] w-full  z-10 pt-2 pb-2">
          <div className="mt-4 flex  justify-end gap-8 pr-4 items-right">
            <p className="flex text-center">
              <span>Total DR : </span>
              <span className="text-red-400">
                {totalDR
                  ? totalDR.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </p>
            <p className="flex text-center">
              <span>Total CR : </span>
              <span className="text-red-500">
                {totalCR
                  ? totalCR.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </p>
            <p className="flex text-center">
              <span>Total cumualtive : </span>
              <span className="text-red-500">
                {totalcummualtive
                  ? totalcummualtive.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </p>
          </div>
        </div>
        <aside className="flex justify-end ">
          <p>
            <p className="  ">0-30 Days</p>
            <input
              disabled
              value={
                ageingDetails?.ZEROTHIRTYDAYS
                  ? ageingDetails.ZEROTHIRTYDAYS.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : 0
              }
              className="border-[1px] border-black m-1 w-[120px] mr-8"
            ></input>
          </p>
          <p className="block w-[160px]">
            <p className="  ">0-30 Days</p>
            <input
              disabled
              value={
                ageingDetails?.ZEROTHIRTYDAYS
                  ? ageingDetails.ZEROTHIRTYDAYS.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : 0
              }
              className="border-[1px] border-black m-1 w-[120px] mr-8"
            ></input>
          </p>
          <p className="block ">
            <p className="  ">30-60 Days</p>
            <input
              disabled
              value={
                ageingDetails?.THIRTYSIXTYDAYS
                  ? ageingDetails.THIRTYSIXTYDAYS.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : 0
              }
              className="border-[1px] border-black m-1 w-[120px] mr-8"
            ></input>
          </p>
          <p className="block ">
            <p className="  ">60-90 Days</p>
            <input
              disabled
              value={
                ageingDetails?.SIXTYNINETYDAYS
                  ? ageingDetails.SIXTYNINETYDAYS.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : 0
              }
              className="border-[1px] border-black m-1 w-[120px] mr-8"
            ></input>
          </p>
          <p className="block ">
            <p className="  "> Above 90 Days</p>
            <input
              disabled
              value={
                ageingDetails?.ABOVENINETYDAYS
                  ? ageingDetails.ABOVENINETYDAYS.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : 0
              }
              className="border-[1px] border-black m-1 w-[120px] mr-8"
            ></input>
          </p>
        </aside>
      </section>
      <section className="hidden print:block">
        <div>
          <header className=" lg:flex justify-between px-3 items-center">
            <h1 className="">{menu ? menu : "CLIENT STATEMENT VIEW"}</h1>
          </header>
          <p className="lg:flex flex items-center gap-[6px]">
            <div className="lg:flex items-center mx-[10px] lg:mx-[30px]  gap-3">
              <h2 className="">Select Client : {selectedClient?.label}</h2>
            </div>

            <div className=" flex items-center mx-[20px] ">
              <p className=" lg:w-auto py-1">From&nbsp;</p>
              <input
                type="date"
                value={fromDateFilter}
                onChange={handleFromDateFilterChange}
              />
            </div>
            <div className=" flex items-center ml-[5px]">
              <p className=" lg:w-auto py-1">To&nbsp;</p>
              <input
                type="date"
                value={toDateFilter}
                onChange={handleToDateFilterChange}
              />
            </div>
          </p>

          <article className="mt-2">
            <div className="service_detials">
              {filteredData.length === 0 ? (
                <p>
                  {selectedClient == ""
                    ? "Please Select Client Name"
                    : "No data available"}{" "}
                </p>
              ) : (
                <table className="table_1">
                  <thead>
                    <tr>
                      <td>SALE TYPE</td>
                      <td>CLIENT CODE</td>
                      <td>CLIENT NAME</td>
                      <td>PROJECT NAME</td>
                      <td>UNIT</td>
                      <td>INVOICE DATE</td>
                      <td className="text-right">INVOICE NUMBER</td>
                      <td>REFERENCE NUMBER</td>
                      <td>SERVICE</td>
                      {/* <td className="text-right">DR</td> */}
                      {/* <td className="text-right">CR</td>
                      <td className="text-right">CUMMUALTIVE</td>
                      <td>CURRENCY</td>
                      <td>DEPOSIT</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={index} className="table-row">
                          <td>{item?.SALETYPE || "N/A"}</td>
                          <td>{item?.CLIENTCODE || "N/A"}</td>
                          <td className="w-100px">
                            {item?.CLIENTNAME || "N/A"}
                          </td>
                          <td className="w-100px">{item?.PROJECT || "N/A"}</td>
                          <td className="text-right">{item?.UNIT || "N/A"}</td>
                          <td className="text-right">
                            {item?.INVOICEDATE?.slice(8, 10) +
                              "/" +
                              item?.INVOICEDATE?.slice(5, 7) +
                              "/" +
                              item?.INVOICEDATE?.slice(0, 4) || "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.INVOICENUMBER || "N/A"}
                          </td>
                          <td>{item?.REFNUMBER || "N/A"}</td>
                          <td>{item?.SERVICE || "N/A"}</td>
                          {/* <td className="text-right">
                            {item?.DR?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.CR?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                          <td className="text-right">
                            {item?.CUMMUALTIVE?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                          <td className="text-right">
                            {item?.CURRENCY?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                          <td className="text-right">
                            {item?.DEPOSIT?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {selectedClient == "" ? (
                          <td colSpan="12">Please select Client Name</td>
                        ) : (
                          <td colSpan="12">No data available</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </article>

          <article className="mt-2">
            <div className="service_detials">
              {filteredData.length === 0 ? (
                <p>
                  {selectedClient == ""
                    ? "Please Select Client Name"
                    : "No data available"}{" "}
                </p>
              ) : (
                <table className="table_1">
                  <thead>
                    <tr>
                      {/* <td>SALE TYPE</td>
                      <td>CLIENT CODE</td>
                      <td>CLIENT NAME</td>
                      <td>PROJECT NAME</td>
                      <td>UNIT</td>
                      <td>INVOICE DATE</td>
                      <td className="text-right">INVOICE NUMBER</td>
                      <td>REFERENCE NUMBER</td>
                      <td>SERVICE</td>
                      <td className="text-right">DR</td> */}
                      <td className="text-right">DR</td>
                      <td className="text-right">CR</td>
                      <td className="text-right">CUMMUALTIVE</td>
                      <td>CURRENCY</td>
                      <td>DEPOSIT</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={index} className="table-row">
                          {/* <td>{item?.SALETYPE || "N/A"}</td>
                          <td>{item?.CLIENTCODE || "N/A"}</td>
                          <td className="w-100px">
                            {item?.CLIENTNAME || "N/A"}
                          </td>
                          <td className="w-100px">{item?.PROJECT || "N/A"}</td>
                          <td className="text-right">{item?.UNIT || "N/A"}</td>
                          <td className="text-right">
                            {item?.INVOICEDATE?.slice(8, 10) +
                              "/" +
                              item?.INVOICEDATE?.slice(5, 7) +
                              "/" +
                              item?.INVOICEDATE?.slice(0, 4) || "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.INVOICENUMBER || "N/A"}
                          </td>
                          <td>{item?.REFNUMBER || "N/A"}</td>
                          <td>{item?.SERVICE || "N/A"}</td>
                          <td className="text-right">
                            {item?.DR?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}
                          </td> */}
                          <td className="text-right">
                            {item?.DR?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "N/A"}
                          </td>
                          <td className="text-right">
                            {item?.CR?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                          <td className="text-right">
                            {item?.CUMMUALTIVE?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                          <td className="text-left">
                            {item?.CURRENCY?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                          <td className="text-right">
                            {item?.DEPOSIT?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) || "0"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        {selectedClient == "" ? (
                          <td colSpan="12">Please select Client Name</td>
                        ) : (
                          <td colSpan="12">No data available</td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </article>
        </div>
        <div className="relative flex bottom-0 justify-end right-[14px] w-full  z-10 pt-2 pb-2">
          <div className="mt-4 flex  justify-end gap-8 pr-4 items-right">
            <p className="flex text-center">
              <span>Total DR : </span>
              <span className="text-red-400">
                {totalDR
                  ? totalDR.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </p>
            <p className="flex text-center">
              <span>Total CR : </span>
              <span className="text-red-500">
                {totalCR
                  ? totalCR.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </p>
            <p className="flex text-center">
              <span>total cummualtive : </span>
              <span className="text-red-500">
                {totalcummualtive
                  ? totalcummualtive.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ClientStatementDetails;
