import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import "../PropertyManagementCss/ProjectMaster.css";
import { BiSolidEditAlt } from "react-icons/bi";
import { Country, City } from "country-state-city";
import Select from "react-select";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
function ProjectMaster() {
  const [Projectid, setProjectId] = useState("");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const authToken = window.sessionStorage.getItem("auth-token");
  const [projectName, setProjectName] = useState("");
  const [projectdescription, setProjectDescription] = useState("");
  const [Projecttype, setProjectType] = useState([]);
  const [selprojecttype, setselprojecttype] = useState("");
  const [Projectdeveloper, setProjectDeveloper] = useState("");
  const [Projectcontractor, setProjectContractor] = useState("");
  const [Projectlocation, setProjectLocation] = useState("");
  const [projectunits, setProjectUnits] = useState(null);
  const [createdby, setCreatedBy] = useState("");
  const [projectmastertabledetails, setprojectmastertabledetails] = useState(
    []
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const countries = Country.getAllCountries();
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const filteredData = projectmastertabledetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTID",
        "PROJECTNAME",
        "PROJECTDESCRIPTION",
        "PROJECTTYPE",
        "PROJECTDEVELOPER",
        "PROJECTCONTRACTOR",
        "PROJECTLOCATION",
        "PROJECTUNITS",
        "COUNTRY",
        "CITY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    setCreatedBy(username?.split("@")[0].toUpperCase());
    getnewProjectid();
    getPropertyType();
    getProjectMasterTableDetails();
  }, []);

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "280px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      zIndex: "50",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const FetchTableValuesInFields = (item) => {
    setProjectId(item.PROJECTID);
    setProjectName(item.PROJECTNAME);
    setProjectDescription(item.PROJECTDESCRIPTION);
    setselprojecttype(item.PROJECTTYPE);
    setProjectDeveloper(item.PROJECTDEVELOPER);
    setProjectContractor(item.PROJECTCONTRACTOR);
    setProjectLocation(item.PROJECTLOCATION);
    setSelectedCountry(item.COUNTRY);
    setSelectedCity(item.CITY);
    setProjectUnits(item.PROJECTUNITS);
  };
  const clearallinputs = () => {
    setProjectId("");
    setProjectName("");
    setProjectDescription("");
    setselprojecttype("");
    setProjectDeveloper("");
    setProjectContractor("");
    setProjectLocation("");
    setSelectedCountry("");
    setSelectedCity("");
    setProjectUnits("");
  };
  const getnewProjectid = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/NewProjectIdfortheproperty`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        if (
          response.data &&
          response.data.length > 0 &&
          response.data[0].NEXTNO
        ) {
          setProjectId(response.data[0].NEXTNO);
          setisloading(false);
        } else {
          console.error("Error: Invalid data format received from the server");
          setisloading(false);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
          setisloading(false);
        } else if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(
            "Server responded with an error:",
            error.response.status,
            error.response.data
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error(
            "No response received from the server. Check your network connection."
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error in making the request:", error.message);
          setisloading(false);
        }
      });
  };

  const getPropertyType = () => {
    axios
      .get(`${BASEURL}/PropertyTypeMasterDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setProjectType(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Property Type:", error);
      });
  };

  const getProjectMasterTableDetails = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/ListOfProperties`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setprojectmastertabledetails(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching fuel station data:", error);
        setisloading(false);
      });
  };

  async function Savedetails1() {
    try {
      const response = await axios.post(
        `${BASEURL}/SaveProjectDetails`,
        {
          PROJECTID: Projectid,
          PROJECTNAME: projectName,
          PROJECTDESCRIPTION: projectdescription,
          PROJECTTYPE: selprojecttype,
          PROJECTDEVELOPER: Projectdeveloper,
          PROJECTCONTRACTOR: Projectcontractor,
          PROJECTLOCATION: Projectlocation,
          PROJECTUNITS: projectunits,
          PROJECTCOUNTRY: selectedCountry,
          PROJECTCITY: selectedCity,
          PROJECTCREATEDBY: createdby,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.label);

    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;

      const countryCities = City.getCitiesOfCountry(selectedCountryIso);
      setCities(
        countryCities.map((city) => ({ value: city.name, label: city.name }))
      );
      setSelectedCity("");
    } else {
      setCities([]);
      setSelectedCity("");
    }
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption.label);
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {" "}
                  {menu ? menu : "Project Master"}
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project ID</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={Projectid ? Projectid : "N/A"}
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Project Name </label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={projectName}
                        onChange={(e) => {
                          setProjectName(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project Description </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={projectdescription}
                        onChange={(e) => {
                          setProjectDescription(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Project Type </label>
                    </p>
                    <div style={{ zIndex: 200 }}>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (e.value === "") {
                            alert("Kindly Supply proper project type");
                            setselprojecttype("");
                          } else {
                            setselprojecttype(e.value);
                          }
                        }}
                        value={
                          selprojecttype
                            ? { label: selprojecttype, value: selprojecttype }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...Projecttype.map((item) => ({
                            value: item.PROJECTTYPE,
                            label: item.PROJECTTYPE,
                          })),
                        ]}
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project Developer </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={Projectdeveloper}
                        onChange={(e) => {
                          setProjectDeveloper(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Project Contractor </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={Projectcontractor}
                        onChange={(e) => {
                          setProjectContractor(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project Location </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={Projectlocation}
                        onChange={(e) => {
                          setProjectLocation(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Project Units </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={projectunits}
                        onChange={(e) => {
                          setProjectUnits(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">Country </label>
                    </p>
                    <div style={{ zIndex: 100 }}>
                      <Select
                        name="COUNTRY"
                        required
                        styles={customStyless}
                        value={
                          selectedCountry
                            ? { label: selectedCountry, value: selectedCountry }
                            : { label: "", value: "" }
                        }
                        onChange={handleCountryChange}
                        options={countries.map((country) => ({
                          value: country.isoCode,
                          label: country.name,
                        }))}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">City </label>
                    </p>
                    <div style={{ zIndex: 50 }}>
                      <Select
                        name="CITY"
                        required
                        styles={customStyless}
                        value={
                          selectedCity
                            ? { label: selectedCity, value: selectedCity }
                            : { label: "", value: "" }
                        }
                        onChange={handleCityChange}
                        options={cities}
                        isDisabled={cities.length === 0}
                      />
                    </div>
                  </div>
                </aside>

                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                      getnewProjectid();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!Projectid) {
                        alert("Kindly supply Project id");
                      } else if (!projectName) {
                        alert("Kindly supply Project Name");
                      } else if (!projectdescription) {
                        alert("Kindly supply Project Description");
                      } else if (!selprojecttype) {
                        alert("Kindly supply Project Type");
                      } else if (!Projectdeveloper) {
                        alert("Kindly supply Project Developer");
                      } else if (!Projectcontractor) {
                        alert("Kindly supply Project Contractor");
                      } else if (!Projectlocation) {
                        alert("Kindly supply Project Location");
                      } else if (!projectunits) {
                        alert("Kindly supply Project Units");
                      } else if (!selectedCountry) {
                        alert("Kindly supply Project Country");
                      } else if (!selectedCity) {
                        alert("Kindly supply Project City");
                      } else {
                        await Savedetails1();
                        clearallinputs();
                        getnewProjectid();
                        getProjectMasterTableDetails();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>

      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>Project Id</td>
              <td>Project Name</td>
              <td>Project Description</td>
              <td>Project Type</td>
              <td>Project Developer</td>
              <td>Project Contractor</td>
              <td>Location</td>
              <td>Project Units</td>
              <td>Country</td>
              <td>City</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr style={{ textTransform: "uppercase", fontSize: "12px" }}>
                  <td style={{ paddingLeft: "54px" }}>
                    {item.PROJECTID ? item.PROJECTID : "NA"}
                  </td>
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td>
                    {item.PROJECTDESCRIPTION ? item.PROJECTDESCRIPTION : "NA"}
                  </td>
                  <td>{item.PROJECTTYPE ? item.PROJECTTYPE : "NA"}</td>
                  <td>
                    {item.PROJECTDEVELOPER ? item.PROJECTDEVELOPER : "NA"}
                  </td>
                  <td>
                    {item.PROJECTCONTRACTOR ? item.PROJECTCONTRACTOR : "NA"}
                  </td>
                  <td>{item.PROJECTLOCATION ? item.PROJECTLOCATION : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.PROJECTUNITS
                      ? item.PROJECTUNITS.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </td>
                  <td>{item.COUNTRY ? item.COUNTRY : "NA"}</td>
                  <td>{item.CITY ? item.CITY : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProjectMaster;
