import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Select from "react-select";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { useLocation } from "react-router-dom";

const LeaseExpiry = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [search, setSearch] = useState("");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [startdatesort, setStartdatesort] = useState(
    moment().startOf("year").add(0, "months").date(2).toISOString()
  );

  const [enddatesort, setEnddatesort] = useState(moment().toISOString());
  const [date, setDate] = useState(new Date().toISOString());

  const [monthlyrentaltable, setmonthlyrentaltable] = useState([]);
  const [transstatus, settransstatus] = useState("");
  const [daysExpiredFilter, setDaysExpiredFilter] = useState("Expired");
  const [isloading, setisloading] = useState(true);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "100px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  useEffect(() => {
    ListOfPropertyunitleases();
  }, []);

  const filteredData = monthlyrentaltable.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.LEASESTARTDATE;
    const eDate = item.LEASEENDDATE;
    const status = transstatus;
    const daysExpired = moment(item.LEASEENDDATE).diff(moment(date), "days");

    if (
      searchData.length === 0 &&
      !startdatesort &&
      !enddatesort &&
      !status &&
      !daysExpiredFilter
    ) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "UNITNUMBER",
        "LEASENUMBER",
        "CLIENTNAME",
        "RENTAMOUNT",
        "SERVICEAMOUNT",
        "TRANSPORTCHARGE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort || sDate >= startdatesort) &&
      (!enddatesort || eDate <= enddatesort);

    const TransportstatusMatch =
      !transstatus ||
      (transstatus === "YES"
        ? !!item.TRANSPORTSERVICES
        : !item.TRANSPORTSERVICES);

    // New filter option for "Days Expired"
    const DaysExpiredMatch =
      !daysExpiredFilter ||
      (daysExpiredFilter === "Expired" && daysExpired <= 0) ||
      daysExpiredFilter === "ALL" ||
      (daysExpiredFilter === "About to Expire" &&
        daysExpired > 0 &&
        daysExpired <= 90) ||
      (daysExpiredFilter === "Active" && daysExpired > 90);

    return textMatch && dateMatch && TransportstatusMatch && DaysExpiredMatch;
  });

  const ListOfPropertyunitleases = () => {
    axios
      .get(`${BASEURL}/ListOfPropertyunitleases`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setmonthlyrentaltable(response.data);
        console.log(response.data, "dattt");
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Property Unit Leases:", error);
        setisloading(false);
      });
  };

  const ExcelLeaseData = () => {
    const confirmed = window.confirm(
      "Do you want to generate the Excel Lease Expiry Report?"
    );

    if (!confirmed) {
      return; // User canceled, do nothing
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Lease Expiry Report");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [200, 100, 100, 150, 130, 100, 250, 170, 80, 20];

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "PROJECT NAME",
      "Unit Number",
      "Lease Number",
      "Client Code",
      "Start Date",
      "End Date",
      "Rent Amount",
      "Service Amount",
      "Transport Services",
      "Transport Charge",
      "Days Expired",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Lease_Expiry_Report.xlsx";
      link.click();
    });
  };

  const PdfLeaseData = () => {
    const confirmed = window.confirm(
      "Do you want to generate the Pdf Lease Expiry Report?"
    );
    if (!confirmed) {
      return; // User canceled, do nothing
    }
    const doc = new jsPDF();

    doc.text("Lease Expiry - Report", 10, 10);

    const columns = [
      "PROJECT NAME",
      "Unit Number",
      "Lease Number",
      "Client Code",
      "Start Date",
      "End Date",
      "Rent Amount",
      "Service Amount",
      "Transport Services",
      "Transport Charge",
      "Days Expired",
    ];
    const data = filteredData.map((lease) => [
      lease.PROJECTNAME ? lease.PROJECTNAME : "",
      lease.UNITNUMBER ? lease.UNITNUMBER : "",
      lease.LEASENUMBER ? lease.LEASENUMBER : "",
      lease.CLIENTNAME ? lease.CLIENTNAME : "NA",
      lease.LEASESTARTDATE
        ? moment(lease.LEASESTARTDATE).format("DD/MM/YYYY")
        : "NA",
      lease.LEASEENDDATE
        ? moment(lease.LEASEENDDATE).format("DD/MM/YYYY")
        : "NA",
      lease.RENTAMOUNT
        ? lease.RENTAMOUNT.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "NA",
      lease.SERVICEAMOUNT
        ? lease.SERVICEAMOUNT.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "NA",
      lease.TRANSPORTSERVICES == 1 ? "YES" : "NO",
      lease.TRANSPORTCHARGE
        ? lease.TRANSPORTCHARGE.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
      moment(lease.LEASEENDDATE).diff(moment(date), "days").toString(),
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("Lease_Expiry_Report.pdf");
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <header className="header__of__main flex justify-between items-center">
        <h1 className="header__of__page">{menu ? menu : "Lease Expiry"}</h1>
      </header>

      <main className="lease__bothfilte">
        <div className="searc__lease">
          <p>Search</p>
          <input
            className="input_1 w-[400px] ml-[15px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
          <p>Transport Services</p>
          <div className="w-[250px] lg:w-[150px]">
            {" "}
            <Select
              styles={customStyless}
              onChange={(e) => {
                if (e.value === "") {
                  alert("Kindly supply proper Services");
                  settransstatus("");
                } else {
                  settransstatus(e.value);
                }
              }}
              value={
                transstatus
                  ? { label: transstatus, value: transstatus }
                  : { label: "", value: "" }
              }
              options={[
                { value: "select", label: "select" },
                { value: "YES", label: "YES" },
                { value: "NO", label: "NO" },
              ]}
            />
          </div>
          <p className="mx-3">Days Expired</p>
          <div className="w-[250px] font-normal">
            <Select
              styles={customStyless}
              onChange={(e) => {
                setDaysExpiredFilter(e.value);
              }}
              value={
                daysExpiredFilter
                  ? { label: daysExpiredFilter, value: daysExpiredFilter }
                  : { label: "", value: "" }
              }
              options={[
                { value: "ALL", label: "ALL" },
                { value: "Expired", label: "Expired" },
                { value: "About to Expire", label: "About to Expire" },
                { value: "Active", label: "Active" },
              ]}
            />
          </div>
        </div>
      </main>
      <section className="lg:flex justify-between">
        <aside className="startand__enddate__lease ">
          <div>
            <p>
              <label className="">Start date</label>
            </p>
            <div>
              <input
                required
                className="input_1"
                type="date"
                value={startdatesort.split("T")[0]}
                onChange={(e) => setStartdatesort(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p>
              <label className="">End Date</label>
            </p>
            <div>
              <input
                className="input_1"
                type="date"
                required
                value={enddatesort.split("T")[0]}
                onChange={(e) => setEnddatesort(e.target.value)}
              />
            </div>
          </div>
        </aside>
        <div className="export-buttons flex gap-2 mx-10 my-1 cursor-pointer">
          <RiFileExcel2Line onClick={ExcelLeaseData} size={35} />
          <BsFileEarmarkPdf onClick={PdfLeaseData} size={35} />
        </div>
      </section>
      <div className="service_detials">
        <table className="table_1" style={{ textTransform: "uppercase" }}>
          <thead>
            <tr>
              <td>PROJECT NAME</td>
              <td style={{ textAlign: "right" }}>Unit Number</td>
              <td>LEASE NUMBER</td>
              <td>CLIENT CODE</td>
              <td>START DATE</td>
              <td>END DATE</td>
              <td style={{ textAlign: "right" }}>RENT AMOUNT</td>
              <td style={{ textAlign: "right" }}>SERVICE AMOUNT</td>
              <td>TRANSPORT SERVICES</td>
              <td style={{ textAlign: "right" }}>TRANSPORT CHARGE</td>
              <td>DAYS EXPIRED</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              // ... (your existing code)

              // Calculate the days expired
              const daysExpired = moment(item.LEASEENDDATE).diff(
                moment(date),
                "days"
              );

              // Determine the Tailwind CSS class based on the condition
              let daysExpiredClass = "";
              if (daysExpired <= 0) {
                daysExpiredClass = "bg-red-100"; // Change text color to red
              } else if (daysExpired > 0 && daysExpired <= 90) {
                daysExpiredClass = "bg-yellow-100"; // Change text color to yellow
              } else {
                daysExpiredClass = "bg-green-100"; // Change text color to green
              }

              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                  className={daysExpiredClass}
                >
                  <td style={{ width: "200px" }}>
                    {item.PROJECTNAME ? item.PROJECTNAME : ""}
                  </td>
                  <td
                    style={{
                      width: "100px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {item.UNITNUMBER ? item.UNITNUMBER : ""}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.LEASENUMBER ? item.LEASENUMBER : ""}
                  </td>
                  <td>{item.CLIENTNAME ? item.CLIENTNAME : "NA"}</td>

                  <td style={{ width: "130px" }}>
                    {/* {item.LEASESTARTDATE} --lease-- */}
                    {item.LEASESTARTDATE
                      ? item?.LEASESTARTDATE?.slice(8, 10) +
                        "/" +
                        item?.LEASESTARTDATE?.slice(5, 7) +
                        "/" +
                        item?.LEASESTARTDATE?.slice(0, 4)
                      : "NA"}
                  </td>
                  <td style={{ width: "100px" }}>
                    {item.LEASEENDDATE
                      ? item?.LEASEENDDATE?.slice(8, 10) +
                        "/" +
                        item?.LEASEENDDATE?.slice(5, 7) +
                        "/" +
                        item?.LEASEENDDATE?.slice(0, 4)
                      : "NA"}
                  </td>
                  <td
                    style={{
                      width: "250px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {item.RENTAMOUNT
                      ? item.RENTAMOUNT.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0"}
                  </td>
                  <td style={{ width: "170px", textAlign: "right" }}>
                    {item.SERVICEAMOUNT
                      ? item.SERVICEAMOUNT.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0"}
                  </td>
                  <td style={{ width: "80px" }}>
                    {item.TRANSPORTSERVICES == 1 ? "YES" : "NO"}
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    {item.TRANSPORTCHARGE
                      ? item.TRANSPORTCHARGE.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0"}
                  </td>
                  <td style={{ textAlign: "right", fontWeight: 600 }}>
                    {daysExpired}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaseExpiry;
