import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
// import "../src/Navbar.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { logIn } from "../../Redux/Action";
import Loading from "../../Components/Loading/Loading";
const NotFoundNavbar = () => {
  const navigate = useNavigate();
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isOpen, setIsOpen] = useState(false);
  const [ModuleAccess, setModuleAccess] = useState([]);
  const [openGroup, setOpenGroup] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  const state = useSelector((state) => state.changeTheState);
  console.log(state);
  console.log(state?.user?.userName);
  const email = state?.user?.emailId;
  const modulename = state?.user?.userName;
  const dispatch = useDispatch();
  function logOut() {
    dispatch(logIn([]));
    navigate("/");
  }

  function AuthorizseUserLogin() {
    setIsLoading(true);
    console.log(email);
    axios
      .post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: email,
          MODULENAME: modulename,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setModuleAccess(response.data);
        // console.log(response.data.recordsets[0], "setModuleAccess");
        setIsLoading(false);
      });
  }
  useEffect(() => {
    AuthorizseUserLogin();
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleGroupClick = (index) => {
    setOpenGroup(openGroup === index ? null : index);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleNavigation = (page) => {
    console.log(page);
    navigate(`/${page.link}`, { state: { menu: page.menu } });
  };
  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      <nav className="centernav">
        <div className="navbar-container">
          <nav className="navbar">
            <div className="">
              <div className="fabar" onClick={toggleMenu}>
                {isMenuOpen ? <FaTimes /> : <FaBars />}
                {/* <FaBars /> */}
              </div>
            </div>
            <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
              <aside>
                {ModuleAccess.reduce((acc, item, index) => {
                  // Check if MENU_TYPE already exists in the accumulator
                  const existingType = acc.find(
                    (group) => group.type === item.OM_ACCOUNT_MODULE_MENU_TYPE
                  );

                  if (existingType) {
                    // If MENU_TYPE exists, add the current ID to its list
                    if (typeof item === "object") {
                      existingType.ids.push({
                        name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                        link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                        menu: item.OM_ACCOUNT_MODULE_MENU,
                      });
                      existingType.weblink.push(
                        item.OM_ACCOUNT_MODULE_WEB_PAGE
                      );
                    } else {
                      existingType.ids.push(item);
                      existingType.weblink.push(existingType.weblink[index]);
                    }
                  } else {
                    // If MENU_TYPE doesn't exist, create a new group with the current ID
                    const newId =
                      typeof item === "object"
                        ? {
                            name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                            link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                            menu: item.OM_ACCOUNT_MODULE_MENU,
                          }
                        : item;

                    acc.push({
                      type: item.OM_ACCOUNT_MODULE_MENU_TYPE,
                      ids: [newId],
                      weblink: [item.OM_ACCOUNT_MODULE_WEB_PAGE],
                    });
                  }
                  //   console.log(existingType);
                  return acc;
                }, []).map((group, index) => (
                  <div key={index} style={{ fontSize: "14px" }}>
                    <p
                      style={{
                        cursor: "pointer",
                        paddingBottom: "0px",
                        marginBottom: "0px",
                      }}
                      onClick={() => handleGroupClick(index)}
                    >
                      {group.type}
                    </p>

                    {openGroup === index && (
                      <ul
                        style={{ listStyleType: "none", paddingLeft: "15px" }}
                      >
                        {group.ids.map((id, idIndex) => (
                          <li key={idIndex} style={{ cursor: "pointer" }}>
                            {typeof id === "object" && (
                              <span
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleNavigation(id)}
                              >
                                {id.name}
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </aside>{" "}
            </ul>
          </nav>
          <div className="w-[85%] flex ">
            {/* <h4 className="hover-underline-animation dashboard name__head__big__screen">
              TENANT PORTAL
            </h4> */}
            <ul className={`navbar_links ${isOpen ? "active" : ""}`}>
              <h2 className="hover-underline-animation name__head__big__screen ">
                Logged in as :{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {state.user ? state.user.emailId.split("@")[0] : "N/A"}
                </span>
              </h2>
            </ul>
          </div>
          <aside>
            <button onClick={logOut} className="button__logout__navbar">
              {" "}
              LOGOUT
            </button>
          </aside>

          <div className="logout__fullscren">
            <button onClick={logOut} className="log__out__icon">
              {" "}
              <MdLogout />
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NotFoundNavbar;
