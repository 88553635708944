import React, { useEffect, useState, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import { logIn } from "../../Redux/Action";
import "../PropertyManagementCss/Navbar.css";
import { MdLogout } from "react-icons/md";
const MenuNavbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ModuleAccess, setModuleAccess] = useState([]);
  const [openGroup, setOpenGroup] = useState(null);

  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isloading, setIsLoading] = useState(false);
  // const authToken = window.sessionStorage.getItem("auth-token");
  const navbarRef = useRef(null);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.changeTheState);
  // console.log(state);
  const modulename = state?.user?.userName;
  // console.log(state.user.emailId);
  const email = state?.user?.emailId;
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    AuthorizseUserLogin();
  }, []);
  useEffect(() => {
    scrollToSection();

    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        // Click occurred outside of navbar, close it
        setIsOpen(false);
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const logOut = () => {
    dispatch(logIn([]));
    navigate("/");
  };
  const Dashboard = () => {
    // dispatch(logIn([]));
    navigate("/PropertyManagerDashboard");
  };
  const handleCategory = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };
  function AuthorizseUserLogin() {
    setIsLoading(true);
    console.log(email);
    axios
      .post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: email,
          MODULENAME: modulename,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        setModuleAccess(response.data);
        // console.log(response.data.recordsets[0], "setModuleAccess");
        setIsLoading(false);
      });
  }
  useEffect(() => {
    AuthorizseUserLogin();
  }, []);

  const handleGroupClick = (index) => {
    setOpenGroup(openGroup === index ? null : index);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // setIsDeliveryNoteClicked(false);
  };
  const handleNavigation = (page) => {
    console.log(page);
    navigate(`/${page.link}`, { state: { menu: page.menu } });
  };

  return (
    <nav ref={navbarRef} className="centernav uppercase">
      <div className="navbar-container">
        <nav className="navbar">
          <div className="">
            <div className="fabar" onClick={toggleMenu}>
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>

          {/* <div className="hover-dropdown">
              <li onClick={() => handleCategory("Master")}>MASTER</li>{" "}
              {selectedCategory === "Master" && (
                <ul className="ul__list__style__scanner ml-[20px]">
                  <li onClick={() => navigate("/ProjectMaster")}>
                    PROJECT MASTER
                  </li>
                  <li onClick={() => navigate("/PropertyTypeMaster")}>
                    PROPERTY TYPE MASTER
                  </li>
                  <li onClick={() => navigate("/UnitMaster")}>UNIT MASTER</li>
                  <li onClick={() => navigate("/SubUnitMaster")}>
                    SUB UNIT MASTER
                  </li>
                  <li onClick={() => navigate("/InspectionMaster")}>
                    INSPECTION MASTER
                  </li>
                  <li onClick={() => navigate("/DocumentMaster")}>
                    DOCUMENT MASTER
                  </li>
                  <li onClick={() => navigate("/ClientMaster")}>
                    Client Master
                  </li>
                  <li onClick={() => navigate("/UnoccupiedUnit")}>
                    Unoccupied Unit
                  </li>
                  
                </ul>
              )}
              <li onClick={() => handleCategory("transation")}>TRANSACTION </li>{" "}
              {selectedCategory === "transation" && (
                <ul className="ul__list__style__scanner ml-[20px]">
                  <li onClick={() => navigate("/PropertyUnitLeases")}>
                    PROPERTY UNIT LEASES
                  </li>
                  <li onClick={() => navigate("/Meterreading")}>
                    TOTAL CONSUMPTION
                  </li>
                  <li onClick={() => navigate("/ElectricityhistoricalDetails")}>
                    ELECTRICITY HISTORICAL DETAILS
                  </li>
                  <li onClick={() => navigate("/electricitychargecalculation")}>
                    electricity charge calculation
                  </li>
                  <li onClick={() => navigate("/InspectionDetails")}>
                    INSPECTION DETAILS
                  </li>
                  <li
                    onClick={() =>
                      navigate("/InspectionDetailsHistoricDocuments")
                    }
                  >
                    PENDING EXIT INSPECTION DETAILS
                  </li>
                  <li onClick={() => navigate("/TicketCreation")}>
                    TICKET CREATION
                  </li>
                  <li onClick={() => navigate("/TenantNoticeProperty")}>
                    TENANT NOTICE
                  </li>
                  <li onClick={() => navigate("/LeaseExpiry")}>LEASE EXPIRY</li>
                  <li onClick={() => navigate("/TenantUnitTransfer")}>
                    TENANT SUB UNIT TRANSFER
                  </li>
                  <li onClick={() => navigate("/RentAmountCalculation")}>
                    Rent Amount Calculation
                  </li>
                  <li onClick={() => navigate("/RentSaved")}>processed rent</li>
                  <li onClick={() => navigate("/TransportBill")}>
                    Transport Bill Calculation
                  </li>
                  <li onClick={() => navigate("/TransportExpencesHistoric")}>
                    processed Transport Expences
                  </li>
                  <li onClick={() => navigate("/InternetCalculation")}>
                    Internet Calculation
                  </li>
                  <li onClick={() => navigate("/ProceededInternet")}>
                    Proceeded Internet Charges
                  </li>
                  <li onClick={() => navigate("/AdminClientDashboard")}>
                    Client Receipt Allocation
                  </li>
                  <li onClick={() => navigate("/ClientStatementDetails")}>
                    Client Statement Details
                  </li>{" "}
                  <li onClick={() => navigate("/ExpiredLeases")}>
                    Expired Leases
                  </li>
                </ul>
              )}
            </div> */}
          <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            {isloading ? (
              <p className="text-[14px] ml-2 my-2">Loading...</p>
            ) : (
              <aside>
                {ModuleAccess.reduce((acc, item, index) => {
                  // Check if MENU_TYPE already exists in the accumulator
                  const existingType = acc.find(
                    (group) => group.type === item.OM_ACCOUNT_MODULE_MENU_TYPE
                  );

                  if (existingType) {
                    // If MENU_TYPE exists, add the current ID to its list
                    if (typeof item === "object") {
                      existingType.ids.push({
                        name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                        link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                        menu: item.OM_ACCOUNT_MODULE_MENU,
                      });
                      existingType.weblink.push(
                        item.OM_ACCOUNT_MODULE_WEB_PAGE
                      );
                    } else {
                      existingType.ids.push(item);
                      existingType.weblink.push(existingType.weblink[index]);
                    }
                  } else {
                    // If MENU_TYPE doesn't exist, create a new group with the current ID
                    const newId =
                      typeof item === "object"
                        ? {
                            name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                            link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                            menu: item.OM_ACCOUNT_MODULE_MENU,
                          }
                        : item;

                    acc.push({
                      type: item.OM_ACCOUNT_MODULE_MENU_TYPE,
                      ids: [newId],
                      weblink: [item.OM_ACCOUNT_MODULE_WEB_PAGE],
                    });
                  }
                  // console.log(existingType)
                  return acc;
                }, []).map((group, index) => (
                  <div key={index} className="text-[14px] ml-2 my-2">
                    <p
                      style={{
                        cursor: "pointer",
                        paddingBottom: "0px",
                        marginBottom: "0px",
                        fontWeight: "600",
                      }}
                      onClick={() => handleGroupClick(index)}
                    >
                      {group.type}
                    </p>

                    {openGroup === index && (
                      <ul
                        style={{ listStyleType: "none", paddingLeft: "15px" }}
                      >
                        {group.ids.map((id, idIndex) => (
                          <li key={idIndex} style={{ cursor: "pointer" }}>
                            {typeof id === "object" && (
                              <span
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleNavigation(id)}
                              >
                                {id.name}
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </aside>
            )}{" "}
          </ul>
        </nav>
        <div className="w-[80%] flex justify-between mr-4">
          <h4 className="hover-underline-animation  hidden lg:block dashboard name__head__big__screen">
            PROPERTY MANAGEMENT
          </h4>
          <ul className={`navbar_links ${isOpen ? "active" : ""}`}>
            <h2 className="hover-underline-animation ">
              &nbsp;Logged in as :{" "}
              <span style={{ textTransform: "capitalize" }}>
                {" "}
                {state?.user ? state?.user?.emailId?.split("@")[0] : "N/A"}
              </span>
            </h2>
          </ul>
        </div>
        <div className="flex gap-[8px]">
          <aside>
            <button onClick={Dashboard} className="button__logout__navbar">
              {" "}
              DASHBOARD
            </button>
          </aside>
          <aside>
            <button onClick={logOut} className="button__logout__navbar">
              {" "}
              LOGOUT
            </button>
          </aside>
        </div>
        <div className="logout__fullscren">
          <button onClick={logOut} className="log__out__icon">
            {" "}
            <MdLogout />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default MenuNavbar;
