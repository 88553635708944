import build_1 from "./images/build_1.jpg";
import build_2 from "./images/build_2.avif";
import build_3 from "./images/build_3.jpg";
import build_4 from "./images/build_4.avif";
import build_5 from "./images/build_5.jpg";

export const countries = [
  {
    image: build_1,
    title: "Brazil",
  },
  {
    image: build_2,
    title: "China",
  },
  {
    image: build_3,
    title: "France",
  },
  {
    image: build_4,
    title: "Japan",
  },
  {
    image: build_5,
    title: "Norway",
  },
];
