import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClientDashboardNavbar from "../NavbarClient/ClientDashboardNavbar";
import property from "../../Images/property.png";
import InfiniteCardSlider from "../Advertising/ClientInfiniteCardSlider";
import Footer from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
function ClientMainDashboard() {
  const navigate = useNavigate();
  const logOutIfNotLogin = useSelector(
    (logOutIfNotLogin) => logOutIfNotLogin.changeLoginState
  );
  console.log(logOutIfNotLogin);
  useEffect(() => {
    if (logOutIfNotLogin === "FALSE") {
      navigate("/");
    }
  }, [logOutIfNotLogin, navigate]);
  return (
    <div>
      <ClientDashboardNavbar />

      <InfiniteCardSlider />

      {/* <picture>
        <img src={property} height={1000} width={2000} alt="" />
      </picture> */}
      {/* <Footer /> */}
    </div>
  );
}

export default ClientMainDashboard;
