import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { FaEdit } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";

const TenantNoticeProperty = () => {
  const navigate = useNavigate();
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const state = useSelector((state) => state.changeTheState);
  const loginAs = state?.user?.emailId?.split("@")[0];
  console.log(loginAs);
  const userState = useSelector((state) => state.changeTheState.user);
  const [noticeId, setNoticeId] = useState(0);
  const [clientProperty, setClientProperty] = useState("");
  const [unitId, setUnitId] = useState(0);
  const [noticeDate, setNticeDate] = useState("");
  const [noticeReason, setNoticeReasoon] = useState("");
  const [isloading, setisloading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [clientcode, setClientCode] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectname, setprojectname] = useState("");
  const [projectid, setprojectid] = useState("");
  const date = moment().format("YYYY-MM-DD");
  const [approvalnarration, setapprovalnarration] = useState("");
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [bitToApprove, setBitToApprove] = useState(false);
  const [unitnumber, setUnitNumber] = useState("");
  const [approvedFilter, setApprovedFilter] = useState("");
  const [noticeinspectiondate, setnoticeinspectiondate] = useState(
    new Date().toISOString()
  );
  const [clientList, setClientList] = useState([]);
  const [propertyDisplay, setPropertyDisplay] = useState("");
  const [noticeProcessDate, setNoticeProcessDate] = useState("");
  console.log(bitToApprove);
  const [unitDisplay, setUnitDisplay] = useState("");
  const [entryDate, setEntryDate] = useState("");
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filteredData = tableData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const itemDate = item.EXITDATE;

    if (searchData.length === 0 && !fromDate && !toDate) {
      return true;
    }

    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return ["NOTICEID", "UNITNUMBER", "CLIENTNAME"].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

    const dateCondition =
      (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate);

    const approvedMatch =
      !approvedFilter ||
      (approvedFilter === "Yes" ? !!item.APPROVED : !item.APPROVED);

    return textMatch && dateCondition && approvedMatch;
  });

  function fetchInitialData() {
    axios
      .get(`${BASEURL}/getnewnoticeid`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response);
        setNoticeId(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response);
        setClientList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${BASEURL}/getnoticelist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setTableData(response.data);
        console.log(response.data, "DATA");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    scrollToSection();
    fetchInitialData();
  }, []);

  function handleCaptureMeterReading(
    readingNo,
    subunitid,
    subunitno,
    projectid,
    projectnames,
    unitid,
    unitnumber,
    leasenumber,
    entrydate,
    entrytime,
    entryvalue,
    meterNo
  ) {
    navigate("/CaptureMeterReading", {
      state: {
        readingNo,
        subunitid,
        subunitno,
        projectid,
        projectnames,
        unitid,
        unitnumber,
        leasenumber,
        entrydate,
        entrytime,
        entryvalue,
        meterNo,
        typeofreading: "EXIT",
      },
    });
  }

  async function handleSave() {
    if (!bitToApprove) {
      if (approvalnarration === "") {
        alert("enter all feilds");
      } else {
        if (noticeDate && unitId && clientProperty && noticeId) {
          try {
            const obj = {
              NOTICEID: noticeId,
              NOTICECLIENTCODE: userState.userCode,
              NOTICECLIENTPROPERTY: projectid,
              NOTICECLIENTUNITID: unitId,
              NOTICEEXITDATE: noticeDate,
              NOTICEREASON: noticeReason,
              NOTICEPROCESSEDBY: userState.emailId.toString().split("@")[0],
              NOTICEPROCESSEDDATE: date,
              NOTICEAPPROVEDBY: loginAs,
              NOTICEAPPROVALNARRATION: approvalnarration,
              NOTICEINSPECTIONDUEDATE: noticeinspectiondate,
            };
            const response = await axios.post(
              `${BASEURL}/inserttenantnotice/confirm`,
              obj,
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            );
            alert(response.data.message);
            console.log(response);
            window.location.reload();
          } catch (err) {
            console.log(err);
            alert(err.response.data.message);
          }
        }
      }
    } else {
      alert("Already Approved");
    }
  }

  function setItemforApproval(obj) {
    console.log(obj);
    setNoticeId(obj.NOTICEID);
    setClientCode(obj.CLIENTCODE);
    console.log(obj.CLIENTCODE[0]);
    setClientProperty(obj.PROPERTY);
    setPropertyDisplay(obj.PROJECTNAME);
    setUnitId(obj.UNITID);
    console.log(obj.UNITID[0]);
    setUnitNumber(obj.UNITNUMBER);
    setNticeDate(obj.EXITDATE);
    setNoticeReasoon(obj.REASON);
    setClientName(obj.CLIENTNAME);
    setBitToApprove(obj.APPROVED);
    setprojectname(obj.PROJECTNAME);
    setprojectid(obj.PROJECTID);
    setUnitDisplay(obj.UNITNUMBER);
  }

  async function saveNewticket() {
    if (approvalnarration === "") {
      alert("enter all feilds");
    } else {
      if (noticeDate && unitId && clientProperty && noticeId) {
        try {
          const obj = {
            NOTICEID: noticeId,
            NOTICECLIENTCODE: userState.userCode,
            NOTICECLIENTPROPERTY: projectid,
            NOTICECLIENTUNITID: unitId,
            NOTICEEXITDATE: noticeDate,
            NOTICEREASON: noticeReason,
            NOTICEPROCESSEDBY: userState.emailId.toString().split("@")[0],
            NOTICEPROCESSEDDATE: date,
            NOTICEAPPROVEDBY: loginAs,
            NOTICEAPPROVALNARRATION: approvalnarration,
            NOTICEINSPECTIONDUEDATE: noticeinspectiondate,
          };
          const response = await axios.post(
            `${BASEURL}/inserttenantnotice/save`,
            obj,
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          alert(response.data.message);
          console.log(response);
          window.location.reload();
        } catch (err) {
          console.log(err);
          alert(err.response.data.message);
        }
      }
    }
  }

  function fetchClientDetails(clientcode) {
    axios
      .post(
        `${BASEURL}/getclentunitidandpropid`,
        {
          CLIENTCODE: clientcode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setprojectid(response.data[0]?.PROJECTID);
        setClientProperty(response.data[0]?.PROJECTID);
        setPropertyDisplay(response.data[0]?.PROJECTNAME);
        setUnitId(response.data[0]?.UNITID);
        setClientName(response.data[0]?.CLIENTNAME);
        setisloading(false);
        if (response.data[0]?.PROJECTID) {
          setisloading(true);
          axios
            .post(
              `${BASEURL}/getunitnobasedonunitid`,
              {
                PROJECTID: response.data[0]?.PROJECTID,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              console.log(response);
              setUnitDisplay(response.data[0]?.UNITNO);
              setisloading(false);
            });

          axios
            .post(
              `${BASEURL}/getleaseentrydate`,
              {
                CLIENTCODE: userState.userCode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              setEntryDate(response.data[0]?.LEASESTARTDATE.split("T")[0]);
              console.log(response);
            });
        }
      });
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  if (isloading) {
    <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "TENANT NOTICE"}
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE ID </label>
                    </p>
                    <div>
                      <input
                        value={noticeId}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE CLIENT NAME </label>
                    </p>
                    <div>
                      {/* <input
                        value={clientName}
                        disabled
                        required
                        className="input_1"
                      /> */}
                      <Select
                        onChange={(e) => {
                          setClientName(e.label);
                          setClientCode(e.value);
                          fetchClientDetails(e.value);
                        }}
                        value={{ label: clientName, value: clientcode }}
                        options={[
                          { value: "", label: "" },
                          ...clientList.map((item) => ({
                            value: item.CLIENTCODE,
                            label: item.CLIENTNAME,
                          })),
                        ]}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">
                        NOTICE CLIENT PROPERTY{" "}
                      </label>
                    </p>
                    <div>
                      <input
                        value={propertyDisplay}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">
                        Notice Client Unit Number
                      </label>
                    </p>
                    <div>
                      <input
                        value={unitDisplay}
                        disabled
                        required
                        className="input_1"
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE EXIT DATE </label>
                    </p>
                    <div>
                      <input
                        onChange={(e) => {
                          setNticeDate(e.target.value);
                        }}
                        className="input_1"
                        value={noticeDate?.split("T")[0]}
                        type="date"
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">NOTICE REASON </label>
                    </p>
                    <div>
                      <textarea
                        name=""
                        onChange={(e) => {
                          setNoticeReasoon(e.target.value);
                        }}
                        value={noticeReason}
                        class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[60px] max-h-[60px]"
                      ></textarea>
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">APPROVAL NARRATION </label>
                    </p>
                    <textarea
                      name=""
                      value={approvalnarration}
                      onChange={(e) => {
                        setapprovalnarration(e.target.value);
                      }}
                      class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[60px] max-h-[60px]"
                    ></textarea>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">
                        NOTICE INSPECTION DATE
                      </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        type="date"
                        value={noticeinspectiondate.split("T")[0]}
                        onChange={(e) => {
                          setnoticeinspectiondate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <div className="savebutton">
                  <button
                    onClick={() => {
                      window.location.reload();
                    }}
                    className="button"
                  >
                    Clear
                  </button>
                  {/* <button onClick={handleSave} className="button">
                    Approve
                  </button> */}
                  <button onClick={saveNewticket} className="button">
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>

      <div className="sm:block lg:flex my-4 items-center justify-between sm:mx-10  lg:mx-[60px]">
        <div className="sm:block lg:flex">
          <p>Search: &nbsp;</p>
          <input
            className="input_1 w-[250px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="relative top-[6px] right-5">
            {" "}
            <FaSearch />
          </div>
        </div>
        <div>
          <label>Approved: &nbsp;</label>
          <select
            style={{ height: "30px", width: "100px" }}
            value={approvedFilter}
            onChange={(e) => setApprovedFilter(e.target.value)}
            className="input_1"
          >
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <div className="sm:block lg:flex items-center gap-4">
          <p>From Date:</p>
          <input
            className="input_1"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <p>To Date:</p>
          <input
            className="input_1"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
      </div>

      <div className="service_detials ">
        <table className="table_1">
          <thead>
            <tr>
              <td style={{ textAlign: "right", width: "100px" }}>NOTICE ID</td>
              <td
                style={{
                  textAlign: "right",
                  width: "100px",
                }}
              >
                UNIT&nbsp;NUMBER
              </td>
              <td>CLIENT&nbsp;NAME</td>
              <td>PROJECT NAME</td>
              {/* <td style={{ textAlign: "right", paddingRight: "40px" }}>
                LEASE NUMBER
              </td> */}
              <td>EXIT DATE</td>
              <td>STATUS</td>
              <td>APPROVE</td>
              <td>CAPTURE METER READING (EXIT)</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                // key={item.NOTICEID}
                style={{
                  textTransform: "uppercase",
                }}
              >
                <td style={{ textAlign: "right" }}>{item?.NOTICEID}</td>
                <td style={{ textAlign: "right" }}>{item?.UNITNUMBER}</td>
                <td>{item?.CLIENTNAME}</td>
                <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                <td style={{ textAlign: "right", paddingRight: "40px" }}>
                  {item?.PROPERTY}
                </td>

                <td>
                  {item.EXITDATE
                    ? moment(item.EXITDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
                <td>{item.APPROVED ? "APPROVED" : "NOT APPROVED"}</td>
                <td className="text-center">
                  <p
                    className="text-[20px] flex items-center cursor-pointer"
                    onClick={(e) => {
                      setItemforApproval(item);
                      scrollToSection();
                    }}
                  >
                    <FaEdit />
                  </p>
                </td>
                <td
                  onClick={() => {
                    handleCaptureMeterReading(
                      item.READINGNO,
                      item.SUBUNITID,
                      item.SUBUNITNO,
                      item.PROJECTID,
                      item.PROJECTNAME,
                      item.UNITID,
                      item.UNITNUMBER,
                      item.LEASENUMBER,
                      item.READINGDATEONENTRY,
                      item.READINGTIMEONENTRY,
                      item.READINGVALUEONENTRY,
                      item.METERNO
                    );
                  }}
                >
                  <FaTasks />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TenantNoticeProperty;
