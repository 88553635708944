import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../Pages/ClientDashboard/ClientDashboard.css";
import Loading from "../../Components/Loading/Loading";
// import NavbarDashboard from "../../Pages/NavbarClient/ClientMenuNavbar";
import MenuNavbar from "./MenuNavbar";
import { statementPass } from "../../Redux/Action/selectStatements";
import jsPDF from "jspdf";
import { FaFileInvoice } from "react-icons/fa";
import moment from "moment";
import Select from "react-select";
const AdminClientDashboard = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const userState = useSelector((state) => state.changeTheState.user);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(userState);
  console.log(userState.userCode);
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [statements, setStatements] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [noDatamsg, setNoDataMessage] = useState("");
  const [ageingDetails, setAgeingDetails] = useState({});
  const [totalDr, setTotalDr] = useState(0);
  const [totalCr, setTotalCr] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [Document, setDocument] = useState([]);
  const [Clientcode, setClientcode] = useState("");
  const [compPin, setCompPin] = useState("");
  // const[companyDetails, setCompanydetails] = useState
  const [selectedClient, setSelectedClient] = useState({});
  const navigate = useNavigate();
  const [Companydetails, setCompanydetails] = useState([]);
  const [lastInv, setLastInv] = useState(0);
  const [clientList, setClientList] = useState([]);
  const logOutIfNotLogin = useSelector(
    (logOutIfNotLogin) => logOutIfNotLogin.changeLoginState
  );
  const [search, setSearch] = useState("");
  const filteredData = statements?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    if (searchData.length === 0 && !fromDate && !toDate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        ["SALETYPE"],
        ["INVOICENUMBER"],
        ["INVOICEDATE"],
        ["SERVICE"],
        ["DR"],
        ["CR"],
        ["CURRENCY"],
        ["CUMULATIVE"],
        ["INVOICENUMBER"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const Date = item.INVOICEDATE;
    if (searchData.length === 0 && !fromDate && !toDate) {
      return true;
    }
    const dateMatch =
      (!fromDate || Date >= fromDate) && (!toDate || Date <= toDate);

    return textMatch && dateMatch;
  });
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setClientList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  console.log(logOutIfNotLogin);
  useEffect(() => {
    if (logOutIfNotLogin === "FALSE") {
      navigate("/");
    }
  }, [logOutIfNotLogin, navigate]);
  const dispatch = useDispatch();
  console.log(userState);
  async function fetchStatement() {
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("From date cannot be greater than To date");
        return;
      }

      try {
        setIsLoading(true);
        console.log(userState.userCode);
        console.log(fromDate);
        console.log(toDate);
        const DateFrom =
          fromDate.slice(8, 10) +
          "/" +
          fromDate.slice(5, 7) +
          "/" +
          fromDate.slice(0, 4);
        const dateTo =
          toDate.slice(8, 10) +
          "/" +
          toDate.slice(5, 7) +
          "/" +
          toDate.slice(0, 4);
        console.log(DateFrom);
        console.log(dateTo);

        const response = await axios.post(
          `${BASEURL}/getclientstatement`,
          {
            CLIENTCODE: userState.userCode,
            STARTDATE: DateFrom,
            ENDDATE: dateTo,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        console.log(response.data.length);
        if (response.data.length === 0) {
          console.log("No statements available");
          setNoDataMessage("No statements available");
          setIsLoading(false);
        } else {
          console.log(response.data);
          var temparray = response.data ? response.data : [];
          const newKey = "CUMULATIVE";
          let tempData = 0;
          setIsLoading(false);
          temparray.forEach((obj, i) => {
            if (i === 0) {
              obj[newKey] = response.data[0]?.DR;
              tempData = response.data[0]?.DR;
            } else {
              if (response.data[i].CR) {
                obj[newKey] = tempData - response.data[i]?.CR;
                tempData = tempData - response.data[i]?.CR;
              } else if (response.data[i]?.DR) {
                obj[newKey] = tempData + response.data[i]?.DR;
                tempData = tempData + response.data[i]?.DR;
              } else {
                // Handle other cases if needed
                obj[newKey] = tempData; // Set to the current tempData value
              }
            }
          });
          setTotal(temparray[temparray.length - 1]?.CUMULATIVE);
          console.log(temparray);
          calculatetotalDrCr(temparray);
          setStatements(temparray);
          const DateFrom =
            fromDate.slice(8, 10) +
            "/" +
            fromDate.slice(5, 7) +
            "/" +
            fromDate.slice(0, 4);
          const dateTo =
            toDate.slice(8, 10) +
            "/" +
            toDate.slice(5, 7) +
            "/" +
            toDate.slice(0, 4);
          console.log(userState.userCode);
          console.log(DateFrom);
          console.log(dateTo);
          const ageingdetailsResponse = await axios.post(
            `${BASEURL}/getagingdetails`,
            {
              CLIENTCODE: userState.userCode,
              STARTDATE: DateFrom,
              ENDDATE: dateTo,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          setTotalOutstanding(
            ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
              ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
              ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
              ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
          );
          setAgeingDetails(
            ageingdetailsResponse.data[0] ? ageingdetailsResponse.data[0] : {}
          );
        }
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("Select from and to date");
    }
  }

  function calculatetotalDrCr(item) {
    var totaldr = 0;
    var totalcr = 0;

    for (var i = 0; i < item.length; i++) {
      if (item[i].CR) {
        totalcr += item[i].CR;
      } else if (item[i].DR) {
        totaldr += item[i].DR;
      }
    }

    // Set the state using the setTotalDr and setTotalCr functions
    setTotalDr(totaldr);
    setTotalCr(totalcr);
  }

  function navigateToCheckOut() {
    // if (selectedRows.length === 0 && !selectedClient.code) {
    //   alert("Select Client and transaction to proceed");
    // } else
    if (selectedRows.length > 0 && selectedClient) {
      dispatch(
        statementPass({
          statements: selectedRows,
          total: total,
          clienCode: selectedClient.code,
        })
      );
      navigate("/AdminClientCheckoutPage");
    } else {
      alert("Select Client and transaction to proceed");
    }
  }

  async function makePaypent() {
    if (selectedRows?.length === 0) {
      alert("Select atleast one statement");
    } else {
      for (var i = 0; i < selectedRows.length; i++) {
        try {
          const savePaymentResponse = await axios.post(
            `${BASEURL}/savepayment`,
            {
              CLIENTCODE: userState.userCode,
              MPESAREFERENCENO: "",
              PAYMENTAMOUNT: selectedRows[i].AMOUNT,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          const processREcieptResponse = await axios.post(
            `${BASEURL}/processallocatedreciept`,
            {
              CLIENTCODE: userState.userCode,
              MPESAREFNO: "RX003",
              INVNO: selectedRows[i].DOCNO,
              INVOICEDATE: selectedRows[i].DOCDATE,
              INVOICEAMOUNT: selectedRows[i].AMOUNT,
              ALLOCATEDAMOUNT: selectedRows[i].AMOUNT,
              PROCESSEDBY: userState.emailId,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
        } catch (err) {
          alert(err.response.data.message);
        }
      }
    }
  }

  function fetchInitialData(clientCode) {
    // setIsLoading(true);
    // Set default values when the component first renders
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // Set fromDate to January 1 of the current year
    const defaultFromDate = `${currentYear}-01-01`;
    setFromDate(defaultFromDate);

    // Set toDate to today's date
    const defaultToDate = currentDate.toISOString().split("T")[0];
    setToDate(defaultToDate);

    // Call fetchStatement after setting initial dates
    const DateFrom =
      fromDate.slice(8, 10) +
      "/" +
      fromDate.slice(5, 7) +
      "/" +
      fromDate.slice(0, 4);
    const dateTo =
      toDate.slice(8, 10) + "/" + toDate.slice(5, 7) + "/" + toDate.slice(0, 4);
    console.log(DateFrom);
    console.log(dateTo);
    try {
      axios
        .post(
          `${BASEURL}/getclientdetails`,
          {
            CLIENTCODE: clientCode,
            STARTDATE: DateFrom,
            ENDDATE: dateTo,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          // console.log(response.data[0]);
          setClientDetails(response.data ? response.data[0] : {});
          setClientcode(response.data[0].USERNAME);
          console.log(response.data[0].USERNAME);
          setIsLoading(false);
          axios
            .post(
              `${BASEURL}/getclentunitidandpropid`,
              {
                CLIENTCODE: response.data[0].USERNAME,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              setDocument(response.data[0]);
            });

          axios
            .get(
              `${BASEURL}/getcompanydetails`,

              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sessiontoken,
                },
              }
            )
            .then((response) => {
              setCompanydetails(response.data[0].ORG_NAME);
              setCompPin(response.data[0].ORG_PIN_NO);
            });
        });
      console.log(clientCode);
      console.log(defaultFromDate);
      console.log(defaultFromDate);
      const fromdate =
        defaultFromDate.slice(8, 10) +
        "/" +
        defaultFromDate.slice(5, 7) +
        "/" +
        defaultFromDate.slice(0, 4);
      const Todate =
        defaultToDate.slice(8, 10) +
        "/" +
        defaultToDate.slice(5, 7) +
        "/" +
        defaultToDate.slice(0, 4);
      axios
        .post(
          `${BASEURL}/getclientstatement`,
          {
            CLIENTCODE: clientCode,
            STARTDATE: fromdate,
            ENDDATE: Todate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          if (response.data.length === 0) {
            setNoDataMessage("No statements available");
          } else {
            console.log(response.data);
            var temparray = response.data;
            const newKey = "CUMULATIVE";
            let tempData = 0;

            temparray.forEach((obj, i) => {
              if (i === 0) {
                obj[newKey] = response.data[0]?.DR;
                tempData = response.data[0]?.DR;
              } else {
                if (response.data[i].CR) {
                  obj[newKey] = tempData - response.data[i]?.CR;
                  tempData = tempData - response.data[i]?.CR;
                } else if (response.data[i]?.DR) {
                  obj[newKey] = tempData + response.data[i]?.DR;
                  tempData = tempData + response.data[i]?.DR;
                } else {
                  // Handle other cases if needed
                  obj[newKey] = tempData; // Set to the current tempData value
                }
              }
            });
            setTotal(temparray[temparray.length - 1]?.CUMULATIVE);
            console.log(temparray[temparray.length - 1]?.CUMULATIVE);
            calculatetotalDrCr(temparray);
            setStatements(temparray);
            const fromdate =
              defaultFromDate.slice(8, 10) +
              "/" +
              defaultFromDate.slice(5, 7) +
              "/" +
              defaultFromDate.slice(0, 4);
            const Todate =
              defaultToDate.slice(8, 10) +
              "/" +
              defaultToDate.slice(5, 7) +
              "/" +
              defaultToDate.slice(0, 4);
            console.log(clientCode);
            console.log(defaultFromDate);
            console.log(defaultToDate);
            axios
              .post(
                `${BASEURL}/getagingdetails`,
                {
                  CLIENTCODE: clientCode,
                  STARTDATE: fromdate,
                  ENDDATE: Todate,
                },
                {
                  headers: {
                    "auth-token": authToken,
                    "session-token": sessiontoken,
                  },
                }
              )
              .then((ageingdetailsResponse) => {
                setTotalOutstanding(
                  ageingdetailsResponse.data[0]?.ABOVENINETYDAYS +
                    ageingdetailsResponse.data[0]?.SIXTYNINETYDAYS +
                    ageingdetailsResponse.data[0]?.THIRTYSIXTYDAYS +
                    ageingdetailsResponse.data[0]?.ZEROTHIRTYDAYS
                );
                setAgeingDetails(ageingdetailsResponse.data[0]);
              });
          }
        });
    } catch (err) {
      alert(err?.response?.data?.message);
    }
  }
  //   useEffect(fetchInitialData, []);

  const generateInvoicePDF = (invoiceData, docno) => {
    console.log(invoiceData);
    const doc = new jsPDF();

    let yPos = 30;

    // Text for Project and Invoice title
    doc.setFontSize(17);
    doc.text(
      `${clientDetails.PROJECT ? clientDetails.PROJECT : "N/A"}`,
      15,
      yPos
    );
    doc.text("Invoice", 155, yPos);

    // Draw a horizontal line after the header
    // yPos += 20;
    // doc.setLineWidth(0.5);
    // doc.line(15, yPos, 190, yPos);

    yPos += 20;

    // Text for Tenant name
    doc.setFontSize(12);
    doc.text(
      `Tenant Code: ${
        clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : ""
      }`,
      15,
      yPos
    );
    doc.text(
      `Tenant: ${clientDetails.CLIENTNAME ? clientDetails.CLIENTNAME : "N/A"}`,
      75,
      yPos
    );

    doc.text(
      `Unit: ${clientDetails.UNIT ? clientDetails.UNIT : ""}`,
      170,
      yPos
    );

    // Headers for service name, qty, and amount
    yPos += 10;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text("Service Name", 20, yPos);
    // Added header for "Qty" column
    doc.text("Amount", 120, yPos); // Adjusted position for "Amount"

    // Draw vertical lines for each column
    const verticalLineStart1 = 60; // X-coordinate for the vertical line after "Service Name" column
    const verticalLineStart2 = 110; // X-coordinate for the vertical line after "Qty" column
    const tableStartY = yPos - 5; // Starting Y-coordinate of the table
    let tableEndY; // Ending Y-coordinate of the table (to be calculated)

    // Draw a horizontal line below the headers
    yPos += 5;
    doc.setLineWidth(0.5); // Set a normal line width
    doc.line(15, yPos, 190, yPos);

    // Loop through invoice data to populate the table
    // Calculate the maximum width of the "Amount" column
    let maxAmountWidth = 0;
    invoiceData.forEach((item, index) => {
      const amountText = item.DR ? item.DR.toString() : item.CR.toString();
      const amountWidth =
        (doc.getStringUnitWidth(amountText) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      if (amountWidth > maxAmountWidth) {
        maxAmountWidth = amountWidth;
      }
    });

    // Calculate the x-coordinate for the "Amount" column
    const tableWidthx = 90; // Width of the table
    const tableXx = 200 - tableWidthx; // X-coordinate of the table
    const amountX = tableXx + tableWidthx - maxAmountWidth - 20; // Adjusted x-coordinate for the "Amount" column, subtracting a small offset

    // Loop through invoice data to populate the table
    invoiceData.forEach((item, index) => {
      yPos += 10;
      doc.text(item.SERVICE, 20, yPos);

      // Adjusted position for "Amount" to right-align
      const amountText = item.DR ? item.DR.toString() : item.CR.toString();
      doc.text(amountText, amountX, yPos);

      // Update the ending Y-coordinate of the table for each row
      tableEndY = yPos + 5;
    });

    // Draw vertical lines for separating columns
    doc.setLineWidth(0.5); // Set a normal line width
    doc.line(verticalLineStart1, tableStartY, verticalLineStart1, tableEndY);
    // doc.line(verticalLineStart2, tableStartY, verticalLineStart2, tableEndY);

    // Draw a horizontal line above the table
    doc.line(15, tableStartY, 190, tableStartY);

    // Draw a horizontal line below the table
    doc.line(15, tableEndY, 190, tableEndY);

    // Draw vertical lines to cover the left and right boundaries of the table
    doc.line(15, tableStartY, 15, tableEndY);
    doc.line(190, tableStartY, 190, tableEndY);
    // Calculate total amount
    const totalAmount = invoiceData.reduce((total, item) => {
      return total + (item.DR ? parseFloat(item.DR) : parseFloat(item.CR));
    }, 0);

    // Text for total amount
    yPos += 15;
    doc.text(`Total Amount: ${totalAmount.toFixed(2)}`, 125, yPos);
    // Draw a box and write "Created By: Admin" inside it
    const boxWidth = 80;
    const boxHeight = 15;
    const boxX = 15; // Adjusted x-coordinate to align with total amount text
    // const boxY = yPos + 10;
    doc.rect(boxX, yPos, boxWidth, boxHeight);
    doc.text("Created By: Admin", boxX + 5, yPos + 5);

    const tableWidth = 90; // Adjust this value as per the content

    // Calculate the starting x-coordinate for the table to align it to the right
    const tableX = 200 - tableWidth;

    // Draw the table with borders
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(tableX, yPos + 20, tableWidth, 20, "F");
    doc.setFontSize(12);
    doc.setTextColor(0);
    // Draw outer boundaries for the table
    doc.line(tableX, yPos + 20, tableX + tableWidth, yPos + 20); // Top horizontal line
    doc.line(tableX, yPos + 20, tableX, yPos + 40); // Left vertical line
    doc.line(tableX + tableWidth, yPos + 20, tableX + tableWidth, yPos + 40); // Right vertical line
    doc.line(tableX, yPos + 40, tableX + tableWidth, yPos + 40); // Bottom horizontal line

    // Draw column boundaries
    doc.line(tableX + 30, yPos + 20, tableX + 30, yPos + 40); // Vertical line between Company Pin and Tax Date
    doc.line(tableX + 60, yPos + 20, tableX + 60, yPos + 40); // Vertical line between Tax Date and Invoice No
    doc.line(tableX + 90, yPos + 20, tableX + 90, yPos + 40); // Right boundary

    // Add table headers
    doc.text("Company Pin", tableX + 3, yPos + 27);
    doc.text("Tax Date", tableX + 35, yPos + 27);
    doc.text("Invoice No", tableX + 65, yPos + 27);
    yPos += 5;
    doc.setLineWidth(0.5); // Set a normal line width
    doc.line(tableX, yPos + 25, tableX + tableWidth, yPos + 25);
    // Add table data
    // doc.setFontStyle("normal");
    doc.text(compPin, tableX + 5, yPos + 30);
    doc.text(
      moment(invoiceData[0]?.INVOICEDATE).format("MMM YYYY"),
      tableX + 35,
      yPos + 30
    );
    doc.text(docno.toString(), tableX + 65, yPos + 30);

    yPos += 80;
    doc.setFontSize(10);
    doc.text("Powered by Genius Business System", 105, yPos, {
      align: "center",
    });
    yPos += 5;
    doc.setFontSize(10);
    doc.text("Marstrack ERP", 105, yPos, { align: "center" });
    yPos += 5;
    doc.setFontSize(10);
    doc.text("www.gbsafrica.net", 105, yPos, { align: "center" });
    yPos += 5;

    doc.save("invoice.pdf");
  };

  const generateStatementPDF = (invoiceData) => {
    const doc = new jsPDF();
    let yPos = 30;
    let cumulative = 0; // Initialize cumulative amount
    let currentPage = 1;
    const maxRowsPerPage = 20; // Maximum number of rows per page
    let rowIndex = 0; // Track the current row index

    // Function to add content to the current page
    const addContentToPage = () => {
      // Add headers for the content
      doc.setFontSize(17);
      doc.text(
        `${clientDetails.PROJECT ? clientDetails.PROJECT : "N/A"}`,
        15,
        yPos
      );
      doc.text("Statement", 155, yPos);
      yPos += 20;

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0); // Red color
      doc.text(
        `Start Date: ${moment(fromDate).format("DD/MM/YYYY")}`,
        15,
        yPos
      );

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0); // Red color
      doc.text(`End Date: ${moment(toDate).format("DD/MM/YYYY")}`, 80, yPos);

      yPos += 10;
      doc.setFontSize(12);
      doc.setTextColor(255, 0, 0); // Red color
      doc.text(
        `Tenant: ${
          clientDetails.CLIENTNAME ? clientDetails.CLIENTNAME : "N/A"
        }`,
        15,
        yPos
      );
      doc.text(
        `Tenant Code: ${
          clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : ""
        }`,
        100,
        yPos
      );
      // doc.text(
      //   `Unit: ${clientDetails.UNIT ? clientDetails.UNIT : ""}`,
      //   100,
      //   yPos
      // );
      yPos += 10;
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8);
      // Add headers for each column
      doc.text("Transaction Type", 20, yPos);
      doc.text("Document Date", 60, yPos);
      doc.text("Document No", 80, yPos);
      doc.text("Service Name", 100, yPos);
      doc.text("DR", 135, yPos); // New column header for DR
      doc.text("CR", 160, yPos); // New column header for CR
      doc.text("Cumulative", 185, yPos); // New column header for Cumulative

      // Draw horizontal line below the headers
      yPos += 5;
      doc.setLineWidth(0.5);
      doc.line(15, yPos, 230, yPos); // Extend the line to cover the new columns

      // Calculate the number of rows to display on the current page
      const rowsPerPage = Math.min(
        maxRowsPerPage,
        invoiceData.length - rowIndex
      );

      // Loop through invoice data to populate the table
      for (let i = 0; i < rowsPerPage; i++) {
        const item = invoiceData[rowIndex];
        yPos += 10;
        // Add content for each column
        doc.text(item?.SALETYPE, 20, yPos);
        doc.text(moment(item?.INVOICEDATE).format("DD/MM/YYYY"), 60, yPos);
        doc.text(item?.INVOICENUMBER.toString(), 80, yPos);
        doc.text(item.SERVICE, 100, yPos);
        if (item.DR !== 0) {
          doc.text(
            item.DR?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }),
            135,
            yPos
          ); // Add DR value
          cumulative += parseFloat(item.DR);
        }
        if (item.CR !== 0) {
          doc.text(
            item.CR?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }),
            160,
            yPos
          ); // Add CR value
          cumulative -= parseFloat(item.CR);
        }
        if (item.DR !== 0 || item.CR !== 0) {
          doc.text(
            cumulative.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            }),
            185,
            yPos
          ); // Add cumulative value
        }
        rowIndex++;
      }

      // Check if there are more rows to display on a new page
      if (rowIndex < invoiceData.length) {
        // Add a new page
        doc.addPage();
        currentPage++;
        yPos = 30;
        // Call the function recursively to add content to the new page
        addContentToPage();
      } else {
        doc.setFontSize(12);
        // If all rows are displayed, add additional content
        yPos += 15;
        const totalAmount = invoiceData.reduce((total, item) => {
          return (
            total +
            (item.DR ? parseFloat(item.DR) : 0) -
            (item.CR ? parseFloat(item.CR) : 0)
          );
        }, 0);
        doc.text(
          `Total Outstanding: ${totalAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}`,
          125,
          yPos
        );
        // const boxWidth = 80;
        // const boxHeight = 15;
        // const boxX = 15;
        // doc.rect(boxX, yPos, boxWidth, boxHeight);
        // doc.text("Created By: Admin", boxX + 5, yPos + 5);
        yPos += 80;
        doc.setFontSize(10);
        doc.text("Powered by Genius Business System", 105, yPos, {
          align: "center",
        });
        yPos += 5;
        doc.text("Marstrack ERP", 105, yPos, { align: "center" });
        yPos += 5;
        doc.text("www.gbsafrica.net", 105, yPos, { align: "center" });
      }
    };

    // Call the function to start adding content to the PDF
    addContentToPage();

    // Save the PDF
    doc.save(`statement_${clientDetails.CLIENTNAME}`);
  };

  function generateInvoice(docno) {
    if (docno) {
      axios
        .post(
          `${BASEURL}/getstatementDetails`,
          {
            INVOICENUMBER: docno,
            CLIENTCODE: userState.userCode,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.length === 0) {
            alert("Statement details not found");
          } else {
            generateInvoicePDF(response.data, docno);
          }
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    } else {
      alert("Select a statement first");
    }
  }

  const handleCheckboxChange = (item) => {
    if (selectedRows.includes(item)) {
      // If the item is already selected, remove it from the selectedRows array
      setSelectedRows(selectedRows.filter((row) => row !== item));
      // total > 0 ? setTotal(total - Number(item.AMOUNT)) : setTotal(0);
    } else {
      // If the item is not selected, add it to the selectedRows array
      setSelectedRows([...selectedRows, item]);
      // setTotal(total + item.AMOUNT);
    }
    setLastInv(item.DOCNO);
  };
  const Viewdocument = () => {
    console.log(Document);
    if (selectedClient.code) {
      if (Document) {
        if (Document.CONFIRMED == 1) {
          navigate("/ResidentAgreement", {
            state: { inspectionData: Document, companyname: Companydetails },
          });
        } else {
          alert("Document Not confirmed");
        }
      } else {
        alert("Lease not found");
      }
    } else {
      alert("Select client first");
    }
  };
  const renderList = (
    <div>
      <MenuNavbar />

      <header className="header__of__main flex items-center justify-between mr-5">
        {menu ? (
          <h1 className="header__of__page"> {menu}</h1>
        ) : (
          <h1 className="header__of__page">
            {" "}
            PROCESS PAYMENT ON BEHALF OF TENANT
          </h1>
        )}
      </header>
      <div className="lg:flex items-center mx-[20px] lg:mx-[30px] mt-[20px] gap-3">
        <h2>Select client</h2>
        <div className="w-[500px] z-[999]">
          {" "}
          <Select
            onChange={(e) => {
              fetchInitialData(e.value);
              setSelectedClient({ name: e.label, code: e.value });
            }}
            value={{ value: selectedClient.value, label: selectedClient.name }}
            options={clientList.map((item) => ({
              value: item?.CLIENTCODE,
              label: item?.CLIENTNAME,
            }))}
          />
        </div>
      </div>
      <div className="custom-container">
        <div
          className="flex items-center custom-item"
          style={{ marginLeft: "10px" }}
        >
          <h2>CUSTOMER NAME:</h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.CLIENTNAME ? clientDetails.CLIENTNAME : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>PROJECT:</h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.PROJECT ? clientDetails.PROJECT : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>ACCOUNT NUMBER: </h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>UNIT: </h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.UNIT ? clientDetails.UNIT : "N/A"}
          </h2>
        </div>
        <div className="flex items-center custom-item">
          <h2>UNIT PRICE: &nbsp;</h2>
          <h2 className="font-bold text-lg text-red-500">
            {clientDetails.UNITPRICE ? clientDetails.UNITPRICE : "N/A"}
          </h2>
        </div>
      </div>

      {/* <div className="flex mx-8 my-3 ">
        <section className="mr-[100px]">
          <table>
            <tr>
              <td>
                {" "}
                <h2>CUSTOMER NAME:</h2>
              </td>
              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.CLIENTNAME ? clientDetails.CLIENTNAME : "N/A"}
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <h2>ACCOUNT NUMBER: </h2>
              </td>
              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.CLIENTCODE ? clientDetails.CLIENTCODE : "N/A"}
                </h2>
              </td>
            </tr>
          
          </table>
        </section>
        <section>
          <table>
            <tr>
              <td>
                {" "}
                <h2>PROJECT:</h2>
              </td>
              <td>
                <h2 className="font-bold text-lg text-red-500">
                  {" "}
                  {clientDetails.PROJECT ? clientDetails.PROJECT : "N/A"}
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <h2>UNIT: </h2>
              </td>
              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.UNIT ? clientDetails.UNIT : "N/A"}
                </h2>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <h2>UNIT PRICE: &nbsp;</h2>
              </td>

              <td>
                {" "}
                <h2 className="font-bold text-lg text-red-500">
                  {clientDetails.UNITPRICE ? clientDetails.UNITPRICE : "N/A"}
                </h2>
              </td>
            </tr>
          </table>
        </section>
      </div> */}

      <div>
        <section class="flex justify-between items-center lg:mx-[2%] my-2">
          <div className="flex">
            <p className="lg:mx-2 text-[16px]">Search</p>
            <input
              className="w-[300px]  h-[30px]"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
          </div>{" "}
          <p> From</p>
          <input
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            className="border-[1px]  border-black m-1"
            type="date"
            defaultValue={fromDate}
          />
          <p>To</p>
          <input
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            className="border-[1px]  border-black m-1"
            type="date"
            defaultValue={toDate}
          />
          <button
            className=" bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={fetchStatement}
          >
            VIEW STATEMENTS
          </button>
          <button
            className=" bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={Viewdocument}
          >
            RESIDENT AGREEMENT
          </button>
          <button
            className=" bg-[#002d62] rounded p-1 px-2 text-white font-[400] border-[1px] border-green-300"
            onClick={() => {
              if (selectedClient.code) {
                generateStatementPDF(statements);
              } else {
                alert("Select a client first");
              }
            }}
          >
            PRINT STATEMENT
          </button>
        </section>
      </div>
      <aside className="to__center__main">
        <table className="table__test3">
          <thead>
            <tr className="tr__test1">
              <td></td>
              <td>
                <h5 className="text-right pr-4 w-[80px]"> Sr.No.</h5>
              </td>
              <td>
                <h5> TRANSACTION&nbsp;TYPE </h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> DOC&nbsp;NO</h5>
              </td>
              <td>
                <h5> DATE </h5>
              </td>
              <td>
                <h5> SERVICE&nbsp;NAME</h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> DR</h5>
              </td>
              <td>
                <h5 className="text-right pr-4 w-[100px]"> CR </h5>
              </td>
              <td>
                <h5> CURRENCY </h5>
              </td>
              {/* <td>
                <h5 className="text-right pr-4 w-[100px]"> PAID </h5>
              </td> */}
              <td>
                <h5 className="text-right pr-4 w-[150px]"> CUMULATIVE </h5>
              </td>
              <td>
                <h5 className=" pr-4 w-[150px]"> INVOICE </h5>
              </td>
            </tr>
          </thead>

          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    disabled={
                      item.SALETYPE === "RECIEPT" ||
                      item.DR === item.ALREADYPAIDAMOUNT
                        ? true
                        : false
                    }
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={selectedRows.includes(item)}
                  />
                </td>
                <td>
                  <p className="text-right pr-4 w-[80px]"> {index + 1}</p>
                </td>
                <td>{item.SALETYPE ? item.SALETYPE : "N/A"}</td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.INVOICENUMBER ? item.INVOICENUMBER : "N/A"}{" "}
                  </p>
                </td>

                <td>
                  {item.INVOICEDATE
                    ? new Date(item.INVOICEDATE).toLocaleDateString("en-GB")
                    : "N/A"}
                </td>
                <td>
                  <p className=" pr-4 ">
                    {item.SERVICE ? item.SERVICE : "N/A"}{" "}
                  </p>
                </td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {" "}
                    {item.DR
                      ? item.DR?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0}
                  </p>
                </td>
                <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.CR
                      ? item.CR?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : 0}
                  </p>
                </td>
                <td>{item.CURRENCY ? item.CURRENCY : "N/A"}</td>

                {/* <td>
                  <p className="text-right pr-4 w-[100px]">
                    {item.PAIDAMOUNT
                      ? item.PAIDAMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "0.00"}
                  </p>
                </td> */}
                <td>
                  <p className="text-right pr-4 w-[150px]">
                    {item.CUMULATIVE
                      ? Number(item.CUMULATIVE).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : "N/A"}
                  </p>
                </td>
                <td className="">
                  <button
                    onClick={() => {
                      generateInvoice(item.INVOICENUMBER);
                      setLastInv(item.INVOICENUMBER);
                    }}
                    className=" pr-4 text-lg"
                  >
                    <FaFileInvoice />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </aside>
      <div className="flex justify-end items-center gap-8 ">
        <h2>
          Total Outstanding:{" "}
          <span className="font-bold text-green-600 text-lg">
            {" "}
            {total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </span>
        </h2>
        <button
          className="mx-8 bg-[#002d62] rounded p-1 px-2 text-white font-[500] border-[1px] border-green-300"
          onClick={navigateToCheckOut}
        >
          Make Payment
        </button>
      </div>
      <div className="p-2">
        <header>
          {/* <table>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>LEDGER TYPE</th>
                <th>LEDGER</th>
                <th>Dr/Cr</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table> */}
          <aside className="input__and__mapped__values">
            <div>
              <p>
                {" "}
                <span className=" inline-block ">Total Dr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={totalDr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div>
            <div>
              <p>
                {" "}
                <span className="inline-block ">Total Cr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={totalCr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div>
            <div>
              <p>
                {" "}
                <span className="w-[84px] inline-block ">Balance Cr :</span>
              </p>
              <p>
                {" "}
                <input
                  disabled
                  value={(totalCr - totalDr).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div>
          </aside>
          <aside className="input__and__mapped__values">
            <div>
              <p className="  ">0-30 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.ZEROTHIRTYDAYS
                    ? ageingDetails?.ZEROTHIRTYDAYS?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
            <div>
              <p className="  ">30-60 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.THIRTYSIXTYDAYS
                    ? ageingDetails?.THIRTYSIXTYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
            <div>
              <p className="  ">60-90 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.SIXTYNINETYDAYS
                    ? ageingDetails?.SIXTYNINETYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
            <div>
              <p className=" above__90days ">Above 90 Days :</p>
              <input
                disabled
                value={
                  ageingDetails?.ABOVENINETYDAYS
                    ? ageingDetails?.ABOVENINETYDAYS?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
          </aside>
          {/*   <aside className="input__and__mapped__values">
            <div>
              <p>
                <span>Total Outstanding :</span>
              </p>
              <input
                type="number"
                disabled
                value={totalOutstanding?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              />
            </div>
          </aside> */}

          <aside className="mx-[25px] mb-4">
            {/* <div className="flex justify-end items-center">
              <p>
                <span className="w-[84px] inline-block text-right">
                  Total Dr :
                </span>
                <input
                  disabled
                  value={totalDr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
              <p>
                <span className="w-[84px] inline-block text-right">
                  Total Cr :
                </span>
                <input
                  disabled
                  value={totalCr.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                  className="border-[1px] border-black m-1 w-[120px] mr-8"
                  type=""
                  name=""
                />
              </p>
            </div> */}
            {/* <p>
              <span className="w-[84px] inline-block text-right">
                Balance Cr :
              </span>
              <input
                disabled
                value={(totalCr - totalDr).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
                type=""
                name=""
              />
            </p> */}
          </aside>
          {/* <aside className="flex justify-end ">
            <p className="block ">
              <p className="  ">0-30 Days</p>
              <input
                disabled
                value={
                  ageingDetails.ZEROTHIRTYDAYS
                    ? ageingDetails.ZEROTHIRTYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  ">30-60 Days</p>
              <input
                disabled
                value={
                  ageingDetails.THIRTYSIXTYDAYS
                    ? ageingDetails.THIRTYSIXTYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  ">60-90 Days</p>
              <input
                disabled
                value={
                  ageingDetails.SIXTYNINETYDAYS
                    ? ageingDetails.SIXTYNINETYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
            <p className="block ">
              <p className="  "> Above 90 Days</p>
              <input
                disabled
                value={
                  ageingDetails.ABOVENINETYDAYS
                    ? ageingDetails.ABOVENINETYDAYS.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : 0
                }
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
          </aside> */}
          {/* <aside>
            <p className="text-end">
              <span>Total Outstanding : </span>
              <input
                disabled
                value={totalOutstanding.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                className="border-[1px] border-black m-1 w-[120px] mr-8"
              ></input>
            </p>
          </aside> */}
        </header>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default AdminClientDashboard;
