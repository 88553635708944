const initialState = "";
const UnapprovedReceiptred = (state = initialState, action) => {
  switch (action.type) {
    case "CLIENT":
      return action.data;
    default:
      return state;
  }
};

export default UnapprovedReceiptred;
