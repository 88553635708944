import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Location } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import MenuNavbar from "./MenuNavbar";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Select from "react-select";
import "../PropertyManagementCss/elctricpage.css";
import moment from "moment";
import { IoMdExit } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
const InspectionDetailsHistoricDocuments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [isLoading, setIsloading] = useState(true);
  const authToken = window.sessionStorage.getItem("auth-token");

  const [FetchInspectionHeaderDetails, setFetchInspectionHeaderDetails] =
    useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  function fetchInitialData() {
    setIsloading(true);
    axios
      .get(`${BASEURL}/getinspectionheaders`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setFetchInspectionHeaderDetails(response.data);
        setIsloading(false);
      })
      .catch((err) => {
        alert("Server error");
      });
  }
  const [search, setSearch] = useState("");
  const filteredData = FetchInspectionHeaderDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "INSPECTIONNO",
        "CLIENTCODE",
        "LEASENUMBER",
        "INSPECTIONDATE",
        "PROCESSEDBY",
        "PROCESSSEDDATE",
        "CLIENTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });
  useEffect(() => {
    fetchInitialData();
  }, []);
  if (isLoading) {
    return <Loading />;
  }
  function reset() {
    window.location.reload();
  }
  const renderList = (
    <div>
      <MenuNavbar />{" "}
      <header className="header__of__main flex justify-between mr-5">
        <h1 className="header__of__page">
          {menu ? menu : "PENDING EXIT INSPECTION DETAILS"}
        </h1>
      </header>
      <aside className="lg:flex items-center justify-between">
        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div className="search__icon">
            {" "}
            <FaSearch />
          </div>
        </div>
        <button
          onClick={() => {
            navigate("/InspectionDetails");
          }}
          className="button "
          style={{ marginRight: "35px" }}
        >
          Back
        </button>
      </aside>
      <div className="service_detials uppercase">
        <table className="table_1">
          <thead>
            <tr>
              <td style={{ textAlign: "right", paddingRight: "20px" }}>
                INSPECTION NO
              </td>
              <td> PROPERTY NAME</td>
              <td> SUB-UNIT NUMBER</td>
              {/* <td> CLIENT CODE</td> */}
              <td> CLIENT NAME</td>
              <td style={{ textAlign: "right", paddingRight: "40px" }}>
                {" "}
                LEASE NUMBER
              </td>
              <td>INSPECTION DATE</td>
              {/* <td>PROCESSED BY</td>
              <td>PROCESSSED DATE</td> */}
              <td>EDIT</td>
              <td className="w-[100px]">Proceed to inspection for exit </td>
              <td> VIEW STATEMENT</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr>
                <td style={{ textAlign: "right", paddingRight: "20px" }}>
                  {item.INSPECTIONNO ? item.INSPECTIONNO : "N/A"}
                </td>
                <td>{item.PROJECTNAME ? item.PROJECTNAME : "N/A"}</td>
                <td>{item.SUBUNITNO ? item.SUBUNITNO : "N/A"}</td>
                {/* <td>{item.CLIENTCODE ? item.CLIENTCODE[0] : "N/A"}</td> */}
                <td>{item.CLIENTNAME ? item.CLIENTNAME : "N/A"}</td>
                <td style={{ textAlign: "right", paddingRight: "40px" }}>
                  {item.LEASENUMBER ? item.LEASENUMBER : "N/A"}
                </td>
                {/* <td>
                  {item.INSPECTIONDATE
                    ? moment(item.INSPECTIONDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
                <td>{item.PROCESSEDBY ? item.PROCESSEDBY : "N/A"}</td> */}
                <td>
                  {item.PROCESSSEDDATE
                    ? moment(item.PROCESSSEDDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                    textAlign: "right",
                  }}
                  onClick={(e) => {
                    navigate("/InspectionDetails", {
                      state: { inspectionData: item },
                    });
                  }}
                >
                  {" "}
                  <BiSolidEditAlt />
                </td>
                <td
                  onClick={(e) => {
                    navigate("/EditInspectionDetails", {
                      state: {
                        inspectionData: item.INSPECTIONNO,
                        clientCode: item.CLIENTCODE,
                      },
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                    textAlign: "right",
                  }}
                >
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    {" "}
                    <IoMdExit />
                  </span>
                </td>
                <td
                  onClick={(e) => {
                    navigate("/ClientViewStatement", {
                      state: {
                        clientCode: item?.CLIENTCODE,
                        // leaseStartDate: item?.LEASESTARTDATE,
                        // leaseEndDate: item?.LEASEENDDATE,
                        // CustomerName: item.CLIENTNAME,
                        // Project: item?.PROJECTNAME,
                        // AccountNumber:item.
                        // Unit: item?.UNIT,
                        // UnitPrice: item?.UNITPRICE,
                      },
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                    textAlign: "right",
                  }}
                >
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    <FaFileAlt />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default InspectionDetailsHistoricDocuments;
