import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { unAllocatedRec } from "../../Redux/Action/unAllocateReceipts";
const getLastDayOfMonth = (year, month) => {
  // Return the first day of the next month
  return new Date(year, month + 1, 1);
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the first day of the current month (day = 01)
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${currentYear}-${(currentMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;

  return formattedDate;
};

function UnallocatedReceipts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);

  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [client, setclient] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [listOfTrans, setlistOfTrans] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Rentdetails, setRentdetails] = useState([]);
  const [Days, setDays] = useState("");
  const [daysDifference, setDaysDifference] = useState([]);
  const [BillNumber, setBillNumber] = useState("");
  const [Startdatesort, setStartdatesort] = useState(getCurrentDate());
  const getClientcode = () => {
    axios
      .get(`${BASEURL}/getallunallocatedreceipts`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setclient(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
    axios
      .get(`${BASEURL}/getallunallocatedreceipts`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistOfTrans(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
  };

  useEffect(() => {
    getClientcode();
  }, []);
  const [search, setSearch] = useState("");

  const currentDateFilter = new Date();
  const firstDayOfMonth = new Date(
    currentDateFilter.getFullYear(),
    currentDateFilter.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = listOfTrans?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        // ["BILLNO"],
        ["RECEIPTNO"],
        ["CLIENTCODE"],
        ["MPESAREFERENCENO"],
        ["RECEIPTDATE"],
        ["RECEIPTNO"],
        ["AMOUNT"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const Date = item.RECEIPTDATE;
    if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
      return true;
    }
    const dateMatch =
      (!fromDateFilter || Date >= fromDateFilter) &&
      (!toDateFilter || Date <= toDateFilter);

    return textMatch && dateMatch;
  });

  function handleProcesToAllocate(statement) {
    dispatch(unAllocatedRec(statement));
    navigate("/processunalloctaedreceipts");
  }

  return (
    <div className="">
      <MenuNavbar />

      <header className="header__of__main flex justify-between items-center lg:mx-[20px]">
        <h1 className="header__of__page">
          {menu ? menu : "UNALLOCATED RECEIPTS"}
        </h1>

        {/* <div className="">
          <button
            className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
            onClick={HandleSave}
          >
            Save
          </button>
          <button
            className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
            onClick={(e) => {
              navigate("/PROCESSPAYMENTONBEHALFOFTENANT");
            }}
          >
            Allocate Receipts
          </button>
        </div> */}
      </header>
      <div className="lg:flex items-center justify-between m-[20px]">
        <div className="flex">
          <p className="lg:mx-2 text-[16px]">Search</p>
          <input
            className="w-[300px] lg:mr-[50px] h-[30px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
        </div>
        <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
          <div className="lg:ml-[10px] md:ml-0 flex items-center ">
            <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
            <input
              type="date"
              value={fromDateFilter}
              onChange={handleFromDateFilterChange}
            />
          </div>
          <div className="lf:ml-[10px] md:ml-0 flex items-center ">
            <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
            <input
              type="date"
              value={toDateFilter}
              onChange={handleToDateFilterChange}
            />
          </div>
        </section>
      </div>
      <section className="section__newgarage">
        <aside className="aside__new__garage UnitTypeMaster__width">
          {/* <div style={{ width: "202px" }}>
                  <p style={{ width: "140px" }}>
                    <label style={{ width: "140px" }}>BILL&nbsp;NO: </label>
                  </p>
                  <div className="ml-4 text-red-600 font-bold">
                    {BillNumber}
                  </div>
                </div> */}
          {/* <div>
                  <p>
                    <label className="garage_id_">Project Name : </label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(selectedOption) => {
                        if (selectedOption.value === "") {
                          alert("Kindly Supply proper Project Name");
                          setselprojectname("");
                        } else {
                          setselprojectname(selectedOption.value);
                          //   handleProject(selectedOption);
                        }
                      }}
                      value={
                        selprojectname
                          ? {
                              label: selprojectname,
                              value: selprojectname,
                            }
                          : { label: "", value: "" }
                      }
                      options={[
                        { value: "", label: "" },
                        ...listofprojectid.map((item) => ({
                          value: item.PROJECTNAME,
                          label: item.PROJECTNAME,
                        })),
                      ]}
                    />
                  </div>
                </div> */}

          {/* <div style={{ width: "300px" }}>
                  <p>
                    <label className="garage_id_">Date</label>
                  </p>
                  <div>
                    <input
                      style={{ width: "200px" }}
                      type="date"
                      onChange={handleDateChange}
                      value={Startdatesort}
                    ></input>
                  </div>
                </div> */}
        </aside>
      </section>

      {isloading ? (
        <Loading />
      ) : (
        <div className="mx-[2%] flex justify-between w-full">
          <table className="table_1 " style={{ fontSize: "12px" }}>
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <td>CLIENT CODE </td>
                <td>MPESA REF NO</td>
                <td>RECEIPT DATE</td>
                <td className="text-right">RECEIPT NO</td>
                <td className="text-right">AMOUNT</td>
                <td className="text-right">ALREADY ALLOCATED</td>
                <td>Allocate Amount</td>

                {/* <td>SELECT CLIENT</td> */}
                {/* <td className="text-right">LEASE END DATE</td>

                <td className="text-right">RENT AMOUNT</td>
                <td className="text-right">RENT CHARGED </td> */}
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index} style={{ textTransform: "uppercase" }}>
                    <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td>
                    <td>
                      {item.MPESAREFERENCENO ? item.MPESAREFERENCENO : "N/A"}
                    </td>
                    <td style={{ paddingRight: "50px" }}>
                      {item.RECEIPTDATE
                        ? moment(item.RECEIPTDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                    <td className="text-right">
                      {item.RECEIPTNO ? item.RECEIPTNO : "NA"}
                    </td>
                    <td className="text-right">
                      {item?.AMOUNT.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0"}
                    </td>
                    <td className="text-right">
                      {item?.ALREADYALLOCATED.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0"}
                    </td>
                    <td className="text-center">
                      <button
                        onClick={() => {
                          handleProcesToAllocate(item);
                        }}
                      >
                        Allocate
                      </button>
                    </td>

                    {/* <td className="w-[300px]">
                      <Select
                        onChange={(e) => {
                          item.SELCLIENT = e.value;
                        }}
                        
                        options={client.map((clientItem) => ({
                          value: clientItem?.CLIENTCODE,
                          label: clientItem?.CLIENTNAME,
                        }))}
                      />
                    </td> */}
                    {/*   <td className="text-right">
                      {item.LEASEENDDATE
                        ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>

                    <td
                      // className="text-right"
                      className={`uppercase text-right ${
                        item.RENTAMOUNT === 0 ? "bg-red-200" : ""
                      }`}
                      title={item.RENTAMOUNT === 0 ? "Rent Amount is zero" : ""}
                    >
                      {item?.RENTAMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-right">
                      {daysDifference[index] !== undefined &&
                      Startdatesort?.slice(5, 7) ===
                        item.LEASESTARTDATE?.slice(5, 7) &&
                      Startdatesort?.slice(0, 4) ===
                        item.LEASESTARTDATE?.slice(0, 4)
                        ? (
                            (item.RENTAMOUNT /
                              moment(item.LEASESTARTDATE).daysInMonth()) *
                            daysDifference[index]
                          ).toFixed(2)
                        : item?.RENTAMOUNT?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                     
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UnallocatedReceipts;
