import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar2 from "./DashboardNavbar";
import property from "../../Images/property.png";
import InfiniteCardSlider from "../PropertyManagementJS/Advertising/InfiniteCardSlider";
import Footer from "../../Components/Footer/Footer";
function PropertyManagerDashboard() {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar2 />

      <InfiniteCardSlider />

      {/* <picture>
        <img src={property} height={1000} width={2000} alt="" />
      </picture> */}
      {/* <Footer /> */}
    </div>
  );
}

export default PropertyManagerDashboard;
