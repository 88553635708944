import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";

const PropertyTypeMaster = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [propertytypeno, setpropertytypeno] = useState("");
  const [propertytype, setpropertytype] = useState("");
  const [createdby, setCreatedby] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [documenttable, setdocumenttable] = useState([]);
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [isloading, setisloading] = useState(true);
  const filteredData = documenttable.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["PROJECTTYPEID", "PROJECTTYPE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    setCreatedby(username?.split("@")[0].toUpperCase());
    getnewPropertyTypeNo();
    getpropertytypeMasterDetails();
  }, []);

  const getnewPropertyTypeNo = () => {
    axios
      .get(`${BASEURL}/NewPropertyTypeNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setpropertytypeno(response.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching fuel station data:", error);
      });
  };

  const FetchTableValuesInFields = (item) => {
    setpropertytypeno(item.PROJECTTYPEID);
    setpropertytype(item.PROJECTTYPE);
  };

  const clearallinputs = () => {
    setpropertytypeno("");
    setpropertytype("");
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getpropertytypeMasterDetails = () => {
    axios
      .get(`${BASEURL}/PropertyTypeMasterDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setdocumenttable(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Property Type Master:", error);
        setisloading(false);
      });
  };

  async function SavepropertytypeMaster() {
    try {
      const response = await axios.post(
        `${BASEURL}/SavePropertyTypeMaster`,
        {
          propertytypeno: propertytypeno,
          propertytype: propertytype,
          createdby: createdby,
          createddate: date,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Property Type Master"}
              </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage PropertyTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Property Type No</label>
                  </p>
                  <div>
                    <input
                      required
                      className="input_1"
                      value={propertytypeno}
                      readOnly
                    ></input>
                  </div>
                </div>

                <div>
                  <p>
                    <label className="garage_id_">Property Type</label>
                  </p>
                  <div>
                    <input
                      required
                      className="input_1"
                      value={propertytype}
                      onChange={(e) => {
                        setpropertytype(e.target.value);
                      }}
                    ></input>{" "}
                  </div>
                </div>
              </aside>
              <div className="savebutton ">
                <button
                  className="buttonunit2"
                  onClick={() => {
                    clearallinputs();
                    getnewPropertyTypeNo();
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit"
                  onClick={async () => {
                    if (!propertytype) {
                      alert("Kindly Supply Property Type");
                    } else {
                      await SavepropertytypeMaster();
                      clearallinputs();
                      getnewPropertyTypeNo();
                      getpropertytypeMasterDetails();
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1 table__wifth__small ">
          <thead>
            <tr>
              <td
                style={{
                  paddingRight: "76px",
                  width: "200px",
                }}
              >
                PROPERTY TYPE NO
              </td>
              <td>PROPERTY TYPE</td>
              <td>EDIT</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td
                    style={{
                      textAlign: "right",
                      width: "200px",
                      paddingRight: "96px",
                    }}
                  >
                    {item.PROJECTTYPEID ? item.PROJECTTYPEID : "N/A"}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      width: "300px",
                    }}
                  >
                    {item.PROJECTTYPE ? item.PROJECTTYPE : "N/A"}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{ margin: "20px" }}></div>
    </div>
  );
};

export default PropertyTypeMaster;
