import React, { useState, useEffect, useRef } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";

function TENANTKYC() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const selStatement = useSelector((state) => state.unallocatedReceipts);
  console.log(selStatement);
  const [ticketid, setticketid] = useState("");
  const [ticketdate, setticketdate] = useState(new Date().toISOString());
  const [date, setdate] = useState(new Date().toISOString());
  const [commonareaorunit, setcommonareaorunit] = useState("");
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selprojectid, setSelProjectId] = useState("");
  const [selprojectname, setselprojectname] = useState("");

  const [client, setclient] = useState([]);
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");

  const [createdby, setCreatedBy] = useState("");
  const [ticketlist, setticketlist] = useState([]);

  const [search, setSearch] = useState("");
  const [Types, setTypes] = useState("new");
  const state = useSelector((state) => state.changeTheState);
  const loginAs = state?.user?.emailId?.split("@")[0];

  const [commonareaunitflag, setcommonareaunitflag] = useState(0);

  const [statuss, setstatus] = useState("");
  const [editflag, seteditflag] = useState(0);
  // const currentDate = new Date();
  // const firstDayOfMonth = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth() + 0,
  //   2
  // );
  // console.log("First day of current month:", firstDayOfMonth);
  // const [startdatesort, setStartdatesort] = useState(
  //   firstDayOfMonth?.toISOString()?.split("T")[0]
  // );
  // const [enddatesort, setEnddatesort] = useState(
  //   currentDate?.toISOString()?.split("T")[0]
  // );

  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 2);
  console.log("First day of current year:", firstDayOfYear);

  const [startdatesort, setStartdatesort] = useState(
    firstDayOfYear.toISOString().split("T")[0]
  );
  const [enddatesort, setEnddatesort] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [initialJobStatus, setInitialJobStatus] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file

    if (!file) return; // If no file selected, return

    const fileType = file.type; // Get the file type

    // Update selectedFiles with the newly selected file
    setSelectedFiles([{ file, name: file.name }]);

    // Create preview for the selected file
    const preview =
      fileType === "application/pdf" ? URL.createObjectURL(file) : ""; // For PDF files only
    setImagePreviews([preview]);
  };

  console.log(selectedFiles);

  const removeImage = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedImagePreviews = [...imagePreviews];
    updatedImagePreviews.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    console.log(updatedSelectedFiles);
    setImagePreviews(updatedImagePreviews);
    fileInputRef.current.value = null;

    console.log(`Removed image: ${selectedFiles[index].name}`);
  };

  const [isloading, setisloading] = useState(true);

  const FetchTableValuesInFields = (item) => {
    setselprojectname(item.DOCTYPE);
    setSelClientCode(item.CLIENTCODE);
    setselclientname(item.CLIENTNAME);
    setTypes("update");

    // Set the selected files and image previews
    setSelectedFiles([
      {
        file: new Blob([new Uint8Array(item.ATTACHMENT?.data)], {
          type: item.ATTACHMENT?.type,
        }),
        name: "Selected File",
      },
    ]);

    // Create preview for the selected file
    const preview = URL.createObjectURL(
      new Blob([new Uint8Array(item.ATTACHMENT?.data)], {
        type: "application/pdf",
      })
    );
    setImagePreviews([preview]);
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const clearallinputs = () => {
    setticketid("");
    // setselunitno("");
    setticketdate(new Date().toISOString());
    setcommonareaorunit("");
    setSelProjectId("");
    setselprojectname("");
    // setselunitid("");
    setSelClientCode("");
    setselclientname("");
    // setproblemreported("");

    setImagePreviews([]);
    setSelectedFiles([]);
    setSelectedImage(null); // Clear selected image when clearing inputs
  };

  const getnewProjectid = () => {
    axios
      .get(`${BASEURL}/getjobidforpropertytickets`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setticketid(response.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching Job Id:", error);
      });
  };

  const getListOfProjectIds = () => {
    axios
      .get(`${BASEURL}/getkyc`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectid(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Ids:", error);
      });
  };

  const ListOfTickets = () => {
    axios
      .get(`${BASEURL}/getkycattachedclient`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setticketlist(response.data);
        console.log(response.data[0].ATTACHMENT.type);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Tickets:", error); // Log error
        setisloading(false);
      });
  };

  const getClientcode = () => {
    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setclient(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Client Code:", error);
      });
  };

  const handleOptionChange = (selectedOption) => {
    const selected = client.find(
      (item) => item.CLIENTNAME === selectedOption.value
    );
    setSelClientCode(selected.CLIENTCODE);
  };

  console.log("selclientcode:", selclientcode);
  console.log(selclientcode);
  console.log("date:", date);
  console.log("createdby:", createdby);
  console.log(selprojectname);
  console.log(Types);
  async function Savedetails() {
    try {
      const formData = new FormData();
      formData.append("CLIENTCODE", selclientcode);
      formData.append("KYCTYPE", selprojectname);
      formData.append("KYCCREATEDBY", createdby);
      formData.append("KYCCREATEDDATE", date);
      console.log(selectedFiles.length);
      if (selectedFiles.length > 0) {
        formData.append("imageValue", selectedFiles[0].file);
      } else {
        alert("Please select an image file.");
        return;
      }
      const type = Types;
      const ticketResponse = await axios.post(
        `${BASEURL}/attachkyctoclient/${type}`,
        formData,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
            // Set content type for FormData
          },
        }
      );

      alert(ticketResponse.data.message);
      //   window.location.reload();
      clearallinputs();
      setTypes("new");
    } catch (error) {
      console.log(error.message);
    }
  }
  const filteredData = ticketlist.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);
    const textMatch = searchData.every((term) => {
      return [
        "TICKETID",
        "PROJECTNAME",
        "UNITNUMBER",
        "CLIENTNAME",
        "CLIENTCODE",
        "DOCTYPE",
        "TICKETSTATUS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  console.log(filteredData);
  useEffect(() => {
    scrollToSection();
    setCreatedBy(loginAs);
    getnewProjectid();
    getListOfProjectIds();
    getClientcode();
    ListOfTickets();
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpenPdf = (ATTACHMENTs) => {
    // Open the PDF in a new window
    window.open(ATTACHMENTs, "_blank");
  };
  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between">
                <h1 className="header__of__page">
                  {menu ? menu : "Ticket Creation"}
                </h1>
                <button
                  className="px-3 py-1 h-9 bg-[#002d62] text-white rounded mx-2 m-2"
                  onClick={() => {
                    navigate("/UnattachedKYC");
                  }}
                >
                  {" "}
                  UNATTACHED KYC
                </button>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>

                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Client Name </label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(e) => {
                            if (e.value === "") {
                              alert("Kindly supply proper Client Name");
                              setselclientname("");
                            } else {
                              setselclientname(e.value);
                              handleOptionChange(e);
                            }
                          }}
                          value={
                            selclientname
                              ? { label: selclientname, value: selclientname }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            ...client.map((item) => ({
                              value: item.CLIENTNAME,
                              label: item.CLIENTNAME,
                            })),
                          ]}
                          isDisabled={
                            editflag === 1 || commonareaunitflag === 1
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">KYC TYPE </label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(selectedOption) => {
                            setselprojectname(selectedOption.value);
                            // handleProject(selectedOption);
                          }}
                          value={
                            selprojectname
                              ? {
                                  label: selprojectname,
                                  value: selprojectname,
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            ...listofprojectid.map((item) => ({
                              value: item.KYC,
                              label: item.KYC,
                            })),
                          ]}
                          isDisabled={editflag === 1}
                        />
                      </div>
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">KYC ATTACHMENT </label>
                    </p>
                    <div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        // accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={(e) => handleFileChange(e)}
                      />

                      <div className="flex gap-2 flex-wrap">
                        {imagePreviews.map((preview, index) => (
                          <div key={index} className="">
                            (
                            <embed
                              src={preview}
                              type="application/pdf"
                              width="300"
                              height="200"
                            />
                            )
                            <button
                              className="button-50"
                              onClick={() => removeImage(index)}
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                      <div>
                        {selectedImage && ( // Display the selected image
                          <embed
                            src={URL.createObjectURL(
                              new Blob([new Uint8Array(selectedImage.data)], {
                                type: "application/pdf",
                              })
                            )}
                            type="application/pdf"
                            width="300"
                            height="200"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                      seteditflag(0);
                      setcommonareaunitflag(0);
                      getnewProjectid();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!selclientname) {
                        alert("Kindly supply  Client name");
                      } else if (!selprojectname) {
                        alert("Kindly Supply Project Name");
                      } else {
                        await Savedetails();
                        // seteditflag(0);
                        // setcommonareaunitflag(0);
                        // clearallinputs();
                        // getnewProjectid();
                        // getListOfProjectIds();
                        // getClientcode();
                        // ListOfTickets();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>

      <main className="lease__bothfilte">
        <div className="searc__lease">
          <p>Search:</p>
          <input
            className="w-[400px] ml-[10px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          {/* <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
          <p>Common Area Or Unit:</p>
          <div className="w-[200px]">
            {" "}
            <Select
              styles={customStylesselect}
              onChange={(e) => {
                if (e.value === "") {
                  alert("Kindly Supply proper Status");
                  setstatus("");
                } else {
                  setstatus(e.value);
                }
              }}
              value={
                statuss
                  ? { label: statuss, value: statuss }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                { value: "COMMON AREA", label: "COMMON AREA" },
                { value: "UNIT", label: "UNIT" },
              ]}
            />
          </div> */}
        </div>
      </main>

      <div className="service_detials ">
        <table className="table_1">
          <thead>
            <tr>
              <td>CLIENT NAME</td>
              <td>CLIENT CODE</td>
              <td>DOCUMENT TYPE</td>
              <td>ATTACHMENT</td>
              <td>EDIT</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                style={{
                  textTransform: "uppercase",
                }}
              >
                <td>{item.CLIENTNAME ? item.CLIENTNAME : "NA"}</td>
                <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td>
                <td>{item.DOCTYPE ? item.DOCTYPE : "NA"}</td>

                <td className="w-[339px] h-[100px]  overflow-hidden">
                  {isExpanded ? (
                    <iframe src={item?.ATTACHMENT} title="PDF Document" />
                  ) : (
                    <>
                      <embed
                        src={item?.ATTACHMENT}
                        type="application/pdf"
                        width="300px"
                        height="100px"
                      />
                      <button
                        onClick={() => {
                          handleOpenPdf(item?.ATTACHMENT);
                        }}
                        className="bg-green-100 p-1 rounded my-1"
                      >
                        <strong>expand</strong>
                      </button>
                    </>
                  )}
                </td>

                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    FetchTableValuesInFields(item);
                    scrollToSection();
                    setInitialJobStatus(false);
                  }}
                >
                  <BiSolidEditAlt />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TENANTKYC;
