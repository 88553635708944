import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Select from "react-select";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function CaptureMeterReading() {
  const location = useLocation();
  console.log(location.state);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [readingNo, setreadingNo] = useState(
    location.state
      ? location.state.readingNo
        ? location.state.readingNo
        : ""
      : ""
  );
  const userState = useSelector((state) => state.changeTheState?.user);
  const [selprojectname, setselprojectname] = useState(
    location.state
      ? location.state.projectnames
        ? location.state.projectnames
        : location.state.projectname
      : ""
  );
  const [selectedpid, setselectedpid] = useState(
    location.state ? location.state.projectid : ""
  );
  const [selecteduno, setselecteduno] = useState(
    location.state ? location.state.unitnumber : ""
  );
  const [selunitid, setselunitid] = useState(
    location.state ? location.state.unitid : ""
  );
  const [subunitid, setsubunitid] = useState(
    location.state ? location.state.subunitid : ""
  );
  const [subunitno, setsubunitno] = useState(
    location.state ? location.state.subunitno : ""
  );
  const [leaseno, setleaseno] = useState(
    location.state ? location.state.leasenumber : ""
  );
  const [typeflag, settypeflag] = useState(
    location.state
      ? location.state.typeofreading
        ? location.state.typeofreading === "ENTRY"
          ? 1
          : 0
        : ""
      : ""
  );

  const [meterNo, setMeterNo] = useState(
    location.state ? (location.state.meterNo ? location.state.meterNo : "") : ""
  );
  const [readingValue, setreadingValue] = useState(
    location.state
      ? location.state.entryvalue
        ? location.state.entryvalue
        : ""
      : ""
  );
  const [dateValue, setDateValue] = useState(
    // location.state
    //   ? location.state.entrydate
    //     ? location.state.entrydate.slice(0, 10)
    //     : ""
    //   :
    new Date().toISOString().split("T")[0]
  );
  const [timeValue, setTimeValue] = useState(
    location.state
      ? location.state.entrytime
        ? location.state.entrytime.slice(11, 19)
        : ""
      : ""
  );
  const [readingValueend, setreadingValueend] = useState(0);
  const [dateValueend, setDateValueend] = useState("");
  const [timeValueend, setTimeValueend] = useState("");
  const [listElectricityBill, setListElectricityBill] = useState([]);
  const [search, setSearch] = useState("");
  const [tableflag, settableflag] = useState("");
  const [isloading, setisloading] = useState(true);
  const [startdatesort, setStartdatesort] = useState("");
  const [enddatesort, setEnddatesort] = useState("");
  const navigate = useNavigate();
  const filteredData = listElectricityBill.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const Startmonthreadingdate = item.READINGDATEONENTRY;

    if (searchData.length === 0 && !startdatesort && !enddatesort) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "READINGNO",
        "METERNO",
        "LEASENO",
        "READINGVALUEONENTRY",
        "PROJECTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort || Startmonthreadingdate >= startdatesort) &&
      (!enddatesort || Startmonthreadingdate <= enddatesort);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    getReadingNo();
    scrollToSection();
    fetchmeternumberforlease();
    fetchElcticData();
  }, []);

  function fetchElcticData() {
    axios
      .get(`${BASEURL}/getleasemeterreadinglist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setListElectricityBill(response.data);
        setisloading(false);
      })
      .catch((err) => {
        alert("Server error");
        setisloading(false);
      });
  }

  function fetchmeternumberforlease() {
    console.log(location.state.unitnumber);
    if (location.state.meterNo) {
      setMeterNo(location.state.meterNo);
    } else {
      axios
        .post(
          `${BASEURL}/fetchelectricitymeterfromunitid`,
          {
            UNITNO: location.state ? location.state.unitnumber : "",
            PROJECTID: location.state ? location.state.projectid : "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          console.log(response.data[0]);
          setMeterNo(response.data[0]?.ELECTRICITYMETER);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function getReadingNo() {
    if (location.state.readingNo) {
      setreadingNo(location.state.readingNo);
    } else {
      axios
        .get(`${BASEURL}/getnewreadingnumberforpropertyleases`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        })
        .then((response) => {
          setreadingNo(response.data[0].NEXTNO);
        })
        .catch((err) => {
          alert("Server error");
        });
    }
  }
  console.log(readingValue);
  console.log("Saving Meter Reading...");
  console.log("TYPEOFREADING:", typeflag === 1 ? "ENTRY" : "EXIT");
  console.log("READINGNO:", readingNo);
  console.log("PROJECTNO:", selectedpid);
  console.log("SUBUNITNO:", subunitid);
  console.log("UNITNO:", selecteduno);
  console.log("METERNO:", meterNo);
  console.log("LEASENO:", leaseno);
  console.log("READINGDATEONENTRY:", dateValue);
  console.log("READINGTIMEONENTRY:", timeValue);
  console.log("READINGVALUEONENTRY:", readingValue);
  console.log(
    "CREATEDBYONENTRY:",
    userState.emailId.split("@")[0].toUpperCase()
  );
  console.log("READINGDATEONEXIT:", dateValueend);
  console.log("READINGTIMEONEXIT:", timeValueend);
  console.log("READINGVALUEONEXIT:", readingValueend);
  console.log(
    "CREATEDBYONEXIT:",
    userState.emailId.split("@")[0].toUpperCase()
  );

  async function saveMeterReading() {
    try {
      const response = await axios.post(
        `${BASEURL}/savemeterreadingforleases`,
        {
          TYPEOFREADING: typeflag === 1 ? "ENTRY" : "EXIT",
          READINGNO: readingNo,
          PROJECTNO: selectedpid,
          SUBUNITNO: subunitid,
          UNITNO: selecteduno,
          METERNO: meterNo,
          LEASENO: leaseno,
          READINGDATEONENTRY: dateValue,
          READINGTIMEONENTRY: timeValue,
          READINGVALUEONENTRY: readingValue,
          CREATEDBYONENTRY: userState.emailId.split("@")[0].toUpperCase(),
          READINGDATEONEXIT: dateValueend,
          READINGTIMEONEXIT: timeValueend,
          READINGVALUEONEXIT: readingValueend,
          CREATEDBYONEXIT: userState.emailId.split("@")[0].toUpperCase(),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (err) {
      console.log(err);
      alert(err.response.data.message);
    }
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const FetchTableValuesInFields = (item) => {
    settypeflag(0);
    settableflag(1);
    setreadingNo(item.READINGNO);
    setselectedpid(item.PROPERTYNO);
    setselprojectname(item.PROJECTNAME);
    setsubunitid(item.SUBUNIT);
    setsubunitno(item.SUBUNITNO);
    setselunitid(item.UNITID);
    console.log(item.UNITID);
    setselecteduno(item.UNITNUMBER);
    setMeterNo(item.METERNO);
    setleaseno(item.LEASENO);
    setDateValue(
      item.READINGDATEONENTRY ? item.READINGDATEONENTRY.slice(0, 10) : ""
    );
    setTimeValue(
      item.READINGTIMEONENTRY ? item.READINGTIMEONENTRY.slice(11, 19) : ""
    );
    setreadingValue(item.READINGVALUEONENTRY);
    setDateValueend(
      item.READINGDATEONEXIT ? item.READINGDATEONEXIT.slice(0, 10) : ""
    );
    setTimeValueend(
      item.READINGTIMEONEXIT ? item.READINGTIMEONEXIT.slice(11, 19) : ""
    );
    setreadingValueend(item.READINGVALUEONEXIT);
  };

  const clearallinputs = () => {
    getReadingNo();
    setreadingValue(
      location.state
        ? location.state.entryvalue
          ? location.state.entryvalue
          : ""
        : ""
    );
    setMeterNo(
      location.state
        ? location.state.meterNo
          ? location.state.meterNo
          : ""
        : ""
    );
    setDateValue(
      location.state
        ? location.state.entrydate
          ? location.state.entrydate.slice(0, 10)
          : ""
        : ""
    );
    setTimeValue(
      location.state
        ? location.state.entrytime
          ? location.state.entrytime.slice(11, 19)
          : ""
        : ""
    );
    setselprojectname(
      location.state
        ? location.state.projectnames
          ? location.state.projectnames
          : location.state.projectname
        : ""
    );
    setselectedpid(location.state ? location.state.projectid : "");
    setselecteduno(location.state ? location.state.unitnumber : "");
    setselunitid(location.state ? location.state.unitid : "");
    setsubunitid(location.state ? location.state.subunitid : "");
    setsubunitno(location.state ? location.state.subunitno : "");
    setleaseno(location.state ? location.state.leasenumber : "");
    setDateValueend("");
    setTimeValueend("");
    setreadingValueend("");
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="elctric__page">
      <MenuNavbar />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main flex justify-between items-center">
              <h1 className="header__of__page">Capture Meter Reading</h1>
              <button
                onClick={() => {
                  navigate("/PropertyUnitLeases");
                }}
                className="button"
              >
                Back
              </button>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p className="w-[200px] mr-2 ">
                    <label className="garage_id_ ">Reading Number </label>
                  </p>
                  <div
                    className="text-red-600 font-bold "
                    disabled={tableflag === 1}
                  >
                    {readingNo}
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_ ">Project Names</label>
                  </p>
                  <div>
                    <input
                      value={selprojectname}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Unit Number</label>
                  </p>
                  <div>
                    <input
                      value={selecteduno}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Sub Unit Number</label>
                  </p>
                  <div>
                    <input
                      value={subunitno}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Lease Number</label>
                  </p>
                  <div>
                    <input
                      value={leaseno}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Meter Number</label>
                  </p>
                  <div>
                    <input
                      value={meterNo}
                      type="text"
                      readOnly
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Entry Date</label>
                  </p>
                  <div>
                    <input
                      value={dateValue}
                      type="date"
                      onChange={(e) => {
                        setDateValue(e.target.value);
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Entry Time</label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={timeValue}
                      type="time"
                      onChange={(e) => {
                        setTimeValue(e.target.value);
                      }}
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Entry Reading value</label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={readingValue}
                      type="number"
                      onChange={(e) => {
                        setreadingValue(e.target.value);
                      }}
                      disabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Exit Date</label>
                  </p>
                  <div>
                    <input
                      value={dateValueend}
                      type="date"
                      onChange={(e) => {
                        setDateValueend(e.target.value);
                      }}
                      hidden={typeflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Exit Time </label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={timeValueend}
                      type="time"
                      onChange={(e) => {
                        setTimeValueend(e.target.value);
                      }}
                      hidden={typeflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Exit Reading value </label>
                  </p>
                  <div>
                    {" "}
                    <input
                      value={readingValueend}
                      type="text"
                      onChange={(e) => {
                        setreadingValueend(e.target.value);
                        console.log(e.target.value);
                        console.log(readingValueend);
                      }}
                      hidden={typeflag === 1}
                    />
                  </div>
                </div>
              </aside>

              <div className="savebutton">
                <button
                  className="buttonunit mr-2"
                  onClick={() => {
                    clearallinputs();
                    settableflag(0);
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit mr-4"
                  onClick={async () => {
                    if (!selprojectname) {
                      alert("Kindly Supply Project Name");
                    } else if (!subunitno) {
                      alert("Kindly Supply Sub Unit Number");
                    } else if (!leaseno) {
                      alert("Kindly Supply Lease Number");
                    } else if (!meterNo) {
                      alert("Kindly Supply Meter Number");
                    } else if (!dateValue) {
                      alert("Kindly Supply Date Value");
                    } else if (!timeValue) {
                      alert("Kindly Supply Time Value");
                    } else if (!readingValue) {
                      alert("Kindly Supply Reading Value");
                    } else {
                      clearallinputs();
                      settableflag(0);
                      await saveMeterReading();
                      fetchElcticData();
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <main className="lease__bothfilte mx-[10px]">
        <div className="searc__lease">
          <p>Search:</p>
          <input
            className="  w-[350px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
        </div>
        <aside className="startand__enddate__lease ">
          <div>
            <p>
              <label className="">Start date</label>
            </p>
            <div>
              <input
                required
                className="input_1"
                type="date"
                value={startdatesort.split("T")[0]}
                onChange={(e) => setStartdatesort(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p>
              <label className="">End Date</label>
            </p>
            <div>
              <input
                className="input_1"
                type="date"
                required
                value={enddatesort.split("T")[0]}
                onChange={(e) => setEnddatesort(e.target.value)}
              />
            </div>
          </div>
        </aside>
      </main>

      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>PROJECT NAME</td>
              <td style={{ textAlign: "right" }}>READING NO</td>
              <td style={{ textAlign: "right" }}>UNIT NUMBER</td>
              <td style={{ textAlign: "right" }}>SUB UNIT NUMBER</td>
              <td style={{ textAlign: "right" }}>METER NO</td>
              <td style={{ textAlign: "right" }}>LEASE NO</td>
              <td>ENTRY DATE</td>
              <td style={{ textAlign: "right" }}>ENTRY READING VALUE</td>
              <td>EXIT DATE</td>
              <td style={{ textAlign: "right" }}>EXIT READING VALUE</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right" }}>
                    {item.READINGNO ? item.READINGNO : "NA"}
                  </td>
                  <td className="w-[100px] text-right ">
                    {item.UNITNUMBER ? item.UNITNUMBER : ""}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.SUBUNITNO ? item.SUBUNITNO : "NA"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.METERNO ? item.METERNO : "NA"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.LEASENO ? item.LEASENO : "NA"}
                  </td>
                  <td>
                    {item.READINGDATEONENTRY
                      ? moment(item.READINGDATEONENTRY).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.READINGVALUEONENTRY ? item.READINGVALUEONENTRY : "NA"}
                  </td>
                  <td>
                    {item.READINGDATEONEXIT
                      ? moment(item.READINGDATEONEXIT).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.READINGVALUEONEXIT ? item.READINGVALUEONEXIT : "NA"}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      //   clearallinputs();
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <></>
    </div>
  );
}

export default CaptureMeterReading;
