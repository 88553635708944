import React from "react";
import "./ClientLandingPage.css";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar/ClientNavbar";
import Employee from "../../Images/successful-employee.jpg";
import logo_email from "../../Images/logo_email.jpg";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { FaMoneyBillWave, FaRegHandPointRight } from "react-icons/fa";
import { SiWebmoney, SiFastapi } from "react-icons/si";
import { GrShieldSecurity } from "react-icons/gr";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Footer from "../../Components/Footer/Footer";
const LandingPage = () => {
  return (
    <div>
      <Navbar />

      <div id="About" className="mx-[100px] my-[50px] flex justify-between">
        <section>
          <p className="text-[35px] font-[500]">
            Easy <span className="text-blue-800">Payment</span> <br />
            <span className="text-blue-800"> Solution</span> For Your <br />{" "}
            Financial With <span className="text-blue-800"> EazyPay</span>.
          </p>
          <p className="my-2">
            Revolutionize your financial experience with Easy Payment, <br />{" "}
            offering transactions and personalized solutions for ultimate
            convenience.
          </p>
          <div>
            <Link to="/login">
              {" "}
              <button className="w-[120px] mr-2 my-3 bg-[#002d62] rounded py-2 text-white font-bold shadow-xl">
                Get Started
              </button>
            </Link>
            <button className="w-[120px] ml-2 my-3 bg-white-600 rounded py-2 text-black font-bold border-[2px] border-double border-gray-500 shadow-l">
              Mobile App
            </button>
          </div>
        </section>
        <section>
          {" "}
          <picture>
            <img
              src={Employee}
              height={400}
              width={400}
              alt=""
              className="rounded-tl-[25%]"
            />
          </picture>
        </section>
      </div>
      <div
        id="Product"
        className="rounded mx-[100px] bg-green-200 p-5 flex justify-between"
      >
        <div>
          <p className="text-[24px] font-bold  text-blue-800">
            <p> You can now view your statements </p>
            <div className="flex items-center gap-3">
              <p> & pay your Bills through </p>
              <p>
                {" "}
                <FaRegHandPointRight />
              </p>
            </div>
          </p>
          <p className="font-bold text-blue-800">In just few Clicks..</p>
        </div>
        <section className="flex gap-8 cursor-pointer">
          <aside className="h-[80px] w-[100px] gap-2 font-bold bg-white rounded flex justify-center items-center flex-col shadow-xl">
            <p className=" text-[25px]">
              {" "}
              <BsCreditCard2FrontFill />
            </p>
            <p> Credit Card</p>
          </aside>
          <aside className="h-[80px] w-[100px] gap-2 font-bold bg-white rounded flex justify-center items-center flex-col shadow-xl">
            <p className=" text-[25px]">
              {" "}
              <FaMoneyBillWave />
            </p>
            <p>Pesa Pal</p>
          </aside>
          <aside className="h-[80px] w-[100px] gap-2 font-bold bg-white rounded flex justify-center items-center flex-col shadow-xl">
            <p className=" text-[25px]">
              <SiWebmoney />
            </p>{" "}
            <p> MPESA</p>
          </aside>
        </section>
      </div>
      <div
        id="Solution"
        className="mx-[120px] mt-[100px] font-bold flex  justify-between items-center"
      >
        <aside>
          {" "}
          <p className="text-[25px]">Why Choose Tech23 ?</p>
          <p>Keeping you connected... Keeping you informed...</p>
        </aside>
        <picture>
          <img src={logo_email} alt="" />
        </picture>
      </div>
      <div className="mx-[100px] my-[20px] flex justify-between">
        <section className="h-[200px] w-[300px] p-4 fit-content rounded shadow-xl m-5 bg-gradient-to-r from-green-100 to-blue-100 hover:from-green-200 hover:to-blue-200">
          <header className="h-[30px] font-bold text-center text-[20px] flex justify-center items-center gap-2">
            <p>
              {" "}
              <GrShieldSecurity />{" "}
            </p>
            <p>Security &nbsp;</p>
          </header>
          <p
            style={{
              textAlign: "justify",
              textJustify: "distribute-all-lines",
            }}
          >
            Revolutionize your financial experience with Easy Payment, offering
            seamless transactions and personalized solutions for ultimate
            convenience.
          </p>
        </section>
        <section className="h-[200px] w-[300px] p-4 fit-content rounded shadow-xl m-5 bg-gradient-to-r from-green-100 to-blue-100 hover:from-green-200 hover:to-blue-200">
          <header className="h-[30px] font-bold text-center text-[20px] flex justify-center items-center gap-2">
            <p>
              {" "}
              <AiFillSafetyCertificate />{" "}
            </p>
            <p> Confidentiality &nbsp;</p>
          </header>
          <p
            style={{
              textAlign: "justify",
              textJustify: "distribute-all-lines",
            }}
          >
            Your information is kept strictly confidential, with strict policies
            in place to ensure your privacy and maintain trust.
          </p>
        </section>
        <section className="h-[200px] w-[300px] p-4 fit-content rounded shadow-xl m-5 bg-gradient-to-r from-green-100 to-blue-100 hover:from-green-200 hover:to-blue-200">
          <header className="h-[30px] font-bold text-center text-[20px] flex justify-center items-center gap-2">
            <p>
              <SiFastapi />
            </p>
            <p> Quick &nbsp;</p>
          </header>
          <p
            style={{
              textAlign: "justify",
              textJustify: "distribute-all-lines",
            }}
          >
            Experience lightning-fast transactions and responses, ensuring you
            can complete tasks efficiently and without unnecessary delays.
          </p>
        </section>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default LandingPage;
