import React, { useState, useEffect, useRef } from "react";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import "../PropertyManagementCss/ProjectMaster.css";
import { BiSolidEditAlt } from "react-icons/bi";
import { Country, City } from "country-state-city";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
import moment from "moment";

function ServiceProviderSetup() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const countries = Country.getAllCountries();
  const [date, setDate] = useState(new Date().toISOString());
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const [providerId, setProviderId] = useState("");
  const [providerName, setProviderName] = useState("");
  const [providerMobile, setProviderMobile] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [providerGeoLocation, setProviderGeoLocation] = useState("");
  const [providerAddress, setProviderAddress] = useState("");
  const [providerNotes, setProviderNotes] = useState("");
  const [selSpecialization, setSelSpecialization] = useState("");
  const [listOfSpecialisation, setListOfSpecialization] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",
    libraries: ["places"],
  });

  const searchBoxRef = useRef(null);

  const onSearchBoxLoad = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places.length > 0) {
        setProviderAddress(places[0].formatted_address);
        const location = places[0].geometry.location;
        setProviderGeoLocation(location.lat() + " , " + location.lng());
      }
    }
  };

  const filteredData = tableData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.PROVIDERREGISTERDATE;
    const eDate = item.PROVIDERREGISTERDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        "PROVIDERADDRESS",
        "PROVIDERCITY",
        "PROVIDERCOUNTRY",
        "PROVIDEREMAIL",
        "PROVIDERGEOLOCATION",
        "PROVIDERID",
        "PROVIDERMOBILE",
        "PROVIDERNAME",
        "PROVIDERNOTES",
        "SPECIALIZATION",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    scrollToSection();
    getSpecialization();
    getnewProviderId();
    getServiceProvidersList();
  }, []);

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "280px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      zIndex: "50",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const FetchTableValuesInFields = (item) => {
    setProviderAddress(item.PROVIDERADDRESS);
    setSelectedCity(item.PROVIDERCITY);
    setSelectedCountry(item.PROVIDERCOUNTRY);
    setProviderEmail(item.PROVIDEREMAIL);
    setProviderGeoLocation(item.PROVIDERGEOLOCATION);
    setProviderId(item.PROVIDERID);
    setProviderMobile(item.PROVIDERMOBILE);
    setProviderName(item.PROVIDERNAME);
    setProviderNotes(item.PROVIDERNOTES);
    setDate(item.PROVIDERREGISTERDATE);
    setSelSpecialization(item.SPECIALIZATION);
  };

  const getnewProviderId = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/getNewServiceProviderNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setProviderId(response?.data[0]?.NEXTNO);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching the latest provider Id ", error.message);
      });
  };

  const getSpecialization = () => {
    axios
      .get(`${BASEURL}/getSpecializations`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setListOfSpecialization(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of specialization", error);
      });
  };

  const getServiceProvidersList = () => {
    axios
      .get(`${BASEURL}/getServiceProvidersList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setTableData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching Service Provider data", error);
      });
  };

  async function Savedetails1() {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/saveserviceProvider`,
        {
          PROVIDERID: providerId,
          SPECIALIZATION: selSpecialization,
          PROVIDERNAME: providerName,
          PROVIDERMOBILE: providerMobile,
          PROVIDERADDRESS: providerAddress,
          PROVIDERGEOLOCATION: providerGeoLocation,
          PROVIDEREMAIL: providerEmail,
          PROVIDERCITY: selectedCity,
          PROVIDERCOUNTRY: selectedCountry,
          PROVIDERREGISTERDATE: moment(date).format("DD/MM/YYYY"),
          PROVIDERACTIVE: 1,
          PROVIDERNOTES: providerNotes,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert(error.response.data.message);
    }
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.label);

    if (selectedOption) {
      const selectedCountryIso = selectedOption.value;

      const countryCities = City.getCitiesOfCountry(selectedCountryIso);
      setCities(
        countryCities.map((city) => ({ value: city.name, label: city.name }))
      );
      setSelectedCity("");
    } else {
      setCities([]);
      setSelectedCity("");
    }
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption.label);
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {" "}
                  {menu ? menu : "Project Master"}
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER ID</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={providerId ? providerId : "N/A"}
                        readOnly
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER NAME</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={providerName}
                        onChange={(e) => {
                          setProviderName(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER MOBILE</label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={providerMobile}
                        onChange={(e) => {
                          setProviderMobile(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">SPECIALIZATION</label>
                    </p>
                    <div style={{ zIndex: 200 }}>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (e.value === "") {
                            alert("Kindly Supply proper project type");
                            setSelSpecialization("");
                          } else {
                            setSelSpecialization(e.value);
                          }
                        }}
                        value={
                          selSpecialization
                            ? {
                                label: selSpecialization,
                                value: selSpecialization,
                              }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...listOfSpecialisation.map((item) => ({
                            value: item.SPECNAME,
                            label: item.SPECNAME,
                          })),
                        ]}
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER ADDRESS </label>
                    </p>
                    <div>
                      {isLoaded ? (
                        <StandaloneSearchBox
                          onLoad={onSearchBoxLoad}
                          onPlacesChanged={onPlacesChanged}
                        >
                          <input
                            className="input_1"
                            type="text"
                            placeholder="Enter your location"
                            style={{
                              boxSizing: `border-box`,
                              border: `1px solid transparent`,
                              width: `300px`,
                              height: `64px`,
                              padding: `0 12px`,
                              borderRadius: `3px`,
                              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                              fontSize: `14px`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                              position: "relative",
                              margin: "20px auto",
                              display: "block",
                            }}
                          />
                        </StandaloneSearchBox>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">
                        PROVIDER GEOLOCATION{" "}
                      </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={providerGeoLocation}
                        onChange={(e) => {
                          setProviderGeoLocation(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER EMAIL </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={providerEmail}
                        onChange={(e) => {
                          setProviderEmail(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER NOTES </label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={providerNotes}
                        onChange={(e) => {
                          setProviderNotes(e.target.value);
                        }}
                      ></input>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage ProjectMaster__width">
                  <div>
                    <p>
                      <label className="garage_id_">PROVIDER Country </label>
                    </p>
                    <div style={{ zIndex: 100 }}>
                      <Select
                        name="COUNTRY"
                        required
                        styles={customStyless}
                        value={
                          selectedCountry
                            ? { label: selectedCountry, value: selectedCountry }
                            : { label: "", value: "" }
                        }
                        onChange={handleCountryChange}
                        options={countries.map((country) => ({
                          value: country.isoCode,
                          label: country.name,
                        }))}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_"> PROVIDER City </label>
                    </p>
                    <div style={{ zIndex: 50 }}>
                      <Select
                        name="CITY"
                        required
                        styles={customStyless}
                        value={
                          selectedCity
                            ? { label: selectedCity, value: selectedCity }
                            : { label: "", value: "" }
                        }
                        onChange={handleCityChange}
                        options={cities}
                        isDisabled={cities.length === 0}
                      />
                    </div>
                  </div>
                </aside>

                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!providerId) {
                        alert("Kindly supply Provider id");
                      } else if (!providerName) {
                        alert("Kindly supply Provider Name");
                      } else if (!providerMobile) {
                        alert("Kindly supply Provider Mobile");
                      } else if (!selSpecialization) {
                        alert("Kindly supply Provider Specialization");
                      } else if (!providerAddress) {
                        alert("Kindly supply Provider Address");
                      } else if (!providerEmail) {
                        alert("Kindly supply Provider Email");
                      } else if (!providerNotes) {
                        alert("Kindly supply Provider Notes");
                      } else if (!selectedCountry) {
                        alert("Kindly supply Provider Country");
                      } else if (!selectedCity) {
                        alert("Kindly supply Provider City");
                      } else {
                        await Savedetails1();
                        window.location.reload();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>

      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
        <p>Start Date:</p>
        <input
          className="input_1"
          type="date"
          value={startdate.split("T")[0]}
          onChange={(e) => {
            setstartdate(e.target.value);
          }}
        ></input>
        <p>End Date:</p>
        <input
          className="input_1"
          type="date"
          value={enddate.split("T")[0]}
          onChange={(e) => {
            setEnddate(e.target.value);
          }}
        ></input>
      </div>
      <div className="service_detials">
        <table className="table_1 " style={{ fontSize: "12px" }}>
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td>PROVIDER Id</td>
              <td>PROVIDER Name</td>
              <td>PROVIDER SPECIALIZATION</td>
              <td>PROVIDER Mobile</td>
              <td>PROVIDER email</td>
              <td>PROVIDER date</td>
              <td>PROVIDER NOTES</td>
              <td>PROVIDER ADDRESS</td>
              <td>PROVIDER geolocation</td>
              <td>PROVIDER Country</td>
              <td>PROVIDER City</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr style={{ textTransform: "uppercase", fontSize: "12px" }}>
                  <td style={{ paddingLeft: "54px" }}>
                    {item.PROVIDERID ? item.PROVIDERID : "NA"}
                  </td>
                  <td>{item.PROVIDERNAME ? item.PROVIDERNAME : "NA"}</td>
                  <td>{item.SPECIALIZATION ? item.SPECIALIZATION : "NA"}</td>
                  <td>{item.PROVIDERMOBILE ? item.PROVIDERMOBILE : "NA"}</td>
                  <td>{item.PROVIDEREMAIL ? item.PROVIDEREMAIL : "NA"}</td>
                  <td>
                    {item.PROVIDERREGISTERDATE
                      ? moment(item.PROVIDERREGISTERDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>{item.PROVIDERNOTES ? item.PROVIDERNOTES : "NA"}</td>
                  <td>{item.PROVIDERADDRESS ? item.PROVIDERADDRESS : "NA"}</td>
                  <td>
                    {item.PROVIDERGEOLOCATION ? item.PROVIDERGEOLOCATION : "NA"}
                  </td>

                  <td>{item.PROVIDERCOUNTRY ? item.PROVIDERCOUNTRY : "NA"}</td>
                  <td>{item.PROVIDERCITY ? item.PROVIDERCITY : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ServiceProviderSetup;
