import { React, useState, useEffect, useRef } from "react";
import Navbar1 from "../../PropertyManagement/PropertyManagementJS/MenuNavbar";
import axios from "axios";
import "../../PropertyManagement/PropertyManagementCss/ProjectMaster.css";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const MySales = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [selFilter, setSelFilter] = useState("");
  const showDates = useRef(false);
  const showProperty = useRef(false);
  const showTable = useRef(false);
  const showTable1 = useRef(false);
  const [selProperty, setSelProperty] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [grandTotal, setGrandTotal] = useState("");

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "240px",
      maxWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      zIndex: "50",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const filteredData = tableData.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.REQUESTDATE;
    const eDate = item.REQUESTDATE;

    if (searchData.length === 0 && !startdate && !enddate && !selProperty) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        "REQUESTID",
        "FROMOPROPERTYNAME",
        "PROPERTYUNITID",
        "TENANTNAME",
        "REQUESTSTATUS",
        "STATUSREASON",
        "STATUSUPDATEDBY",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    const propertyMatch =
      !selProperty ||
      !selProperty.length ||
      selProperty.toLowerCase() === item.FROMOPROPERTYNAME.toLowerCase();

    return textMatch && dateMatch && propertyMatch;
  });

  useEffect(() => {
    ListOfProjectIds();
    getServiceRequests();
  }, []);

  useEffect(() => {
    let total = 0;
    filteredData.forEach((item) => {
      total += Number(item.PAYMENTSTATUSAMOUNT);
    });
    setGrandTotal(total);
  }, [filteredData]);

  const ListOfProjectIds = () => {
    setisloading(true);
    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setProjectList(response?.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching the List Of Projects", error.message);
      });
  };

  const getServiceRequests = () => {
    axios
      .get(`${BASEURL}/getServiceRequests?EMAILADDRESS=${username}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response?.data);
        const filteredData = response?.data.filter(
          (item) =>
            item.STATUSUPDATEDBYCLIENTORPROVIDER !== "TENANT" &&
            item.REQUESTSTATUS !== "CANCEL"
        );
        console.log(filteredData);

        setTableData(filteredData);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching the Service Requests", error.message);
      });
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <header className="header__of__main px-4">
        <h1 className="header__of__page"> {menu ? menu : "My Enqueries"}</h1>
      </header>
      <div className="flex gap-2 mt-2 ml-8 items-center">
        <h1>Filter By :</h1>
        <Select
          styles={customStyless}
          onChange={(e) => {
            if (e.value === "") {
              alert("Kindly Supply Proper Filter");
            } else {
              setSelFilter(e.value);
              if (e.value === "SALES BY DATE") {
                showDates.current = true;
                showProperty.current = false;
                showTable.current = true;
                showTable1.current = false;
              } else {
                showProperty.current = true;
                showDates.current = false;
                showTable.current = false;
              }
            }
          }}
          value={
            selFilter
              ? { value: selFilter, label: selFilter }
              : { value: "", label: "" }
          }
          options={[
            { value: "", label: "" },
            { value: "SALES BY DATE", label: "SALES BY DATE" },
            { value: "SALES BY PROPERTY", label: "SALES BY PROPERTY" },
          ]}
        />
      </div>

      {showDates.current === true && (
        <div className="lg:flex items-center ">
          <div className="searchhhhhh">
            <p>Search</p>
            <input
              className="input_1"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>{" "}
          </div>
          <div className="mx-8 lg:flex items-center gap-4">
            <p>Start Date:</p>
            <input
              className=""
              type="date"
              value={startdate.split("T")[0]}
              onChange={(e) => {
                setstartdate(e.target.value);
              }}
            ></input>
            <p>End Date:</p>
            <input
              className=""
              type="date"
              value={enddate.split("T")[0]}
              onChange={(e) => {
                setEnddate(e.target.value);
              }}
            ></input>
          </div>
        </div>
      )}

      {showProperty.current === true && (
        <div className="lg:flex items-center gap-3">
          <div className=" searchhhhhh ">
            <p>Search </p>
            <input
              className="input_1 h-[30px]"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>
          <p className="ml-8 lg:ml-0">Property Name :</p>
          <Select
            className="ml-8 lg:ml-0"
            styles={customStyless}
            onChange={(e) => {
              if (e.value === "") {
                setSelProperty("");
                showTable.current = false;
                showTable1.current = false;
              } else {
                setSelProperty(e.value);
                showTable.current = false;
                showTable1.current = true;
              }
            }}
            value={
              selProperty
                ? { value: selProperty, label: selProperty }
                : { value: "", label: "" }
            }
            options={[
              { value: "", label: "" },
              ...projectList.map((item) => ({
                value: item.PROJECTNAME,
                label: item.PROJECTNAME,
              })),
            ]}
          />
        </div>
      )}

      {showTable.current === true && (
        <div className="service_detials flex flex-col">
          <table className="table_1 " style={{ fontSize: "12px" }}>
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <td>REQUEST ID</td>
                {showDates.current === true ? <td>Property Name</td> : ""}
                <td>Unit Name</td>
                <td>Client Name</td>
                <td>REQUEST DATE & TIME</td>
                <td>Work Status</td>
                <td className="text-right">Payment Amount</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{ textTransform: "uppercase", fontSize: "12px" }}
                  >
                    <td style={{ paddingLeft: "54px" }}>
                      {item.REQUESTID ? item.REQUESTID : "NA"}
                    </td>
                    {showDates.current === true ? (
                      <td>
                        {item.FROMOPROPERTYNAME ? item.FROMOPROPERTYNAME : "NA"}
                      </td>
                    ) : (
                      ""
                    )}
                    <td>{item.PROPERTYUNITID ? item.PROPERTYUNITID : "NA"}</td>
                    <td>{item.TENANTNAME ? item.TENANTNAME : "NA"}</td>
                    <td>
                      {item.REQUESTDATE
                        ? moment(item.REQUESTDATE).format("DD/MM/YYYY HH:mm:ss")
                        : "NA"}
                    </td>
                    <td>{item.WORKSTATUS ? item.WORKSTATUS : "NA"}</td>

                    <td className="text-right pr-4">
                      {item.PAYMENTSTATUSAMOUNT
                        ? item.PAYMENTSTATUSAMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="w-full flex justify-end pr-8 gap-2">
            <p>Total Sales</p>
            <input
              className="w-32 h-7 rounded-md text-right pr-2"
              value={
                grandTotal
                  ? grandTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"
              }
            ></input>
          </div>
        </div>
      )}

      {showTable1.current === true && (
        <div className="service_detials flex flex-col">
          <table className="table_1 " style={{ fontSize: "12px" }}>
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <td>REQUEST ID</td>
                <td>Unit Name</td>
                <td>Client Name</td>
                <td>REQUEST DATE & TIME</td>
                <td>Work Status</td>
                <td className="text-right">Payment Amount</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{ textTransform: "uppercase", fontSize: "12px" }}
                  >
                    <td style={{ paddingLeft: "54px" }}>
                      {item.REQUESTID ? item.REQUESTID : "NA"}
                    </td>
                    <td>{item.PROPERTYUNITID ? item.PROPERTYUNITID : "NA"}</td>
                    <td>{item.TENANTNAME ? item.TENANTNAME : "NA"}</td>
                    <td>
                      {item.REQUESTDATE
                        ? moment(item.REQUESTDATE).format("DD/MM/YYYY HH:mm:ss")
                        : "NA"}
                    </td>
                    <td>{item.WORKSTATUS ? item.WORKSTATUS : "NA"}</td>

                    <td className="text-right pr-4">
                      {item.PAYMENTSTATUSAMOUNT
                        ? item.PAYMENTSTATUSAMOUNT.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="w-full flex justify-end pr-8 gap-2">
            <p>Total Sales</p>
            <input
              className="w-32 h-7 rounded-md text-right pr-2"
              value={
                grandTotal
                  ? grandTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"
              }
            ></input>
          </div>
        </div>
      )}
    </div>
  );
};

export default MySales;
