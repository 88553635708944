import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import jsPDF from "jspdf";
import Select from "react-select"; // Import react-select
import { Link, useNavigate } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { EmployeeMasterEdit } from "../../Redux/Action/EmployeeMasterEdit";

import MenuNavbar from "./MenuNavbar";

import Loading2 from "../../Components/Loading/Loading";

import { useDispatch, useSelector } from "react-redux";

const EMPLOYEEMASTERProcessed = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = window.sessionStorage.getItem("auth-token");
  const [search, setSearch] = useState("");
  const [listOfEmployees, setListOfEmployees] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [desigVal, setDesigVal] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null); // State to hold selected designation

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );

  useEffect(() => {
    masterData();
    masterDataDesignation();
  }, []);

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
  };

  const filteredData = listOfEmployees?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    console.log(listOfEmployees);
    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return [
          "EMPID",
          "EMPCODE",
          "EMPNAME",
          "JOINDATE",
          "NATIONALID",
          "TELEPHONE",
          "OVERTIME",
          "FREEZE",
          "DEPARTMENT",
          "DESIGNATION",
        ].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    const isDateRangeMatch =
      (!fromDateFilter ||
        new Date(item?.["JOINDATE"]) >= new Date(fromDateFilter)) &&
      (!toDateFilter || new Date(item?.["JOINDATE"]) <= new Date(toDateFilter));

    let isDesignationMatch = true;

    if (selectedDesignation?.value) {
      isDesignationMatch = item["DESIGNATION"] === selectedDesignation.value;
    }

    return (
      textMatch && (isDesignationMatch || selectedDesignation?.value === "")
    );
  });

  function masterData() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getemployees`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setListOfEmployees(response.data);
        console.log(response.data);
        setisloading(false);
      });
  }
  function masterDataDesignation() {
    axios
      .get(`${BASEURL}/getdesignations`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        // Transform the response data into options for the Select component
        const options = response.data.map((designation) => ({
          value: designation.OM_DESIGNATION_NAME,
          label: designation.OM_DESIGNATION_NAME,
        }));
        // Add a "Select All" option at the beginning
        options.unshift({ value: "", label: "Select All" });
        setDesigVal(options);
        console.log(options, "getdesignations");
      });
  }
  console.log(filteredData);

  if (isloading) {
    return <Loading2 />;
  }

  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
        <div>
          <header className="header__of__main lg:flex justify-between items-end px-3">
            <h1 className="header__of__page"> List Of Employees</h1>
            <button
              className="button-50 "
              onClick={() => {
                navigate("/EMPLOYEEMASTER");
              }}
            >
              Back
            </button>
          </header>
          <article>
            <div className="lg:flex items-center mx-[20px]">
              <p className="lg:mx-2 text-[16px]">Search</p>
              <input
                className="w-[300px] lg:mr-[50px] h-[30px]"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <p className="lg:mx-2 text-[16px]">Designation</p>
              <Select
                className="w-[400px] lg:mr-[50px] z-[500]"
                options={desigVal}
                placeholder="Select Designation"
                value={selectedDesignation}
                onChange={handleDesignationChange}
              />
            </div>

            <div className="service_detials">
              <table className="table_1 " style={{ fontSize: "12px" }}>
                <thead>
                  <tr>
                    {/* <td>EMPLOYEE ID</td> */}
                    <td>EMPLOYEE CODE</td>
                    <td>EMPLOYEE NAME</td>
                    <td>DATE JOINED</td>
                    <td>NATIONAL ID NO</td>
                    <td>TELEPHONE NO</td>
                    <td>OVERTIME</td>
                    <td>DESIGNATION</td>
                    <td>DEPARTMENT</td>
                    <td>EMPLOYEE FREEZE</td>
                    {/* <td>IMAGE</td> */}
                    <td>EDIT</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((value, index) => (
                      <tr key={index} className="table-row cursor-pointer">
                        <td className="text-right">
                          {value["EMPCODE"] || "N/A"}
                        </td>
                        <td>{value["EMPNAME"] || "N/A"}</td>
                        <td className="text-right">
                          {value["JOINDATE"]
                            ? value["JOINDATE"].slice(8, 10) +
                              "/" +
                              value["JOINDATE"].slice(5, 7) +
                              "/" +
                              value["JOINDATE"].slice(0, 4)
                            : "N/A"}
                        </td>
                        <td>{value["NATIONALID"] || "N/A"}</td>
                        <td className="text-right">
                          {value["TELEPHONE"] || "N/A"}
                        </td>
                        <td>{value["OVERTIME"] ? "YES" : "NO"}</td>
                        <td>{value["DESIGNATION"] || "N/A"}</td>
                        <td>{value["DEPARTMENT"] || "N/A"}</td>
                        <td>{value["FREEZE"] ? "YES" : "NO"}</td>
                        {/* <td>
                          {" "}
                          <img
                            className="p-1 w-full h-[80px]  aspect-w-3 aspect-h-2 object-contain rounded-lg shadow-md transition-transform duration-500 transform hover:scale-95 outline-2 outline-gray-400"
                            src={URL.createObjectURL(
                              new Blob(
                                [new Uint8Array(value.IMAGEBINARY?.data)],
                                {
                                  type: "image/jpeg",
                                }
                              )
                            )}
                            alt=""
                          />
                        </td> */}
                        <td
                          onClick={() => {
                            navigate("/EMPLOYEEMASTER");
                            dispatch(EmployeeMasterEdit(value));
                          }}
                        >
                          <BiSolidEditAlt />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="15">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default EMPLOYEEMASTERProcessed;
