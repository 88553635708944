import React, { useState, useEffect } from "react";
import Navbar from "../NavbarClient/ClientMenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
// import { FaSearch } from "react-icons/fa";
// import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { FaEdit } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import { Clientname } from "../../Redux/Action/UnapprovedReceipt";
import "../NavbarClient/Navbar.css";
import { FaSearch, FaFilePdf, FaFileExcel } from "react-icons/fa";

import moment from "moment";
import html2canvas from "html2canvas"; // Library to convert HTML to canvas
import jsPDF from "jspdf"; // Library to generate PDF
// import * as XLSX from 'xlsx';

function UnapprovedInvoicesSave() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [Startdatesort, setStartdatesort] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selectedInvoiceAmount, setSelectedInvoiceAmount] = useState(0);
  const state = useSelector((state) => state.UnapprovedReceiptred);
  console.log(state);
  const userState = useSelector((state) => state.changeTheState.user);
  console.log(userState);
  const [isloading, setisloading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  var clientname = state?.CLIENTNAME;
  var clientcode = state?.CLIENTCODE;
  var receiptno = state?.RECEIPTNO;
  var AMOUNT = state?.AMOUNT;

  var receiptno = state?.RECEIPTNO;
  function getListOfProjectIds() {
    console.log(clientname);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/getUnallocatedinvoices`,
        {
          CLIENTNAME: clientname,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofprojectid(response.data);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Project Idss:", error);
      });
  }
  useEffect(() => {
    getListOfProjectIds();
  }, []);
  const filteredData = listofprojectid.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const startDate = moment(Startdatesort).format("YYYY-MM-DD"); // Format start date

    if (searchData.length === 0 && !Startdatesort) {
      // Check if start date is not selected
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "DOCNO",
        "AMOUNT",
        "INVOICEDATE",
        "RECEIPTDATE",
        "SERVICENAME",
        "CLIENTNAME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return (
      textMatch &&
      (!Startdatesort ||
        moment(item.RECEIPTDATE).format("YYYY-MM-DD") === startDate)
    );
  });

  const handleCheckboxChange = (item) => {
    const isChecked = selectedItems.some(
      (selectedItem) =>
        selectedItem.DOCNO === item.DOCNO && selectedItem.AMOUNT === item.AMOUNT
    );

    if (isChecked) {
      // Remove the item if it's already checked
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) =>
            selectedItem.DOCNO !== item.DOCNO ||
            selectedItem.AMOUNT !== item.AMOUNT
        )
      );
    } else {
      // Add the item if it's not checked
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    }

    setSelectedItems((prevSelectedItems) => {
      const totalAmount = prevSelectedItems.reduce(
        (acc, curr) => acc + curr.AMOUNT,
        0
      );
      setSelectedInvoiceAmount(totalAmount);
      return prevSelectedItems;
    });
  };

  console.log(selectedItems);
  function HandleSave() {
    // Check if selected invoice amount is greater than allocated amount
    if (selectedInvoiceAmount > AMOUNT) {
      // Show alert message if selected invoice amount is greater than allocated amount
      alert(`Selected amount is greater than Receipt amount.`);

      // window.location.reload();
      return;
    }

    const promises = selectedItems.map((item) => {
      console.log({
        RECEIPTNO: receiptno,
        RECEIPTFORINCOME: item.SERVICENAME,
        RECEIPTAMOUNT: item.AMOUNT,
        RECEIPTINVOICENO: item.DOCNO,
      });

      return axios.post(`${BASEURL}/unallocatedInsert`, {
        RECEIPTNO: receiptno,
        RECEIPTFORINCOME: item.SERVICENAME,
        RECEIPTAMOUNT: item.AMOUNT,
        RECEIPTINVOICENO: item.DOCNO,
      });
    });

    Promise.all(promises)
      .then((responses) => {
        alert("All items saved successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error posting selected item details:", error);
      });
  }
  const handleExportPDF = () => {
    const doc = new jsPDF();
    let yPos = 30;

    // Add debug statement
    console.log("listofprojectid:", listofprojectid);

    // Text for Invoice title
    doc.setFontSize(17);
    doc.text("Invoice Document", 155, yPos);

    yPos += 20;

    // Text for Client name
    doc.setFontSize(12);
    doc.text(`Client Name: ${clientname ? clientname : "N/A"}`, 15, yPos);

    // Headers for invoice details
    yPos += 10;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text("Invoice Number", 20, yPos);
    doc.text("Invoice Date", 70, yPos);
    doc.text("Amount", 120, yPos);
    doc.text("Service Name", 160, yPos);

    // Draw horizontal line below the headers
    yPos += 5;
    doc.setLineWidth(0.5);
    doc.line(15, yPos, 190, yPos);

    // Loop through invoice data to populate the table
    listofprojectid.forEach((item) => {
      yPos += 10;
      console.log("item:", item); // Add debug statement

      // Check if DOCNO is a number and convert it to string if necessary
      const docNo =
        typeof item.DOCNO === "number"
          ? item.DOCNO.toString()
          : typeof item.DOCNO === "string"
          ? item.DOCNO
          : "NA";

      // Check if INVOICEDATE is a valid date and format it accordingly
      const invoiceDate = item.INVOICEDATE
        ? moment(item.INVOICEDATE, "YYYY-MM-DD").format("DD/MM/YYYY")
        : "NA";

      // Check if AMOUNT is a number and format it accordingly
      const amount =
        typeof item.AMOUNT === "number" && !isNaN(item.AMOUNT)
          ? item.AMOUNT.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "NA";

      // Check if SERVICENAME is a string
      const serviceName =
        typeof item.SERVICENAME === "string" ? item.SERVICENAME : "NA";

      // Print the values before passing them to doc.text
      console.log("docNo:", docNo);
      console.log("invoiceDate:", invoiceDate);
      console.log("amount:", amount);
      console.log("serviceName:", serviceName);

      // Use the processed values in doc.text
      doc.text(docNo, 20, yPos);
      doc.text(invoiceDate, 70, yPos);
      doc.text(amount, 120, yPos);
      doc.text(serviceName, 160, yPos);
    });

    // Save the PDF
    doc.save("invoice.pdf");
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div>
      {" "}
      <Navbar />
      <header className="header__of__main">
        <h1 className="header__of__page">{menu || "Allocation Invoices"}</h1>
      </header>
      <aside className="receipt_details">
        <p>
          <b>Receipt No:</b> <span style={{ color: "red" }}>{receiptno}</span>
        </p>

        <p>
          <b>Client Code:</b> <span style={{ color: "red" }}>{clientcode}</span>
        </p>
        <p>
          <b>Client Name:</b> <span style={{ color: "red" }}>{clientname}</span>
        </p>
        <p>
          <b>Receipt Amount:</b>{" "}
          <span style={{ color: "red" }}>
            {AMOUNT.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </p>
      </aside>
      <aside className="receipt_details_">
        <p style={{ marginRight: "40px" }}>
          <b>Selected Invoice Amount : </b>
          <input type="number" disabled value={selectedInvoiceAmount} />{" "}
        </p>
      </aside>
      <div className="lg:flex gap-2 mt-2 mb-0 items-center filter__no__search  lg:mx-10">
        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="search__icon">
            <FaSearch />
          </div>
        </div>
        <div>
          <button
            style={{ backgroundColor: "#2f2f5a", color: "white" }}
            className=" lg:flex  text-white p-1 rounded "
            onClick={HandleSave}
          >
            SAVE
          </button>
        </div>
        <div>
          {/* <button style={{ fontSize: "30px", marginLeft: "50px" }} onClick={handleExportPDF(listofprojectid)}><FaFilePdf /></button> */}
          <button
            className="lg:ml-2"
            style={{ fontSize: "30px", marginLeft: "50px" }}
            onClick={handleExportPDF}
          >
            <FaFilePdf />
          </button>
        </div>
        {/* <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div> */}
      </div>
      <div
        id="table-container"
        className="service_detials"
        style={{ marginTop: "0px" }}
      >
        <table
          className="table_1 "
          style={{
            fontSize: "12px",
            width: "70%",
            marginRight: "30%",
            marginLeft: "2%",
          }}
        >
          <thead>
            <tr style={{ textTransform: "uppercase" }}>
              <td></td>
              <td>INVOICE NUMBER</td>
              <td>INVOICE DATE</td>
              <td className="w-30 text-right">AMOUNT</td>
              <td>SERVICE NAME</td>
              {/* <td>ALLOCATED AMOUNT</td> */}
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} style={{ textTransform: "uppercase" }}>
                  <td className="w-10">
                    <input
                      className={{ width: "20px" }}
                      type="checkbox"
                      checked={selectedItems.some(
                        (selectedItem) =>
                          selectedItem.DOCNO === item.DOCNO &&
                          selectedItem.AMOUNT === item.AMOUNT
                      )}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </td>
                  <td className="w-36 text-right">
                    {item.DOCNO ? item.DOCNO : "NA"}
                  </td>

                  <td className="w-20">
                    {item.INVOICEDATE
                      ? moment(item.INVOICEDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td className="w-28 text-right">
                    {item.AMOUNT
                      ? item.AMOUNT.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "NA"}
                  </td>

                  <td>{item.SERVICENAME ? item.SERVICENAME : "NA"}</td>
                  {/* <td> {item.ALLOCATEDAMOUNT ? item.ALLOCATEDAMOUNT.toLocaleString() : "NA"}</td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center" }}>
                  No data Avialiable
                </td>
              </tr>
            )}
          </tbody>

          {/* <tbody>
            {listofprojectid.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.CLIENTCODE === item.CLIENTCODE
                        )}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </label>
                  </td>
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right", paddingRight: "50px" }}>
                    {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                  </td>
                  <td>{item.UNITNUMBER ? item.UNITNUMBER : "NA"}</td>
                  <td>
                    {item.LEASESTARTDATE
                      ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                      : "NA"}
                  </td>
                  <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                  <td>
                    {item.LEASEENDDATE
                      ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td>{item.DAYSCONSUMED ? item.DAYSCONSUMED : "NA"}</td>
                  <td>{item.METERREADING ? item.METERREADING : "NA"}</td>
                  <td>{item.RATEPERUNIT ? item.RATEPERUNIT : "NA"}</td>
                  <td>{item.TOTALDAYS ? item.TOTALDAYS : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {item.DAYSCONSUMED * item.RATEPERUNIT}
                  </td>
                </tr>
              );
            })}
          </tbody> */}
        </table>
      </div>
    </div>
  );
}

export default UnapprovedInvoicesSave;
