const initialState = {};
const EmployeeMasterEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EMPLOYEEMASTEREDIT":
      return action.data;

    default:
      return state;
  }
};

export default EmployeeMasterEditReducer;
