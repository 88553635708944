import doc_1 from "./images/doc_1.jpg";
import doc_2 from "./images/doc_2.jpg";
import doc_3 from "./images/doc_3.jpg";
import doc_4 from "./images/doc_4.jpg";
import doc_5 from "./images/doc_5.jpg";

export const countries = [
  {
    image: doc_1,
    title: "Brazil",
  },
  {
    image: doc_2,
    title: "China",
  },
  {
    image: doc_3,
    title: "France",
  },
  {
    image: doc_4,
    title: "Japan",
  },
  {
    image: doc_5,
    title: "Norway",
  },
];
