import React, { useState, useEffect } from "react";
import MenuNavbar from "./MenuNavbar";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
const Electricity = () => {
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(false);
  const [rateChangeValues, setRateChangeValues] = useState(0);
  const [Startdatesort, setStartdatesort] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [listofprojectidprocessed, setlistofprojectidprocessed] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).toISOString().slice(0, 10);
  };

  const handleDateChange = (selectedDate) => {
    if (
      selectedDate.getFullYear() &&
      selectedDate.getMonth() >= 0 &&
      selectedDate.getDate()
    ) {
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1; // Adding 1 since getMonth returns zero-based month index
      const lastDayOfMonth = new Date(year, month, 0).getDate();

      const newDateValue = `${year}-${month
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;

      setStartdatesort(newDateValue);
    }
  };

  function getLastDayOfPreviousMonth() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    // Calculate the previous month
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousYear = month === 0 ? year - 1 : year;

    // Get the last day of the previous month
    const lastDayOfPreviousMonth = new Date(previousYear, previousMonth + 1, 0);

    // Format the date as "YYYY-MM-DD"
    const formattedYear = lastDayOfPreviousMonth.getFullYear();
    const formattedMonth = (lastDayOfPreviousMonth.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const formattedDay = lastDayOfPreviousMonth
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  }

  const lastDayOfPreviousMonth = getLastDayOfPreviousMonth();
  console.log(lastDayOfPreviousMonth); // Output: "YYYY-MM-DD" for the last day of the previous month

  const endOfPreviousMonth = getLastDayOfPreviousMonth();
  console.log(endOfPreviousMonth);

  const getListOfProjectIds = () => {
    const Start =
      Startdatesort.slice(8, 10) +
      "/" +
      Startdatesort.slice(5, 7) +
      "/" +
      Startdatesort.slice(0, 4);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/getlistofelectricitybillwithrate`,
        {
          CURRENTMONTHENDDATE: Start,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofprojectid(response.data ? response.data : "");
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Project Idss:", error);
      });
  };

  useEffect(() => {
    getListOfProjectIds();
  }, [Startdatesort]);
  const getListOfProjectIdsProceed = () => {
    console.log(Startdatesort);
    const Start =
      Startdatesort.slice(8, 10) +
      "/" +
      Startdatesort.slice(5, 7) +
      "/" +
      Startdatesort.slice(0, 4);
    console.log(true);
    setisloading(true);
    axios
      .post(
        `${BASEURL}/gethistoricbilllist`,
        {
          CURRENTMONTHENDDATE: Start,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofprojectidprocessed(response.data ? response.data : "");
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of Project Idss:", error);
      });
  };
  useEffect(() => {
    getListOfProjectIdsProceed();
  }, [Startdatesort]);
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const uniqueIdentifier = `${item.CLIENTCODE}-${item.UNITNUMBER}-${item.SUBUNITNO}-${item.LEASESTARTDATE}`;
      const isAlreadySelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.uniqueIdentifier === uniqueIdentifier
      );

      if (isAlreadySelected) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.uniqueIdentifier !== uniqueIdentifier
        );
      } else {
        return [...prevSelectedItems, { ...item, uniqueIdentifier }];
      }
    });
  };
  console.log(selectedItems);
  function HandleRateEnteredValue(value, index) {
    setRateChangeValues((prevValues) => ({
      ...prevValues,
      [index]: value,
    }));
  }
  function HandleSave() {
    if (selectedItems.length === 0) {
      alert("Please select at least one item to save.");
      return;
    }
    const promises = selectedItems.map((item) => {
      const totalAmountCharged = (
        rateChangeValues
          ? Number(rateChangeValues[0])
          : ((item.METERREADING - item.PREVIOUSMONTHREADING) / item.TOTALDAYS) *
            item.DAYSCONSUMED *
            item.RATEPERUNIT
      ).toFixed(2);
      const totalunitconsumed = (
        ((item.METERREADING - item.PREVIOUSMONTHREADING) / item.TOTALDAYS) *
        item.DAYSCONSUMED
      ).toFixed(2);
      const Forperiod =
        endOfPreviousMonth.slice(8, 10) +
        "/" +
        endOfPreviousMonth.slice(5, 7) +
        "/" +
        endOfPreviousMonth.slice(0, 4);

      console.log({
        PROPERTYID: item.PROJECTID,
        CLIENTCODE: item.CLIENTCODE,
        CLIENTUNITID: item.UNITID,
        CLIENTSUBUNITID: item.SUBUNITID,
        FORPERIOD: Forperiod,
        TOTALDAYSCOUNTED: item.TOTALDAYS,
        TOTALUNITSCONSUMED: totalunitconsumed,
        TOTALRATEPERUNITCHARGED: item.RATEPERUNIT,
        TOTALAMOUNTCHARGED: totalAmountCharged,
      });

      return axios.post(
        `${BASEURL}/savebulkelectrictybill`,
        {
          PROPERTYID: item.PROJECTID,
          CLIENTCODE: item.CLIENTCODE,
          CLIENTUNITID: item.UNITID,
          CLIENTSUBUNITID: item.SUBUNITID,
          FORPERIOD: Forperiod,
          TOTALDAYSCOUNTED: item.TOTALDAYS,
          TOTALUNITSCONSUMED:
            totalunitconsumed == "infinity" ? 0 : Number(totalunitconsumed),
          TOTALRATEPERUNITCHARGED: item.RATEPERUNIT,
          TOTALAMOUNTCHARGED: totalAmountCharged,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
    });

    Promise.all(promises)
      .then((responses) => {
        alert("All items saved successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error posting selected item details:", error);
      });
  }

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const filteredData = listofprojectid.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "CLIENTCODE",
        "UNITNUMBER",
        "LEASESTARTDATE",
        "SUBUNITNO",
        "LEASEENDDATE",
        "DAYSCONSUMED",
        "METERREADING",
        "RATEPERUNIT",
        "TOTALDAYS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  const filteredDataprocedd = listofprojectidprocessed.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "CLIENTCODE",
        "UNITNUMBER",
        "LEASESTARTDATE",
        "SUBUNITNO",
        "LEASEENDDATE",
        "DAYSCONSUMED",
        "METERREADING",
        "RATEPERUNIT",
        "TOTALDAYS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    // Calculate the last day of the previous month
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    // Calculate the previous month
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousYear = month === 0 ? year - 1 : year;

    // Get the last day of the previous month
    const lastDayOfPreviousMonth = new Date(previousYear, previousMonth + 1, 0);

    // Format the date as "YYYY-MM-DD"
    const formattedYear = lastDayOfPreviousMonth.getFullYear();
    const formattedMonth = (lastDayOfPreviousMonth.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const formattedDay = lastDayOfPreviousMonth
      .getDate()
      .toString()
      .padStart(2, "0");
    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    // Set the value of the input field to the last day of the previous month
    setStartdatesort(formattedDate);
  }, []);

  // if (isloading) {
  //   return <Loading />;
  // }
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="elctric__page">
      <MenuNavbar />

      <header className="header__of__main">
        <h1 className="header__of__page">
          {menu ? menu : "Electricity Charge Calculation"}
        </h1>
      </header>

      <div className="lg:flex gap-2 items-center filter__no__search mx-2 lg:mx-10">
        <p>
          <label>Select the Date</label>
        </p>
        <div>
          <input
            required
            className=""
            type="date"
            value={Startdatesort}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value);
              handleDateChange(selectedDate);
            }}
          />
        </div>

        <div className="searchhhhhh">
          <p>Search:</p>
          <input
            className="input_1"
            required
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="search__icon">
            <FaSearch />
          </div>
        </div>
        <button
          style={{ backgroundColor: "#2f2f5a", color: "white" }}
          className="button__logout__navbar  text-white p-1 rounded "
          onClick={HandleSave}
        >
          SAVE
        </button>
        {/* <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div> */}
      </div>
      <article className="button_full_width">
        <div className="mx-8">
          <button
            className={activeTab === 1 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(1)}
          >
            Save Electricity Charges
          </button>
          <button
            className={activeTab === 2 ? "active__tab" : "button_style__tab"}
            onClick={() => handleTabClick(2)}
          >
            View Processed Electricity Charges
          </button>
        </div>
      </article>
      <div
        style={{
          display: activeTab === 1 ? "block" : "none",
        }}
        className={activeTab === 1 ? "active__section" : ""}
      >
        {isloading ? (
          <Loading />
        ) : (
          <div className="flex justify-center">
            <table className="table_1 " style={{ fontSize: "12px" }}>
              <thead>
                <tr style={{ textTransform: "uppercase" }}>
                  <td></td>
                  <td>PROJECT NAME</td>
                  <td>CLIENT CODE</td>
                  <td className="text-right">UNIT NUMBER</td>
                  <td>LEASE START DATE</td>
                  <td>SUB UNIT NUMBER</td>
                  <td>LEASE END DATE</td>
                  <td className="text-right">DAYS CONSUMED</td>
                  <td className="text-right">METER READINGS</td>

                  <td className="text-right">RATE PER UNIT</td>
                  <td className="text-right">PREVIOUS MONTH READING</td>

                  <td className="text-right">TOTAL DAYS</td>
                  <td className="text-right">
                    units consumed per tenent for month
                  </td>
                  <td>Rate charged</td>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index} style={{ textTransform: "uppercase" }}>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedItems.some(
                              (selectedItem) =>
                                selectedItem.uniqueIdentifier ===
                                `${item.CLIENTCODE}-${item.UNITNUMBER}-${item.SUBUNITNO}-${item.LEASESTARTDATE}`
                            )}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        </label>
                      </td>
                      <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                      </td>
                      <td className="text-right">
                        {item.UNITNUMBER ? item.UNITNUMBER : "NA"}
                      </td>
                      <td>
                        {item.LEASESTARTDATE
                          ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                          : "NA"}
                      </td>
                      <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                      <td>
                        {item.LEASEENDDATE
                          ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.DAYSCONSUMED ? item.DAYSCONSUMED : "0"}
                      </td>
                      <td className="text-right">
                        {item.METERREADING ? item.METERREADING : "0"}
                      </td>

                      <td className="text-right">
                        {item.RATEPERUNIT ? item.RATEPERUNIT : "0"}
                      </td>
                      <td className="text-right">
                        {item.PREVIOUSMONTHREADING
                          ? item.PREVIOUSMONTHREADING
                          : "0"}
                      </td>
                      <td className="text-right">
                        {item.TOTALDAYS ? item.TOTALDAYS : "0"}
                      </td>
                      <td className="text-right">
                        {((item.METERREADING - item.PREVIOUSMONTHREADING) /
                          item.TOTALDAYS) *
                          item.DAYSCONSUMED ===
                          Infinity ||
                        isNaN(
                          ((item.METERREADING - item.PREVIOUSMONTHREADING) /
                            item.TOTALDAYS) *
                            item.DAYSCONSUMED
                        )
                          ? 0
                          : (
                              ((item.METERREADING - item.PREVIOUSMONTHREADING) /
                                item.TOTALDAYS) *
                              item.DAYSCONSUMED
                            ).toFixed(2)}
                      </td>
                      <td className="text-right">
                        <input
                          type="number"
                          value={
                            rateChangeValues[index] !== undefined
                              ? rateChangeValues[index]
                              : (
                                  ((item.METERREADING -
                                    item.PREVIOUSMONTHREADING) /
                                    item.TOTALDAYS) *
                                  item.DAYSCONSUMED *
                                  item.RATEPERUNIT
                                ).toFixed(2)
                          }
                          onChange={(e) =>
                            HandleRateEnteredValue(e.target.value, index)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" style={{ textAlign: "center" }}>
                      No data for selected date
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        style={{
          display: activeTab === 2 ? "block" : "none",
        }}
        className={activeTab === 2 ? "active__section" : ""}
      >
        {isloading ? (
          <Loading />
        ) : (
          <div className="flex justify-center">
            <table className="table_1 " style={{ fontSize: "12px" }}>
              <thead>
                <tr style={{ textTransform: "uppercase" }}>
                  <td>PROJECT NAME</td>
                  <td>CLIENT CODE</td>
                  <td className="text-right">UNIT NUMBER</td>
                  <td>LEASE START DATE</td>
                  <td>SUB UNIT NUMBER</td>
                  <td>LEASE END DATE</td>
                  <td className="text-right">DAYS CONSUMED</td>
                  <td className="text-right">METER READINGS</td>
                  <td className="text-right">TOTAL AMOUNT CHARGED</td>
                  <td className="text-right">RATE PER UNIT</td>

                  <td className="text-right">TOTAL DAYS</td>
                </tr>
              </thead>
              <tbody>
                {filteredDataprocedd.length > 0 ? (
                  filteredDataprocedd.map((item, index) => (
                    <tr key={index} style={{ textTransform: "uppercase" }}>
                      <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                      <td style={{ textAlign: "right", paddingRight: "50px" }}>
                        {item.CLIENTCODE ? item.CLIENTCODE : "NA"}
                      </td>
                      <td className="text-right">
                        {item.UNITNUMBER ? item.UNITNUMBER : "NA"}
                      </td>
                      <td>
                        {item.LEASESTARTDATE
                          ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                          : "NA"}
                      </td>
                      <td>{item.SUBUNITNO ? item.SUBUNITNO : "NA"}</td>
                      <td>
                        {item.LEASEENDDATE
                          ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {item.DAYSCONSUMED ? item.DAYSCONSUMED : "NA"}
                      </td>
                      <td className="text-right">
                        {item.METERREADING ? item.METERREADING : "NA"}
                      </td>
                      <td
                        style={{
                          cursor: "pointer",

                          textAlign: "right",
                        }}
                      >
                        {(
                          item?.DAYSCONSUMED * item?.RATEPERUNIT
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">
                        {item.RATEPERUNIT ? item.RATEPERUNIT : "NA"}
                      </td>
                      <td className="text-right">
                        {item.TOTALDAYS ? item.TOTALDAYS : "NA"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" style={{ textAlign: "center" }}>
                      No data for selected date
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Electricity;
